import React from "react";

const ReorderQualityReport = () => {
    return(
        <>
        ReorderQualityReport
        </>
    )
}

export default ReorderQualityReport