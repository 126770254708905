import { useSelector } from "react-redux";
import store from "../store/store";
import { getBranchDetail, getPatientDetail, getCustomerDetail } from "../store/slice/CreateBill";
import CreateBillRepository from "../repository/CreateBillRepository";
import TemplateRepository from "repository/TemplateRepository";
import { getTemplateDetailsById } from "store/slice/Template";

export function useTemplateStore() {
    /**
     * Here is list of use selectors which are relates to template
     * Author: Devanshi Patel
     */
    const templateDetail = useSelector((state) => state.entities.Template.templateDetail);

    let data = {
        templateDetail
    };
    let fetch = {
        /**
         * Here is list of fetch methods which are used for get data from API or Repositories related to template
         * Author: Devanshi Patel
         * dispatch is used for reducer or thunk method
         * if redux not needed use repository method
         */

        getTemplateList: async (params) => {
            // console.log(params);
            return await new TemplateRepository().getTemplateList(params);
        },

        getTemplateDetailsById: async (params) => {
            return await store.dispatch(getTemplateDetailsById(params));
        },

    };
    let set = {
        /**
         * Here is list of fetch methods which are used for set or update data from API or Repositories related to template
         * Author: Devanshi Patel
         */
        createBillTemplate: async (params) => {
            return await new TemplateRepository().createBillTemplate(params);
        },

        updateBillTemplate: async (params) => {
            return await new TemplateRepository().updateBillTemplate(params);
        },
        updateTemplateActiveStatus: async (params) => {
            // console.log(params);
            return await new TemplateRepository().updateTemplateActiveStatus(params);
        },
    };
    return [data, fetch, set];
}
