import Common from "helpers/Common";
import React, { useEffect, useRef, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ChildLoader from "components/Loader/ChildLoader";
import { useActivityStore } from "hooks/Activity";
import RazorPayPayment from "views/payment/RazorPayPayment";
import { usePaymentStore } from "hooks/Payment";
import moment from "moment";
import { FreeBenefits } from "helpers/constantMessages";
import { SmallBusinessBenifits } from "helpers/constantMessages";
import { EnterpriseBenifits } from "helpers/constantMessages";
import PRightImg from "../../assets/images/right.svg";
import { useSubscriptionStore } from "hooks/Subscription";
import { isEmpty } from "underscore";
import { useLoginStore } from "hooks/Login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const Subscription = () => {
  const [
    {},
    { getCompanySubscription },
    {
      createCompanySubscription,
      updateCompanySubscription,
      createCustomPlanSubscription,
    },
  ] = useSubscriptionStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [currentSubscriptionDetail, setCurrentSubscriptionDetail] = useState(
    {}
  );
  const [selectedSubscriptionDetail, setSelectedSubscriptionDetail] = useState(
    {}
  );
  const [showModal, setShowModal] = useState(false);
  const [TCChecked, setTCChecked] = useState(false);
  const [showCustomPlanConirmModal, setshowCustomPlanConirmModal] =
    useState(false);
  const [isCustomPlanPayment, setisCustomPlanPayment] = useState(false);
  const [customPlanAmount, setcustomPlanAmount] = useState({
    amount: 1000,
    errorMessage: "", // To store error message for validation
  });
  const amountCustom = useRef(1000);
  const iconTrue = <FontAwesomeIcon icon={faCheck} className="icon-true" />;
  const iconFalse = <FontAwesomeIcon icon={faTimes} className="icon-false" />;

  const renderIcon = (value) => {
    if (value === "Yes") {
      return iconTrue;
    } else if (value === "No") {
      return iconFalse;
    } else {
      return null; // Return null for numeric values
    }
  };

  const toggle = () => {
    setShowModal(!showModal);
    if (showCustomPlanConirmModal) setshowCustomPlanConirmModal(false);
    setTCChecked(false);
    setcustomPlanAmount((prevState) => ({
      ...prevState,
      amount: 1000,
      errorMessage: "",
    }));
    amountCustom.current = 1000;
  };

  const toggleConfirmCustom = () => {
    setshowCustomPlanConirmModal(!showCustomPlanConirmModal);
    setTCChecked(false);
    setcustomPlanAmount((prevState) => ({
      ...prevState,
      amount: 1000,
      errorMessage: "",
    }));
    amountCustom.current = 1000;
  };

  useEffect(() => {
    const getCurrentSubscription = async () => {
      let res = await getCurrentSubscriptionDetail();

      if (res && isEmpty(res)) {
        await createCompanySubscription({
          subscriptionId: 1,
          totalAmount: 0,
          paidStatus: false,
          paidDate: moment().utc(),
          transactionId: Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS"),
          description: "subscription create",
        });
      }
    };
    getCurrentSubscription();
  }, []);

  const getCurrentSubscriptionDetail = async () => {
    let subscriptionData = await getCompanySubscription();
    setCurrentSubscriptionDetail(subscriptionData);
    setisCustomPlanPayment(false);
    return subscriptionData;
  };

  const manageUpgrade = (subscriptionData) => {
    setSelectedSubscriptionDetail(subscriptionData);
    // createCompanySubscription({
    //   "subscriptionId": subscriptionData.subscriptionId,
    //   "totalAmount": subscriptionData.amount,
    //   "paidStatus": false,
    //   "paidDate": moment().utc(),
    //   "transactionId": Common.getFormatedDate(moment(), 'YYYYMMDDHHmmssSSS'),
    //   "description": "subscription update with false status"
    // })
    toggle();
  };

  const manageRenewPayment = () => {
    createCompanySubscription({
      subscriptionId: currentSubscriptionDetail.subscriptionId,
      totalAmount: currentSubscriptionDetail.paidAmount_Subscrption,
      paidStatus: true,
      paidDate: Common.getFormatedDate(
        moment(currentSubscriptionDetail.endDate).add(1, "d"),
        "DD-MM-YYYY"
      ),
      transactionId: Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS"),
      description: "subscription renew with true status",
    });
    setAlerts({
      type: "success",
      message: "Your subscription renew successfully.",
    });
  };

  const manageSuccessPayment = async (isRenew = false) => {
    if (isRenew) {
      await manageRenewPayment();
      await getCurrentSubscriptionDetail();
    }
    if (isCustomPlanPayment) {
      await createCustomPlanSubscription({
        subscriptionId: selectedSubscriptionDetail.subscriptionId,
        rechargAmount: amountCustom.current,
        paidStatus: true,
        transactionId: Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS"),
        description: "subscription update with true status",
      });
      setAlerts({
        type: "success",
        message: "Your subscription renew successfully.",
      });
      await getCurrentSubscriptionDetail();
      toggle();
    } else {
      await createCompanySubscription({
        subscriptionId: selectedSubscriptionDetail.subscriptionId,
        totalAmount: selectedSubscriptionDetail.amount,
        paidStatus: true,
        paidDate: moment().utc(),
        transactionId: Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS"),
        description: "subscription update with true status",
      });
      setAlerts({
        type: "success",
        message: "Your subscription renew successfully.",
      });
      await getCurrentSubscriptionDetail();
      toggle();
    }
  };

  const values = [
    {
      feature: "Inventory count",
      freeTier: 100,
      economicalCustom: 500,
      smallBusiness: 50000,
      enterprise: 200000,
    },
    {
      feature: "Maximum Invoice/Bill count",
      freeTier: 100,
      economicalCustom: 500,
      smallBusiness: 50000,
      enterprise: 200000,
    },
    {
      feature: "SMS, Email and WhatsApp notification count",
      freeTier: 100,
      economicalCustom: 500,
      smallBusiness: 50000,
      enterprise: 200000,
    },
    {
      feature: "Inventory Management",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Customizable Template",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Historical Analytics",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Bill Template and Recent Product List",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Cloud Web and Mobile App (Android and iOS)",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Advanced Permissions",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Payment Reports and Payment Status",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Analytics",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Unlimited Storage for Product Images",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "Re-Order and Demand Prediction",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    {
      feature: "99.9% Uptime SLA",
      freeTier: "Yes",
      economicalCustom: "Yes",
      smallBusiness: "Yes",
      enterprise: "Yes",
    },
    // {
    //   feature: "Premium Support (Standard Hours)",
    //   freeTier: "No",
    //   economicalCustom: "No",
    //   smallBusiness: "No",
    //   enterprise: "Yes",
    // },
    // {
    //   feature: "Core EMR/EHR Functionality",
    //   freeTier: "No",
    //   economicalCustom: "No",
    //   smallBusiness: "No",
    //   enterprise: "Yes",
    // },
    // {
    //   feature: "Patient Scheduling & Billing",
    //   freeTier: "No",
    //   economicalCustom: "No",
    //   smallBusiness: "No",
    //   enterprise: "Yes",
    // },
    // // Add more rows as needed
  ];

  const handleAmountChange = (e) => {
    let amountT = e.target.value.trim(); // Trim whitespace from input

    // Validate input format: must be numeric
    if (!/^\d*$/.test(amountT)) {
      setcustomPlanAmount({
        ...customPlanAmount,
        amount: amountT === "" ? 1000 : parseInt(amountT) || 0, // Default to 1000 if empty or non-numeric
        errorMessage: "Amount must be numeric.",
      });
    } else {
      // Check if amount exceeds 6 digits
      if (amountT.length > 6) {
        amountT = amountT.slice(0, 6); // Truncate input to first 6 characters
      }
    }

    // Check if the input is empty or non-numeric
    if (isNaN(amountT) || e.target.value.trim() === "") {
      amountT = 1000; // Set default value if input is empty or non-numeric
    }

    if (amountT < 1000 || amountT > 100000) {
      setcustomPlanAmount({
        ...customPlanAmount,
        amount: amountT,
        errorMessage: "Amount must be between 1000 and 100000.",
      });
      setTCChecked(false);
      amountCustom.current = amountT;
    } else {
      setcustomPlanAmount({
        ...customPlanAmount,
        amount: amountT,
        errorMessage: "",
      });
      amountCustom.current = amountT;
    }
  };

  return (
    <>
      <div className="content-dashboard-report">
        <div className="d-flex justify-content-between">
          <h4 className="title">Subscription</h4>
        </div>

        <Card>
          <CardBody>
            <Row
              className="justify-content-center"
              style={{ paddingBottom: "20px" }}
            >
              <Col lg={4} md={6} sm={6} xs={6} className="text-center">
                <div
                  className="item_box"
                  key={currentSubscriptionDetail.subscriptionId}
                >
                  <div>
                    <b>
                      {currentSubscriptionDetail.subscriptionName} ₹{" "}
                      {currentSubscriptionDetail.paidAmount_Subscrption}
                    </b>
                  </div>
                  <div>
                    Subscription end date:{" "}
                    {Common.getFormatedDate(
                      currentSubscriptionDetail.endDate,
                      "DD-MM-YYYY"
                    )}
                  </div>
                  {currentSubscriptionDetail?.subscriptionId >= 2 && (
                    <RazorPayPayment
                      companyName={currentSubscriptionDetail?.companyName}
                      title="Renew Plan"
                      amount={currentSubscriptionDetail?.paidAmount_Subscrption}
                      manageSuccessPayment={() => manageSuccessPayment(true)}
                    />
                  )}
                </div>
              </Col>

              <Col lg={8} md={6} sm={6} xs={6} className="text-center">
                <p>
                  <b>Try the plan that’s best for your team</b>
                </p>
                <p>
                  Enjoy a free trial of Small business or Enterprice on us! If
                  you don’t upgrade after your trial,<br></br> your team will go
                  back to Free.
                </p>
              </Col>
            </Row>
            <div>
              <DataTable scrollable showGridlines value={values}>
                {/* Header Row for Features */}
                <Column
                  style={{ width: "250px" }}
                  header={
                    <div style={{ fontSize: "19px" }} className="header-cell">
                      Features
                    </div>
                  }
                  body={(rowData) => <div>{rowData.feature}</div>}
                />

                {/* Data Columns */}
                {/* Column for Free Tier */}
                <Column
                  style={{ width: "150px" }}
                  align="center"
                  header={
                    <div style={{ fontSize: "19px", textAlign: "center" }}>
                      {currentSubscriptionDetail?.subscriptionId == 1 && (
                        <button
                          style={{
                            alignItems: "center",
                            display: "flex",
                            height: "25px",
                          }}
                          type="button"
                          className="blink_text button_strat_get btn-success btn btn-secondary disabled"
                          disabled
                        >
                          Current Plan
                        </button>
                      )}
                      Free Tier <br />
                      <div
                        style={{
                          fontSize: "25px",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            position: "relative",
                            top: "-0.2em",
                            marginRight: "0.1em",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          ₹
                        </span>
                        <span>0/Year</span>
                      </div>
                      <br />
                    </div>
                  }
                  body={(rowData) => (
                    <div className="body-cell">
                      {typeof rowData.freeTier === "number"
                        ? rowData.freeTier
                        : renderIcon(rowData.freeTier)}
                    </div>
                  )}
                />

                {/* Column for Economical Custom */}
                <Column
                  align="center"
                  header={
                    <div style={{ fontSize: "19px" }} className="header-cell">
                      {/* {(currentSubscriptionDetail?.subscriptionId == 1 ||
                        currentSubscriptionDetail?.subscriptionId == 4) && ( */}
                      <button
                        style={{
                          alignItems: "center",
                          display: "flex",
                          height: "25px",
                        }}
                        className={
                          currentSubscriptionDetail?.subscriptionId === 4
                            ? "blink_text button_strat_get btn-success btn btn-secondary"
                            : "button_strat_get btn-success btn btn-secondary"
                        }
                        disabled={
                          currentSubscriptionDetail?.subscriptionId === 4
                            ? true
                            : false
                        }
                        onClick={() => {
                          setshowCustomPlanConirmModal(true);
                          setisCustomPlanPayment(true);
                        }}
                      >
                        {currentSubscriptionDetail?.subscriptionId === 4
                          ? "Current Plan"
                          : "Upgrade Plan"}
                      </button>
                      {/* )}  */}
                      Economical Custom Plan
                      <br />
                      <div
                        style={{
                          fontSize: "25px",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            position: "relative",
                            top: "-0.2em",
                            marginRight: "0.1em",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          ₹
                        </span>
                        <span>1000/5 Years</span>
                      </div>
                      <br />
                      <span
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          fontFamily: "Arial, sans-serif",
                          fontWeight: "550",
                        }}
                      >
                        Plan Price: Flexible
                      </span>
                    </div>
                  }
                  body={(rowData) => (
                    <div className="body-cell">
                      {typeof rowData.economicalCustom === "number"
                        ? rowData.economicalCustom
                        : renderIcon(rowData.economicalCustom)}
                    </div>
                  )}
                />
                {/* Column for Small Business */}
                <Column
                  align="center"
                  header={
                    <div style={{ fontSize: "19px" }} className="header-cell">
                      {/* {currentSubscriptionDetail?.subscriptionId == 2 && ( */}
                      <button
                        style={{
                          alignItems: "center",
                          display: "flex",
                          height: "25px",
                        }}
                        className={
                          currentSubscriptionDetail?.subscriptionId == 2
                            ? "blink_text button_strat_get btn-success btn btn-secondary disabled"
                            : "button_strat_get btn-success btn btn-secondary"
                        }
                        disabled={
                          currentSubscriptionDetail?.subscriptionId == 2
                            ? true
                            : false
                        }
                        onClick={() =>
                          manageUpgrade({
                            subscriptionId: 2,
                            subscriptionName: "Small Business",
                            amount: 7999 + Math.round((7999 * 18) / 100),
                          })
                        }
                      >
                        {currentSubscriptionDetail?.subscriptionId == 2
                          ? "Current Plan"
                          : "Upgrade Plan"}
                      </button>
                      {/* )} */}
                      Small Business <br />
                      <div
                        style={{
                          fontSize: "25px",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            position: "relative",
                            top: "-0.2em",
                            marginRight: "0.1em",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          ₹
                        </span>
                        <span>9439/Year</span>
                      </div>
                      <br />
                      <span
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          fontFamily: "Arial, sans-serif",
                          fontWeight: "550",
                        }}
                      >
                        Plan Price: ₹7999 GST (18%): ₹1440
                      </span>
                    </div>
                  }
                  body={(rowData) => (
                    <div className="body-cell">
                      {typeof rowData.smallBusiness === "number"
                        ? rowData.smallBusiness
                        : renderIcon(rowData.smallBusiness)}
                    </div>
                  )}
                />

                {/* Column for Enterprise */}

                <Column
                  align="center"
                  header={
                    <div style={{ fontSize: "19px" }} className="header-cell">
                      {/* {currentSubscriptionDetail?.subscriptionId == 3 && ( */}
                      <button
                        style={{
                          alignItems: "center",
                          display: "flex",
                          height: "25px",
                        }}
                        className={
                          currentSubscriptionDetail?.subscriptionId === 3
                            ? "blink_text button_strat_get btn-success btn btn-secondary disabled"
                            : "button_strat_get btn-success btn btn-secondary"
                        }
                        disabled={
                          currentSubscriptionDetail?.subscriptionId === 3
                            ? true
                            : false
                        }
                        onClick={() =>
                          manageUpgrade({
                            subscriptionId: 3,
                            subscriptionName: "Enterprise",
                            amount: 14999 + Math.round((14999 * 18) / 100),
                          })
                        }
                      >
                        {currentSubscriptionDetail?.subscriptionId === 3
                          ? "Current Plan"
                          : "Upgrade Plan"}
                      </button>
                      {/* )} */}
                      Enterprise <br />
                      <div
                        style={{
                          fontSize: "25px",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            position: "relative",
                            top: "-0.2em",
                            marginRight: "0.1em",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          ₹
                        </span>
                        <span>17699/Year</span>
                      </div>
                      <br />
                      <span
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          fontFamily: "Arial, sans-serif",
                          fontWeight: "550",
                        }}
                      >
                        Plan Price: ₹14999 GST (18%): ₹2700
                      </span>
                    </div>
                  }
                  body={(rowData) => (
                    <div className="body-cell">
                      {typeof rowData.enterprise === "number"
                        ? rowData.enterprise
                        : renderIcon(rowData.enterprise)}
                    </div>
                  )}
                />
              </DataTable>
            </div>
            <Modal isOpen={showModal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Upgrade Plan</ModalHeader>
              <ModalBody>
                <p>
                  Current Subscription:{" "}
                  <b>{currentSubscriptionDetail?.subscriptionName}</b>
                </p>
                <p>
                  New Subscription:{" "}
                  <b>{selectedSubscriptionDetail?.subscriptionName}</b>
                </p>

                {selectedSubscriptionDetail.subscriptionName ==
                "Economical Custom Plan" ? (
                  <div style={{ paddingBottom: "10px" }}>
                    <p style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: "10px" }}>
                        Final Amount * :
                      </span>
                      <FormGroup
                        style={{ marginRight: "10px", marginBottom: "0" }}
                      >
                        <Input
                          placeholder="Final Amount"
                          type="text"
                          name="finalAmount"
                          value={customPlanAmount.amount}
                          onChange={handleAmountChange}
                        />
                      </FormGroup>
                    </p>
                    {customPlanAmount.errorMessage && (
                      <p style={{ color: "red", fontSize: "18px" }}>
                        {customPlanAmount.errorMessage}
                      </p>
                    )}
                    <span style={{ fontSize: "11px", paddingBottom: "10px" }}>
                      The default amount is 1000, you can recharge with a
                      maximum amount of 1,00,000.
                    </span>
                  </div>
                ) : (
                  <p>
                    Final Amount: <b>{selectedSubscriptionDetail?.amount}</b>
                  </p>
                )}
                <div>
                  <label>
                    <input
                      disabled={
                        isCustomPlanPayment
                          ? customPlanAmount.errorMessage.length > 0
                            ? true
                            : false
                          : false
                      }
                      type="checkbox"
                      onChange={(e) => setTCChecked(e.target.checked)}
                    />{" "}
                    I agree to the{" "}
                    <a href="/auth/terms" target="_blank">
                      Terms and Conditions
                    </a>
                  </label>
                </div>

                <RazorPayPayment
                  companyName={currentSubscriptionDetail?.companyName}
                  title="Pay Now"
                  disabled={TCChecked ? false : true}
                  amount={
                    selectedSubscriptionDetail.subscriptionName ==
                    "Economical Custom Plan"
                      ? amountCustom
                      : selectedSubscriptionDetail?.amount
                  }
                  isCustomPlanPayment={
                    selectedSubscriptionDetail.subscriptionName ==
                    "Economical Custom Plan"
                      ? true
                      : false
                  }
                  manageSuccessPayment={() => manageSuccessPayment()}
                />
              </ModalBody>
            </Modal>
            <div>
              <Modal
                isOpen={showCustomPlanConirmModal}
                toggle={toggleConfirmCustom}
                centered={true}
              >
                <ModalBody>
                  You are currently on a different plan, Do you want to continue
                  to upgrade?
                </ModalBody>
                <ModalFooter style={{ justifyContent: "center" }}>
                  <Button
                    color="primary"
                    onClick={() => {
                      manageUpgrade({
                        subscriptionId: 4,
                        subscriptionName: "Economical Custom Plan",
                        amount: amountCustom.current,
                      });
                      // setcustomPlanAmount((prevState) => ({
                      //   ...prevState,
                      //   amount: 1000,
                      // }));
                    }}
                  >
                    Continue
                  </Button>{" "}
                  <Button color="secondary" onClick={toggleConfirmCustom}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Subscription;
