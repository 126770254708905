import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/DownloadReport.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const DownloadReport = ({ reportData, headers, headersPDF }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [popoverRef]);

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(reportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    XLSX.writeFile(workbook, "report.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF();

    if (!headersPDF) {
      doc.autoTable({
        head: [headers.map((header) => header.label)],
        body: reportData.map((row) => headers.map((header) => row[header.key])),
      });
      doc.save("report.pdf");
    } else {
      doc.autoTable({
        head: [headersPDF.map((header) => header.label)],
        body: reportData.map((row) =>
          headersPDF.map((header) => row[header.key])
        ),
      });
      doc.save("report.pdf");
    }
  };

  return (
    <>
      <div className="popover-container">
        <button
          id="UncontrolledPopover"
          type="button"
          className="download-button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon icon="fa-solid fa-download" className="large-icon" />
        </button>
        {isOpen && (
          <div className="popup" ref={popoverRef}>
            <div className="popover-header">Download as</div>
            <div className="popover-body">
              <p className="popover-list" onClick={downloadExcel}>
                Excel
              </p>
              <p className="popover-list">
                <CSVLink
                  data={reportData}
                  headers={headers}
                  filename="report.csv"
                  className="popover-list-csv"
                >
                  CSV
                </CSVLink>
              </p>
              <p className="popover-list" onClick={downloadPDF}>
                PDF
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DownloadReport;
