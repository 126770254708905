import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Common from "helpers/Common";
import { useLoginStore } from "hooks/Login";
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  Container,
  Col,
  Row,
  Spinner, FormGroup, InputGroupText, InputGroup, Nav, NavItem
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NavLink, useNavigate } from "react-router-dom";


const Registration = () => {
  const navigate = useNavigate();
  const isLogin = Common.getJwtToken();

  useEffect(() => {
    checkIfAlreadyLoggedIn();
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const checkIfAlreadyLoggedIn = () => {
    if (isLogin) {
      navigate(ADMIN_URL_DASHBOARD);
    }
  }

  return (
    <div className="login-page">
      <div class="full-width">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="form-center">
              <Form className="form">
                <Card className="card-login">
                  <CardHeader>
                    <h4 className="header text-center mt-0">Sign Up</h4>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className="has-label">
                      <label>Organization Name *</label>
                      <Input
                        placeholder="Enter Organization Name"
                        type="text"
                        maxLength={100}
                      />
                    </FormGroup>
                    <FormGroup className="has-label">
                      <label>Employee Code *</label>
                      <Input
                        placeholder="Enter Employee Code"
                        type="text"
                        maxLength={100}
                      />
                    </FormGroup>
                    <FormGroup className="has-label">
                      <label>First Name *</label>
                      <Input
                        placeholder="Enter First Name"
                        type="text"
                        maxLength={100}
                      />
                    </FormGroup>
                    <FormGroup className="has-label">
                      <label>Last Name *</label>
                      <Input
                        placeholder="Enter Last Name"
                        type="text"
                        maxLength={100}
                      />
                    </FormGroup>
                    <FormGroup className="has-label">
                      <label>Designation *</label>
                      <Input
                        placeholder="Enter Designation"
                        type="text"
                        maxLength={100}
                      />
                    </FormGroup>
                    <div className="category form-category">
                      * Required fields
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-1 btn-custom"
                      color="warning"
                      type="submit"
                    >
                      Sign Up
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      <div className="full-page-background" />
    </div>
  );
}


export default Registration;