import APIHandler from "helpers/APIHandler";
import { HISTORICAL_DEMAND_REPORT_API } from "helpers/APIRouter";
import { WEEKLY_DEMAND_FORECAST_REPORT_API } from "helpers/APIRouter";
import { DAILY_DEMAND_FORECAST_REPORT_API } from "helpers/APIRouter";
import { MINIMUM_STOCK_USAGE_REPORT_API } from "helpers/APIRouter";
import { MAXIMUM_STOCK_USAGE_REPORT_API, REVENUE_REPORT_API } from "helpers/APIRouter";

const API = new APIHandler();

class ReportAPICall {

    getMaximumStockUsageReport = async (params = {}) => {
        let res = {}
        let param = {};
        param.params = params
        await API
            .get(MAXIMUM_STOCK_USAGE_REPORT_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };

    getMinimumStockUsageReport = async (params = {}) => {
        let res = {}
        let param = {};
        param.params = params
        await API
            .get(MINIMUM_STOCK_USAGE_REPORT_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };

    getRevenueReport = async (params = {}) => {
        let res = {}
        let param = {};
        param.params = params
        await API
            .get(REVENUE_REPORT_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };

    getDailyDemandForecastData = async (params = {}) => {
        let res = {}
        let param = {};
        param.params = params
        await API
            .get(DAILY_DEMAND_FORECAST_REPORT_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };

    getWeeklyDemandForecastData = async (params = {}) => {
        let res = {}
        let param = {};
        param.params = params
        await API
            .get(WEEKLY_DEMAND_FORECAST_REPORT_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };

    getHistoricalDemandData = async (params = {}) => {
        let res = {}
        let param = {};
        param.params = params
        await API
            .get(HISTORICAL_DEMAND_REPORT_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };
}

export default ReportAPICall;