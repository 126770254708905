import {
  Row,
  Col,
  ListGroupItem,
  Modal,
  ModalBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { useState } from "react";
import { usePaymentStore } from "hooks/Payment";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import { AiOutlineClose } from "react-icons/ai";
import CustomButton from "components/Button/CustomButton";

const CreditDebitCardModal = (props) => {
  const {
    payDetails,
    creditDebitCardModal,
    setCreditDebitCardModal,
    paymentModeSelect,
    transactionServiceSelect,
    reset,
    setLoader,
    partialPayAmount,
    partialPay,
    customerID,
    setSelectedBills,
    getBillDetails,
    selectedBills
  } = props;
  const [Description, setDescription] = useState("");
  const [{ }, { }, { savePayment, saveAdvancePayment }] = usePaymentStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();

  const toggle = () => setCreditDebitCardModal(!creditDebitCardModal);

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    const transactionAmount = payDetails?.map((x) => x.netProductPayment);
    let totalTransactionAmount = 0;
    transactionAmount?.forEach((el) => (totalTransactionAmount += el));

    let transactionTax = payDetails?.map((x) => x.applicableTax);
    let totalTransactionTax = 0;
    transactionTax?.forEach((el) => (totalTransactionTax += el));

    let amount = payDetails?.map((x) => x.netPayment);
    let totalAmount = 0;
    amount?.forEach((el) => (totalAmount += el));
    let invocies = payDetails.map((item) => {
      return {
        billID: item.billId,
        grandTotal: parseFloat(item.netPayment.toFixed(2)),
        referenceNumber: "",
        isSuccess: true,
      };
    });
    let param = {
      transactionServiceID: transactionServiceSelect?.value,
      customerId: partialPay ? customerID : payDetails[0]?.customerId,
      transactionAmount: parseFloat(totalTransactionAmount?.toFixed(2)),
      transactionTax: parseFloat(totalTransactionTax?.toFixed(2)),
      transactionTotalAmount: partialPay
        ? parseFloat(partialPayAmount)
        : parseFloat(totalAmount?.toFixed(2)),
      referenceNumber: "", // kept temporary staic ref numbernder development of payment
      PaymentTypeID: paymentModeSelect?.value,
      paymentStatus: true,
      description: Description,
      invoice_Payments: invocies,
    };
    let res = await savePayment(param);
    if (res) {
      await setAlerts({
        type: "success",
        message: notificationMessages.paymentSuccess,
      });
      setCreditDebitCardModal(!creditDebitCardModal);
      // setSelectedBills("");
      getBillDetails(selectedBills);
    }
    reset();
    setLoader(false);
  };

  const handleAdvancePaymentSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let param = {
      transactionServiceID: transactionServiceSelect?.value,
      customerId: customerID,
      TransactionTotalAmount: parseFloat(partialPayAmount),
      paymentTypeID: paymentModeSelect?.value,
      description: Description,
    };
    let res = await saveAdvancePayment(param);
    if (res) {
      await setAlerts({
        type: "success",
        message: notificationMessages.paymentSuccess,
      });
      setCreditDebitCardModal(!creditDebitCardModal);
      // setSelectedBills("");
      getBillDetails(selectedBills);
    }
    reset();
    setLoader(false);
  };

  return (
    <>
      <Modal
        isOpen={creditDebitCardModal}
        toggle={toggle}
        centered={true}
        fullscreen={"md"}
        size="lg"
      >
        {/* <ListGroupItem>
          <Row xs="2">
            <Col
              xs="10"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <h4 toggle={toggle} style={{}}>
                Credit/Debit Card Payment
              </h4>
            </Col>
            <Col
              xs="2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                width: "100%",
              }}
            >
              <AiOutlineClose onClick={toggle} style={{ fontWeight: "bold" }} />
            </Col>
          </Row>
        </ListGroupItem> */}
        <ModalBody>
          <div className="payment-modal-header">
            <h5>Credit/Debit Card Payment</h5>
            <AiOutlineClose onClick={toggle} size={20} className="payment-modal-close-btn" />
          </div>

          <Form
            onSubmit={(e) => {
              if (transactionServiceSelect?.value == 3) {
                handleAdvancePaymentSubmit(e);
              } else {
                handlePaymentSubmit(e);
              }
            }}
          >
            <Row className="g-3">
              <Col md={6}>
                <FormGroup className="has-label customer-input-container">
                  <Label for="doctor">Transaction Reference ID</Label>
                  <Input
                    placeholder="Transaction Reference ID"
                    type="input"
                    value=""
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="has-label customer-input-container">
                  <Label for="doctor">Card Number (last 4 digits)</Label>
                  <Input placeholder="Card Number" type="input" value="" />
                </FormGroup>
              </Col>
            </Row>
            <Row className="g-3">
              <Col md={6}>
                <FormGroup className="has-label customer-input-container">
                  <Label for="doctor">Card holder/Account Name</Label>
                  <Input
                    placeholder="Card holder/Account Name"
                    type="input"
                    value=""
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="has-label customer-input-container">
                  <Label for="doctor">Mobile Number</Label>
                  <Input placeholder="Mobile Number" type="input" value="" />
                </FormGroup>
              </Col>
            </Row>

            <div className="w-100 d-flex align-items-center justify-content-end">
              <CustomButton type="submit" label="Make Payment" />
            </div>

            {/* <Row form>
              <Col className="text-right">
                <Button className="btn btn-primary" type="submit">
                  Make Payment
                </Button>
              </Col>
            </Row> */}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreditDebitCardModal;
