import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LoginRepository from "repository/LoginRepository";

export const companyLogin = createAsyncThunk('login_companyLogin', async (params) => {
    return await new LoginRepository().companyLogin(params);
});

export const loginVerifyOTP = createAsyncThunk('login_loginVerifyOTP', async (params) => {
    return await new LoginRepository().loginVerifyOTP(params);
});


const initialState = {
    companyDetail: {},
    alertList: []
};
const slice = createSlice({
    name: "login",
    initialState: initialState,
    reducers: {
        logout: {
            reducer: (state, action) => {
                state.companyDetail = {}
                localStorage.removeItem('jwtToken')
                localStorage.removeItem('commonKey')
                localStorage.removeItem('loginId')
                localStorage.removeItem('companyId')
                localStorage.removeItem('companyName')
                localStorage.removeItem('companyAddress')
                localStorage.removeItem('companyPhone')
                localStorage.removeItem('companyEmail')
                localStorage.removeItem('userId')
                localStorage.removeItem('roleId')
                localStorage.removeItem('companyProfile')
            },
        },

        setAlerts: {
            reducer: (state, action) => {
                state.alertList = [action.payload]
            },
        },

        resetAlerts: {
            reducer: (state, action) => {
                state.alertList = []
            }
        }
    },
    extraReducers: {
        [companyLogin.fulfilled]: (state, { payload }) => {
            if (payload?.token) {
                localStorage.setItem('jwtToken', payload.token)
                localStorage.setItem('loginId', payload.loginId)
                localStorage.setItem('companyId', payload.companyId)
                localStorage.setItem('userId', payload.companyUserID)
                localStorage.setItem('gstin', payload.gstin)
                localStorage.setItem('companyName', payload.companyName)
                localStorage.setItem('companyAddress', payload.companyAddress)
                localStorage.setItem('companyPhone', payload.companyPhone)
                localStorage.setItem('companyEmail', payload.companyEmail)
                localStorage.setItem('roleId', payload.role_id)
                localStorage.setItem('companyProfile', JSON.stringify(payload.companyProfile))
                state.companyDetail = payload;
            }

            return state;
        },
        [companyLogin.rejected]: (state, { payload }) => {
        },
        [loginVerifyOTP.fulfilled]: (state, { payload }) => {
            if (payload?.companyLoginResponse?.token) {
                localStorage.setItem('jwtToken', payload.companyLoginResponse.token)
                localStorage.setItem('loginId', payload.companyLoginResponse.loginId)
                localStorage.setItem('companyId', payload.companyLoginResponse.companyId)
                localStorage.setItem('userId', payload.companyLoginResponse.companyUserID)
                localStorage.setItem('gstin', payload.companyLoginResponse.gstin)
                localStorage.setItem('companyName', payload.companyLoginResponse.companyName)
                localStorage.setItem('companyAddress', payload.companyLoginResponse.companyAddress)
                localStorage.setItem('companyPhone', payload.companyLoginResponse.companyPhone)
                localStorage.setItem('companyEmail', payload.companyLoginResponse.companyEmail)
                localStorage.setItem('roleId', payload.role_id)
                localStorage.setItem('companyProfile', JSON.stringify(payload.companyProfile))
                state.companyDetail = payload.companyLoginResponse;
            }

            return state;
        },
        [loginVerifyOTP.rejected]: (state, { payload }) => {
        }
    },
});

export let { logout, setAlerts, resetAlerts } = slice.actions;

export default slice.reducer;
