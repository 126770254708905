import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Container,
  Input,
  Button,
} from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "../../assets/styles/Payment.css";
import PageLoader from "components/Loader/PageLoader";
import ChildLoader from "components/Loader/ChildLoader";
import autoTable from "jspdf-autotable";
import {
  RiFilePdfFill,
  RiFileExcelFill,
  RiFileExcel2Fill,
} from "react-icons/ri";
import { usePaymentStore } from "hooks/Payment";
import CustomButton from "components/Button/CustomButton";
import { BsFileEarmarkPdfFill } from "react-icons/bs";

const AccountPayable = () => {
  const [{}, { getAccountpayableDetails }, {}] = usePaymentStore();
  const [accountPayData, setAccountPayData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loader, setLoader] = useState(false);
  const dt = useRef(null);
  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState(1);

  const loadMoreData = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
    setLoadingMore(true);
    getAccountPayable(pageNumber + 1);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const parseISODate = (isoString) => {
    const date = new Date(isoString);
    return isNaN(date.getTime())
      ? "Invalid Date"
      : date.toLocaleString("en-US", { dateStyle: "long", timeStyle: "short" });
  };

  const formatNull = (value) => (value === null ? "N/A" : value);

  const getAccountPayable = async (page = 1) => {
    setLoader(true);
    if (page === 1) {
      setPageNumber(1);
    }

    const today = new Date();

    let param = {
      amount: amount === 0 ? null : amount,
      from_date: fromDate || "2024-03-10",
      to_date: toDate || formatDate(today),
      pagesize: pageSize,
      pagenumber: page,
    };

    let Data = await getAccountpayableDetails(param);

    const transformedData = Data.map((jsonData) => {
      return {
        ...jsonData,
        createdDate: parseISODate(jsonData.createdDate),
        paymentStatus: jsonData.paymentStatus === "True" ? "Paid" : "Pending",
        transactionServiceType: formatNull(jsonData.transactionServiceType),
        vendorInformation: formatNull(jsonData.vendorInformation),
        invoiceNumber: formatNull(jsonData.invoiceNumber),
        productName: formatNull(jsonData.productName),
        quantity: jsonData.quantity > 0 ? jsonData.quantity : "0.00",
        totalTax:
          jsonData.totalTax > 0 ? `${jsonData.totalTax.toFixed(2)}` : "0.00",
        totalDiscount:
          jsonData.totalDiscount > 0
            ? `${jsonData.totalDiscount.toFixed(2)}`
            : "0.00",
        totalNetPrice:
          jsonData.totalNetPrice > 0
            ? `${jsonData.totalNetPrice.toFixed(2)}`
            : "0.00",
        totalNetProductCost:
          jsonData.totalNetProductCost > 0
            ? `${jsonData.totalNetProductCost.toFixed(2)}`
            : "0.00",
        amountDue:
          jsonData.amountDue > 0 ? `${jsonData.amountDue.toFixed(2)}` : "0.00",
        transactionAmount: `${jsonData.transactionAmount.toFixed(2)}`,
        transactionTax: `${jsonData.transactionTax.toFixed(2)}`,
        transactionTotalAmount: `${jsonData.transactionTotalAmount.toFixed(2)}`,
      };
    });

    if (page > 1) {
      setAccountPayData([...accountPayData, ...transformedData]);
    } else {
      setAccountPayData(transformedData);
    }

    setLoadingMore(false);
    setLoader(false);
  };

  const preventNegativeValues = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const exportToPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: "A4",
    });

    doc.setFontSize(12);
    doc.text("Account Payable Report", 40, 40);

    autoTable(doc, {
      startY: 60,
      head: [
        [
          "Service Name",
          "Payment ID",
          "Payment Status",
          "Date Created",
          "Amount",
          "Tax",
          "Total Amount",
          "Service Type",
          "Vendor Information",
          "Invoice Number",
          "Product Name",
          "Quantity",
          "Total Tax",
          "Total Discount",
          "Net Price",
          "Net Product Cost",
          "Amount Due",
        ],
      ],
      body: accountPayData.map((data) => [
        data.transactionServiceName,
        data.transactionPaymentID,
        data.paymentStatus,
        data.createdDate,
        data.transactionAmount,
        data.transactionTax,
        data.transactionTotalAmount,
        data.transactionServiceType,
        data.vendorInformation,
        data.invoiceNumber,
        data.productName,
        data.quantity,
        data.totalTax,
        data.totalDiscount,
        data.totalNetPrice,
        data.totalNetProductCost,
        data.amountDue,
      ]),
      theme: "grid",
      styles: {
        cellPadding: 5,
        fontSize: 8,
        halign: "center",
      },
      headStyles: {
        fillColor: [52, 152, 219],
        textColor: [255, 255, 255],
      },
      footStyles: {
        fillColor: [52, 152, 219],
      },
    });

    doc.save("account_payable_report.pdf");
  };

  const exportToExcel = () => {
    const worksheetData = [
      [
        "Transaction Service Type",
        "Transaction Service Name",
        "Transaction ID",
        "Vendor Information",
        "Invoice Number",
        "Product Name",
        "Quantity",
        "Total Tax",
        "Total Discount",
        "Total Net Price",
        "Total Net Product Cost",
        "Amount Due",
      ],
      ...accountPayData.map((data) => [
        data.transactionServiceType,
        data.transactionServiceName,
        data.transactionPaymentID,
        data.vendorInformation,
        data.invoiceNumber,
        data.productName,
        data.quantity,
        data.totalTax,
        data.totalDiscount,
        data.totalNetPrice,
        data.totalNetProductCost,
        data.amountDue,
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Account Payable Report");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(data, "account_payable_report.xlsx");
  };

  useEffect(() => {
    setLoader(true);
    getAccountPayable();
  }, []);

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="content-dashboard-payment">
          <div className="title-route-container">
            <h4 className="title">Payment / Account Payable</h4>
          </div>

          <Card className="payment-data-card">
            <CardBody>
              <div className="payment-card-title-container">
                <h5>Account Payable</h5>

                <div className="payment-header-btn-container">
                  <button onClick={exportToExcel} className="export-xls-btn">
                    Export XLS <RiFileExcel2Fill size={20} />
                  </button>
                  <button onClick={exportToPDF} className="export-pdf-btn">
                    Export PDF <BsFileEarmarkPdfFill size={20} />
                  </button>
                </div>
              </div>
              <Row xs="2" className="mb-3">
                <Col xs={12} md={3} lg={3}>
                  <FormGroup className="customer-input-container">
                    <Label style={{ display: "flex" }}>Amount</Label>
                    <Input
                      type="number"
                      min="0"
                      maxLength={10}
                      placeholder="Enter Amount"
                      onChange={(e) => setAmount(e.target.value)}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <FormGroup className="customer-input-container">
                    <Label style={{ display: "flex" }}>From Date</Label>
                    <Input
                      id="fromDate"
                      name="date"
                      placeholder="From Date"
                      type="date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <FormGroup className="customer-input-container">
                    <Label style={{ display: "flex" }}>To Date</Label>
                    <Input
                      id="toDate"
                      name="date"
                      placeholder="To Date"
                      type="date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col
                  xs={12}
                  md={3}
                  lg={3}
                  className="d-flex justify-content-start align-items-end"
                  style={{ marginBottom: "10px" }}
                >
                  <CustomButton
                    label="Search"
                    onClick={() => getAccountPayable()}
                  />
                </Col>
              </Row>
              {loader ? (
                <PageLoader />
              ) : (
                <Row>
                  <Col xs={12} md={12} lg={12} className="p-0">
                    <DataTable
                      tableStyle={{ minWidth: "25rem" }}
                      size="large"
                      resizableColumns
                      columnResizeMode="expand"
                      paginator
                      rowsPerPageOptions={[5, 10, 15]}
                      rows={5}
                      pt={{
                        root: { className: "table" },
                      }}
                      value={accountPayData}
                      stripedRows
                      scrollable
                      scrollHeight="500px"
                      ref={dt}
                    >
                      <Column
                        field="transactionPaymentID"
                        header="Payment ID"
                        align="center"
                      />
                      <Column
                        field="transactionServiceName"
                        header="Service Name"
                        align="center"
                      />
                      <Column
                        field="paymentStatus"
                        header="Status"
                        align="center"
                      />
                      <Column
                        field="customerName"
                        header="Contact Person"
                        align="center"
                      />
                      <Column
                        field="invoiceNumber"
                        header="Invoice Number"
                        align="center"
                      />
                      <Column
                        field="transactionTotalAmount"
                        header="Total Amount"
                        align="center"
                        bodyStyle={{ fontWeight: "600", color: "#000000" }}
                      />
                      <Column field="createdDate" header="Date" align="left" />
                    </DataTable>
                  </Col>
                </Row>
              )}
              {loadingMore && <ChildLoader />}
            </CardBody>
          </Card>
        </div>
      )}{" "}
    </>
  );
};

export default AccountPayable;
