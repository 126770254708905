import { useSelector } from "react-redux";
import store from "../store/store";
import { getBranchDetail, getPatientDetail } from "../store/slice/CreateBill";
import CreateDashboardRepository from "repository/DashboardRepository";
import ProductQytRepository from "repository/ProductQytRepository";

export function useDashboardDataStore() {
     
    let fetch = {
        getDashboardData: async (params) => {
            return await new CreateDashboardRepository().getDashboardData(params);
        },
    };

    let fetchCompany = {
        getCompanyData: async (params) => {
            return await new CreateDashboardRepository().getCompanyData(params);
        },
    }; 
     
    return [fetch,fetchCompany];
}
