import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
  Row,
  Col,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useInventoryStore } from "hooks/Inventory";
import { useLoginStore } from "hooks/Login";
import { isEmpty, isObject } from "underscore";
import { notificationMessages } from "helpers/constantMessages";
import Common from "helpers/Common";
import { TabView, TabPanel } from "primereact/tabview";
import { ADMIN_URL_DASHBOARD } from "helpers/Paths";
import PageLoader from "components/Loader/PageLoader";
import Placeholder from "../../assets/images/placeholder.jpeg";
import { useAzureStore } from "hooks/Azure";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { AutoComplete } from "primereact/autocomplete";
import { useCreateBillStore } from "hooks/CreateBill";
import CustomButton from "components/Button/CustomButton";

const DiscountByProduct = () => {
  const [loader, setLoader] = useState(false);
  const [{ }, { getProductSearchList }, { }] = useCreateBillStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();
  const [
    { },
    { getDiscountDetailsByProductId },
    { createModifyDiscountByProduct },
  ] = useInventoryStore();
  const [inventoryValidate, setInventoryValidate] = useState({});
  const [isTax1Percentage, setIsTax1Percentage] = useState(true);
  const [isTax2Percentage, setIsTax2Percentage] = useState(true);
  const [tax1, setTax1] = useState(0);
  const [tax2, setTax2] = useState(0);
  const [tax1Description, setTax1Description] = useState("");
  const [tax2Description, setTax2Description] = useState("");
  const [discountTypeSelect, setDiscountTypeSelect] = useState(null);
  const [discValue, setDiscValue] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [description, setDescription] = useState("");

  const [filteredProduct, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const debounceTimer = useRef(null);

  const ref = useRef();

  const filterProduct = (event) => {
    const query = event.query;

    // Clear the existing timer if any
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer
    debounceTimer.current = setTimeout(() => {
      retriveProducts(query);
    }, 1000); // 1s delay
  };

  const retriveProducts = async (queryText) => {
    if (queryText.length >= 2) {
      // Check if at least 2 characters are entered
      let productList = await getProductSearchList(queryText);
      if (productList && !isEmpty(productList)) {
        setFilteredProducts(productList);
      } else {
        setFilteredProducts([
          {
            productName: (
              <span style={{ cursor: "none", padding: "70px" }}>
                no result found
              </span>
            ),
          },
        ]);
      }
    } else {
      setTimeout(() => {
        setAlerts({
          type: "danger",
          message: "Minimum 2 characters are needed for product search",
        });
      }, 2000);
    }
  };

  function getProductDiscountDetails(e) {
    async function getProductQytDataFunction() {
      let param = {
        productId: e?.productId,
      };
      let discountDetails = await getDiscountDetailsByProductId(param);
      if (discountDetails && !isEmpty(discountDetails).length > 0) {
        setIsTax1Percentage(discountDetails.isTax1Percentage);
        setIsTax2Percentage(discountDetails.isTax2Percentage);
        setTax1(discountDetails.tax1);
        setTax2(discountDetails.tax2);
        setTax1Description(discountDetails.tax1Description);
        setTax2Description(discountDetails.tax2Description);
        setDiscountTypeSelect({
          value: discountDetails.discountType == "Fixed" ? 1 : 2,
          label: discountDetails.discountType,
        });
        setDiscValue(discountDetails.dicountValue);
        setDiscount(discountDetails.discount);
      }
      setLoader(false);
    }
    getProductQytDataFunction();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let param = {
      productid: selectedProduct.productId,
      tax1: parseFloat(tax1),
      isTax1Percentage: isTax1Percentage,
      tax1Description: tax1Description,
      tax2: parseFloat(tax2),
      isTax2Percentage: isTax2Percentage,
      tax2Description: tax2Description,
      discountType: discountTypeSelect.label,
      dicountValue: parseFloat(discValue),
      discount: parseFloat(discount),
    };
    let res = await createModifyDiscountByProduct(param);
    setLoader(false);
    if (res) {
      await setAlerts({
        type: "success",
        message: notificationMessages.addInventoryDiscountSuccess,
      });
    }
  };

  const close = () => {
    navigate(ADMIN_URL_DASHBOARD);
  };

  const preventNegativeValues = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <Form className="form" onSubmit={handleSubmit} innerRef={ref}>
          <Row xs="3">
            <Col xs="12" md="4">
              <FormGroup className="customer-input-container">
                <Label for="discount">Product Name</Label>
                <AutoComplete
                  value={selectedProduct}
                  suggestions={filteredProduct}
                  completeMethod={filterProduct}
                  field="productName"
                  placeholder="Search by Product Name"
                  minLength={1}
                  showEmptyMessage={true}
                  emptyMessage="No results found"
                  onChange={(e) => {
                    setSelectedProduct(e.value);
                  }}
                  onSelect={(e) => {
                    setSelectedProduct(e.value);
                    getProductDiscountDetails(e.value);
                    setLoader(true);
                  }}
                  pt={{
                    panel: {
                      className: "autocomplete-Zindex",
                    },
                    input: {
                      root: {
                        className: "custom-width-input form-control",
                      },
                    },
                  }}
                  className="custom-autocomplete product-name-autocomplete"
                  style={{ width: "100%" }}
                  delay={300}
                />
              </FormGroup>
            </Col>

            <Col xs="12" md="4">
              <FormGroup className="customer-input-container">
                <Label for="discount">Discount Type</Label>
                <Input
                  id="description"
                  name="discountType"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col xs="12" md="4">
              <div className="customer-input-container">
                <label>
                  Tax
                </label>
                <div className="inventory-discount-control">
                  <input
                    value={tax1}
                    placeholder="Enter Tax"
                    type="number"
                    min="0"
                    maxLength={10}
                    onKeyDown={preventNegativeValues}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onChange={(e) => setTax1(e.target.value)}
                    className="manual-discount-input"
                  />
                  <Input
                    id="discount"
                    name="taxType"
                    type="select"
                    className="type-dropdown"
                    onChange={(e) => {
                      setTaxTypeSelect(e);
                    }}
                    style={{ width: "100px" }}
                  >
                    <option value={1}>Fixed</option>
                    <option value={2}>Percentage</option>
                  </Input>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="4">
              <div className="customer-input-container">
                <label>
                  Discount
                </label>
                <div className="inventory-discount-control">
                  <input
                    type="number"
                    min="0"
                    maxLength={10}
                    onKeyDown={preventNegativeValues}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    value={discValue}
                    placeholder="Enter Discount Value"
                    onChange={(e) => setDiscValue(e.target.value)}
                    className="manual-discount-input"
                  />
                  <Input
                    id="discount"
                    name="discType"
                    type="select"
                    className="type-dropdown"
                    onChange={(e) => {
                      setDiscountTypeSelect(e);
                    }}
                    style={{ width: "100px" }}
                  >
                    <option value={1}>Fixed</option>
                    <option value={2}>Percentage</option>
                  </Input>
                </div>
              </div>
            </Col>

            <Col xs="12" md="4">
              <FormGroup className="custom-input-container">
                <label>Total Discount</label>
                <Input
                  type="text"
                  // value={totalDiscount}
                  disabled
                />
              </FormGroup>
            </Col>

          </Row>
          <Row style={{ justifyContent: "flex-start" }}>
            <Col xs="12" md="4">
              <CustomButton type="submit" label="Add Discount" style={{ backgroundColor: "#207841", margin: "20px 0" }} />
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default DiscountByProduct;

{/* <Col className="" xs={12} md={4} lg={4}>
  <FormGroup>
    <Label style={{ display: "flex" }}>Discount</Label>

    <Input
      type="number"
      min="0"
      maxLength={10}
      onKeyDown={preventNegativeValues}
      onPaste={(e) => {
        e.preventDefault();
        return false;
      }}
      value={discount}
      placeholder="Enter Discount"
      onChange={(e) => setDiscount(e.target.value)}
    />
  </FormGroup>
</Col> */}