import CustomButton from "components/Button/CustomButton";
import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { useLoginStore } from "hooks/Login";
import { useEffect, useState } from "react";
import {
  Col,
  FormFeedback,
  Row
} from "reactstrap";
import { isEmpty } from "underscore";

const LocationDetail = () => {
  const [
    { locationDetails },
    { getLocationDetails },
    { updateLocationDetails },
  ] = useCompanyProfileStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();
  const [locationData, setLocationData] = useState({});
  const [locationValidate, setLocationValidate] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    async function getData() {
      await getLocationDetails();
    }
    getData();
  }, []);

  useEffect(() => {
    setLocationData(locationDetails);
  }, [locationDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = { ...locationValidate };
    if (!Common.verifyLength(value, 1)) {
      error[name] = "has-danger";
    } else {
      error[name] = "has-success";
    }
    setLocationValidate(error);
    setLocationData({
      ...locationData,
      [name]: value,
    });
  };

  const saveLocationDetails = async (e) => {
    e.preventDefault();
    let error = false;
    let validate = { ...locationValidate };
    let currentErrors = { ...errors };

    const requiredFields = [
      "address1",
      "address2",
      "area",
      "state",
      "city",
      "country",
    ];
    requiredFields.forEach((field) => {
      if (!locationData[field]) {
        error = true;
        validate[field] = "has-danger";
        currentErrors[field] = "This field is required.";
      }
    });

    setLocationValidate(validate);
    setErrors(currentErrors);

    if (locationValidate && isEmpty(locationValidate)) {
      error = true;
      validate.address1 = "has-danger";
      validate.address2 = "has-danger";
      validate.area = "has-danger";
      validate.state = "has-danger";
      validate.city = "has-danger";
      validate.country = "has-danger";
    } else {
      if (!locationData.address1) {
        error = true;
        validate.address1 = "has-danger";
      }
      if (!locationData.address2) {
        error = true;
        validate.address2 = "has-danger";
      }
      if (!locationData.area) {
        error = true;
        validate.area = "has-danger";
      }
      if (!locationData.state) {
        error = true;
        validate.state = "has-danger";
      }
      if (!locationData.city) {
        error = true;
        validate.city = "has-danger";
      }
      if (!locationData.country) {
        error = true;
        validate.country = "has-danger";
      }
    }

    setLocationValidate(validate);

    if (!error) {
      let param = {
        Address1: locationData.address1,
        Address2: locationData.address2,
        Area: locationData.area,
        City: locationData.city,
        State: locationData.state,
        Country: locationData.country,
      };
      let res = await updateLocationDetails(param);
      if (res) {
        await getLocationDetails();
        await setAlerts({
          type: "success",
          message: notificationMessages.updateLocationSuccess,
        });
      }
    }
  };

  const resetLocationDetail = async (ev) => {
    ev.preventDefault();
    setLocationData(locationDetails);
    setLocationValidate({});
    setErrors({});
  };

  return (
    <>
      <div className="profile-content-container">
        <div className="profile-content-title">
          <h5>Location Details</h5>
        </div>
        <form className="profile-address-form" onSubmit={saveLocationDetails}>
          <Row>
            <Col sm="12" md="12" lg="6">
              <div className="custom-input-container">
                <label>Company Address 1</label>
                <input
                  placeholder="Write your address here"
                  type="text"
                  name="address1"
                  valid={locationValidate?.address1 === "has-success"}
                  invalid={locationValidate?.address1 === "has-danger"}
                  value={locationData.address1}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>Address is required.</FormFeedback>
              </div>
            </Col>
            <Col sm="12" md="12" lg="6">
              <div className="custom-input-container">
                <label>Company Address 2</label>
                <input
                  placeholder="Write your address here"
                  type="text"
                  name="address2"
                  valid={locationValidate?.address2 === "has-success"}
                  invalid={locationValidate?.address2 === "has-danger"}
                  value={locationData.address2}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>Address is required.</FormFeedback>
              </div>
            </Col>
          </Row>

          <Row className="mt-lg-3">
            <Col sm="12" md="12" lg="6">
              <div className="custom-input-container">
                <label>Area</label>
                <input
                  placeholder="Please enter your value here"
                  type="text"
                  name="area"
                  valid={locationValidate?.area === "has-success"}
                  invalid={locationValidate?.area === "has-danger"}
                  value={locationData.area}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>Area is required.</FormFeedback>
              </div>
            </Col>
            <Col sm="12" md="12" lg="6">
              <div className="custom-input-container">
                <label>City</label>
                <input
                  placeholder="Please enter your value here"
                  type="text"
                  name="city"
                  valid={locationValidate?.city === "has-success"}
                  invalid={locationValidate?.city === "has-danger"}
                  value={locationData.city}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>City is required.</FormFeedback>
              </div>
            </Col>
          </Row>

          <Row className="mt-lg-3">
            <Col sm="12" md="12" lg="6">
              <div className="custom-input-container">
                <label>State</label>
                <input
                  placeholder="Please enter your value here"
                  type="text"
                  name="state"
                  valid={locationValidate?.state === "has-success"}
                  invalid={locationValidate?.state === "has-danger"}
                  value={locationData.state}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>State is required.</FormFeedback>
              </div>
            </Col>
            <Col sm="12" md="12" lg="6">
              <div className="custom-input-container">
                <label>Country</label>
                <input
                  placeholder="Please enter your value here"
                  type="text"
                  name="country"
                  valid={locationValidate?.country === "has-success"}
                  invalid={locationValidate?.country === "has-danger"}
                  value={locationData.country}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>Country is required.</FormFeedback>
              </div>
            </Col>
          </Row>

          <div className="mt-lg-3 profile-button-container">
            <CustomButton
              label="Save Details"
              onClick={saveLocationDetails}
            />
            <CustomButton
              label="Reset"
              onClick={resetLocationDetail}
              customStyle='profile-reset-btn'
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default LocationDetail;

{
  /* <Card>
  <Form className="form" onSubmit={saveLocationDetails}>
    <CardHeader>
      <h5>Location Details</h5>
    </CardHeader>
    <CardBody>
      <Row>
        <Col md="4">
          <label>Company Address 1</label>
          <FormGroup>
            <Input
              placeholder="Write your address here"
              type="text"
              name="address1"
              valid={locationValidate?.address1 === "has-success"}
              invalid={locationValidate?.address1 === "has-danger"}
              value={locationData.address1}
              onChange={handleChange}
              maxLength={100}
            />
            <FormFeedback>Address is required.</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="4">
          <label>Company Address 2</label>
          <FormGroup>
            <Input
              placeholder="Write your address here"
              type="text"
              name="address2"
              valid={locationValidate?.address2 === "has-success"}
              invalid={locationValidate?.address2 === "has-danger"}
              value={locationData.address2}
              onChange={handleChange}
              maxLength={100}
            />
            <FormFeedback>Address is required.</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="4">
          <label>Area</label>
          <FormGroup>
            <Input
              placeholder="Please enter your value here"
              type="text"
              name="area"
              valid={locationValidate?.area === "has-success"}
              invalid={locationValidate?.area === "has-danger"}
              value={locationData.area}
              onChange={handleChange}
              maxLength={100}
            />
            <FormFeedback>Area is required.</FormFeedback>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md="4">
          <label>City</label>
          <FormGroup>
            <Input
              placeholder="Please enter your value here"
              type="text"
              name="city"
              valid={locationValidate?.city === "has-success"}
              invalid={locationValidate?.city === "has-danger"}
              value={locationData.city}
              onChange={handleChange}
              maxLength={100}
            />
            <FormFeedback>City is required.</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="4">
          <label>State</label>
          <FormGroup>
            <Input
              placeholder="Please enter your value here"
              type="text"
              name="state"
              valid={locationValidate?.state === "has-success"}
              invalid={locationValidate?.state === "has-danger"}
              value={locationData.state}
              onChange={handleChange}
              maxLength={100}
            />
            <FormFeedback>State is required.</FormFeedback>
          </FormGroup>
        </Col>
        <Col md="4">
          <label>Country</label>
          <FormGroup>
            <Input
              placeholder="Please enter your value here"
              type="text"
              name="country"
              valid={locationValidate?.country === "has-success"}
              invalid={locationValidate?.country === "has-danger"}
              value={locationData.country}
              onChange={handleChange}
              maxLength={100}
            />
            <FormFeedback>Country is required.</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    </CardBody>
    <CardFooter className="d-flex justify-content-center">
      <Button color="primary" type="submit" className="m-r-20">
        Save Details{" "}
      </Button>
      <Button color="primary" outline onClick={resetLocationDetail}>
        Reset
      </Button>
    </CardFooter>
  </Form>
</Card>; */
}
