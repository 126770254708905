// Common
import Common from "helpers/Common";
// reactstap
import { FormFeedback, FormGroup, Input } from "reactstrap";


const SmsContent = (
  { handleNotificationData,
    notificationData,
    handleNotificationValidation,
    notificationValidate }
) => {
  return (
    <div className="custom-input-container">
      <label>Message*</label>
      <FormGroup>
        <Input
          type="text"
          placeholder="Type your message here..."
          value={notificationData.smsData}
          valid={notificationValidate.smsData === "has-success"}
          invalid={notificationValidate.smsData === "has-danger"}
          onChange={(e) => {
            let error = { ...notificationValidate };
            if (!Common.verifyLength(e.target.value, 1)) {
              error.smsData = "has-danger";
            } else {
              error.smsData = "has-success";
            }
            handleNotificationValidation(error);
            handleNotificationData({
              ...notificationData,
              smsData: e.target.value
            });
          }}
          maxLength={100}
        />
        <FormFeedback>Message is required.</FormFeedback>
      </FormGroup>
    </div>
  );
}
export default SmsContent