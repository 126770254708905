import { BlobServiceClient } from "@azure/storage-blob";
import BaseRepository from "./BaseRepository";
import accountDetails from "azureAccountDetails";
import AzureAPICall from "service/AzureAPICall";

class AzureRepository extends BaseRepository {
  uploadToAzure = async (file, moduleName) => {
    const account = accountDetails.STORAGE_ACCOUNT; // get the storage account name from the .env file
    const sasToken =
      moduleName?.toLowerCase() === "bill"
        ? accountDetails.BILL_STORAGE_SAS
        : accountDetails.INVENTORY_STORAGE_SAS; // get the SAS token from the .env file
    const containerName =
      moduleName?.toLowerCase() === "bill"
        ? accountDetails.BILL_STORAGE_CONTAINER
        : accountDetails.INVENTORY_STORAGE_CONTAINER; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
      `https://${account}.blob.core.windows.net/?${sasToken}`
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    if (!file) {
      // check if the file is selected
      alert("Please select an file to upload");
      return;
    }
    if (!account || !sasToken || !containerName) {
      // check if the credentials are set
      alert(
        "Please make sure you have set the Azure Storage credentials in the .env file"
      );
      return;
    }

    try {
      const blobName = `${file.name}`; // Specify a default blob name if needed
      const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
      await blobClient.uploadData(file, {
        blobHTTPHeaders: { blobContentType: file.type },
      }); // upload the image
      // console.log("success", blobName);
    } catch (error) {
      console.error(error); // Handle error
    }
  };

  fetchImagesFromAzure = async (file, moduleName) => {
    try {
      const account = accountDetails.STORAGE_ACCOUNT;
      const containerName =
        moduleName?.toLowerCase() === "bill"
          ? accountDetails.BILL_STORAGE_CONTAINER
          : accountDetails.INVENTORY_STORAGE_CONTAINER;
      const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/`
      );
      const containerClient =
        blobServiceClient.getContainerClient(containerName);

      if (!account || !containerName) {
        alert(
          "Please make sure you have set the Azure Storage credentials for image upload"
        );
        return null;
      }

      return new Promise((resolve) => {
        setTimeout(async () => {
          try {
            const blobItems = containerClient.listBlobsFlat();
            const urls = [];

            for await (const blob of blobItems) {
              const tempBlockBlobClient = containerClient.getBlockBlobClient(
                blob.name
              );
              urls.push({ name: blob.name, url: tempBlockBlobClient.url });
            }
            // Delay the filter operation by a small amount of time
            setTimeout(() => {
              const prodImageURL = urls.filter((d) =>
                d.name.split('.')[0] === file.toString()
                // d.name.includes(file.toString())
              );
              resolve(prodImageURL[0]?.url || null);
            }, 500); // Adjust the delay as needed (500 ms is just an example)
          } catch (error) {
            console.error("Error fetching blob items:", error);
            resolve(null);
          }
        }, 100); // Initial delay before starting the fetching process
      });
    } catch (error) {
      console.error("Error in fetchImagesFromAzure:", error);
      return null;
    } finally {
      // Any necessary cleanup can be done here
    }
  };

  fetchInventorySampleFromAzure = async () => {
    const account = accountDetails.STORAGE_ACCOUNT; // get the storage account name from the .env file
    const sasToken = accountDetails.INVENTORY_SAMPLE_FILE_SAS; // get the SAS token from the .env file
    const containerName = accountDetails.INVENTORY_SAMPLE_FILE_CONTAINER; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
      `https://${account}.blob.core.windows.net`
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    if (!account || !sasToken || !containerName) {
      // check if the credentials are set
      alert(
        "Please make sure you have set the Azure Storage credentials for image upload"
      );
      return;
    }
    try {
      const blobItems = containerClient.listBlobsFlat(); // get all blobs in the container
      const urls = [];
      for await (const blob of blobItems) {
        const tempBlockBlobClient = containerClient.getBlockBlobClient(
          blob.name
        ); // get the blob url
        urls.push({ name: blob.name, url: tempBlockBlobClient.url }); // push the image name and url to the urls array
      }
      let fileURL = [];
      fileURL = urls.filter((d) =>
        d.name.includes(accountDetails.SAMPLE_FILE_NAME)
      );
      return fileURL[0]?.url;
    } catch (error) {
      console.error(error); // Handle error
    } finally {
    }
  };

  inventoryFileUploadToAzure = async (params) => {
    return await new AzureAPICall().inventoryFileUploadToAzure(params);
  };

  billFileUploadToAzure = async (params) => {
    return await new AzureAPICall().billFileUploadToAzure(params);
  };
}

export default AzureRepository;
