import APIHandler from "helpers/APIHandler";
import { GET_CUSTOMER_By_COMPANY_ID_API } from "helpers/APIRouter";
import { UPDATE_COMPANY_API } from "helpers/APIRouter";
import { CREATE_COMPANY_USER_API } from "helpers/APIRouter";
import { CREATE_COMPANY_API } from "helpers/APIRouter";

const API = new APIHandler();

class CompanyAPICall {

    createCompany = async (params = {}) => {
        let res = {};
        let param = {};
        param.data = params;
        await API.post(CREATE_COMPANY_API, param)
            .then((result) => {
                if (result) {
                    res = result.result;
                }
            })
            .catch((e) => {
                console.log(e);
            });
        return res;
    };

    createCompanyUser = async (params = {}) => {
        let res = {};
        let param = {};
        param.data = params;
        await API.post(CREATE_COMPANY_USER_API, param)
            .then((result) => {
                if (result) {
                    res = result.result;
                }
            })
            .catch((e) => {
                console.log(e);
            });
        return res;
    };

    updateCompany = async (params = {}) => {
        let res = {};
        let param = {};
        param.data = params;
        await API.post(UPDATE_COMPANY_API, param)
            .then((result) => {
                if (result) {
                    res = result.result;
                }
            })
            .catch((e) => {
                console.log(e);
            });
        return res;
    };

    getCustomerByCompanyId = async () => {
        let res = {}
        await API
            .get(GET_CUSTOMER_By_COMPANY_ID_API)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };


}

export default CompanyAPICall;