import React, { useEffect, useState } from "react";

// reactstrap components
import { Row } from "reactstrap";

// used components
import { Dropdown } from "primereact/dropdown";
import Widgets from "./Widgets";
import FinanceAnalysis from "./FinanceAnalysis";
import TopSellingMedicines from "./TopSellingMedicine";
import RecentInventory from "./RecentInventory";
import Bills from "./Bills";
import "index.css";

function getTodayDate() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

function formatDate(date) {
  let inputDate = new Date(date);
  let year = inputDate.getFullYear();
  let month = String(inputDate.getMonth() + 1).padStart(2, "0");
  let day = String(inputDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function formatMonthYear(date) {
  let inputDate = new Date(date);
  let year = inputDate.getFullYear();
  let month = inputDate.toLocaleString("default", { month: "short" });
  return `${month} ${year}`;
}

function getFirstDayOfWeek(date) {
  const dayOfWeek = date.getDay();
  const daysUntilFirstDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  const firstDayOfWeek = new Date(date);
  firstDayOfWeek.setDate(date.getDate() - daysUntilFirstDayOfWeek);
  return firstDayOfWeek;
}

function getLastDayOfWeek(date) {
  const firstDayOfWeek = getFirstDayOfWeek(date);
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
  return lastDayOfWeek;
}

function getFirstDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getLastDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

const now = new Date();
const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

const timelines = [
  {
    name: "Today",
    value: "Today",
    fromDate: formatDate(now),
    toDate: formatDate(now),
  },
  {
    name: "This Week",
    value: `This Week (${formatDate(getFirstDayOfWeek(now))} to ${formatDate(
      getLastDayOfWeek(now)
    )})`,
    fromDate: formatDate(getFirstDayOfWeek(now)),
    toDate: formatDate(getLastDayOfWeek(now)),
  },
  {
    name: "Last Week",
    value: `Last Week (${formatDate(getFirstDayOfWeek(lastWeek))} to ${formatDate(
      getLastDayOfWeek(lastWeek)
    )})`,
    fromDate: formatDate(getFirstDayOfWeek(lastWeek)),
    toDate: formatDate(getLastDayOfWeek(lastWeek)),
  },
  {
    name: "This Month",
    value: formatMonthYear(now),
    fromDate: formatDate(getFirstDayOfMonth(now)),
    toDate: formatDate(getLastDayOfMonth(now)),
  },
  {
    name: "Last Month",
    value: formatMonthYear(new Date(now.getFullYear(), now.getMonth() - 1, 1)),
    fromDate: formatDate(
      getFirstDayOfMonth(new Date(now.getFullYear(), now.getMonth() - 1, 1))
    ),
    toDate: formatDate(
      getLastDayOfMonth(new Date(now.getFullYear(), now.getMonth() - 1, 1))
    ),
  },
];

const Dashboard = () => {
  const [timelineOptions, setTimelineOptions] = useState(
    timelines.find((t) => t.name.toLocaleLowerCase() === "today")
  );

  useEffect(() => {
    // Google Ads conversion tracking code
    window.gtag('event', 'conversion', {
      send_to: 'AW-16685553251',
    });
  }, []);

  return (
    <>
      <div className="content-dashboard">
        <div className="dashboard-title-row pt-4 mb-3 d-flex align-items-center justify-content-between dashboard-container">
          <div className="dashboard-title poppins-title">Dashboard</div>

          <Dropdown
            value={timelineOptions.value}
            onChange={(e) => {
              setTimelineOptions(timelines.find((t) => t.value === e.value));
            }}
            options={timelines}
            optionLabel="value"
            className="w-30 dashboard-filter-dropdown"
          />
        </div>
        <Row>
          <Widgets
            fromDate={timelineOptions.fromDate}
            toDate={timelineOptions.toDate}
          ></Widgets>
        </Row>
        <Row className="mt-2">
          <FinanceAnalysis
            fromDate={timelineOptions.fromDate}
            toDate={timelineOptions.toDate}
          ></FinanceAnalysis>
          <TopSellingMedicines
            fromDate={timelineOptions.fromDate}
            toDate={timelineOptions.toDate}
          ></TopSellingMedicines>
        </Row>
        <Row className="mt-2">
          <RecentInventory
            fromDate={timelineOptions.fromDate}
            toDate={timelineOptions.toDate}
          ></RecentInventory>
        </Row>
        <Row className="mt-2">
          <Bills
            fromDate={timelineOptions.fromDate}
            toDate={timelineOptions.toDate}
          ></Bills>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
