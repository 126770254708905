import APIHandler from "helpers/APIHandler";
import { CREATE_CUSTOM_PLAN_SUBSCRIPTION } from "helpers/APIRouter";
import { CREATE_COMPANY_SUBSCRIPTION } from "helpers/APIRouter";
import { UPDATE_COMPANY_SUBSCRIPTION } from "helpers/APIRouter";
import {
  GET_COMPANY_SUBSCRIPTION,
  GET_SUBSCRIPTION_DETAILS,
} from "helpers/APIRouter";

const API = new APIHandler();

class SubscriptionAPICall {
  getCompanySubscription = async () => {
    let res = {};
    await API.get(GET_COMPANY_SUBSCRIPTION)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getCompanySubscriptionDetails = async () => {
    let res = {};
    await API.get(GET_SUBSCRIPTION_DETAILS)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  createCompanySubscription = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(CREATE_COMPANY_SUBSCRIPTION, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  updateCompanySubscription = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPDATE_COMPANY_SUBSCRIPTION, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  createCustomPlanSubscription = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(CREATE_CUSTOM_PLAN_SUBSCRIPTION, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default SubscriptionAPICall;
