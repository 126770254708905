import { useSelector } from "react-redux";
import CompanyRepository from "repository/CompanyRepository";
import { getCustomerByCompanyId } from "store/slice/Company";
import store from "../store/store";

export function useCompanyStore() {
    /**
     * Here is list of use selectors which are relates to company
     * Author: Devanshi Patel
     */
    const customerList = useSelector(
        (state) => state.entities.Company.customerList
    );

    let data = {
        customerList
    };
    let fetch = {
        /**
         * Here is list of fetch methods which are used for get data from API or Repositories related to company
         * Author: Devanshi Patel
         * dispatch is used for reducer or thunk method
         * if redux not needed use repository method
         */
        getCustomerByCompanyId: async () => {
            return await store.dispatch(getCustomerByCompanyId());
        },

    };
    let set = {
        /**
         * Here is list of fetch methods which are used for set or update data from API or Repositories related to company
         * Author: Devanshi Patel
         */

        createCompany: async (params) => {
            return await new CompanyRepository().createCompany(params);
        },

        createCompanyUser: async (params) => {
            return await new CompanyRepository().createCompanyUser(params);
        },

        updateCompany: async (params) => {
            return await new CompanyRepository().updateCompany(params);
        },
    };
    return [data, fetch, set]
}