import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import { useNotificationStore } from "hooks/Notification";
import React, { useRef, useState } from "react";
import "index.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  Col,
  Row,
  FormGroup,
  Spinner,
  Container
} from "reactstrap";
import CustomButton from "components/Button/CustomButton";

const WPSMS = () => {
  const ref = useRef();

  const [{ }, { sendWPSMS }, { }] = useNotificationStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();

  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [smsNumberState, setSMSNumberState] = useState("");
  const [smsMessageState, setSMSMessageState] = useState("");
  const [subjectState, setSubjectState] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let error = false
    if (smsNumberState === "" || smsNumberState === "has-danger") {
      setSMSNumberState("has-danger");
      error = true
    }
    if (subjectState === "" || subjectState === "has-danger") {
      error = true
      setSubjectState("has-danger");
    }
    if (smsMessageState === "" || smsMessageState === "has-danger") {
      error = true
      setSMSMessageState("has-danger");
    }

    if (!error) {
      let param = {
        "toMobileNumber": number,
        "subject": subject,
        "messageBody": message,
        "isHtml": '',
        "templateName": 'test'
      }
      let res = await sendWPSMS(param);
      if (res) {
        await setAlerts({ type: 'success', message: notificationMessages.sendSMSSuccess })
        ref.current.reset();
      }
    }
    setLoader(false);
  }

  return (
    <div className="customer-content-container">
      <div className="customer-content-title">
        <h5>Send Whatsapp</h5>
      </div>
      <Form className="form" onSubmit={handleSubmit} innerRef={ref}>

        <FormGroup className={`has-label customer-input-container ${smsNumberState}`}>
          <label>Number *</label>
          <Input
            placeholder="Number"
            type="text"
            onChange={(e) => {
              if (!Common.verifyLength(e.target.value, 1)) {
                setSMSNumberState("has-danger");
              } else {
                setSMSNumberState("has-success");
              }
              setNumber(e.target.value);
            }}
          />
          {smsNumberState === "has-danger" ? (
            <label className="error">
              Number is required.
            </label>
          ) : null}
        </FormGroup>

        <FormGroup className={`has-label customer-input-container ${subjectState}`}>
          <label>Subject *</label>
          <Input
            placeholder="Subject"
            type="text"
            onChange={(e) => {
              if (!Common.verifyLength(e.target.value, 1)) {
                setSubjectState("has-danger");
              } else {
                setSubjectState("has-success");
              }
              setSubject(e.target.value);
            }}
            maxLength={100}
          />
          {subjectState === "has-danger" ? (
            <label className="error">
              Subject is required.
            </label>
          ) : null}
        </FormGroup>

        <FormGroup className={`has-label customer-input-container ${smsMessageState}`}>
          <label>Message *</label>
          <Input
            className="textarea"
            type="textarea"
            rows="4"
            placeholder="Type your message here.."
            onChange={(e) => {
              if (!Common.verifyLength(e.target.value, 1)) {
                setSMSMessageState("has-danger");
              } else {
                setSMSMessageState("has-success");
              }
              setMessage(e.target.value);
            }}
            maxLength={200}
          />
          {smsMessageState === "has-danger" ? (
            <label className="error">Message field is required.</label>
          ) : null}
        </FormGroup>


        <CustomButton
          type="submit"
          label={loader ? <Spinner size="sm" color="secondary" style={{ marginLeft: '15px' }} /> : "Send Whatsapp"}
          disabled={loader ? true : false}
        />

      </Form>
    </div>
  );
};

export default WPSMS;
