import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
  Row,
  Col,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useInventoryStore } from "hooks/Inventory";
import { useLoginStore } from "hooks/Login";
import { isEmpty, isObject } from "underscore";
import { notificationMessages } from "helpers/constantMessages";
import Common from "helpers/Common";
import { TabView, TabPanel } from "primereact/tabview";
import { ADMIN_URL_DASHBOARD } from "helpers/Paths";
import PageLoader from "components/Loader/PageLoader";
import Placeholder from "../../assets/images/placeholder.jpeg";
import { useAzureStore } from "hooks/Azure";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

const AddInventory = ({ setFormChanged }) => {
  const [loader, setLoader] = useState(false);
  const [{}, {}, { createModifyInventory }] = useInventoryStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const navigate = useNavigate();
  const [categorySelect, setCategorySelect] = useState(null);
  const [subCategorySelect, setSubCategorySelect] = useState(null);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [productName, setProductName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [upcCode, setupcCode] = useState("");
  const [sku, setSKU] = useState("");
  const [measure, setmeasure] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [unUseduantity, setUnusedQuantity] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [mrp, setmrp] = useState(0);

  const [expiryDate, setExpiryDate] = useState("");

  const [igst, setigst] = useState("");
  const [description, setDescription] = useState("");

  const [applicableDiscount, setapplicableDiscount] = useState(0);
  const [buyPrice, setbuyPrice] = useState(0);
  const [gst, setgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [netBuyPrice, setNetBuyPrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [reOrderPoint, setReOrderPoint] = useState(0);
  const [leadTime, setLeadTime] = useState(0);

  const [{}, { getSubCategoryList, getCategoryList }, {}] = useInventoryStore();

  const [inventoryValidate, setInventoryValidate] = useState({});

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [activeStep, setactiveStep] = useState(0);
  const [displayMsg, setDisplayMsg] = useState("");
  const ref = useRef();
  const [{}, {}, { inventoryFileUploadToAzure }] = useAzureStore();
  const discountTypeRef = useRef();

  useEffect(() => {
    getCategory();
    discountTypeRef.current = "Fixed";
  }, []);

  const getCategory = async () => {
    let categoryList = await getCategoryList();
    let list =
      categoryList &&
      !isEmpty(categoryList).length > 0 &&
      categoryList.map((prop, key) => {
        return {
          value: prop.categoryID,
          label: prop.categoryName,
        };
      });
    setCategory(list);
  };

  const getSubCategory = async (val) => {
    let param = {
      categoryIds: val?.value.toString(),
    };
    let subCategoryList = await getSubCategoryList(param);
    let list =
      subCategoryList &&
      !isEmpty(subCategoryList).length > 0 &&
      subCategoryList.map((prop, key) => {
        return {
          value: prop.subCategoryID,
          label: prop.subCategoryName,
        };
      });
    setSubCategory(list);
  };

  const handleSubmit = async (e) => {
    // When the user clicks on the button, scroll to the top of the document
    e.preventDefault();
    let error = false;
    let validate = { ...inventoryValidate };
    if (inventoryValidate && isEmpty(inventoryValidate)) {
      alert("Please fill out required fields in previous steps.");
      error = true;
      validate.category = "has-danger";
      validate.subCategory = "has-danger";
      validate.productName = "has-danger";
      validate.quantity = "has-danger";
      validate.mrp = "has-danger";
    } else {
      if (
        !inventoryValidate.category ||
        inventoryValidate.category === "has-danger"
      ) {
        error = true;
        validate.category = "has-danger";
      }
      if (
        !inventoryValidate.subCategory ||
        inventoryValidate.subCategory === "has-danger"
      ) {
        error = true;
        validate.subCategory = "has-danger";
      }
      if (
        !inventoryValidate.productName ||
        inventoryValidate.productName === "has-danger"
      ) {
        error = true;
        validate.productName = "has-danger";
      }
      if (
        !inventoryValidate.quantity ||
        inventoryValidate.quantity === "has-danger"
      ) {
        error = true;
        validate.quantity = "has-danger";
      }
      if (!inventoryValidate.mrp || inventoryValidate.mrp === "has-danger") {
        error = true;
        validate.mrp = "has-danger";
      }
    }
    setInventoryValidate(validate);
    if (!error) {
      setLoader(true);
      let param = {
        productId: 0,
        productName: productName,
        categoryID: categorySelect.value,
        subCategoryId: subCategorySelect.value,
        brand: brandName,
        upC_Code: upcCode,
        sku: sku,
        quantity: quantity,
        un_used_quantity: unUseduantity,
        maximumRetailPrice: mrp,
        discountPercentage:
          discount != ""
            ? discountTypeRef.current == "Percentage"
              ? discount
              : 0
            : 0,
        discountFixed:
          discount != ""
            ? discountTypeRef.current == "Fixed"
              ? discount
              : 0
            : 0,
        applicableDiscountAmount:
          applicableDiscount == "" ? 0 : applicableDiscount,
        expiryDate: expiryDate == "" ? null : expiryDate,
        cgst: cgst == "" ? 0 : cgst,
        sgst: sgst == "" ? 0 : sgst,
        gstRate: gst == "" ? 0 : gst,
        igstRate: igst == "" ? 0 : igst,
        taxAmount: totalTax,
        productImageName: fileName,
        measure: measure,
        reorder_point: reOrderPoint,
        lead_time: leadTime,
        productDescription: description,
      };
      let res = await createModifyInventory(param);
      setLoader(false);
      if (res?.isSuccess == true) {
        await setAlerts({
          type: "success",
          message: notificationMessages.addInventorySuccess,
        });

        // file upload section starts
        if (file != null) {
          let formData = new FormData();
          formData.append("formFile", file);
          await inventoryFileUploadToAzure(formData);
        }
        // file upload section ends
      }
      if (res?.isSuccess == false) {
        await setAlerts({
          type: "danger",
          message: res?.message,
        });
      }
      reset(e);
    }
  };

  const reset = (ev) => {
    ev.preventDefault();
    // ref.current.reset();
    setCategorySelect(null);
    setSubCategorySelect(null);
    setProductName("");
    setBrandName("");
    setupcCode("");
    setSKU("");
    setmeasure("");
    setQuantity(0);
    setUnusedQuantity(0);
    setDiscount(0);
    setmrp(0);
    setgst(0);
    setigst("");
    setExpiryDate("");
    setDescription("");
    setInventoryValidate({});
    setFile(null);
    setFileName("");
    setapplicableDiscount(0);
    discountTypeRef.current = "Fixed";
    setbuyPrice(0);
    setCgst(0);
    setSgst(0);
    setgst(0);
    setTotalTax(0);
    setNetBuyPrice(0);
    setTotalAmount(0);
    setReOrderPoint(0);
    setLeadTime(0);
    setactiveStep(0);
    setFormChanged(false); // Reset formChanged state
  };

  const close = () => {
    // reset(null);
    navigate(ADMIN_URL_DASHBOARD);
  };

  const handleCategorySelect = (val) => {
    setSubCategorySelect(null);
    getSubCategory(val);
  };

  const uploadProduct = (e) => {
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      alert("Please select image size lesser than 2MB.");
      e.target.value = "";
    } else {
      let fileName =
        Common.getCompanyId() +
        "_" +
        Date.now() +
        "." +
        e.target.files[0].name.split(".")[1];
      setFileName(fileName);
      setFile(new File([e.target.files[0]], fileName));
    }
  };

  const preventNegativeValues = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const calculateTaxAndDiscount = () => {
    if (quantity != "" && mrp != "") {
      let finalDiscAmount = 0;
      if (discountTypeRef.current == "Fixed") {
        setapplicableDiscount(parseFloat(discount));
        calculateTaxAndFinalAmount(parseFloat(discount));
      }
      if (discountTypeRef.current == "Percentage") {
        finalDiscAmount = (discount * mrp) / 100;
        setapplicableDiscount(parseFloat(finalDiscAmount));
        calculateTaxAndFinalAmount(parseFloat(finalDiscAmount));
      }
    } else {
      setDisplayMsg(
        "Please enter Quantity/MRP/Discount to calculate Tax/Discounts."
      );
    }
  };

  const calculateTaxAndFinalAmount = (finalDiscAmount) => {
    let buyP;
    let cgstP;
    let sgstP;
    let gstP;
    let totaltaxP;
    let netBuyP;
    let totalAmountP;

    buyP = parseFloat(mrp).toFixed(2) - parseFloat(finalDiscAmount).toFixed(2);
    cgstP = (buyP * 7) / 100;
    sgstP = (buyP * 9) / 100;
    gstP = (buyP * 5) / 100;
    totaltaxP = cgstP + sgstP + gstP;
    netBuyP = buyP + totaltaxP;
    totalAmountP = netBuyP.toFixed(2) * parseFloat(quantity).toFixed(2);

    setbuyPrice(buyP.toFixed(2));
    setCgst(cgstP.toFixed(2));
    setSgst(sgstP.toFixed(2));
    setgst(gstP.toFixed(2));
    setTotalTax(totaltaxP.toFixed(2));
    setNetBuyPrice(netBuyP.toFixed(2));
    setTotalAmount(totalAmountP.toFixed(2));
  };

  const today = new Date().toISOString().split("T")[0];

  const handleFieldsChangeStep = (handleNext) => {
    if (
      categorySelect != null &&
      subCategorySelect != null &&
      (productName != "" || productName != null)
    ) {
      handleNext();
      setactiveStep(activeStep + 1);
    } else {
      setAlerts({
        type: "info",
        message: "Please enter required fields.",
      });
    }
  };

  const RemoveImage = () => {
    setFile(null);
    setFileName(null);
  };

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
    setFormChanged(true); // Notify parent of form change
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <Form className="form" onSubmit={handleSubmit} innerRef={ref}>
          <FormWizard
            stepSize="sm"
            backButtonTemplate={(handlePrevious) => (
              <Button
                // style={{ display: activeStep == 2 ? "none" : "inline" }}
                style={
                  activeStep == 2
                    ? {
                        marginTop: "-62px",
                        position: "absolute",
                        left: "281px",
                      }
                    : null
                }
                color="info"
                className=""
                size="md"
                onClick={() => {
                  handlePrevious();
                  setactiveStep(activeStep - 1);
                }}
              >
                Previous
              </Button>
            )}
            nextButtonTemplate={(handleNext) => (
              <Button
                style={{ display: activeStep == 2 ? "none" : "inline" }}
                color="info"
                className=""
                size="md"
                onClick={() => {
                  if (activeStep == 0) {
                    if (
                      categorySelect != null &&
                      subCategorySelect != null &&
                      productName != ""
                    ) {
                      handleNext();
                      setactiveStep(activeStep + 1);
                    } else {
                      setAlerts({
                        type: "info",
                        message: "Please enter required fields.",
                      });
                    }
                  }
                  if (activeStep == 1) {
                    if (
                      quantity != 0 &&
                      quantity != null &&
                      mrp != 0 &&
                      mrp != null
                    ) {
                      handleNext();
                      setactiveStep(activeStep + 1);
                    } else {
                      setAlerts({
                        type: "info",
                        message: "Please enter required fields.",
                      });
                    }
                  }
                }}
              >
                Next
              </Button>
            )}
            finishButtonTemplate={(handleComplete) => (
              <Button style={{ display: "none" }}></Button>
            )}
          >
            <FormWizard.TabContent title="Product Details">
              <Row xs="2">
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Category *</Label>
                    <Input
                      name="category"
                      placeholder="Select Category"
                      type="hidden"
                      valid={inventoryValidate?.category === "has-success"}
                      invalid={inventoryValidate?.category === "has-danger"}
                    />
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Category"
                      name="category"
                      value={categorySelect}
                      options={category}
                      onChange={(e) => {
                        let error = { ...inventoryValidate };
                        if (e.value == null) {
                          error.category = "has-danger";
                        } else {
                          error.category = "has-success";
                        }
                        setInventoryValidate(error);
                        setCategorySelect(e);
                        handleCategorySelect(e);
                        setFormChanged(true);
                      }}
                    />
                    <FormFeedback>Category is required.</FormFeedback>
                  </FormGroup>
                </Col>

                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Sub Category *</Label>

                    <Input
                      name="subcategory"
                      placeholder="Select Sub Category"
                      type="hidden"
                      valid={inventoryValidate?.subCategory === "has-success"}
                      invalid={inventoryValidate?.subCategory === "has-danger"}
                    />
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Sub Category"
                      name="subcategory"
                      value={subCategorySelect}
                      options={subCategory}
                      onChange={(e) => {
                        let error = { ...inventoryValidate };
                        if (e.value == null) {
                          error.subCategory = "has-danger";
                        } else {
                          error.subCategory = "has-success";
                        }
                        setInventoryValidate(error);
                        setSubCategorySelect(e);
                        setFormChanged(true);
                      }}
                    />
                    <FormFeedback>Sub Category is required.</FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Product Name *</Label>
                    <Input
                      placeholder="Enter Product Name"
                      valid={inventoryValidate?.productName === "has-success"}
                      invalid={inventoryValidate?.productName === "has-danger"}
                      value={productName}
                      maxLength={25}
                      onChange={(e) => {
                        let error = { ...inventoryValidate };
                        if (!Common.verifyLength(e.target.value, 1)) {
                          error.productName = "has-danger";
                        } else {
                          error.productName = "has-success";
                        }
                        setInventoryValidate(error);
                        setProductName(e.target.value);
                        handleInputChange(e, setProductName);
                      }}
                      type="text"
                    />
                    <FormFeedback>Product name is required.</FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Brand</Label>

                    <Input
                      value={brandName}
                      maxLength={25}
                      placeholder="Enter Brand"
                      type="text"
                      onChange={(e) => {
                        setBrandName(e.target.value);
                        handleInputChange(e, setBrandName);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>UPC Code</Label>

                    <Input
                      value={upcCode}
                      maxLength={25}
                      placeholder="Enter UPC Code"
                      type="text"
                      onChange={(e) => {
                        setupcCode(e.target.value);
                        handleInputChange(e, setupcCode);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>SKU</Label>

                    <Input
                      value={sku}
                      maxLength={25}
                      placeholder="Enter SKU"
                      type="text"
                      onChange={(e) => {
                        setSKU(e.target.value);
                        handleInputChange(e, setSKU);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Measure</Label>

                    <Input
                      maxLength={25}
                      value={measure}
                      placeholder="Enter Measure"
                      type="text"
                      onChange={(e) => {
                        setmeasure(e.target.value);
                        handleInputChange(e, setmeasure);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormWizard.TabContent>
            <FormWizard.TabContent title="Product Totals">
              <Row xs="2">
                <Col className="" xs={12} md={4} lg={2}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Quantity *</Label>
                    <Input
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      name="quantity"
                      placeholder="Enter Quantity"
                      valid={inventoryValidate?.quantity === "has-success"}
                      invalid={inventoryValidate?.quantity === "has-danger"}
                      value={quantity}
                      // onBlur={() => calculateTaxAndDiscount()}
                      onBlur={() => calculateTaxAndDiscount()}
                      onChange={(e) => {
                        let error = { ...inventoryValidate };
                        if (
                          !Common.verifyLength(e.target.value, 1) ||
                          e.target.value <= 0
                        ) {
                          error.quantity = "has-danger";
                        } else {
                          error.quantity = "has-success";
                        }
                        setInventoryValidate(error);
                        setQuantity(e.target.value);
                      }}
                    />
                    <FormFeedback>
                      Quantity in positive amount is required.
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={2}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Unused Quantity</Label>
                    <Input
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      name="unUsedquantity"
                      placeholder="Enter Unused Quantity"
                      value={unUseduantity}
                      onChange={(e) => {
                        setUnusedQuantity(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col className="" xs={12} md={4} lg={2}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>MRP *</Label>
                    <Input
                      maxLength={10}
                      type="number"
                      min="0"
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      valid={inventoryValidate?.mrp === "has-success"}
                      invalid={inventoryValidate?.mrp === "has-danger"}
                      value={mrp}
                      onBlur={() => calculateTaxAndDiscount()}
                      onChange={(e) => {
                        let error = { ...inventoryValidate };
                        if (
                          !Common.verifyLength(e.target.value, 1) ||
                          e.target.value <= 0
                        ) {
                          error.mrp = "has-danger";
                        } else {
                          error.mrp = "has-success";
                        }
                        setInventoryValidate(error);
                        setmrp(e.target.value);
                      }}
                      placeholder="Enter MRP"
                    />
                    <FormFeedback>
                      MRP in positive amount is required.
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={2}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Discount</Label>

                    <Input
                      type="number"
                      min="0"
                      maxLength={10}
                      value={discount}
                      placeholder="Enter Discount"
                      onBlur={() => calculateTaxAndDiscount()}
                      onChange={(e) => setDiscount(e.target.value)}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col
                  className=""
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ paddingTop: "22px", left: "30px" }}
                >
                  <Row style={{ display: "flex" }}>
                    <Col>
                      <Row style={{ display: "flex" }}>
                        {" "}
                        <Input
                          value={"Fixed"}
                          checked={discountTypeRef.current == "Fixed"}
                          onChange={(e) => {
                            discountTypeRef.current = e.target.value;
                            calculateTaxAndDiscount();
                          }}
                          name="Discount"
                          type="radio"
                        />
                        <Label check>Fixed Discount</Label>
                      </Row>
                      <Row style={{ display: "flex" }}>
                        {" "}
                        <Input
                          value={"Percentage"}
                          checked={discountTypeRef.current == "Percentage"}
                          onChange={(e) => {
                            discountTypeRef.current = e.target.value;
                            calculateTaxAndDiscount();
                          }}
                          name="Discount"
                          type="radio"
                        />
                        <Label check>Percentage Discount</Label>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  className=""
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <span>{displayMsg}</span>
                </Col>
                <Col
                  className=""
                  xs={12}
                  md={4}
                  lg={4}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <FormGroup>
                    <Label style={{ display: "flex" }}>
                      Applicable Discount
                    </Label>

                    <Input
                      value={applicableDiscount}
                      readOnly
                      type="number"
                      min="0"
                      maxLength={10}
                      placeholder="0"
                    />
                  </FormGroup>
                </Col>
                <Col
                  className=""
                  xs={12}
                  md={4}
                  lg={4}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Buy Price</Label>

                    <Input
                      readOnly
                      value={buyPrice}
                      onChange={(e) => buyPrice(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col
                  className=""
                  xs={12}
                  md={4}
                  lg={4}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <FormGroup>
                    <Label style={{ display: "flex" }}>CGST(7%)</Label>

                    <Input
                      readOnly
                      value={cgst}
                      onChange={(e) => setCgst(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>SGST(9%)</Label>

                    <Input
                      readOnly
                      value={sgst}
                      onChange={(e) => setSgst(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>GST(5%)</Label>

                    <Input
                      readOnly
                      value={gst}
                      onChange={(e) => setgst(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Total Tax</Label>

                    <Input
                      readOnly
                      value={totalTax}
                      onChange={(e) => setTotalTax(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Net Buy Price</Label>

                    <Input
                      readOnly
                      value={netBuyPrice}
                      onChange={(e) => setNetBuyPrice(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Total Amount</Label>

                    <Input
                      readOnly
                      value={totalAmount}
                      onChange={(e) => setTotalAmount(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormWizard.TabContent>
            <FormWizard.TabContent title="Other Details">
              <Row xs="2">
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Reorder Point</Label>
                    <Input
                      value={reOrderPoint}
                      onChange={(e) => setReOrderPoint(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Lead Time</Label>

                    <Input
                      value={leadTime}
                      onChange={(e) => setLeadTime(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Expiry Date</Label>
                    <Input
                      id="exampleDate"
                      name="date"
                      placeholder="Enter Expiry Date"
                      min={today}
                      max="9999-12-31"
                      type="date"
                      value={expiryDate}
                      onChange={(e) => {
                        setExpiryDate(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>

                {/* <Row>
    <Col md="6">
      <FormGroup row className="align-items-baseline">
        <Label for="btotal" className="width-120">
          IGST
        </Label>
        <Col className="ml-1">
          <Input
            onChange={(e) => setigst(e.target.value)}
            placeholder="0"
            type="number"
            min="0"
            maxLength={10}
            onKeyDown={preventNegativeValues}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
        </Col>
      </FormGroup>
    </Col>
  </Row> */}
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Description</Label>

                    <Input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      maxLength={500}
                      type="textarea"
                      placeholder="Enter Description"
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Inventory Image</Label>

                    <Button className="button-upload" outline>
                      <input
                        accept="image/x-png,image/svg,image/jpeg,image/jpg"
                        onChange={(e) => uploadProduct(e)}
                        type="file"
                        name="file"
                      />
                    </Button>
                  </FormGroup>
                </Col>
                {/**
                 * Preview uploaded image
                 * Author: Devanshi Patel
                 */}
                <Col className="" xs={12} md={4} lg={4}>
                  <div className="upload-form_display">
                    {file ? (
                      <img
                        className="displayImg"
                        src={URL.createObjectURL(file)}
                        alt="no pic"
                      />
                    ) : (
                      <img
                        className="displayImg"
                        src={`${ERP_CDN_IMAGE_URL}/placeholder.jpeg`}
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop if logo also fails
                          e.target.src = Placeholder;
                        }}
                        alt="nopic"
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <CardFooter className="d-flex justify-content-flex-end">
                  <Button color="success" type="submit" className="m-r-20">
                    Add
                  </Button>
                  <Button color="danger" onClick={() => close()}>
                    Cancel
                  </Button>
                  <Button
                    color="warning"
                    style={{ marginLeft: "45%" }}
                    onClick={RemoveImage}
                  >
                    Delete Image
                  </Button>
                </CardFooter>
              </Row>
            </FormWizard.TabContent>
          </FormWizard>
          {/* add style */}
          <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
      `}</style>
        </Form>
      )}
    </>
  );
};

export default AddInventory;
