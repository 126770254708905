import React from 'react'
import Navbar from 'views/New_LandingPage/components/Navbar'
import Hero from 'views/New_LandingPage/components/Hero'
import Features from 'views/New_LandingPage/components/Features'
import OtherSection from 'views/New_LandingPage/components/OtherFeatuers'
import Footer from 'views/New_LandingPage/components/Footer'
import Pricing from 'views/New_LandingPage/components/Pricing'
import 'assets/styles/NewLandingPage.css'

const LandingPage = () => {
    return (
        <section className="landing-page">
            <Navbar />
            <div className="landing-page-container">
                <Hero />
                <Features />
                <Pricing />
                <OtherSection />
            </div>
            <Footer />
        </section>
    )
}

export default LandingPage