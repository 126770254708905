import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

import { TabView, TabPanel } from "primereact/tabview";
import AddInventory from "./AddInventory";
import EditInventory from "./EditInventory";
import "index.css";
import helpIcon from "../../assets/img/icons/helpIcon.png";
import HelpIconBtn from "views/HelpCenter/HelpIconBtn";
import { INVENTORY_SECTION_ID } from "helpers/Paths";
import { useParams } from "react-router-dom";
import DiscountByCategory from "./DiscountByCategory";
import DiscountByProduct from "./DiscountByProduct";
import CustomButton from "components/Button/CustomButton";

const InventoryDiscounts = () => {
  const [activeIndexTemp, setActiveIndexTemp] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleTabChange = (e) => {
    setConfirmModal(true);
    // setActiveIndexTemp(e.index);
    setActiveIndexTemp(e);
  };

  const handleConfirm = () => {
    setActiveIndex(activeIndexTemp);
    setConfirmModal(!confirmModal);
  };

  const toggle = () => {
    if (activeIndex == 0) {
      setActiveIndex(0);
      setActiveIndexTemp(0);
    }
    if (activeIndex == 1) {
      setActiveIndex(1);
      setActiveIndexTemp(1);
    }
    setConfirmModal(!confirmModal);
  };

  return (
    <div className="content-dashboard-inventory">
      <div className="title-route-container">
        <h4 className="title">Inventory / Discounts</h4>
      </div>

      <Card>
        <CardBody>
          <Container className="" fluid>
            <Row xs="2">
              <Col className="" xs="12" md="12" lg="12" style={{ marginBottom: "30px" }}>
                {/* Custom Tab Start */}
                <div className="custom-tab-wrapper">
                  <div className={`custom-tab-switch ${activeIndex === 0 ? 'left' : 'right'} text-center`}>
                    <div
                      className={`custom-tab ${activeIndex === 0 ? 'custom-active-tab' : ''}`}
                      onClick={() => handleTabChange(0)}
                    >
                      Discount by Category
                    </div>
                    <div
                      className={`custom-tab ${activeIndex === 1 ? 'custom-active-tab' : ''}`}
                      onClick={() => handleTabChange(1)}
                    >
                      Discount by Product
                    </div>
                  </div>
                </div>
                {/* Custom Tab End */}
              </Col>

              <Col className="" xs="12" md="12" lg="12">
                {
                  activeIndex === 0 ? <DiscountByCategory></DiscountByCategory> : <DiscountByProduct></DiscountByProduct>
                }
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>


      <Modal isOpen={confirmModal} toggle={toggle} centered={true}>
        <ModalBody>
          <p style={{ textAlign: "center", fontSize: "16px", fontFamily: "'DM Sans', sans-serif" }}>
            All data if entered will be erased. Do you want to continue ?
          </p>

          <div className="d-flex align-items-center justify-content-center mt-3" style={{ gap: "20px" }}>
            <CustomButton
              label="Continue"
              onClick={() => {
                handleConfirm();
              }}
            />

            <CustomButton
              label="Cancel"
              onClick={toggle}
              style={{ backgroundColor: "#555555" }}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default InventoryDiscounts;
