import CompanyAPICall from "service/CompanyAPICall";
import BaseRepository from "./BaseRepository";


class CompanyRepository extends BaseRepository {
    createCompany = async (params) => {
        return await new CompanyAPICall().createCompany(params);
    };

    createCompanyUser = async (params) => {
        return await new CompanyAPICall().createCompanyUser(params);
    };

    updateCompany = async (params) => {
        return await new CompanyAPICall().updateCompany(params);
    };

    getCustomerByCompanyId = async () => {
        return await new CompanyAPICall().getCustomerByCompanyId();
    };
}

export default CompanyRepository;