import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import CompanyProfileRepository from 'repository/CompanyProfileRepository';

function QRCodeGenerator(props) {
  const [error, setError] = useState(null); // State to store error message
  const UPIId = new CompanyProfileRepository().getUPIId()
  // Example UPI payment data
  const upiPaymentData = {
    pa: UPIId, // UPI ID of the recipient
    am: props.amount, // amount in rupees
    //optional
    // mc: '1234',
    // tid: '1234567890123456',
    // tr: '12345678',
    // tn: 'Payment for test',
    // cu: 'INR'
  };

  // Convert UPI payment data to a string
  const upiPaymentString = Object.entries(upiPaymentData)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  // Function to handle errors
  const handleError = (error) => {
    setError(error);
    // Optionally, you can log the error or perform other actions
    console.error('UPI payment error:', error);
  };

  return (
    <div style={{marginRight:'2%'}}>
        {/* Render QR code */}
        {error ? (
          <div>Error: {error}</div>
        ) : (
          <QRCode value={`upi://pay?${upiPaymentString}`} onError={handleError} size={70}/>
        )}
      </div>
  );
}

export default QRCodeGenerator;