export const URL_LOGIN = "/login";
export const URL_OTP = "/otp";
export const URL_CHANGE_PASSWORD = "/change-password";
export const URL_DASHBOARD = "/dashboard";
export const URL_PROFILE = "/profile";
export const URL_PENDING_BILL = "/pending-bill";
export const URL_COMPLETED_BILL = "/completed-bill";
export const URL_BILL_DASHBOARD = "/bill-dashboard";
export const URL_CREATE_BILL = "/create-bill";
export const URL_REFUND_BILL = "/refund-bill";
export const URL_EDIT_BILL = "/edit-bill/:id";
export const URL_INVENTORY_REPORT = "/inventory-report";
export const URL_INVENTORY_REPORTS = "/inventory-reports";
export const URL_INVENTORY_EXPIRY_REPORT = "/inventory-expiry-reports";
export const URL_INVENTORY_MANAGEMENT_MANUAL = "/inventory-management-manual";
export const URL_INVENTORY_MANAGEMENT_BY_FILE = "/inventory-management-by-file";
export const URL_INVENTORY_FILE_SUMMARY = "/inventory-file-summary";
export const URL_INVENTORY_FILE_SUMMARY_DETAIL =
  "/inventory-file-summary-detail";
export const URL_REVENUE_REPORT = "/revenue-report";
export const URL_BEST_SELLING_USAGE_REPORT = "/best-selling-usage-report";
export const URL_LEAST_SELLING_USAGE_REPORT = "/least-selling-usage-report";
export const URL_HISTORICAL_DEMAND_REPORT = "/sales-report";
export const URL_FORECASTING_DEMAND_REPORT = "/forecasting-demand-report";
export const URL_FORECASTING_TIME_DEMAND_REPORT =
  "/forecasting-time-demand-report";
export const URL_COMPANY_PROFILE = "/company-profile";
export const URL_SMS = "/sms-send";
export const URL_EMAIL = "/email-send";
export const URL_WHATSAPP_SMS = "/whatsapp-sms-send";
export const URL_REVENUE_REPORT_LINE_CHART = "/revenue-report-line-chart";
export const URL_REVENUE_REPORT_PIE_CHART = "/revenue-report-pie-chart";
export const URL_Template = "/template";
export const URL_SIGNUP = "/signup";
export const URL_SUPPLIER_SIGNUP = "/supplier/signup";
export const URL_SIGNUPEMAIL = "/signupemail";
export const URL_REGISTRATION = "/registration";
export const URL_TERMS = "/terms";
export const URL_NOTIFICATIONSCHEDULE = "/notificationschedule";
export const URL_NOTIFICATIONSCHEDULE_REPORT = "/notification-report";
export const URL_MANAGE_CUSTOMER = "/manage-customer";
export const URL_PAYMENT = "/payment";
export const URL_ACCOUNT_RECIEVABLE = "/account-recievable";
export const URL_ACCOUNT_PAYABLE = "/account-payable";
export const URL_INVENTORYHISTORY = "/inventory-history";
export const URL_DASHBOARDV2 = "/dashboardv2";
export const URL_FEEDBACK = "/feedback";
export const URL_INVENTORYMANUAL = "/inventorymanual";
export const URL_INVENTORYDISCOUNTS = "/discount";
export const URL_AUTOINVENTORY = "/autoinventory";
export const URL_COMPANY_PROFILE_V2 = "/company-profile-v2";
export const URL_BILL_V2 = "/bill-v2";
export const URL_STRIPE_PAYMENT = "/stripe-payment";
export const URL_RAZORPAY_PAYMENT = "/razorpay-payment";
export const URL_TECHNICAL_ALERT = "/technical-alert";
export const URL_RECENT_BILLS = "/recent-bills";
export const URL_RECENT_ENVENTORY = "/recent-enventory";
export const URL_ACTIVITY_LOGS = "/activity-logs";
export const URL_SUBSCRIPTION = "/subscription";
export const URL_CreateBillKeyPage = "/CreateBillKeyPage";
export const URL_PlanDetails = "/Billing-Usage-Overview";
export const URL_CUSTOMER_HELP = "/helpcenter";
export const URL_SUB_HELP = "/answers";
export const URL_CUSTOM_PAGE = "/admin/custom";
export const URL_QR_CODE_GENERATOR = "/qr-code-generator";
export const URL_CREATE_BILL_PREVIEW = "/createbill-preview";
export const URL_REORDER_STOCK_REPORT = "/reorder-stock-report";

export const AUTH_URL_LOGIN = "/auth/login";
export const AUTH_SIGNUP_DOCUMENT_DETAILS = "/document-details";
export const URL_SIGNUP_WITH_DOCUMENTS = "/signup-with-documents";
export const URL_SIGNUP_TEST = "/auth/test";
export const AUTH_URL_OTP = "/auth/otp";
export const AUTH_URL_CHANGE_PASSWORD = "/auth/change-password";
export const ADMIN_URL_COMPANY_PROFILE = "/admin/company-profile";
export const ADMIN_URL_COMPANY_PROFILE_V2 = "/admin/company-profile-v2";
export const ADMIN_URL_INVENTORY_FILE_SUMMARY = "/admin/inventory-file-summary";
export const ADMIN_URL_INVENTORY_FILE_SUMMARY_DETAIL =
  "/admin/inventory-file-summary-detail";
export const AUTH_URL_SIGNUP = "/auth/signup";
export const AUTH_URL_SUPPLIER_SIGNUP = "/auth/supplier/signup";
export const AUTH_URL_SIGNUPEMAIL = "/auth/signupemail";
export const AUTH_URL_REGISTRATION = "/auth/registration";
export const AUTH_URL_TERMS = "/auth/terms";
export const ADMIN_URL_FEEDBACK = "/admin/feedback";
export const ADMIN_URL_DASHBOARD = "/admin/dashboard";
export const ADMIN_URL_CREATE_BILL = "/admin/create-bill";

export const ALERT_TECHNICAL_ALERT = "/alert/technical-alert";
export const ADMIN_URL_RECENT_BILLS = "/admin/recent-bills";
export const ADMIN_URL_RECENT_ENVENTORY = "/admin/recent-enventory";
export const ADMIN_URL_ACTIVITY_LOGS = "/admin/activity-logs";
export const ADMIN_URL_CUSTOMER_HELP = "/admin/helpcenter";
export const ADMIN_URL_SUB_HELP = "/admin/answers";
export const ADMIN_URL_CUSTOM_PAGE = "/admin/custom";
export const ADMIN_URL_BILL_DASHBOARD = "/admin/bill-dashboard";

export const URL_INVOICE_OCR = "/admin/invoice-ocr";
export const URL_SPEECH_RECOGNITION = "/admin/speec-recognition";
export const URL_DEMAND_FORECAST = "/admin/demand-forecast";

/**
 * Particular section ID of helpPage
 * used for navigate that section
 */

export const BILL_SECTION_ID = "#bill";
export const INVENTORY_SECTION_ID = "#inventory";
export const URL_INVENTORY_PRODUCTS_LIST = "/inventory-products-list";
export const URL_INVENTORY_PRODUCTS_ADD = "/inventory-add-product";
export const URL_ADD_INVENTORY_BY_FILE = "/add-inventory-by-file";
export const URL_FORECAST_REPORT = "/forecast-report";

export const URL_BROADCAST_NOTIFICATION = "/broadcast-notification";
export const URL_CUSTOMER_LIST = "/customers-list";