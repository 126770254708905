import ReportAPICall from "service/ReportAPICall";
import BaseRepository from "./BaseRepository";

class ReportRepository extends BaseRepository {
  getMaximumStockUsageReport = async (params) => {
    return await new ReportAPICall().getMaximumStockUsageReport(params);
  };

  getMinimumStockUsageReport = async (params) => {
    return await new ReportAPICall().getMinimumStockUsageReport(params);
  };

  getRevenueReport = async (params) => {
    return await new ReportAPICall().getRevenueReport(params);
  };

  getDailyDemandForecastData = async (params) => {
    return await new ReportAPICall().getDailyDemandForecastData(params);
  };

  getWeeklyDemandForecastData = async (params) => {
    return await new ReportAPICall().getWeeklyDemandForecastData(params);
  };

  getHistoricalDemandData = async (params) => {
    return await new ReportAPICall().getHistoricalDemandData(params);
  };
}

export default ReportRepository;
