import React, { useEffect, useState } from "react";

// reactstrap components
import { Col, Card, CardBody } from "reactstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// used components
import { useDashboardV2DataStore } from "hooks/DashboardV2";
import PageLoader from "components/Loader/PageLoader";
import ChildLoader from "components/Loader/ChildLoader";
import { MdOutlineTrendingDown, MdOutlineTrendingUp } from "react-icons/md";

const FinanceAnalysis = (props) => {
  const [{ }, { getFinancialAnalysis }, { }] = useDashboardV2DataStore();
  let fromDateParam = props.fromDate;
  let toDateParam = props.toDate;
  const [loader, setLoader] = useState(false);
  const [financialAnalysisData, setFinancialAnalysisData] = useState([]);
  useEffect(() => {
    getFinancialAnalysisData();
  }, []);

  useEffect(() => {
    getFinancialAnalysisData();
  }, [fromDateParam]);

  function formatDate(date) {
    let inputDate = new Date(date);
    let year = inputDate.getFullYear();
    let month = String(inputDate.getMonth() + 1).padStart(2, "0");
    let day = String(inputDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getTodayDate() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  const getFinancialAnalysisData = async () => {
    try {
      setLoader(true);
      let FinancialAnalysisData = await getFinancialAnalysis({
        params: {
          year: new Date().getFullYear(),
          fromdate: fromDateParam,
          todate: toDateParam || formatDate(getTodayDate()),
        },
      });
      // console.log("finance data", FinancialAnalysisData);
      setFinancialAnalysisData(FinancialAnalysisData);
      setLoader(false);
    } catch (error) {
      // for error handling
      alert(error);
    }
  };

  const data = financialAnalysisData.map((obj) => ({
    Month: obj.monthName.substring(0, 3),
    Income: obj.netSalesAmount,
    Expense: obj.netPurchaseAmount,
  }));


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
          backgroundColor: '#000000',
          padding: '10px',
          borderRadius: '5px',
          color: "#FFFFFF",
          position: 'relative',
          top: '-10px',
          left: '-10px'
        }}>
          <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold' }}>{`${label}`}</p>
          <p style={{ margin: 0, }}>
            <span><MdOutlineTrendingUp style={{ fontSize: '16px', color: "#00AA07" }} /></span> ₹ {`${payload[0].value}`}
          </p>
          <p style={{ margin: 0, }}>
            <span><MdOutlineTrendingDown style={{ fontSize: '16px', color: "#EF0404" }} /></span> ₹ {`${payload[1].value}`}
          </p>
        </div>
      );
    }

    return null;
  };


  return (
    <>
      <Col lg={8} md={6} xs={12}>
        <Card>
          <CardBody style={{ height: "380px" }}>
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="title poppins-title finance-analysis-title">Finance Analysis</h6>
            </div>
            {loader ? (
              <ChildLoader />
            ) : (
              <div>
                <ResponsiveContainer className="h-300 mt-3" width="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="Month" />
                    {/* <YAxis /> */}
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar radius={[0, 0, 5, 5]} dataKey="Income" stackId="a" fill="#4caf50" />
                    <Bar radius={[5, 5, 0, 0]} dataKey="Expense" stackId="a" fill="#EF0404" />
                  </BarChart>
                </ResponsiveContainer>  
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default FinanceAnalysis;
