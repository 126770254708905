import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomInput from "components/Input/CustomInput";
import Common from "helpers/Common";
import { AUTH_URL_OTP } from "helpers/Paths";
import { URL_OTP } from "helpers/Paths";
import { AUTH_URL_LOGIN } from "helpers/Paths";
import { ADMIN_URL_DASHBOARD } from "helpers/Paths";
import { useLoginStore } from "hooks/Login";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  Container,
  Col,
  Row,
  Spinner,
  FormGroup,
  InputGroupText,
  InputGroup,
  Nav,
  NavItem,
} from "reactstrap";
import { isEmpty, isObject } from "underscore";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [loginPasswordState, setloginPasswordState] = useState("");
  const [loginConfirmPasswordState, setloginConfirmPasswordState] =
    useState("");
  const [{}, {}, { changePassword }] = useLoginStore();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const navigate = useNavigate();
  const isLogin = Common.getJwtToken();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const location = useLocation();

  useEffect(() => {
    checkIfAlreadyLoggedIn();
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const checkIfAlreadyLoggedIn = () => {
    if (isLogin) {
      navigate(ADMIN_URL_DASHBOARD);
    }
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let error = false;
    if (loginPasswordState === "" || loginPasswordState === "has-danger") {
      error = true;
      setloginPasswordState("has-danger");
    }
    if (
      loginConfirmPasswordState === "" ||
      loginConfirmPasswordState === "has-danger"
    ) {
      error = true;
      setloginConfirmPasswordState("has-danger");
    }

    if (!error) {
      let param = {
        RegMobileNumber: "+" + location.state.mobileNumber,
        // OTP: location.state.otp,
        NewPassword: password,
      };
      let res = await changePassword(param);
      if (res) {
        navigate(AUTH_URL_LOGIN);
        await setAlerts({
          type: "success",
          message: "Password changed successfully.",
        });
      } else {
        await setAlerts({ type: "danger", message: "Something went wrong." });
      }
    }

    setLoader(false);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  return (
    <Form className="form login-form-container" onSubmit={handleSubmit}>
      <h4 className="header text-center mt-0">Change Password</h4>
      <FormGroup className={`has-label ${loginPasswordState}`}>
        <label>New Password *</label>

        <CustomInput
          placeholder="Password"
          name="password"
          type={passwordType}
          autoComplete="off"
          onChange={(e) => {
            if (!Common.verifyLength(e.target.value, 6, 15)) {
              setloginPasswordState("has-danger");
            } else {
              setloginPasswordState("has-success");
            }
            setPassword(e.target.value);
          }}
          maxLength={15}
          onEyeClick={togglePassword}
          passwordType={passwordType}
          showEyeIcon={true}
        />

        {/* <InputGroupText style={{ cursor: 'pointer' }} onClick={togglePassword}>
          {passwordType === "password" ? <FontAwesomeIcon icon="fa-solid fa-eye-slash" /> : <FontAwesomeIcon icon="fa-solid fa-eye" />}
        </InputGroupText> */}

        {loginPasswordState === "has-danger" ? (
          <label className="error">
            Password must be 6 characters long and not more than 15 characters.
          </label>
        ) : null}
      </FormGroup>

      <FormGroup className={`has-label ${loginConfirmPasswordState}`}>
        <label>Confirm Password *</label>

        <CustomInput
          placeholder="Password"
          name="confirm password"
          type={confirmPasswordType}
          autoComplete="off"
          onChange={(e) => {
            if (!Common.verifySameValue(e.target.value, password)) {
              setloginConfirmPasswordState("has-danger");
            } else {
              setloginConfirmPasswordState("has-success");
            }
            setConfirmPassword(e.target.value);
          }}
          maxLength={15}
          onEyeClick={toggleConfirmPassword}
          passwordType={confirmPasswordType}
          showEyeIcon={true}
        />

        {/* <InputGroupText style={{ cursor: 'pointer' }} onClick={toggleConfirmPassword}>
          {passwordType === "password" ? <FontAwesomeIcon icon="fa-solid fa-eye-slash" /> : <FontAwesomeIcon icon="fa-solid fa-eye" />}
        </InputGroupText> */}

        {loginConfirmPasswordState === "has-danger" ? (
          <label className="error">
            Confirm password not matched with password.
          </label>
        ) : null}
      </FormGroup>
      <div className="category">* Required fields</div>
      <button
        className="custom-login-btn"
        disabled={loader ? true : false}
        type="submit"
      >
        Change Password
        {loader ? (
          <Spinner size="sm" color="light" style={{ marginLeft: "15px" }} />
        ) : (
          ""
        )}
      </button>
    </Form>
  );
};

export default ChangePassword;
