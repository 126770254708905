import APIHandler from "helpers/APIHandler";
import { GET_RECENT_BILL_API } from "helpers/APIRouter";
import {
  GET_CUSTOMER_COUNTS,
  GET_PRODUCT_SALES,
  GET_FINANCIAL_ANALYSIS,
  GET_TOP_SELLING_MEDICINES,
  GET_RECENT_INVENTORY,
  GET_RECENT_BILLS,
} from "helpers/APIRouter";

const API = new APIHandler();

class DashboardV2APICall {
  getCustomerCounts = async () => {
    let res = {};
    await API.get(GET_CUSTOMER_COUNTS)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getProducSales = async (params) => {
    let res = {};
    await API.get(GET_PRODUCT_SALES, params)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getFinancialAnalysis = async (params) => {
    let res = {};
    await API.get(GET_FINANCIAL_ANALYSIS, params)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getTopSellingMedicines = async (params) => {
    let res = {};
    await API.get(GET_TOP_SELLING_MEDICINES, params)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getRecentInventory = async (params) => {
    let res = {};
    await API.get(GET_RECENT_INVENTORY, params)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getRecentBills = async (params) => {
    let res = {};
    await API.get(GET_RECENT_BILL_API, params)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default DashboardV2APICall;
