import React from "react";
// reactstrap
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";


const HeaderFooterPreview = ({ header, footer }) => {

    return(
        <Col sm='12'>
        <Row>
            <Col sm='5'>
            <Card>
             <CardHeader style={{ backgroundColor: '#005792' }}>
             <h5 className='title' style={{ color: 'white',margin: '1%' }}>Invoice Header</h5>
            </CardHeader>
            <CardBody>
                 <p
                     dangerouslySetInnerHTML={{ __html: header }}
                ></p>
            </CardBody>
         </Card>
            </Col>
            <Col sm='1'></Col>
            <Col sm='5'>
            <Card>
             <CardHeader style={{ backgroundColor: '#005792' }}>
                <h5 className='title' style={{ color: 'white',margin: '1%' }}>Invoice Footer</h5>
             </CardHeader>
             <CardBody>
                 <p
                     dangerouslySetInnerHTML={{ __html: footer }}
                 ></p>
             </CardBody>
         </Card>
            </Col>
        </Row>
        </Col>
    )
}
export default HeaderFooterPreview