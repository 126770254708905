import React from 'react'
import { Col, Row } from 'reactstrap'

const Pricing = () => {

    const PLANS = [
        {
            name: "Free Trial",
            price: 0,
            duration: "For 1 month",
            inventoryCount: 100,
            billCount: 100,
            sms: 100,
        },
        {
            name: "Cost Effective Plan",
            price: 1000,
            duration: "/ 5 year",
            inventoryCount: 500,
            billCount: 500,
            sms: 500,
        },
        {
            name: "Small Business",
            price: 9439,
            duration: "/ year",
            inventoryCount: 50000,
            billCount: 50000,
            sms: 50000,
        },
        {
            name: "Enterprise",
            price: 17699,
            duration: "/ year",
            inventoryCount: '2L',
            billCount: '2L',
            sms: '2L',
        },
    ]

    return (
        <div className="pricing-section">
            <h1 className="landing-page-title">Pricing</h1>
            <Row className='card-container'>
                {
                    PLANS.map((plan, index) => (
                        <Col key={index} xs={12} md={6} lg={3}>
                            <div className="price-card">
                                <p>{plan.name}</p>
                                <div className='price-text'>
                                    <p><span>₹{plan.price}</span>&nbsp;{plan.duration}</p>
                                </div>

                                <div className="features-included">
                                    <p><span>{plan.inventoryCount}</span> Inventory Count</p>
                                    <p><span>{plan.billCount}</span> Maximum Invoice / Bill Count</p>
                                    <p><span>{plan.sms}</span> SMS, Email, WhatsApp Count</p>
                                </div>

                                <div className="choose-plan-btn">
                                    <button>
                                        Choose Plan
                                    </button>
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}

export default Pricing