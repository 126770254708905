import {
  Row,
  Col,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Tooltip,
  FormFeedback,
} from "reactstrap";
import "../../assets/styles/CreateBill.css";
import { useEffect, useRef, useState } from "react";
import { useCreateBillStore } from "hooks/CreateBill";
import "react-phone-input-2/lib/style.css";
import { AutoComplete } from "primereact/autocomplete";
import { RiEdit2Fill } from "react-icons/ri";
import "../../assets/styles/CreateBill.css";
import { FcInfo } from "react-icons/fc";
import { useParams } from "react-router-dom";
import CustomerBillHistoryModal from "views/CreateBill/CustomerBillHistoryModal";
import TemplateButton from "views/CreateBill/TemplateButton";
import SuggestedProductsButton from "views/CreateBill/SuggestedProductsButton";

const BillHistoryModal = (props) => {
  const [{}, { getCustomerDetail, Get_Bills_By_CustomerID }, {}] =
    useCreateBillStore();
  const { customerName } = useParams();
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [recentPopoverOpen, setRecentPopoverOpen] = useState(false);
  const [suggestedPopoverOpen, setSuggestedPopoverOpen] = useState(false);
  const [customerBillHistoryData, setCustomerBillHistoryData] = useState([]);
  const [backdrop, setBackdrop] = useState(true);
  const [keyboard, setKeyboard] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [noResultFound, setNoResultFound] = useState(false);
  const [selectedCustomer, setselectedCustomer] = useState();
  const addCustomerRef = useRef(null);

  const toggleRecentPopover = () => {
    setRecentPopoverOpen(!recentPopoverOpen);
  };

  const toggleSuggestedPopover = () => {
    setSuggestedPopoverOpen(!suggestedPopoverOpen);
  };

  const recentProductRef = useRef(null);
  const suggestedProductRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        recentProductRef.current &&
        !recentProductRef.current.contains(event.target)
      ) {
        setRecentPopoverOpen(false);
      }
      if (
        suggestedProductRef.current &&
        !suggestedProductRef.current.contains(event.target)
      ) {
        setSuggestedPopoverOpen(false);
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    retriveCustomers(customerName);
  }, [customerName]);

  const handleLoadMore = () => {
    const data = customerBillHistoryData.slice(
      pageNumber * pageSize,
      pageNumber * pageSize + pageSize
    );
    setTableData((prev) => [...prev, ...data]);
    setPageNumber((prev) => prev + 1);
  };

  const fetchCustomerBillHistory = async (customerID) => {
    if (customerID) {
      setPageNumber(1);
      const customerBillHistory = await Get_Bills_By_CustomerID(customerID);

      setCustomerBillHistoryData(customerBillHistory);
      const data = customerBillHistory.slice(0, pageSize);
      setTableData(data);
      toggle();
    }
  };

  const autoCompleteRef = useRef(null);

  const retriveCustomers = async (queryText) => {
    try {
      // Assuming getCustomerDetail(queryText) returns the customerList array
      let customerList = await getCustomerDetail(queryText);
      // Function to modify customer names by concatenating with emailId
      const modifyCustomerNames = () => {
        customerList.forEach((customer) => {
          customer.customerName = `${customer.customerName} (${customer.phoneNumber})`;
        });
      };

      // Call the function to modify customer names
      modifyCustomerNames();

      setFilteredCustomer(customerList);
      if (customerList.length === 0) {
        setNoResultFound(true);
      }
    } catch (error) {
      console.error("Error retrieving customers:", error);
      throw error; // Re-throw the error to handle it further up the call stack
    }
  };

  const filterCustomer = (event) => {
    retriveCustomers(event.query);
  };
  useEffect(() => {
    if (autoCompleteRef.current) {
      autoCompleteRef.current.focus(); // Set focus when component mounts
    }
  }, []);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpenRecentProduct, setTooltipOpenRecentProduct] =
    useState(false);
  const [tooltipDraft, setTooltipDraft] = useState(false);

  const [tooltipOpenSuggestedProduct, setTooltipOpenSuggestedProduct] =
    useState(false);
  const [tooltipBillHistory, setTooltipBillHistory] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const toggleTooltipRecent = () =>
    setTooltipOpenRecentProduct(!tooltipOpenRecentProduct);
  const toggleTooltipSuggested = () =>
    setTooltipOpenSuggestedProduct(!tooltipOpenSuggestedProduct);
  const toggleTooltipBillHistory = () =>
    setTooltipBillHistory(!tooltipBillHistory);
  const toggleTooltipDraft = () => setTooltipDraft(!tooltipDraft);

  return (
    <>
      <button
        className="add-customer-btn"
        type="button"
        tabIndex={0}
        id="editCustomer"
        onClick={toggle}
        ref={addCustomerRef}
      >
        <RiEdit2Fill size={16} />
        {/* <img src={addCustomer} alt="Add Customer" className="bill-icon" /> */}
      </button>

      <Tooltip
        autohide={true}
        flip={true}
        placement="top"
        isOpen={tooltipOpen}
        target="editCustomer"
        toggle={toggleTooltip}
        style={{ backgroundColor: "#434CE6", color: "#ffffff" }}
      >
        Edit Customer
      </Tooltip>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={backdrop}
        keyboard={keyboard}
        fullscreen={"xl"}
        size={"xl"}
      >
        <ModalBody>
          <Container>
            <div className="content-dashboard-report">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="title">
                  Reports / Quantity per product Forecast{" "}
                </h4>
              </div>
              <Row xs="1">
                <Col xs="12" md="6">
                  <Row className="align-items-center">
                    <Col xs="12" md="4" className="d-flex align-items-center">
                      <Label for="searchCustomer" className="mb-0">
                        Search Customer <span style={{ color: "red" }}>*</span>
                      </Label>
                      {/* <p
                        id="TooltipExample"
                        className="mb-0 ml-2"
                        style={{ cursor: "pointer" }}
                      >
                        <FcInfo size="18px" />
                      </p>
                      <Tooltip
                        autohide={true}
                        flip={true}
                        isOpen={tooltipOpen}
                        target="TooltipExample"
                        toggle={toggleTooltip}
                        style={{ backgroundColor: "#000000", color: "#ffffff" }}
                      >
                        Search By Customer Name or Mobile number
                      </Tooltip> */}
                    </Col>

                    <Col xs="12" md="5" style={{ padding: 0 }}>
                      <AutoComplete
                        ref={autoCompleteRef}
                        value={selectedCustomer}
                        suggestions={filteredCustomer}
                        completeMethod={filterCustomer}
                        field="customerName"
                        placeholder="Search by Customer Name or Mobile number"
                        showEmptyMessage={true}
                        emptyMessage="No results found"
                        minLength={1}
                        inputStyle={{ width: "450px" }}
                        onChange={(e) => {
                          setSelectedCustomer(e.value);
                          if (e.value?.customerID) {
                            const customerID = e.value?.customerID;
                            setSelectedCustomerID(customerID);
                          } else {
                            setSelectedCustomerID(null);
                            setCustomerData({
                              customerName: "",
                              address1: "",
                              address2: "",
                              address: "",
                              phoneNumber: "",
                              emailId: "",
                            });
                          }
                          const newAddress = `${e.value?.address1 ?? ""}, ${
                            e.value?.address2 ?? ""
                          }\n${e.value?.city ?? ""}, ${e.value?.state ?? ""}`;
                          customerData.customerName =
                            e.value?.customerName ?? "";
                          customerData.address = newAddress;
                          customerData.phoneNumber = e.value?.phoneNumber ?? "";
                          customerData.emailId = e.value?.emailId ?? "";
                        }}
                        pt={{
                          panel: {
                            className: "autocomplete-Zindex",
                          },
                          input: {
                            root: {
                              className: "custom-width-input form-control",
                            },
                          },
                        }}
                        className="custom-width-input"
                        style={{ width: "100%" }}
                        delay={300}
                      />
                    </Col>

                    <Col
                      xs="12"
                      md="3"
                      className="d-flex justify-content-md-end mt-2 mt-md-0"
                    >
                      {/* <AddCustomerModal
                  customerName={noResultFound ? selectedCustomer : ""}
                  setNoResultFound={() => setNoResultFound(false)}
                />
                <EditCustomerModal /> */}
                    </Col>
                  </Row>
                </Col>

                <Col sx="12" sm="12" md="6" className="addCustomerBtn">
                  <Row style={{ width: "100%" }}>
                    <Col sx="12">
                      <div className="bill-icons-template">
                        <button
                          style={{
                            background: "transparent",
                            border: "none",
                            marginBottom: "10px",
                          }}
                          id="draft"
                          type="button"
                          // onClick={createDraft}
                        >
                          {/* <img
                      src={`${ERP_CDN_IMAGE_URL}/draft.png`}
                      onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop if logo also fails
                        e.target.src = draft;
                      }}
                      alt="draft"
                      className="bill-icon"
                    /> */}
                        </button>

                        <Tooltip
                          autohide={true}
                          flip={true}
                          placement="top"
                          isOpen={tooltipDraft}
                          target="draft"
                          toggle={toggleTooltipDraft}
                          style={{
                            backgroundColor: "#000000",
                            color: "#ffffff",
                          }}
                        >
                          Create Draft
                        </Tooltip>

                        {/* <button
                    style={{
                      background: "transparent",
                      border: "none",
                      marginBottom: "10px",
                      opacity:
                        selectedCustomerID && filteredCustomer.length > 0
                          ? "1"
                          : "0.5",
                      cursor:
                        selectedCustomerID && filteredCustomer.length > 0
                          ? "pointer"
                          : "not-allowed",
                    }}
                    id="billHistory"
                    type="button"
                    onClick={() => fetchCustomerBillHistory(selectedCustomerID)}
                    disabled={
                      !selectedCustomerID || filteredCustomer.length === 0
                    }
                  > */}
                        {/* <img
                      src={`${ERP_CDN_IMAGE_URL}/bill_history.png`}
                      onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop if logo also fails
                        e.target.src = billHistory;
                      }}
                      alt="Bill History"
                      className="bill-icon"
                      style={{
                        cursor:
                          selectedCustomerID && filteredCustomer.length > 0
                            ? "pointer"
                            : "not-allowed",
                      }}
                    />
                  </button> */}

                        {/* <Tooltip
                          autohide={true}
                          flip={true}
                          placement="top"
                          isOpen={tooltipBillHistory}
                          target="billHistory"
                          toggle={toggleTooltipBillHistory}
                          style={{
                            backgroundColor: "#000000",
                            color: "#ffffff",
                          }}
                        >
                          Bill History
                        </Tooltip> */}

                        <CustomerBillHistoryModal
                          modal={modal}
                          toggle={toggle}
                          backdrop={backdrop}
                          keyboard={keyboard}
                          customerBillHistoryData={customerBillHistoryData}
                          customerName={selectedCustomer?.customerName}
                          handleLoadMore={handleLoadMore}
                          tableData={tableData}
                        />
                        <TemplateButton
                          toggleRecentPopover={toggleRecentPopover}
                          tooltipOpenRecentProduct={tooltipOpenRecentProduct}
                          toggleTooltipRecent={toggleTooltipRecent}
                          handleTemplateSelect={handleTemplateSelect}
                        />
                        <SuggestedProductsButton
                          toggleSuggestedPopover={toggleSuggestedPopover}
                          tooltipOpenSuggestedProduct={
                            tooltipOpenSuggestedProduct
                          }
                          toggleTooltipSuggested={toggleTooltipSuggested}
                          suggestedProductList={suggestedProductList}
                          handleSuggestedProductSelect={
                            handleSuggestedProductSelect
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default BillHistoryModal;
