import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CreateBillRepository from "../../repository/CreateBillRepository";
import TemplateRepository from "repository/TemplateRepository";

export const getTemplateDetailsById = createAsyncThunk('template_getTemplateDetailsById', async (params) => {
    return await new TemplateRepository().getTemplateDetailsById(params);
});

const initialState = {
    templateDetail: {}
};
const slice = createSlice({
    name: "template",
    initialState: initialState,

    reducers: {},

    extraReducers: {
        [getTemplateDetailsById.fulfilled]: (state, { payload }) => {
            state.templateDetail = payload;
            return state;
        },
        [getTemplateDetailsById.rejected]: (state, { payload }) => {
        }
    },
});

export let { setPatientDetail } = slice.actions;
export let {setCustomerDetail} = slice.actions;

export default slice.reducer;
