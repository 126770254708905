import AzureRepository from "repository/AzureRepository";
import CreateBillRepository from "../repository/CreateBillRepository";

export function useAzureStore() {
  /**
   * Here is list of use selectors which are relates to create bill
   * Author: Devanshi Patel
   */

  let data = {};
  let fetch = {
    /**
     * Here is list of fetch methods which are used for get data from API or Repositories related to Azure
     * Author: Devanshi Patel
     * dispatch is used for reducer or thunk method
     * if redux not needed use repository method
     */
    fetchImagesFromAzure: async (file, moduleName) => {
      return await new AzureRepository().fetchImagesFromAzure(file, moduleName);
    },

    fetchInventorySampleFromAzure: async () => {
      return await new AzureRepository().fetchInventorySampleFromAzure();
    },
  };
  let set = {
    /**
     * Here is list of fetch methods which are used for set or update data from API or Repositories related to azure
     * Author: Devanshi Patel
     */
    uploadToAzure: async (file, moduleName) => {
      return await new AzureRepository().uploadToAzure(file, moduleName);
    },

    inventoryFileUploadToAzure: async (file) => {
      return await new AzureRepository().inventoryFileUploadToAzure(file);
    },

    billFileUploadToAzure: async (file) => {
      return await new AzureRepository().billFileUploadToAzure(file);
    },
  };
  return [data, fetch, set];
}
