import LoginAPICall from "service/LoginAPICall";
import BaseRepository from "./BaseRepository";

class LoginRepository extends BaseRepository {

    companyLogin = async (params) => {
        return await new LoginAPICall().companyLogin(params);
    };

    changePassword = async (params) => {
        return await new LoginAPICall().changePassword(params);
    };

    loginWithOTP = async (params) => {
        return await new LoginAPICall().loginWithOTP(params);
    };

    loginVerifyOTP = async (params) => {
        return await new LoginAPICall().loginVerifyOTP(params);
    };

    validateUserLogin = async () => {
        return await new LoginAPICall().validateUserLogin();
    };

}

export default LoginRepository;