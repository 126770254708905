import { useEffect, useState } from "react";
// reactstrap
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap";
// CompanyProfile
import HeaderFooterPreview from "./HeaderFooterPreview";
// hooks
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { useLoginStore } from "hooks/Login";
// underscore
import { isEmpty } from "underscore";
// helpers
import CustomButton from "components/Button/CustomButton";
import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";

const HeaderFooter = ({ getGenricData, taxDetails }) => {
  const [headerFooterValue, setHeaderFooterValue] = useState({});
  const [valiDate, setValiDate] = useState({});
  const [preView, setPreView] = useState(false);
  const [{ }, { }, { updateGenricData }] = useCompanyProfileStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();

  function handelPreview() {
    setPreView(!preView);
  }

  useEffect(() => {
    try {
      setHeaderFooterValue({
        headerValue: taxDetails?.filter(
          (data) => data.feature_key === "bill_header"
        )[0].feature_value,
        footerValue: taxDetails?.filter(
          (data) => data.feature_key === "bill_footer"
        )[0].feature_value,
      });
    } catch (error) { }
  }, [taxDetails]);

  async function saveDetails(e) {
    e.preventDefault();
    let error = false;
    let validatation = { ...valiDate };

    if (valiDate && isEmpty(valiDate)) {
      error = true;
      validatation.headerValue = "has-danger";
      validatation.footerValue = "has-danger";
    } else {
      if (!headerFooterValue.headerValue) {
        error = true;
        validatation.headerValue = "has-danger";
      }
      if (!headerFooterValue.footerValue) {
        error = true;
        validatation.footerValue = "has-danger";
      }
    }
    setValiDate(validatation);
    if (!error) {
      let param = [
        {
          feature_key: "bill_header",
          feature_value: headerFooterValue.headerValue,
        },
        {
          feature_key: "bill_footer",
          feature_value: headerFooterValue.footerValue,
        },
      ];
      const res = await updateGenricData(param);
      if (res) {
        const response = await getGenricData();
        await setAlerts({
          type: "success",
          message: notificationMessages.invoiceheaderFooter,
        });
      }
    }
  }

  return (
    <div className="profile-content-container">
      <div className="profile-content-title">
        <h5>Invoice Header And Footer</h5>
      </div>


      <div className="profile-address-form">
        <Row>
          <Col sm="12" md="6">
            <div className="custom-input-container">
              <label>Invoice Header</label>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Enter header text here..."
                  value={headerFooterValue.headerValue}
                  valid={valiDate?.headerValue === "has-success"}
                  invalid={valiDate?.headerValue === "has-danger"}
                  onChange={(e) => {
                    let error = { ...valiDate };
                    if (!Common.verifyLength(e.target.value, 1)) {
                      error.headerValue = "has-danger";
                    } else {
                      error.headerValue = "has-success";
                    }
                    setValiDate(error);
                    setHeaderFooterValue({
                      ...headerFooterValue,
                      headerValue: e.target.value,
                    });
                  }}
                  maxLength={15000}
                />
                <FormFeedback>header is required</FormFeedback>
              </FormGroup>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="6">
            <div className="custom-input-container">
              <label>Invoice Footer</label>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Enter footer text here..."
                  value={headerFooterValue.footerValue}
                  valid={valiDate?.footerValue === "has-success"}
                  invalid={valiDate?.footerValue === "has-danger"}
                  onChange={(e) => {
                    let error = { ...valiDate };
                    if (!Common.verifyLength(e.target.value, 1)) {
                      error.footerValue = "has-danger";
                    } else {
                      error.footerValue = "has-success";
                    }
                    setValiDate(error);
                    setHeaderFooterValue({
                      ...headerFooterValue,
                      footerValue: e.target.value,
                    });
                  }}
                  maxLength={15000}
                />
                <FormFeedback>Footer is required</FormFeedback>
              </FormGroup>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="12">
            {preView ? (
              <HeaderFooterPreview
                header={headerFooterValue.headerValue}
                footer={headerFooterValue.footerValue}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>

        <div className="mt-lg-3 profile-button-container">
          <CustomButton
            label="Save Details"
            onClick={saveDetails}
          />
          <CustomButton
            label="Preview"
            onClick={handelPreview}
            customStyle='profile-reset-btn'
          />
        </div>

      </div>

    </div>
  );
};

export default HeaderFooter;
