import { useGSAP } from "@gsap/react";
import bg from "assets/img/bg/landing-page-bg.png";
import FixedPlugin from "components/FixedPlugin/FixedPlugin";
import gsap from "gsap";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import { useRef } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Hero = () => {
  const heroSection1Ref = useRef();
  const heroSection2Ref = useRef();

  useGSAP(() => {
    gsap.from(heroSection1Ref.current, {
      opacity: 0,
      x: -100,
      duration: 1,
      ease: "power.inOut",
    });

    gsap.from(heroSection2Ref.current, {
      opacity: 0,
      x: 100,
      duration: 1,
      ease: "power.inOut",
    });
  });

  return (
    <div className="hero-section">
      <div className="hero-left-section" ref={heroSection1Ref}>
        <div className="live-badge">
          <div className="white-dot blink"></div>
          Now Live
        </div>

        <h1>Our Secure, Smart & Easy to Use ERP</h1>
        <p>
          Empower your enterprise with Apsthreeai.ai, the ultimate AI-driven
          solution designed to accelerate efficiency and productivity.
          Streamline operations and catalyze business growth with a suite of
          tools tailored for excellence in management and forecasting.
        </p>

        <Link
          to="/auth/signup"
          style={{ textDecoration: "none" }}
          className="hero-start-btn"
        >
          Get Started <FaArrowRightLong />
        </Link>
      </div>
      <div className="hero-right-section" ref={heroSection2Ref}>
        <img
          src={`${ERP_CDN_IMAGE_URL}/landing-page-bg.png`}
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop if logo also fails
            e.target.src = bg;
          }}
          alt="Hero"
        />
      </div>
      <FixedPlugin />
    </div>
  );
};

export default Hero;
