import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import NormalLayout from "layouts/Normal.js";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.1";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/styles/Common.css";
import ReactGA from 'react-ga4';
import { Provider } from "react-redux";
import store from "store/store";
import { AUTH_URL_LOGIN } from "helpers/Paths";
import { loadSiteConfig } from "store/slice/Site";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Alert from "components/Alert/Alert";
import EditBill from "views/CreateBill/EditBill";
import LandingPage from "views/New_LandingPage/LandingPage";
import TrackPageViews from "components/Tracking/TrackPageViews";

ReactGA.initialize('G-0B4NF5CK1E'); // Replace with your GA4 Tracking ID

const APSTHREEAILLPConfig = window.APSTHREEAILLP;
store.dispatch(loadSiteConfig(APSTHREEAILLPConfig));
library.add(fas);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Alert />
      <TrackPageViews />  {/* Add the tracking component here */}
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/alert/*" element={<NormalLayout />} />
        <Route path="/*" element={<LandingPage />} />
        {/* <Route path="/admin/editBill/:id" element={<EditBill />} /> */}
      </Routes>
    </BrowserRouter>
  </Provider>
);