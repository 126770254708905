import { forwardRef } from "react";
import "../../assets/styles/Invoice.css";
import { getDate } from "helpers/utils";
import { Container, Table, Input, Row, Col, Card } from "reactstrap";
import React from "react";
import "../../assets/styles/CreateBill.css";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceFooter from "./InvoiceFooter";
import getSymbolFromCurrency from "currency-symbol-map";
import CreateBillRepository from "repository/CreateBillRepository";

const Invoice = forwardRef((props, ref) => {
  const {
    rows,
    subTotal,
    totalBeforeTax,
    igst,
    total,
    manualDiscountAmount,
    manualDiscount,
    billID,
  } = props;
  const currency = new CreateBillRepository().getCurrencyAbbriviation();
  return (
    <>
      <div
        ref={ref}
        className="invoice-container"
        style={{
          margin: "0px",
          marginTop: "-10px",
          padding: "0px",
          // marginBottom: "-20px",
        }}
      >
        <InvoiceHeader billID={billID} />
        <div className="invoice-content">
          <Container>
            <table className="custom-table custom-table-2">
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#ffb400",
                      boxShadow: "4px 4px 4px 5px #ffb400",
                      textAlign:'left',
                      fontSize:'12px'
                    }}
                  >
                    Particulars
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ffb400",
                      boxShadow: "4px 4px 4px 5px #ffb400",
                      textAlign:'center',
                      fontSize:'12px'
                    }}
                  >
                    Qty
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ffb400",
                      boxShadow: "4px 4px 4px 5px #ffb400",
                      textAlign:'center',
                      fontSize:'12px'
                    }}
                  >
                    Rate
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ffb400",
                      boxShadow: "4px 4px 4px 5px #ffb400",
                      textAlign:'center',
                      fontSize:'12px'
                    }}
                  >
                    Amount
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ffb400",
                      boxShadow: "4px 4px 4px 5px #ffb400",
                      textAlign:'center',
                      fontSize:'12px'
                    }}
                  >
                    Discount
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ffb400",
                      boxShadow: "4px 4px 4px 5px #ffb400",
                      textAlign:'center',
                      fontSize:'12px'
                    }}
                  >
                    Cost
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ffb400",
                      boxShadow: "4px 4px 4px 5px #ffb400",
                      textAlign:'left',
                      fontSize:'10px'
                    }}
                  >
                    SGST
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ffb400",
                      boxShadow: "4px 4px 4px 5px #ffb400",
                      textAlign:'left',
                      fontSize:'10px'
                    }}
                  >
                    CGST
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ffb400",
                      boxShadow: "4px 4px 4px 5px #ffb400",
                      textAlign:'left',
                      fontSize:'11px',
                      wordBreak:'unset',
                      wordWrap:'unset'
                    }}
                  >
                    Net Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td style={{textAlign:'left'}}>{row?.productName}</td>
                    <td style={{textAlign:'center'}}>{row?.qty}</td>
                    <td style={{textAlign:'center'}}>{row?.rate}</td>
                    <td style={{textAlign:'center'}}>{row?.amount}</td>
                    <td style={{textAlign:'center'}}>{row?.discountAmount}</td>
                    <td style={{textAlign:'center'}}>{row?.productCost}</td>
                    <td style={{textAlign:'center'}}>{row?.sgst}</td>
                    <td style={{textAlign:'center'}}>{row?.cgst}</td>
                    <td style={{textAlign:'right'}}>{row?.netAmount&&row?.netAmount?.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
              <Card style={{marginTop:'1%', border:'1px solid #e0e0e0'}}>
            <Row  style={{width:'100%', margin:'auto', 
            }}>
              
                  <Col lg='9' style={{textAlign:'right',paddingTop:'1%',fontWeight:'bold', fontSize:'12px'}}>SubTotal</Col>
                  <Col lg='3'  style={{textAlign:'right', paddingTop:'1%',fontSize:'12px'}}>
                    {
                        getSymbolFromCurrency(currency) +
                        " " +
                        subTotal}</Col>
               </Row>
               <Row style={{width:'100%', margin:'auto',
            }}>
               
                <Col lg='9' style={{textAlign:'right' ,fontWeight:'bold',fontSize:'12px' }}>
                  Manual Discount(%)
                </Col>
                <Col lg='3' style={{textAlign:'right',fontSize:'12px'}}>
                {isNaN(parseFloat(manualDiscountAmount))
                        ? 0.0
                        : parseFloat(manualDiscountAmount).toFixed(2)}
                </Col>
                </Row>
                <Row style={{width:'100%', margin:'auto',
            }}>
             <Col  lg='9'style={{textAlign:'right', fontWeight:'bold',fontSize:'12px'}}>
                  Total (before tax)
                </Col>
                <Col lg='3'  style={{textAlign:'right',fontSize:'12px'}}>
                  {
                        getSymbolFromCurrency(currency) +
                        " " +
                        totalBeforeTax}
                  </Col>
                </Row>
                <Row style={{width:'100%', margin:'auto',
            }}>
                
                <Col lg='9' style={{textAlign:'right', fontWeight:'bold',fontSize:'12px'}}>IGST</Col>
                <Col lg='3'  style={{textAlign:'right',fontSize:'12px'}}>{igst}</Col>
                </Row>
                <Row style={{width:'100%', margin:'auto',
            }}>
                <Col lg='9' style={{textAlign:'right', fontWeight:'bold',color:'#ffb400',fontSize:'12px'}}>Grand Total</Col>
                <Col lg='3'  style={{textAlign:'right',fontSize:'12px'}}>
                  {
                        getSymbolFromCurrency(currency) +
                        " " +
                        total}
                  </Col>
            </Row>
            </Card>
            {/* <Table borderless className="custom-table">
              <tbody>
                <tr style={{ fontSize: "26px" }}>
                  <td
                    colSpan="9"
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    SUBTOTAL
                  </td>
                  <td
                    colSpan="1"
                    style={{
                      display: "flex",
                      width: "350px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ textAlign: "right !important" }}>
                      {'(' + currency + ') ' + getSymbolFromCurrency(currency) + ' ' + subTotal}
                    </div>
                  </td>
                </tr>
                <tr style={{ fontSize: "26px" }}>
                  <td
                    colSpan="9"
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    MANUAL DISCOUNT(%)
                    <Input
                      type="number"
                      min="0"
                      style={{ width: "350px" }}
                      value={manualDiscount}
                    />
                  </td>
                  <td
                    colSpan="1"
                    style={{
                      display: "flex",
                      width: "350px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ textAlign: "right !important" }}>
                      {isNaN(parseFloat(manualDiscountAmount))
                        ? 0.0
                        : parseFloat(manualDiscountAmount).toFixed(2)}
                    </div>
                  </td>
                </tr>
                <tr style={{ fontSize: "26px" }}>
                  <td
                    colSpan="9"
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    Total (before tax)
                  </td>
                  <td
                    colSpan="1"
                    style={{
                      display: "flex",
                      width: "350px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ textAlign: "right !important" }}>
                      {'(' + currency + ') ' + getSymbolFromCurrency(currency) + ' ' + totalBeforeTax}
                    </div>
                  </td>
                </tr>
                <tr style={{ fontSize: "26px" }}>
                  <td
                    colSpan="9"
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    IGST
                  </td>
                  <td
                    colSpan="1"
                    style={{
                      display: "flex",
                      width: "350px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ textAlign: "right !important" }}>{igst}</div>
                  </td>
                </tr>
                <tr style={{ fontSize: "26px" }}>
                  <td
                    colSpan="9"
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    Grand Total
                  </td>
                  <td
                    colSpan="1"
                    style={{
                      display: "flex",
                      width: "350px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ textAlign: "right !important" }}>{'(' + currency + ') ' + getSymbolFromCurrency(currency) + ' ' + total}</div>
                  </td>
                </tr>
              </tbody>
            </Table> */}
          </Container>
          <InvoiceFooter grandTotal={total} />
        <p style={{backgroundColor:'#ffb400', height:'5px'}}></p>
        </div>
       
      </div>
    </>
  );
});

export default Invoice;
