import { useSelector } from "react-redux";
import store from "../store/store";
import {
  getBranchDetail,
  getPatientDetail,
  getCustomerDetail,
} from "../store/slice/CreateBill";
import CreateBillRepository from "../repository/CreateBillRepository";

export function useCreateBillStore() {
  /**
   * Here is list of use selectors which are relates to create bill
   * Author: Devanshi Patel
   */
  const branchDetail = useSelector(
    (state) => state.entities.CreateBill.branchDetail
  );
  const patientDetail = useSelector(
    (state) => state.entities.CreateBill.patientDetail
  );
  const customerDetail = useSelector(
    (state) => state.entities.CreateBill.customerDetail
  );

  let data = {
    branchDetail,
    patientDetail,
    customerDetail,
  };
  let fetch = {
    /**
     * Here is list of fetch methods which are used for get data from API or Repositories related to create bill
     * Author: Devanshi Patel
     * dispatch is used for reducer or thunk method
     * if redux not needed use repository method
     */
    getBranchDetail: async () => {
      return await store.dispatch(getBranchDetail());
    },

    getPatientDetail: async (params) => {
      return await new CreateBillRepository().getPatientDetail(params);
    },

    getCustomerDetail: async (params) => {
      return await new CreateBillRepository().getCustomerDetail(params);
    },

    getProductSearchList: async (params) => {
      return await new CreateBillRepository().getProductSearchList(params);
    },

    getRecentBill: async (params) => {
      return await new CreateBillRepository().getRecentBill(params);
    },

    getBill: async (params) => {
      return await new CreateBillRepository().getBill(params);
    },

    getTemplateList: async (params) => {
      return await new CreateBillRepository().getTemplateList(params);
    },

    GetTemplateDetails_By_ID: async (params) => {
      return await new CreateBillRepository().GetTemplateDetails_By_ID(params);
    },

    getSuggestedProduct: () => {
      return new CreateBillRepository().getSuggestedProduct();
    },
    getProductAdvancedSearch: async (params) => {
      return new CreateBillRepository().getProductAdvancedSearch(params);
    },
    Get_Bills_By_CustomerID: async (params) => {
      return await new CreateBillRepository().Get_Bills_By_CustomerID(params);
    },
  };
  let set = {
    /**
     * Here is list of fetch methods which are used for set or update data from API or Repositories related to create bill
     * Author: Devanshi Patel
     */
    saveBill: async (params) => {
      return await new CreateBillRepository().saveBill(params);
    },

    saveInvoice: async (params) => {
      return await new CreateBillRepository().saveInvoice(params);
    },

    refundBill: async (params) => {
    //   console.log(params);

      return await new CreateBillRepository().refundBill(params);
    },

    createCustomer: async (params) => {
      return await new CreateBillRepository().createCustomer(params);
    },

    updateCustomer: async (params) => {
      return await new CreateBillRepository().updateCustomer(params);
    },

    createBillTemplate: async (params) => {
      return await new CreateBillRepository().createBillTemplate(params);
    },

    setSuggestedProduct: (params) => {
      return new CreateBillRepository().setSuggestedProduct(params);
    },
  };
  return [data, fetch, set];
}
