import React from "react";


const NetProfitReport = () => {
    return(
        <>
        NetProfiteReport
        </>
    )
}

export default NetProfitReport