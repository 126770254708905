import React from "react";
import { Row, Col, Spinner } from "reactstrap";

function ChildLoader(props) {
  return (
    <Col
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row className="mb-1">
        <div>
          <Spinner color="primary" type="grow">
            Loading...
          </Spinner>
          <Spinner color="success" type="grow">
            Loading...
          </Spinner>
          <Spinner color="warning" type="grow">
            Loading...
          </Spinner>
        </div>
      </Row>
    </Col>
  );
}

export default ChildLoader;
