import React, { useState } from "react";
// reactstrap
import { Card,Button, CardBody, CardHeader } from "reactstrap";
// views
import InvoiceBillPrint from "./InvoiceBillPrint";
import CreateInvoice from "./CreateInvoice";
import CreateInventory from "./CreateInvetory";
import ViewRecentOrders from "./ViewRecentOrders";
import FindCustomerOrder from "./FindCustomerOrder";
import ReorderQualityReport from "./ReorderQualityReport";
import ForCastReport from "./ForCastReport";
import NetProfitReport from "./NetProfitReport";
// react-icons
import { BiLeftArrow } from "react-icons/bi";


const InvoiceBillStep = () => {
  let stepList = [
    <CreateInvoice />,
    <CreateInventory />,
    <InvoiceBillPrint />,
    <ViewRecentOrders />,
    <FindCustomerOrder />,
    <NetProfitReport />,
    <ReorderQualityReport />,
    <ForCastReport />,
  ];
  const [ index, setIndex ] = useState(0);

  function handlePreviousStep() {
    if (index !== 0) {
      setIndex(index - 1);
    }
  }

  function handleNextStep() {
    if (stepList.length - 1 !== index) {
      setIndex(index + 1);
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Button onClick={handlePreviousStep} color="primary">
            <BiLeftArrow />
          </Button>
          <Button
            onClick={handleNextStep}
            color="primary"
            style={{ float: "right" }}
          >
            next
          </Button>
        </CardHeader>
        <CardBody>{stepList[index]}</CardBody>
      </Card>
    </>
  );
}
export default InvoiceBillStep