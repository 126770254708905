export const getDate = () => {
  // Date object
  const date = new Date();

  let currentDay = String(date.getDate()).padStart(2, "0");

  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");

  let currentYear = date.getFullYear();

  // we will display the date as DD-MM-YYYY
  let currentDate = `${currentDay}-${currentMonth}-${currentYear}`;

  return currentDate.toString();
};

// CDN Image Paths
export const ERP_LOGO =
  "https://erpcontent.azureedge.net/erpcdncontainer/apslogo.svg";

export const ERP_CDN_IMAGE_URL =
  "https://erpcontent.azureedge.net/erpcdncontainer";
