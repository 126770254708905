import PaymentRepository from "../repository/PaymentRepository";

export function usePaymentStore() {
  /**
   * Here is list of use selectors which are relates to Payments
   * Author: Shivam Rayjada
   */

  let data = {};

  let fetch = {
    /**
     * Here is list of fetch methods which are used for get data from API or Repositories related to Payment screen
     * Author: Shivam Rayjada
     * dispatch is used for reducer or thunk method
     * if redux not needed use repository method
     */
    getTransactionServices: async () => {
      return await new PaymentRepository().getTransactionServices();
    },

    getPaymentModes: async () => {
      return await new PaymentRepository().getPaymentModes();
    },

    getBillsDetails: async (params) => {
      return await new PaymentRepository().getBillsDetails(params);
    },

    getBillsDetailsByID: async (params) => {
      return await new PaymentRepository().getBillsDetailsByID(params);
    },

    getAdvancePayDetailsByID: async (params) => {
      return await new PaymentRepository().getAdvancePayDetailsByID(params);
    },

    getAccountRecievableDetails: async (params) => {
      return await new PaymentRepository().getAccountRecievableDetails(params);
    },

    getAccountpayableDetails: async (params) => {
      return await new PaymentRepository().getAccountpayableDetails(params);
    },

    getAccountStatementDetails: async (params) => {
      return await new PaymentRepository().getAccountStatementDetails(params);
    },
  };
  let set = {
    /**
     * Here is list of fetch methods which are used for set or update data from API or Repositories related to payment and subscription
     * Author: Devanshi Patel
     */
    savePayment: async (params) => {
      return await new PaymentRepository().savePayment(params);
    },

    saveAdvancePayment: async (params) => {
      return await new PaymentRepository().saveAdvancePayment(params);
    },

    razorPayCreateOrder: async (params) => {
      return await new PaymentRepository().razorPayCreateOrder(params);
    },

    stripPaymentIntents: async (params) => {
      return await new PaymentRepository().stripPaymentIntents(params);
    },
  };
  return [data, fetch, set];
}
