import APIHandler from "helpers/APIHandler";
import { GET_BILLS_BY_CUSTOMERID } from "helpers/APIRouter";
import { SAVE_INVOICE_API } from "helpers/APIRouter";
import { UPDATE_CUSTOMER_API } from "helpers/APIRouter";
import { GET_PRODUCT_ADVANCE_SEARCH } from "helpers/APIRouter";
import {
  BRANCH_DETAIL_API,
  GET_BILL_API,
  GET_RECENT_BILL_API,
  PRODUCT_SEARCH_API,
  SAVE_BILL_API,
  SEARCH_MULTIPLE_PATIENT_API,
  SEARCH_MULTIPLE_CUSTOMER_API,
  CREATE_CUSTOMER_API,
  TEMPLATE_LIST_API,
  GET_TEMPLATE_DETAIL_API,
  REFUND_BILL_API,
} from "helpers/APIRouter";
import PerformanceAPIHandler from "helpers/PerformanceAPIHandler";

const API = new APIHandler();
const PerformanceAPI = new PerformanceAPIHandler();

class CreateBillAPICall {
  getBranchDetail = async (params = {}) => {
    let res = {};
    await API.get(BRANCH_DETAIL_API, params)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getCustomerDetail = async (params = {}) => {
    let id = params;
    let res = {};
    const url = SEARCH_MULTIPLE_CUSTOMER_API + id;

    await API.get(url)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getPatientDetail = async (params = {}) => {
    let id = params;
    let res = {};
    const url = SEARCH_MULTIPLE_PATIENT_API + id;
    await API.get(url)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getProductSearchList = async (params = {}) => {
    let id = params;
    let res = {};
    const url = PRODUCT_SEARCH_API + id;
    await PerformanceAPI.get(url)
      .then((result) => {
        // console.log("ProductAdvancedSearch result", result);
        if (result) {
          // console.log("ProductAdvancedSearch result", result);
          res = result.result;
          // res = result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getTemplateList = async (params = {}) => {
    let res = {};
    const url = TEMPLATE_LIST_API;

    await API.get(url, params)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  GetTemplateDetails_By_ID = async (params = {}) => {
    let id = params;
    let res = {};
    const url = GET_TEMPLATE_DETAIL_API + id;
    // console.log(url);
    await API.get(url)
      .then((result) => {
        if (result) {
          // console.log(result);
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  Get_Bills_By_CustomerID = async (params = {}) => {
    let id = params;
    let res = {};
    const url = GET_BILLS_BY_CUSTOMERID + id;
    // console.log(url);
    await API.get(url)
      .then((result) => {
        if (result) {
          res = result.result;
          // console.log(res);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getProductAdvancedSearch = async (params = {}) => {
    // const url = GET_PRODUCT_ADVANCE_SEARCH;

    // console.log(url, params);

    // await API.post(url, params)
    //   .then((result) => {
    //     if (result) {

    //       console.log(result);
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
    // return res;

    let res = {};
    let param = {};
    param.data = params;

    // console.log("testing api", GET_PRODUCT_ADVANCE_SEARCH, params);

    await API.post(GET_PRODUCT_ADVANCE_SEARCH, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log("ProductAdvancedSearch result", e);
      });
    return res;
  };

  saveBill = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;

    await PerformanceAPI.post(SAVE_BILL_API, param)
      .then((result) => {
        // console.log("Bill saved", result);
        if (result) {
          res = result.result;
          // res = result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  saveInvoice = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;

    await PerformanceAPI.post(SAVE_INVOICE_API, param)
      .then((result) => {
        // console.log("Bill saved", result);
        if (result) {
          res = result.result;
          // res = result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  refundBill = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;

    // console.log(param);

    await API.post(REFUND_BILL_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  createCustomer = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;

    // console.log(CREATE_CUSTOMER_API, params);

    await API.post(CREATE_CUSTOMER_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log("create customer result", e);
      });
    return res;
  };

  updateCustomer = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;

    await API.post(UPDATE_CUSTOMER_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log("create customer result", e);
      });
    return res;
  };

  getRecentBill = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;

    // console.log("recent bill", param);

    // console.log(GET_RECENT_BILL_API);
    await API.get(GET_RECENT_BILL_API, param)
      .then((result) => {
        // console.log("recent bill result", result);
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getBill = async (params = {}) => {
    let id = params;
    let res = {};
    const url = GET_BILL_API + id;
    await API.get(url)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default CreateBillAPICall;
