import {
  Button,
  Input,
  Popover,
  PopoverBody,
  Col,
  Row,
  PopoverHeader,
} from "reactstrap";
import React, { useState, useEffect, useRef } from "react";
import { useCreateBillStore } from "hooks/CreateBill";
import { AutoComplete } from "primereact/autocomplete";
import "../../assets/styles/CreateBill.css";
import "../../assets/styles/Invoice.css";
import { isEmpty } from "underscore";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useLoginStore } from "hooks/Login";
import AdvanceFilterModal from "./AdvanceFilterModal";
import { Dialog } from "primereact/dialog";
import InvoiceBillStep from "./InvoiceBillStep";
import getSymbolFromCurrency from "currency-symbol-map";
import CreateBillRepository from "repository/CreateBillRepository";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoAddCircleOutline } from "react-icons/io5";
import CustomButton from "components/Button/CustomButton";
import PlusMinusButton from "components/Button/PlusMinusButton";
import Common from "helpers/Common";
import moment from "moment";
import { RiAddFill, RiArrowRightLine } from "react-icons/ri";
import { MdContactSupport } from "react-icons/md";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import "../../assets/styles/CreateBillDraftButton.css";
import allDrafts from "assets/img/icons/createBill/allDrafts.png";
import closeIcon from "../../assets/img/icons/createBill/closeIcon.png";
import lookup from "../../assets/img/icons/createBill/lookup.png";
import deleteIcon from "../../assets/img/icons/createBill/delete.png";

const BillSummary = (props) => {
  const {
    selectedCustomer,
    setSelectedCustomer,
    customerData,
    setCustomerData,
    rows,
    setRows,
    manualDiscount,
    setManualDiscount,
    setShowInRupees,
    showInRupees,
    igstPercentage,
    setIgstPercentage,
    paymentMethod,
    setPaymentMethod,
    paymentDone,
    setPaymentDone,
    billId,
    setBillId,
    subTotal,
    setSubTotal,
    totalAmount,
    setTotalAmount,
    totalBeforeTax,
    setTotalBeforeTax,
    createDraft,
    setRecentBillId,
    selectedCustomerID,
    selectedRecentProduct,
    isDraftCreated,
  } = props;

  const [isDropdownFocused, setIsDropdownFocused] = useState(false);
  const [manualDiscountAmount, setManualDiscountAmount] = useState(0);
  const [igstAmount, setIgstAmount] = useState(0);
  const [filteredProduct, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(null);
  const [loadingNew, setLoadingNew] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [visible, setVisible] = useState(false);
  const [SelectedRecentProdIndex, setSelectedRecentProdIndex] = useState(0);
  const [focusedButton, setFocusedButton] = useState(null); // Track which button is focused
  const [focusIndex, setFocusIndex] = useState(null);
  const [popoverDraftOpen, setPopoverDraftOpen] = useState(false);
  const [drafts, setDrafts] = useState([]);

  const currency = new CreateBillRepository().getCurrencyAbbriviation();

  const [{}, { getProductSearchList }, { setSuggestedProduct, saveInvoice }] =
    useCreateBillStore();
  const [{}, {}, { setAlerts }] = useLoginStore();

  const debounceTimer = useRef(null);
  const inputRefs = useRef([]);
  const rowsRef = useRef(rows);
  const selectedCustomerRef = useRef(selectedCustomer);
  const customerDataRef = useRef(customerData);
  const totalAmountRef = useRef(totalAmount);
  const paymentDoneRef = useRef(paymentDone);
  const paymentMethodRef = useRef(paymentMethod);
  const autoCompleteRef = useRef(null);
  const paymentModeRef = useRef(null);
  const manualDiscountRef = useRef(null);
  const igstInputRef = useRef(null);
  const saveBtnRef = useRef(null);
  const draftBtnRef = useRef(null);
  const sgstRef = useRef(null);
  const cgstRef = useRef(null);

  const handleFocus = (buttonRef) => {
    setFocusedButton(buttonRef);
  };

  const handleBlur = () => {
    setFocusedButton(null); // Clear the focus state when the button loses focus
  };

  const togglePopover = (index) => {
    if (popoverOpen === index) {
      setPopoverOpen(null);
    } else {
      setPopoverOpen(index);
    }
  };

  const togglePopoverDraft = () => setPopoverDraftOpen(!popoverDraftOpen);

  const updateRow = (index, field, value) => {
    const updatedRows = [...rows];
    const thisRow = updatedRows[index];

    const parseAndValidate = (val) => {
      const parsedValue = parseFloat(val);
      return isNaN(parsedValue) ? 0 : parsedValue;
    };

    if (field === "medicinetime") {
      const transformedData = Object.keys(value).map((time) => ({
        time: time,
        quantity: value[time].quantity.toString(),
        afterfood: value[time].afterfood,
      }));
      thisRow.medicinetime = transformedData;
    } else {
      if (field === "qty" && thisRow.availableQty < value) {
        setAlerts({
          type: "warning",
          message: "We do not have enough stocks which you required.",
        });
      }
      thisRow[field] = value;
    }

    const qty = parseAndValidate(thisRow.qty);
    const rate = parseAndValidate(thisRow.rate);
    const amount = qty * rate;
    thisRow.amount = amount > 0 ? amount : 0;

    const discountAmount = parseAndValidate(thisRow.discountAmount);
    const productCost = thisRow.amount - discountAmount;
    thisRow.productCost = productCost > 0 ? productCost : 0;

    const sgst = parseAndValidate(thisRow.sgst);
    const cgst = parseAndValidate(thisRow.cgst);
    const netAmount =
      thisRow.productCost +
      (thisRow.productCost * sgst) / 100 +
      (thisRow.productCost * cgst) / 100;
    thisRow.netAmount = netAmount > 0 ? netAmount : 0;
    setRows(updatedRows);
  };

  const updateRowProduct = (index, product) => {
    let suggestedProduct = {
      productId: product?.productId,
      productName: product?.productName,
      applicableDiscountAmount: product?.applicableDiscountAmount,
      available_qty: product?.available_qty,
      brand: product?.brand,
      categoryId: product?.categoryId,
      categoryName: product?.categoryName,
      cgst: product?.cgst,
      companyId: product?.companyId,
      discountAmount: product?.discountAmount,
      discountFixed: product?.discountFixed,
      discountPercentage: product?.discountPercentage,
      expiryDate: product?.expiryDate,
      gstRate: product?.gstRate,
      igstRate: product?.igstRate,
      isTax1Percentage: product?.isTax1Percentage,
      isTax2Percentage: product?.isTax2Percentage,
      isazureSearchData: product?.isazureSearchData,
      lead_time: product?.lead_time,
      maximumRetailPrice: product?.maximumRetailPrice,
      maximum_stock: product?.maximum_stock,
      measure: product?.measure,
      minimum_stock: product?.minimum_stock,
      mrpDiscountAmount: product?.mrpDiscountAmount,
      netPrice: product?.netPrice,
      netProductCost: product?.netProductCost,
      productCode: product?.productCode,
      productDescription: product?.productDescription,
      productImageName: product?.productImageName,
      productItemcode: product?.productItemcode,
      productShortcode: product?.productShortcode,
      productType: product?.productType,
      product_discount: product?.product_discount,
      reorder_point: product?.reorder_point,
      sgst: product?.sgst,
      sizeDescription: product?.sizeDescription,
      sku: product?.sku,
      subCategoryId: product?.subCategoryId,
      subCategoryName: product?.subCategoryName,
      tax1: product?.tax1,
      tax1Description: product?.tax1Description,
      tax2: product?.tax2,
      tax2Description: product?.tax2Description,
      taxAmount: product?.taxAmount,
      un_used_quantity: product?.un_used_quantity,
      unit: product?.unit,
      unitItems: product?.unitItems,
      unitPrice: product?.unitPrice,
      upc_code: product?.upc_code,
    };

    setSuggestedProduct(suggestedProduct);

    if (!product?.productName || !product?.unitPrice || !product?.productId) {
      setAlerts({ type: "danger", message: "Please Select a Product" });
      return;
    }

    if (product?.available_qty < 1) {
      setAlerts({
        type: "warning",
        message: "We do not have enough stocks which you required.",
      });
    }

    const isProductAlreadyAdded = rows.some(
      (row, i) => row.productId === product.productId && i !== index
    );

    if (isProductAlreadyAdded) {
      setAlerts({
        type: "warning",
        message: "Product is already added.",
      });

      const updatedRows = [...rows];
      updatedRows[index] = { productName: "", qty: 0, rate: 0 };
      setRows(updatedRows);

      return;
    }

    const productCostBeforeTaxCal =
      product?.unitPrice - (parseFloat(product?.product_discount) || 0);

    const SGSTCost = (productCostBeforeTaxCal * product?.tax2) / 100;
    const CGSTCost = (productCostBeforeTaxCal * product?.tax1) / 100;

    const medicinetime = [
      {
        time: "morning",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "afternoon",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "evening ",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "night",
        quantity: 0,
        afterfood: "yes",
      },
    ];

    // Update the row with new product details
    const newRow = {
      medicinetime: medicinetime,
      productName: product?.productName,
      productId: product?.productId,
      qty: 1,
      rate: product?.unitPrice,
      amount: product?.unitPrice,
      discountAmount: parseFloat(product?.product_discount) || 0,
      productCost: productCostBeforeTaxCal,
      sgst: product?.sgst,
      cgst: product?.cgst,
      availableQty: product?.available_qty,
      netAmount: productCostBeforeTaxCal + SGSTCost + CGSTCost,
      expiryDate: product?.expiryDate,
    };
    const updatedRows = [...rows];
    updatedRows[index] = newRow;

    setRows(updatedRows);
  };

  const retriveProducts = async (queryText, type = "add", index = 0) => {
    if (queryText.length >= 2) {
      // Check if at least 2 characters are entered
      let productList = await getProductSearchList(queryText);

      if (productList && !isEmpty(productList)) {
        setFilteredProducts(productList);

        if (productList.length === 1) {
          if (type === "add") {
            addRow(productList[0]);
            resetFields();
            setFilteredProducts([]);
          } else if (type === "update") {
            const updatedProduct = productList[0];
            updateRowProduct(index, updatedProduct);
            resetFields();
            setFilteredProducts([]);
          }
        }
      } else {
        /**
         * display no result found when no data get in response
         * Author: Devanshi Patel
         */
        setFilteredProducts([
          {
            productName: (
              <span style={{ cursor: "none", padding: "70px" }}>
                no result found
              </span>
            ),
          },
        ]);
      }
    } else {
      /**
       * Display error when only 1 character is type for product search auto complete
       * Author: Devanshi Patel
       */
      setTimeout(() => {
        setAlerts({
          type: "danger",
          message: "Minimum 2 characters are needed for product search",
        });
      }, 2000);
    }
  };

  const filterProduct = (event, type = "add", index = 0) => {
    const query = event.query;

    // Clear the existing timer if any
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer
    debounceTimer.current = setTimeout(() => {
      retriveProducts(query, type, index);
    }, 1000); // 1s delay
  };

  const handleKeyPress = (event) => {
    if (event.altKey && event.key.toLowerCase() === "s") {
      const isChatBotActive =
        localStorage.getItem("isChatBotActive") === "true" || false;
      if (!isChatBotActive) {
        event.preventDefault();
        savingBill();
      }
    }
    if (event.altKey && event.key.toLowerCase() === "d") {
      const isChatBotActive =
        localStorage.getItem("isChatBotActive") === "true" || false;
      if (!isChatBotActive) {
        event.preventDefault();
        const draftButton = document.getElementById("draft");
        if (draftButton) {
          draftButton.click();
        }
      }
    }
  };

  const handleKeyDown = (e, nextRef) => {
    if (e.key === "Tab") {
      e.preventDefault(); // Prevent the default tab behavior
      if (nextRef.current) {
        nextRef.current.focus(); // Focus on the dropdown input
      }
    }
  };

  const savingBill = async () => {
    if (!rowsRef.current) {
      setAlerts({
        type: "danger",
        message: `Please select product.`,
      });
      return;
    }
    if (!selectedCustomerRef.current) {
      setAlerts({
        type: "danger",
        message: `Please select customer.`,
      });
      return;
    }
    selectedCustomerRef.current;
    const updatedRows = rowsRef.current;
    const updatedSelectedCustomer = selectedCustomerRef.current;
    const productInfoData = updatedRows.map((item, index) => {
      return {
        medicinetime: item.medicinetime,
        productId: item.productId,
        productName: item.productName,
        qty: item.qty,
        rate: item.rate?.toFixed(2),
        cgst: item.cgst?.toFixed(2),
        cgstType: "PERCENTAGE",
        sgst: item.sgst?.toFixed(2),
        sgstType: "PERCENTAGE",
        discountAmount: item.discountAmount?.toFixed(2),
        productDiscountType: "AMOUNT",
        amount: item.amount?.toFixed(2),
        productCost: item.productCost?.toFixed(2),
        netAmount: parseFloat(item.netAmount)?.toFixed(2),
        expiryDate: item.expiryDate,
        sgstTotalTaxAmount: ((item.amount * item.sgst) / 100)?.toFixed(2),
        cgstTotalTaxAmount: ((item.amount * item.cgst) / 100)?.toFixed(2),
      };
    });

    const params = {
      companyId: Common.getCompanyId(),
      userId: Common.getUserId(),
      companyName: Common.getCompanyName(),
      companyAddress: Common.getCompanyAddress(),
      companyPhone: Common.getCompanyPhone(),
      companyEmail: Common.getCompanyEmail(),
      companyGSTN: Common.getGstIn(),
      footerText: "Disclaimer and Return Policy",
      invoiceFileName: billId + ".pdf",
      invoiceDate: Common.getFormatedDate(
        moment().utc(),
        "DD-MMMM-YYYY hh:mm:ss",
        true
      ),
      editableStatus: 1,
      refundPossible: 1,
      billId: billId,
      billDescription: "invoice",
      invoiceReferenceNumber: "",
      refundBillId: 0,
      customerId: updatedSelectedCustomer.customerID,
      customerName:
        customerDataRef.current.customerName != ""
          ? customerDataRef.current.customerName
          : updatedSelectedCustomer.customerName,
      customerAddress:
        customerDataRef.current.address != ""
          ? customerDataRef.current.address
          : updatedSelectedCustomer.address1,
      customerContact:
        customerDataRef.current.phoneNumber != ""
          ? customerDataRef.current.phoneNumber
          : updatedSelectedCustomer.phoneNumber,
      customerEmail: updatedSelectedCustomer.emailId,
      productInfos: productInfoData,
      subTotal: parseFloat(subTotal)?.toFixed(2),
      manualDiscount: manualDiscount ? manualDiscount : "0.00",
      manualDiscountAmount: manualDiscountAmount
        ? manualDiscountAmount?.toFixed(2)
        : "0.00",
      manualDiscountType: showInRupees ? "RUPEES" : "PERCENTAGE",
      totalBeforeTax: parseFloat(totalBeforeTax)?.toFixed(2),
      totalTax: igstAmount?.toFixed(2),
      gst: parseFloat(igstPercentage)?.toFixed(2),
      gstType: "PERCENTAGE",
      paidStatus: paymentDoneRef.current,
      paymentMode: paymentMethodRef.current,
      grandTotal: parseFloat(totalAmountRef.current)?.toFixed(2),
    };
    const result = await saveInvoice(params);
    setBillId(result.billId);
    setRecentBillId(result.billId);
    if (!isEmpty(result) && result.billId) {
      setLoadingPrint(false);
      setLoadingNew(false);

      await setAlerts({
        type: "success",
        message: `Bill No. ${result.billId} Saved Successfully.`,
      });

      setTimeout(async () => {
        setBillId(0);
        setRows([]);
        setSelectedCustomer(null);
        setCustomerData({
          customerName: "",
          address1: "",
          address2: "",
          address: "",
          phoneNumber: "",
          emailId: "",
        });
      }, 2000);
    }
  };

  const formatDate = (dateString) => {
    if (dateString !== null) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-indexed.
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }
  };

  const addRow = (data) => {
    let suggestedProduct = {
      productId: data?.productId,
      productName: data?.productName,
      applicableDiscountAmount: data?.applicableDiscountAmount,
      available_qty: data?.available_qty,
      brand: data?.brand,
      categoryId: data?.categoryId,
      categoryName: data?.categoryName,
      cgst: data?.cgst,
      companyId: data?.companyId,
      discountAmount: data?.discountAmount,
      discountFixed: data?.discountFixed,
      discountPercentage: data?.discountPercentage,
      expiryDate: data?.expiryDate,
      gstRate: data?.gstRate,
      igstRate: data?.igstRate,
      isTax1Percentage: data?.isTax1Percentage,
      isTax2Percentage: data?.isTax2Percentage,
      isazureSearchData: data?.isazureSearchData,
      lead_time: data?.lead_time,
      maximumRetailPrice: data?.maximumRetailPrice,
      maximum_stock: data?.maximum_stock,
      measure: data?.measure,
      minimum_stock: data?.minimum_stock,
      mrpDiscountAmount: data?.mrpDiscountAmount,
      netPrice: data?.netPrice,
      netProductCost: data?.netProductCost,
      productCode: data?.productCode,
      productDescription: data?.productDescription,
      productImageName: data?.productImageName,
      productItemcode: data?.productItemcode,
      productShortcode: data?.productShortcode,
      productType: data?.productType,
      product_discount: data?.product_discount,
      reorder_point: data?.reorder_point,
      sgst: data?.sgst,
      sizeDescription: data?.sizeDescription,
      sku: data?.sku,
      subCategoryId: data?.subCategoryId,
      subCategoryName: data?.subCategoryName,
      tax1: data?.tax1,
      tax1Description: data?.tax1Description,
      tax2: data?.tax2,
      tax2Description: data?.tax2Description,
      taxAmount: data?.taxAmount,
      un_used_quantity: data?.un_used_quantity,
      unit: data?.unit,
      unitItems: data?.unitItems,
      unitPrice: data?.unitPrice,
      upc_code: data?.upc_code,
    };

    setSuggestedProduct(suggestedProduct);

    if (!data?.productName || !data?.unitPrice) {
      setAlerts({
        type: "danger",
        message:
          "Please select a product to the current row to add a new Product.",
      });
      return;
    }

    const medicinetime = [
      {
        time: "morning",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "afternoon",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "evening ",
        quantity: 0,
        afterfood: "yes",
      },
      {
        time: "night",
        quantity: 0,
        afterfood: "yes",
      },
    ];

    const isProductAlreadyAdded = rows.some(
      (row) => row?.productName === data.productName
    );

    if (isProductAlreadyAdded) {
      setAlerts({
        type: "warning",
        message: "Product is already added.",
      });
      return;
    }

    if (data?.available_qty < 1) {
      setAlerts({
        type: "warning",
        message: "We do not have enough stocks which you required.",
      });
    }

    const productCostBeforeTaxCal =
      data?.unitPrice - (parseFloat(data?.applicableDiscountAmount) || 0);

    const SGSTCost = (productCostBeforeTaxCal * data?.sgst) / 100;
    const CGSTCost = (productCostBeforeTaxCal * data?.cgst) / 100;

    const newRow = {
      medicinetime: medicinetime,
      productId: data.productId,
      productDescription: data.productDescription,
      productName: data?.productName,
      categoryId: data.categoryId,
      qty: 1,
      availableQty: data?.available_qty,
      rate: data.unitPrice,
      amount: data.unitPrice,
      discountAmount: parseFloat(data?.applicableDiscountAmount) || 0,
      productCost: productCostBeforeTaxCal,
      sgst: data.sgst,
      cgst: data.cgst,
      netAmount: productCostBeforeTaxCal + SGSTCost + CGSTCost,
      expiryDate: data.expiryDate,
    };

    const newRows = [...rows, newRow];
    setRows([...rows, newRow]);
    setFocusIndex(newRows.length - 1);
  };

  const deleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    setSelectedRecentProdIndex((prevIndex) => prevIndex - 1);
  };

  const resetFields = () => {
    setSelectedProducts(null);
  };

  const itemTemplate = (item) => {
    return (
      <div className="autocomplete-item">
        <div>{item.productName}</div>
        <div className="autocomplete-details">
          <div>upc_code: {item.upc_code}</div>
          <div>SKU: {item.sku}</div>
          <div>Brand: {item.brand}</div>
          <div>ExpiryDate: {formatDate(item.expiryDate)}</div>
          <div>ProductDescription: {item.productDescription}</div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedRecentProduct) {
      const isProductAlreadyAdded = rows.some(
        (row) => row?.productName == selectedRecentProduct.productName
      );

      if (isProductAlreadyAdded) {
        setAlerts({
          type: "warning",
          message: "Product is already added.",
        });
        return;
      }

      updateRowProduct(SelectedRecentProdIndex, selectedRecentProduct);
      setSelectedRecentProdIndex((prevIndex) => prevIndex + 1);
    }
  }, [selectedRecentProduct]);

  useEffect(() => {
    if (selectedCustomerID) {
      // Focus the AutoComplete input when selectedCustomer is available and not null
      autoCompleteRef.current?.focus();
    }
  }, [selectedCustomerID]);

  useEffect(() => {
    rowsRef.current = rows;
    selectedCustomerRef.current = selectedCustomer;
  }, [rows, selectedCustomer]);

  useEffect(() => {
    // Calculate subtotal
    const total = rows.reduce(
      (acc, row) => acc + parseFloat(row?.netAmount),
      0
    );
    setSubTotal(total);

    // Calculate manual discount amount
    const manualDiscountAmountCalculation = showInRupees
      ? parseFloat(manualDiscount) || 0.0
      : (total * manualDiscount) / 100;

    const totalWithDiscount =
      total > 0 ? total - manualDiscountAmountCalculation : 0.0;

    setManualDiscountAmount(manualDiscountAmountCalculation);

    if (igstPercentage >= 0 && igstPercentage <= 100) {
      // Calculate IGST and update totals
      const igst = (totalWithDiscount * igstPercentage) / 100;
      setIgstAmount(igst);
      setTotalBeforeTax(totalWithDiscount);
      setTotalAmount(totalWithDiscount + igst);
      totalAmountRef.current = totalWithDiscount + igst;
    }

    // Handle Enter key press to perform save & new button action
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        // Get isChatBotActive from localStorage or assume false if not set
        const isChatBotActive =
          localStorage.getItem("isChatBotActive") === "true" || false;

        // Proceed with saving the bill only if the chatbot is not active
        if (!isChatBotActive) {
          savingBill();
        }
      }
    };

    // Add keypress event listener
    window.addEventListener("keypress", handleKeyPress);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [
    rows,
    updateRowProduct,
    updateRow,
    manualDiscount,
    showInRupees,
    igstPercentage,
  ]);

  useEffect(() => {
    // This will set focus to the last input box in the array of refs whenever a new row is added.
    const lastRef = inputRefs.current[rows.length - 1];
    if (lastRef) {
      lastRef.focus();
      lastRef.select();
    }
  }, [rows]);

  useEffect(() => {
    if (focusIndex !== null) {
      const input = document.getElementById(`qty-input-${focusIndex}`);
      if (input) {
        input.focus();
        input.select();
      }
    }
  }, [focusIndex]);

  useEffect(() => {
    const storedDrafts = JSON.parse(localStorage.getItem("drafts")) || [];
    setDrafts(storedDrafts);
  }, [isDraftCreated]);

  const deleteDraft = (draftName) => {
    const updatedDrafts = drafts.filter(
      (draft) => draft.draftName !== draftName
    );
    setDrafts(updatedDrafts);
    localStorage.setItem("drafts", JSON.stringify(updatedDrafts));
    setPopoverDraftOpen(false);
  };

  const editDraft = (draftName) => {
    const selectedDraft = drafts.filter(
      (draft) => draft.draftName === draftName
    );
    setPaymentMethod(selectedDraft[0].paymentMode);
    setSubTotal(selectedDraft[0].subTotal);
    setTotalBeforeTax(selectedDraft[0].totalBeforeTax);
    setPaymentDone(selectedDraft[0].paidStatus);
    setManualDiscount(selectedDraft[0].manualDiscount);
    setIgstPercentage(selectedDraft[0].gst);
    setRows(selectedDraft[0].productInfos);
    setPopoverDraftOpen(false);
  };

  const icon = popoverDraftOpen
    ? `${ERP_CDN_IMAGE_URL}/closeIcon.png`
      ? `${ERP_CDN_IMAGE_URL}/closeIcon.png`
      : closeIcon
    : `${ERP_CDN_IMAGE_URL}/allDrafts.png`
    ? `${ERP_CDN_IMAGE_URL}/allDrafts.png`
    : allDrafts;

  return (
    <>
      {/* <div className="title-row">
        <h4>Purchase Details</h4>
      </div> */}
      <div style={{ overflowX: "auto" }}>
        <table className="purchase-table">
          <thead>
            <tr>
              <th className="product-name-col">Particulars</th>
              <th className="qty-col">Quantity</th>
              <th className="rate-col">Rate</th>
              <th className="amount-col">Amount</th>
              <th className="discount-col">Discount</th>
              <th className="total-price-col">Total</th>
              <th className="sgst-col">SGST (%)</th>
              <th className="cgst-col">CGST (%)</th>
              <th className="net-amount-col">Net Amount</th>
              <th className="action-btn-col"></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td className="product-name-col cutomize-form-control">
                    <div className="product-seach-input">
                      <AutoComplete
                        className="custom-autocomplete"
                        value={row?.productName}
                        suggestions={filteredProduct}
                        completeMethod={(e) =>
                          filterProduct(e, "update", index)
                        }
                        field="productName"
                        itemTemplate={itemTemplate}
                        placeholder="Search by Product Name - at least 2 characters"
                        minLength={1}
                        onChange={(e) => {
                          updateRow(index, "productName", e.value);
                        }}
                        onSelect={(e) => {
                          updateRowProduct(index, e.value);
                        }}
                        pt={{
                          panel: {
                            className: "autocomplete-Zindex",
                          },
                        }}
                        delay={300}
                      />
                      <AdvanceFilterModal
                        addRow={addRow}
                        setSelectedProducts={setSelectedProducts}
                        index={index}
                        updateRowProduct={updateRowProduct}
                      />
                    </div>
                  </td>
                  <td className="qty-col cutomize-form-control">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <input
                        style={{
                          height: "34.5px",
                          fontSize: "14px",
                          paddingRight: "8px",
                          width: "60%",
                          boxSizing: "border-box",
                          backgroundColor:
                            row?.availableQty < 1 ? "#f0f0f0" : "white",
                        }}
                        className="cutomize-form-control-input custom-number-input"
                        id={`qty-input-${index}`}
                        type="text"
                        value={row?.qty}
                        onChange={(e) => {
                          const newQty = parseFloat(e.target.value);
                          updateRow(index, "qty", isNaN(newQty) ? "" : newQty);
                        }}
                        maxLength={100}
                        disabled={row?.availableQty < 1}
                      />

                      {row?.availableQty < 1 && (
                        <div
                          onClick={() =>
                            window.open(
                              `/admin/inventorymanual?productName=${encodeURIComponent(
                                row?.productName
                              )}`,
                              "_blank" // This opens the URL in a new tab
                            )
                          }
                          style={{
                            backgroundColor: "#4a6cf7",
                            borderRadius: "8px",
                            padding: "6px",
                            color: "white",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            marginLeft: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "34.5px",
                            height: "34.5px",
                            cursor: "pointer",
                          }}
                        >
                          <RiAddFill size={16} />
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="rate-col cutomize-form-control">
                    <Input
                      className="custom-number-input"
                      type="number"
                      value={row?.rate}
                      onChange={(e) => {
                        const newRate = parseFloat(e.target.value);
                        updateRow(
                          index,
                          "rate",
                          Math.max(0, isNaN(newRate) ? 0 : newRate)
                        );
                      }}
                      disabled={row?.availableQty < 1}
                      style={{
                        backgroundColor:
                          row?.availableQty < 1 ? "#f0f0f0" : "white", // Grey background for disabled, white otherwise
                      }}
                    />
                  </td>
                  <td className="amount-col cutomize-form-control">
                    {row?.amount?.toFixed(2)}
                  </td>
                  <td className="discount-col cutomize-form-control">
                    <Input
                      className="cutomize-form-control-input custom-number-input"
                      type="number"
                      value={
                        isNaN(row?.discountAmount) ? "" : row?.discountAmount
                      }
                      onChange={(e) => {
                        const newDiscount = parseFloat(e.target.value);
                        updateRow(
                          index,
                          "discountAmount",
                          Math.max(0, isNaN(newDiscount) ? 0 : newDiscount)
                        );
                      }}
                      disabled={row?.availableQty < 1}
                      style={{
                        backgroundColor:
                          row?.availableQty < 1 ? "#f0f0f0" : "white", // Grey background for disabled, white otherwise
                        flex: 1,
                        textAlign: "center", // Center the text in the input field
                        borderRadius: "5px", // Optional: To match the rounded corners in your design
                        padding: "5px", // Optional: Adjust padding for better visual appearance
                      }}
                    />
                  </td>
                  <td className="amount-col cutomize-form-control">
                    {row?.productCost?.toFixed(2)}
                  </td>
                  <td className="sgst-col cutomize-form-control">
                    <div
                      className="pt-0"
                      style={{
                        flex: 1,
                        textAlign: "center", // Center the text in the input field
                        borderRadius: "5px", // Optional: To match the rounded corners in your design
                        padding: "5px", // Optional: Adjust padding for better visual appearance
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <PlusMinusButton
                        plusBtnClickHandler={() =>
                          !row?.availableQty < 1 && row?.sgst > 0
                            ? updateRow(index, "sgst", row?.sgst + 1)
                            : null
                        }
                        minusBtnClickHandler={() =>
                          !row?.availableQty < 1 && row?.sgst > 0
                            ? updateRow(index, "sgst", row?.sgst - 1)
                            : null
                        }
                        type="number"
                        value={row?.sgst || 0}
                        ref={sgstRef}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          updateRow(
                            index,
                            "sgst",
                            Math.max(0, isNaN(value) ? 0 : value)
                          );
                        }}
                        disabled={row?.availableQty < 1}
                        style={{
                          opacity: row?.availableQty < 1 ? 0.5 : 1, // Optional: Visual feedback for disabled state
                          cursor:
                            row?.availableQty < 1 ? "not-allowed" : "pointer", // Optional: Change cursor to indicate disabled state
                        }}
                      />
                      <span
                        data-tooltip-id="totalTooltip"
                        data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                       <p style="margin: 0; padding: 0;">SGST: ₹${(
                         (row?.productCost * row?.sgst) /
                         100
                       )?.toFixed(2)}</p>
                     </span>`}
                      >
                        <RiArrowRightLine
                          style={{
                            marginLeft: "8px",
                            color: "#4a6cf7",
                            fontSize: "20px", // Increase this value to make the icon larger
                          }}
                        />
                        <ReactTooltip
                          style={{
                            backgroundColor: "#434ce6",
                            color: "white",
                          }}
                          id="totalTooltip"
                          place="bottom"
                        />
                      </span>
                    </div>
                  </td>
                  <td className="cgst-col cutomize-form-control">
                    <div
                      className="pt-0"
                      style={{
                        flex: 1,
                        textAlign: "center", // Center the text in the input field
                        borderRadius: "5px", // Optional: To match the rounded corners in your design
                        padding: "5px", // Optional: Adjust padding for better visual appearance
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <PlusMinusButton
                        plusBtnClickHandler={() =>
                          row?.availableQty >= 1 && row?.cgst > 0
                            ? updateRow(index, "cgst", row?.cgst + 1)
                            : null
                        }
                        minusBtnClickHandler={() =>
                          row?.availableQty >= 1 && row?.cgst > 0
                            ? updateRow(index, "cgst", row?.cgst - 1)
                            : null
                        }
                        type="number"
                        onKeyDown={(e) => handleKeyDown(e, paymentModeRef)}
                        ref={cgstRef}
                        value={row?.cgst || 0}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          updateRow(
                            index,
                            "cgst",
                            Math.max(0, isNaN(value) ? 0 : value)
                          );
                        }}
                        disabled={row?.availableQty < 1}
                        style={{
                          opacity: row?.availableQty < 1 ? 0.5 : 1, // Optional: Visual feedback for disabled state
                          cursor:
                            row?.availableQty < 1 ? "not-allowed" : "pointer", // Optional: Change cursor to indicate disabled state
                        }}
                      />
                      <span
                        data-tooltip-id="totalTooltip"
                        data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                       <p style="margin: 0; padding: 0;">CGST: ₹${(
                         (row?.productCost * row?.cgst) /
                         100
                       )?.toFixed(2)}</p>
                     </span>`}
                      >
                        <RiArrowRightLine
                          style={{
                            marginLeft: "8px",
                            color: "#4a6cf7",
                            fontSize: "20px", // Increase this value to make the icon larger
                          }}
                        />
                        <ReactTooltip
                          style={{
                            backgroundColor: "#434ce6",
                            color: "white",
                          }}
                          id="totalTooltip"
                          place="bottom"
                        />
                      </span>
                    </div>
                  </td>
                  <td className="net-amount-col cutomize-form-control">
                    <span
                      data-tooltip-id="net-amount-tooltip"
                      data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                       <p style="margin: 0; padding: 0;">SGST: ₹${(
                         (row?.productCost * row?.sgst) /
                         100
                       )?.toFixed(2)}</p>
                       <p style="margin: 0; padding: 0;">CGST: ₹${(
                         (row?.productCost * row?.cgst) /
                         100
                       )?.toFixed(2)}</p>
                     </span>`}
                    >
                      {row?.netAmount?.toFixed(2)}
                    </span>
                    <ReactTooltip
                      style={{
                        backgroundColor: "#434ce6",
                        color: "white",
                      }}
                      id="net-amount-tooltip"
                      place="bottom"
                    />
                  </td>
                  <td className="action-btn-col">
                    <FaRegTrashCan
                      className="create-bill-icon delete-row-icon"
                      id={`Popover-${index}`}
                      onClick={() => togglePopover(index)}
                    />
                    <Popover
                      placement="bottom"
                      isOpen={popoverOpen === index}
                      target={`Popover-${index}`}
                      toggle={() => togglePopover(index)}
                    >
                      <PopoverBody style={{ textAlign: "center" }}>
                        <span
                          style={{
                            display: "block",
                            marginBottom: "5px",
                            wordWrap: "break-word",
                          }}
                        >
                          Are you sure you want to delete it?
                        </span>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => {
                            deleteRow(index);
                            togglePopover(index);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          color="secondary"
                          size="sm"
                          onClick={() => togglePopover(index)}
                        >
                          Cancel
                        </Button>
                      </PopoverBody>
                    </Popover>
                  </td>
                </tr>
              </React.Fragment>
            ))}
            <tr>
              <td className="product-name-col">
                <div className="product-seach-input">
                  <AutoComplete
                    ref={autoCompleteRef}
                    onKeyDown={(e) => handleKeyDown(e, paymentModeRef)}
                    className="custom-autocomplete customize-autocomplete"
                    value={selectedProducts}
                    suggestions={filteredProduct}
                    completeMethod={filterProduct}
                    field="productName"
                    placeholder="Search by Product Name"
                    minLength={1}
                    itemTemplate={itemTemplate}
                    onChange={(e) => {
                      setSelectedProducts(e.value);
                    }}
                    onSelect={(e) => {
                      addRow(e.value);
                      resetFields();
                    }}
                    pt={{
                      panel: {
                        className: "autocomplete-Zindex",
                      },
                    }}
                    delay={300}
                  />

                  <AdvanceFilterModal
                    addRow={addRow}
                    setSelectedProducts={setSelectedProducts}
                  />
                </div>
              </td>
              <td className="qty-col cutomize-form-control">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <input
                    style={{
                      height: "34.5px",
                      fontSize: "14px",
                      paddingRight: "8px",
                      width: "60%",
                      boxSizing: "border-box",
                    }}
                    className="custom-number-input"
                    type="text"
                    value={0}
                    min="0"
                    maxLength={100}
                  />
                </div>
              </td>
              <td className="rate-col cutomize-form-control">
                <Input
                  className="custom-number-input"
                  type="number"
                  value={0}
                  min="0"
                />
              </td>
              <td className="amount-col cutomize-form-control">{0}</td>
              <td className="discount-col cutomize-form-control">
                <Input
                  className="custom-number-input"
                  type="number"
                  value={0}
                  min="0"
                />
              </td>
              <td className="total-price-col cutomize-form-control">0 </td>
              <td className="sgst-col cutomize-form-control">0</td>
              <td className="cgst-col cutomize-form-control">0</td>
              <td className="net-amount-col cutomize-form-control">0</td>
              <td className="action-btn-col">
                <IoAddCircleOutline
                  className="create-bill-icon"
                  onClick={addRow}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="invoice-summary-container">
        <Row className="w-100">
          <Col xs={12} sm={12} md={12} lg={8}>
            <Row>
              <Col md={12}>
                <div className="d-flex align-items-center mb-2">
                  <h4 className="title">Invoice & Payment</h4>
                </div>
              </Col>
            </Row>
            <Row style={{ rowGap: "10px" }}>
              <Col sx={12} sm={12} md={4} lg={4}>
                <div className="invoice-form-control">
                  <label>Payment Mode</label>
                  <select
                    id="payment"
                    name="payment"
                    value={paymentMethodRef.current}
                    onKeyDown={(e) => handleKeyDown(e, manualDiscountRef)}
                    className="invoice-payment-mode-control"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    ref={paymentModeRef}
                    onFocus={() => setIsDropdownFocused(true)} // Set focus state
                    onBlur={() => setIsDropdownFocused(false)} // Reset focus state
                    style={{
                      border: isDropdownFocused
                        ? "2px solid blue"
                        : "1px solid gray", // Conditional border style
                      outline: "none", // Remove default outline
                      boxShadow: isDropdownFocused
                        ? "0 0 5px rgba(0, 0, 255, 0.5)"
                        : "none", // Optional: add shadow for better highlight
                    }}
                  >
                    <option value="Cash">Cash</option>
                    <option value="Credit/ Debit Card">
                      {" "}
                      Credit/Debit Card
                    </option>
                    <option value="UPI">UPI</option>
                    <option value="Net Banking">Net Banking</option>
                  </select>
                </div>
              </Col>

              <Col sx={12} sm={12} md={4} lg={4}>
                <div className="invoice-form-control">
                  <label>Manual Discount ({showInRupees ? "₹" : "%"})</label>
                  <div className="custom-manual-discount-control">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={manualDiscount || 0}
                      style={{ width: "130px" }}
                      className="manual-discount-input"
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);
                        if (isNaN(value)) {
                          value = 0;
                        }

                        if (!showInRupees) {
                          if (value > 100) {
                            value = 100;
                          } else if (value < 0) {
                            value = 0;
                          }
                        }
                        setManualDiscount(value);
                      }}
                      ref={manualDiscountRef}
                      onKeyDown={(e) => handleKeyDown(e, igstInputRef)}
                    />
                    <Input
                      id="discount"
                      name="discount"
                      type="select"
                      value={showInRupees}
                      className="type-dropdown"
                      onChange={(e) => setShowInRupees(!showInRupees)}
                    >
                      <option value={true}>Amount</option>
                      <option value={false}>Percentage</option>
                    </Input>
                  </div>
                </div>
              </Col>

              <Col sx={12} sm={12} md={4} lg={4}>
                <div className="invoice-form-control">
                  <label htmlFor="">IGST (%)</label>
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="manual-igst-minus-btn"
                      onClick={() => setIgstPercentage(igstPercentage - 1)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={igstPercentage || 0}
                      className="manual-igst-input"
                      onChange={(e) => {
                        setIgstPercentage(e.target.value);
                      }}
                      ref={igstInputRef}
                      onKeyDown={(e) => handleKeyDown(e, saveBtnRef)}
                    />
                    <button
                      className="manual-igst-plus-btn"
                      onClick={() => setIgstPercentage(igstPercentage + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="my-3" style={{ rowGap: "10px" }}>
              <Col sm={12} md={6}>
                <div className="invoice-form-control">
                  <label htmlFor="">Comments</label>
                  <Input
                    id="comment"
                    name="comment"
                    placeholder="Write down.."
                    type="textarea"
                    maxLength={200}
                    rows={1}
                    className="invoice-comment-control"
                  />
                </div>
              </Col>
              <Col
                sm={12}
                md={6}
                className="d-flex align-items-end justify-content-start pb-2"
                style={{ gap: "10px" }}
              >
                <input
                  type="checkbox"
                  id="custom-checkbox"
                  className="custom-checkbox"
                  checked={paymentDoneRef.current}
                  onChange={() => {
                    setPaymentDone((prev) => !prev);
                  }}
                />
                <p className="received-payment-label">Received Payment</p>
              </Col>
            </Row>
          </Col>

          <Col
            xs={12}
            sm={12}
            md={12}
            lg={4}
            className="d-flex flex-column justify-content-between"
          >
            <Row style={{ gap: "15px" }}>
              <Col md={12} className="invoice-summary-data-row">
                <p>Sub Total</p>
                <p style={{ color: "#483EB6" }}>
                  {"(" + (currency ?? "0") + ") " + (subTotal ?? 0)?.toFixed(2)}
                </p>
              </Col>
              {/* New Manual Discount Row */}
              <Col md={12} className="invoice-summary-data-row">
                <p>Manual Discount</p>
                <p style={{ color: "#FF0000" }}>
                  {"- " + (manualDiscountAmount ?? 0)?.toFixed(2)}
                </p>
              </Col>

              <Col md={12} className="invoice-summary-data-row">
                <p>Total after Discount</p>
                <p style={{ color: "#58AD6B" }}>{totalBeforeTax?.toFixed(2)}</p>
              </Col>
              <Col md={12} className="invoice-summary-data-row">
                <p>TAX Applicable</p>
                <p>{igstAmount?.toFixed(2)}</p>
              </Col>
              <Col
                md={12}
                className="invoice-summary-data-row invoice-summary-grand-total-row"
              >
                <p>Net Payable Amount</p>
                <p>
                  {" "}
                  {"(" +
                    (currency ?? "0") +
                    ") " +
                    (getSymbolFromCurrency(currency) ?? "") +
                    " " +
                    (totalAmount ?? 0)?.toFixed(2)}{" "}
                </p>
              </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
              <Col
                md={12}
                className="d-flex justify-content-center align-items-center"
                style={{ gap: "12px" }}
              >
                <CustomButton
                  ref={saveBtnRef}
                  onKeyDown={(e) => handleKeyDown(e, draftBtnRef)}
                  onFocus={() => handleFocus(saveBtnRef)}
                  onBlur={handleBlur}
                  title="Alter + S"
                  label={
                    loadingNew || loadingPrint ? (
                      <>
                        <span className="loading-text">Saving Bill...</span>{" "}
                      </>
                    ) : (
                      <>Save Invoice</>
                    )
                  }
                  disabled={loadingNew || loadingPrint}
                  onClick={() => savingBill()}
                  style={{
                    border:
                      focusedButton === saveBtnRef
                        ? "2px solid blue"
                        : "1px solid gray", // Highlight border when focused
                    outline: "none", // Remove default outline
                    boxShadow:
                      focusedButton === saveBtnRef
                        ? "0 0 5px rgba(0, 0, 255, 0.5)"
                        : "none", // Optional: add shadow for better highlight
                  }}
                />

                <CustomButton
                  ref={draftBtnRef}
                  onFocus={() => handleFocus(draftBtnRef)}
                  onBlur={handleBlur}
                  title="Alter + D"
                  label="Save as Draft"
                  id="draft"
                  type="button"
                  onClick={createDraft}
                  customStyle="add-draft-btn"
                  style={{
                    marginLeft: "10px",
                    border:
                      focusedButton === draftBtnRef
                        ? "2px solid blue"
                        : "1px solid gray", // Highlight border when focused
                    outline: "none", // Remove default outline
                    boxShadow:
                      focusedButton === draftBtnRef
                        ? "0 0 5px rgba(0, 0, 255, 0.5)"
                        : "none", // Optional: add shadow for better highlight
                  }}
                />
                <span
                  data-tooltip-id="shortcutTooltip"
                  data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block; font-size: 14px;">
                    <p style="margin-bottom: 10px; padding: 0;">Keyboard - Shortcut Keys</p>
                    <p style="margin: 0; padding: 0;">ALTER + S : Save Invoice/Bill</p>
                    <p style="margin: 0; padding: 0;">ALTER + D : Save Invoice as Draft</p>
                    <p style="margin: 0; padding: 0;">ALTER + A : Add Customer</p>
                    <p style="margin: 0; padding: 0;">ALTER + M : Modify Customer</p>  
                  </span>`}
                >
                  <button className="add-customer-btn" type="button">
                    <MdContactSupport size={16} />
                  </button>
                  <ReactTooltip
                    opacity={"initial"}
                    id="shortcutTooltip"
                    place="top"
                    style={{
                      backgroundColor: "#434ce6",
                      color: "white",
                    }}
                  />
                </span>
                <div style={{ position: "inherit" }}>
                  <Button
                    title="All Drafts"
                    id="PopoverButton"
                    type="button"
                    className="btn-fixed"
                    onClick={togglePopoverDraft}
                  >
                    <img
                      src={icon}
                      alt="Toggle"
                      className={`draft-icon ${
                        popoverDraftOpen ? "rotate" : ""
                      }`}
                    />
                  </Button>
                  <Popover
                    placement="top"
                    isOpen={popoverDraftOpen}
                    target="PopoverButton"
                    toggle={togglePopoverDraft}
                  >
                    <PopoverHeader>All Drafts</PopoverHeader>
                    <PopoverBody>
                      {drafts.length === 0 && <>No Drafts Present</>}
                      {drafts.map((draft, index) => (
                        <div key={index} className="draft-card">
                          <div className="draft-title">{draft.draftName}</div>
                          <div className="draft-details">
                            <span className="draft-time">{draft.savedAt}</span>
                            <img
                              src={`${ERP_CDN_IMAGE_URL}/lookup.png`}
                              onError={(e) => {
                                e.target.onerror = null; // Prevent infinite loop if logo also fails
                                e.target.src = lookup;
                              }}
                              alt="Edit"
                              className="action-icon"
                              onClick={() => editDraft(draft.draftName)}
                            />
                            <img
                              src={`${ERP_CDN_IMAGE_URL}/delete.png`}
                              onError={(e) => {
                                e.target.onerror = null; // Prevent infinite loop if logo also fails
                                e.target.src = deleteIcon;
                              }}
                              alt="Delete"
                              className="action-icon"
                              onClick={() => deleteDraft(draft.draftName)}
                            />
                          </div>
                        </div>
                      ))}
                    </PopoverBody>
                  </Popover>
                </div>
              </Col>
            </Row>
          </Col>

          <Dialog
            showHeader={true}
            visible={visible}
            onHide={() => setVisible()}
            style={{ width: "90vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          >
            <InvoiceBillStep />
          </Dialog>
        </Row>
      </div>
    </>
  );
};

export default BillSummary;
