import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import { Col, Card, CardBody, Button, Row } from "reactstrap";

// used components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDashboardV2DataStore } from "hooks/DashboardV2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import PageLoader from "components/Loader/PageLoader";
import ChildLoader from "components/Loader/ChildLoader";
import { ADMIN_URL_RECENT_ENVENTORY } from "helpers/Paths";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import LoadMore from "components/Button/LoadMore";
import { FaPenToSquare } from "react-icons/fa6";

const RecentInventory = (props) => {
  const [{ }, { getRecentInventory }, { }] = useDashboardV2DataStore();
  let fromDateParam = props.fromDate;
  let toDateParam = props.toDate;
  const [loader, setLoader] = useState(false);
  const [recentInventoryData, setRecentInventoryData] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [loadingMore, setLoadingMore] = useState(false);

  const dt = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getRecentInventoryData();
  }, []);

  useEffect(() => {
    setLoader(true);
    getRecentInventoryData();
  }, [fromDateParam]);

  function formatDate(date) {
    let inputDate = new Date(date);
    let year = inputDate.getFullYear();
    let month = String(inputDate.getMonth() + 1).padStart(2, "0");
    let day = String(inputDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getTodayDate() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  const getRecentInventoryData = async (page = 1) => {
    if (page === 1) {
      setPageNumber(1);
    }

    try {
      let RecentInvData = await getRecentInventory({
        params: {
          fromdate: fromDateParam,
          toDate: toDateParam || formatDate(getTodayDate()),
          pagesize: new CompanyProfileRepository().getPageSize(),
          pageNumber: page,

          // fromdate: "2020-07-01",
          // todate: "2024-12-07",
          // topRecords: 3,
        },
      });

      if (page > 1) {
        setRecentInventoryData((prev) => [...prev, ...RecentInvData]);
        setLoadingMore(false);
        setLoader(false);
      } else {
        setRecentInventoryData(RecentInvData);
        setLoader(false);
        setLoadingMore(false);
      }
    } catch (error) {
      // for error handling
      alert(error);
    }

    setLoadingMore(false);
  };

  const onDelete = (value) => console.log(value);

  const loadMoreData = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
    setLoadingMore(true);
    getRecentInventoryData(pageNumber + 1);
  };

  const redirectToRecentInventory = () => {
    navigate(ADMIN_URL_RECENT_ENVENTORY);
  }

  const ActionTemplate = ({ rowData }) => {
    return (
      <div className="d-flex justify-content-around w-100">
        <Link to={`/admin/inventorymanual/${rowData.productName}`}>
          <button type="button" className="actions-btn">
            <FaPenToSquare className="table-action-icon" />
          </button>
        </Link>
        {/* <button
          type="button"
          className="btn btn-link"
          onClick={() => onDelete(rowData)}
        >
          <FontAwesomeIcon icon="fa-solid fa-trash-can" size="lg" />
        </button> */}
      </div>
    );
  };

  return (
    <>
      <div className="content w-100">
        <Col lg={12} md={12} xs={12}>
          <div>
            <Card className="dashboard-inventory-tile">
              <CardBody>
                <div className="d-flex align-items-center justify-content-between pb-2">
                  <div className="poppins-title recent-inventory-title">
                    {/* <Link to={ADMIN_URL_RECENT_ENVENTORY} className="table-title-link"> */}
                    Recent Inventory
                    {/* </Link> */}
                  </div>
                  <Button color="default" className="show-more-button" onClick={redirectToRecentInventory}>Show More</Button>
                </div>
                {loader ? (
                  <ChildLoader />
                ) : (
                  <>
                    <DataTable
                      ref={dt}
                      value={recentInventoryData ?? []}
                      selection={selectedInventory}
                      onSelectionChange={(e) => setSelectedInventory(e.value)}
                      dataKey="productID"
                      tableStyle={{ minWidth: "25rem" }}
                      pt={{
                        root: { className: "table" },
                      }}
                    >
                      <Column
                        field="productName"
                        header="Product Name"
                      ></Column>
                      <Column field="quantity" header="Quantity"></Column>
                      <Column
                        field="categoryName"
                        header="Category ID"
                      ></Column>
                      <Column field="unitPrice" header="Unit Price"></Column>
                      <Column field="unitItems" header="Unit Items"></Column>
                      <Column field="upcCode" header="UPC Code"></Column>
                      <Column
                        field="productCreationDate"
                        header="Created On"
                      ></Column>
                      <Column
                        header="Actions"
                        body={(rowData) => <ActionTemplate rowData={rowData} />}
                        exportable={false}
                      />
                    </DataTable>
                    <Col className="text-center">
                      {loadingMore ? (
                        <ChildLoader />
                      ) : (
                        recentInventoryData.length > 0 &&
                        <Button color="default" className="show-more-button" onClick={() => loadMoreData()}>Load More</Button>
                        
                        // <LoadMore totalData={recentInventoryData.length} handleLoadMore={() => loadMoreData()} />
                      )}
                    </Col>
                  </>
                )}
              </CardBody>
            </Card>
          </div>
        </Col>
      </div>
    </>
  );
};

export default RecentInventory;
