import BaseRepository from "./BaseRepository";
import DashboardV2APICall from "service/DashboardV2APICall";

class DashboardV2Repository extends BaseRepository {
  getCustomerCounts = async () => {
    return await new DashboardV2APICall().getCustomerCounts();
  };
  getProducSales = async (params) => {
    return await new DashboardV2APICall().getProducSales(params);
  };
  getFinancialAnalysis = async (params) => {
    return await new DashboardV2APICall().getFinancialAnalysis(params);
  };
  getTopSellingMedicines = async (params) => {
    return await new DashboardV2APICall().getTopSellingMedicines(params);
  };
  getRecentInventory = async (params) => {
    return await new DashboardV2APICall().getRecentInventory(params);
  };
  getRecentBills = async (params) => {
    return await new DashboardV2APICall().getRecentBills(params);
  };
}

export default DashboardV2Repository;
