import React, { memo } from "react";
import "../../assets/styles/Invoice.css";
import CreateBillRepository from "repository/CreateBillRepository";
import Common from "helpers/Common";
import moment from "moment";

const InvoiceHeader = memo(({ billID }) => {
  const header = new CreateBillRepository().getBillInvoiceHeaderFooter()

  const processedHTML = header?.InvoiceHeader?.replace(/\$DateTime|\$billid|\$companyName/g, (match) => {
    return match === '$DateTime' ? Common.getFormatedDate(moment().utc(), 'DD-MMMM-YYYY hh:mm:ss', true) : match === '$billid' ? billID : Common.getCompanyName();
  });

  return (
    <div dangerouslySetInnerHTML={{ __html: processedHTML }}/>
  );
});

export default InvoiceHeader;
