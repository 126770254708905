import React from "react";

const CreateInventory = () => {
    return(
        <>
            CreateInventory
        </>
    )
}

export default CreateInventory