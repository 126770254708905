import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CompanyRepository from "../../repository/CompanyRepository";

export const getCustomerByCompanyId = createAsyncThunk('company_getCustomerByCompanyId', async () => {
    return await new CompanyRepository().getCustomerByCompanyId();
});

const initialState = {
    customerList: {},
};
const slice = createSlice({
    name: "company",
    initialState: initialState,
    reducers: {},

    extraReducers: {
        [getCustomerByCompanyId.fulfilled]: (state, { payload }) => {
            state.customerList = payload;
            return state;
        },
        [getCustomerByCompanyId.rejected]: (state, { payload }) => {
        }
    },
});

export default slice.reducer;
