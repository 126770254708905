import PageLoader from "components/Loader/PageLoader";
import { useDashboardDataStore } from "hooks/Dashboard";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";

import CustomButton from "components/Button/CustomButton";
import Common from "helpers/Common";
import { roleType } from "helpers/constantMessages";
import { useCompanyStore } from "hooks/Company";
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { useLoginStore } from "hooks/Login";
import PhoneInput from "react-phone-input-2";
import {
  Col,
  CustomInput,
  Input,
  Row
} from "reactstrap";

const isAdminRole = Common.getRoleId() == roleType["Admin"];

const CreateCompanyUser = () => {
  const [{ }, { getCompanyData }] = useDashboardDataStore();
  const [companyList, setCompanyList] = useState([]);
  const [branchSelect, setBranchSelect] = useState(null);
  const [loader, setLoader] = useState(false);
  const dt = useRef(null);
  const [formData, setFormData] = useState({
    // companyID: "",
    branchID: "",
    companyName: "",
    loginID: "",
    loginPassword: "",
    confirmPassword: "",
    registerMobileNumber: "",
    email_address: "",
    activeStatus: 1,
    role_id: 1,
  });
  const [branches, setBranches] = useState([]);
  const [RolesList, setRolesList] = useState([]);
  const [roleSelect, setroleSelect] = useState(null);
  const [{ }, { }, { createCompanyUser }] = useCompanyStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();
  const [{ branchList }, { getBranchList, getUsers, getRolesList }, { }] =
    useCompanyProfileStore();
  const [usersList, setUsersList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNumberChange = (value) => {
    setFormData({
      ...formData,
      ["registerMobileNumber"]: value,
    });
  };

  const handleToggle = () => {
    setFormData({
      ...formData,
      activeStatus: formData.activeStatus === 0 ? 0 : 1,
    });
  };

  useEffect(() => {
    try {
      async function getCompanyListFunction() {
        let branchdataList = await getBranchList();
        let list = [];

        if (Array.isArray(branchdataList?.payload)) {
          list = branchdataList.payload.map((prop) => {
            return {
              value: prop.branchID,
              label: prop.branchName,
            };
          });
        }

        setBranches(list);

        let roleList = await getRolesList();
        let datalist = [];

        if (Array.isArray(roleList?.data)) {
          datalist = roleList.data.map((prop) => {
            return {
              value: prop.role_id,
              label: prop.role_name,
            };
          });
        }
        // Set default value to "Admin"
        const defaultRole = datalist.find((role) => role.value === "1");
        setroleSelect(defaultRole);
        setRolesList(datalist);
      }
      getCompanyListFunction();
      getUsersList();
    } catch (error) {
      alert(error);
    }
  }, []);

  const getUsersList = async () => {
    setLoader(true);
    let data = await getUsers();
    setUsersList(data);
    setLoader(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.registerMobileNumber ||
      formData.registerMobileNumber.length !== 12
    ) {
      await setAlerts({
        type: "danger",
        message: "Valid 10-digit Phone Number is required",
      });
      return;
    }
    if (!branchSelect) {
      await setAlerts({ type: "danger", message: "Select Branch is required" });
      return;
    }
    if (!formData.loginID) {
      await setAlerts({ type: "danger", message: "Login ID is required" });
      return;
    }
    if (
      !formData.loginPassword ||
      !Common.verifyLength(formData.loginPassword, 5)
    ) {
      await setAlerts({
        type: "danger",
        message: "Password must be 5 characters long.",
      });
      return;
    }
    if (
      !formData.confirmPassword ||
      !Common.verifySameValue(formData.confirmPassword, formData.loginPassword)
    ) {
      await setAlerts({
        type: "danger",
        message: "Confirm password not matched with password.",
      });
      return;
    }
    if (
      !formData.email_address ||
      !formData.email_address.includes("@") ||
      !Common.verifyEmail(formData.email_address)
    ) {
      await setAlerts({ type: "danger", message: "Email is not valid" });
      return;
    }
    if (![0, 1].includes(formData.activeStatus)) {
      await setAlerts({ type: "danger", message: "Invalid active status" });
      return;
    }
    try {
      let param = { ...formData };
      param.loginID = formData.loginID.toUpperCase();
      // param.companyID = formData.companyID;
      param.branchID = parseInt(branchSelect?.value);
      param.registerMobileNumber = "+" + formData.registerMobileNumber;
      param.activeStatus = formData.activeStatus;
      param.role_id = parseInt(roleSelect?.value);
      const response = await createCompanyUser(param);
      if (response) {
        if (response?.isSuccess) {
          await setAlerts({
            type: "success",
            message: "Company user successfully created",
          });
          getUsersList();
        } else {
          await setAlerts({
            type: "danger",
            message: response?.message,
          });
        }
      }

      // formData.companyID = "";
      formData.branchID = "";
      formData.companyName = "";
      formData.loginID = "";
      formData.loginPassword = "";
      formData.confirmPassword = "";
      formData.registerMobileNumber = "";
      formData.email_address = "";
      formData.activeStatus = 1;
      setBranchSelect(null);
      // Set default value to "Admin"
      let defaultRole = RolesList.find((role) => role.value === "1");
      setroleSelect(defaultRole);
    } catch (error) {
      console.error("Error creating company user:", error);
      // formData.companyID = "";
      formData.branchID = "";
      formData.companyName = "";
      formData.loginID = "";
      formData.loginPassword = "";
      formData.confirmPassword = "";
      formData.registerMobileNumber = "";
      formData.email_address = "";
      formData.activeStatus = 1;
      setBranchSelect(null);
      // Set default value to "Admin"
      let defaultRole = RolesList.find((role) => role.value === "1");
      setroleSelect(defaultRole);
      await setAlerts({
        type: "danger",
        message: "Error creating company user",
      });
    }
  };

  return (
    <>
      {loader ? (
        <PageLoader></PageLoader>
      ) : (
        <div className="profile-content-container">
          <div className="profile-content-title">
            <h5>Create Company User</h5>
          </div>

          <form className="profile-address-form" onSubmit={handleSubmit}>
            <Row>
              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>Registered Number</label>
                  <Input
                    name="registerMobileNumber"
                    placeholder="Registered Mobile Number"
                    type="hidden"
                  />
                  <PhoneInput
                    containerClass="custom-phone-input-container"
                    buttonClass="profile-flag-search-btn"
                    dropdownClass="profile-flag-dropdown"
                    country={"in"}
                    placeholder="Enter Registered Mobile Number"
                    // value={registerNumber}
                    name="registerMobileNumber"
                    id="registerMobileNumber"
                    onChange={handleNumberChange}
                  />
                </div>
              </Col>

              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>Role ID</label>
                  <Input
                    name="role_id"
                    id="role_id"
                    placeholder="Select Role"
                    type="hidden"
                  />
                  <Select
                    className="profile-custom-select"
                    // classNamePrefix="react-select"
                    placeholder="Select Role"
                    name="role_id"
                    value={roleSelect}
                    options={RolesList}
                    onChange={(e) => {
                      setroleSelect(e);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-lg-3">
              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>Select Company Branch</label>
                  <Input
                    name="branchID"
                    placeholder="Select Company Branch"
                    type="hidden"
                    id="branchID"
                  />
                  <Select
                    className="profile-custom-select"
                    // classNamePrefix="react-select"
                    placeholder="Select Company Branch"
                    name="branchID"
                    value={branchSelect}
                    options={branches}
                    onChange={(e) => {
                      setBranchSelect(e);
                    }}
                  />
                </div>
              </Col>

              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>Login ID</label>
                  <Input
                    type="text"
                    name="loginID"
                    id="loginID"
                    onChange={handleChange}
                    placeholder="Login Id ex. ABC123"
                    value={formData?.loginID.toUpperCase()}
                    onKeyPress={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        /[A-Z]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={100}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-lg-3">
              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>Password</label>
                  <Input
                    type="password"
                    name="loginPassword"
                    id="loginPassword"
                    onChange={handleChange}
                    maxLength={15}
                    placeholder="*****"
                  />
                </div>
              </Col>

              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>Confirm Password</label>
                  <Input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    onChange={handleChange}
                    maxLength={15}
                    placeholder="*****"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-lg-3">
              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>Email ID</label>
                  <Input
                    type="text"
                    name="email_address"
                    id="email_address"
                    onChange={handleChange}
                    placeholder="Email Adress ex. abc@gmail.com"
                    maxLength={100}
                  />
                </div>
              </Col>

              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label
                    for="activeStatus"
                    style={{ marginRight: "12px", marginLeft: "14px" }}
                  >
                    Active Status
                  </label>
                  <CustomInput
                    type="switch"
                    id="activeStatus"
                    name="activeStatus"
                    onChange={handleToggle}
                    checked={formData.activeStatus === 1}
                  />
                </div>
              </Col>
            </Row>

            <div className="mt-3 profile-button-container">
              <CustomButton
                type="submit"
                label="Add User"
              />
            </div>
          </form>

          <Row className="mt-4">
            <Col lg={12} md={12} xs={12}>
              <div style={{ overflowX: "auto" }}>
                <DataTable
                  stripedRows
                  resizableColumns
                  pt={{
                    root: { className: "table" },
                  }}
                  ref={dt}
                  value={usersList ?? []}
                  dataKey="userID"
                  paginator
                  rows={5}
                  rowsPerPageOptions={[5, 10, 15]}
                  tableStyle={{ minWidth: "25rem" }}
                >
                  <Column field="userID" header="User ID"></Column>
                  <Column field="companyId" header="Company ID"></Column>
                  <Column field="companyName" header="Company Name"></Column>
                  <Column field="loginID" header="Login ID"></Column>
                  <Column
                    field="registerMobileNumber"
                    header="Mobile No."
                  ></Column>
                  <Column field="email_address" header="Email"></Column>
                  <Column
                    field="activeStatus"
                    header="Active"
                    body={(rowData) => (rowData.activeStatus ? "True" : "False")}
                  ></Column>
                  <Column field="branchName" header="Branch"></Column>
                  <Column field="role_name" header="Role"></Column>
                  <Column
                    field="createDateTime"
                    header="Created Date"
                    dataType="date"
                    body={(rowData) => {
                      try {
                        return Common.getFormatedDate(
                          rowData.createDateTime,
                          "DD MMMM YYYY, hh:mm A",
                          true
                        );
                      } catch (error) {
                        console.error(error.message);
                        return "Invalid Date"; // or any fallback display text
                      }
                    }}
                  ></Column>
                </DataTable>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default CreateCompanyUser;
