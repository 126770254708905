import React from "react";
import { useLocation } from "react-router-dom";
import "../../assets/styles/MultiStepRegister.css";

const DocumentDetailsPage = () => {
  const location = useLocation();
  const { details } = location.state || {};

  const renderDetailItem = (key, value) => {
    // Recursive rendering for nested objects
    if (typeof value === "object" && value !== null) {
      return (
        <div key={key} className="detail-item">
          <h5>{key}</h5>
          {Object.entries(value).map(([subKey, subValue]) =>
            renderDetailItem(subKey, subValue)
          )}
        </div>
      );
    }
    return (
      <div key={key} className="detail-item">
        <strong>{key.replace(/_/g, " ")}:</strong> {value}
      </div>
    );
  };

  return (
    <>
      <div className="register-document-container">
        <div className="card-registration-document">
          <div className="document-details-page">
            <h2>Document Details</h2>
            {details ? (
              Object.entries(details).map(([key, value]) =>
                renderDetailItem(key, value)
              )
            ) : (
              <p>No details available.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentDetailsPage;
