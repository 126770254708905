import APIHandler from "helpers/APIHandler";
import { RAZORPAY_PROD_CREATE_ORDER_API } from "helpers/APIRouter";
import { CREATE_COMPANY_SUBSCRIPTION_API } from "helpers/APIRouter";
import { STRIPE_PAYMENGT_INTENTS_API } from "helpers/APIRouter";
import {
  GET_BILLS_DETAILS_BY_ID,
  SAVE_PAYMENT,
  SAVE_ADVANCE_PAYMENT,
  GET_TRANSACTION_SERVICES,
  RAZORPAY_CREATE_ORDER_API,
  GET_PAYMENT_MODES,
  GET_ADVANCE_PAY_DETAILS_BY_ID,
  GET_ACCOUNT_RECIEVABLE_DETAILS,
  GET_ACCOUNT_PAYABLE_DETAILS,
  GET_ACCOUNT_STATEMENT_DETAILS,
} from "helpers/APIRouter";

const API = new APIHandler();

class PaymentAPICall {
  getTransactionServices = async () => {
    let res = {};
    await API.get(GET_TRANSACTION_SERVICES)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getAccountStatementDetails = async (params) => {
    let res = {};

    let param = {};
    param.data = params;

    await API.post(GET_ACCOUNT_STATEMENT_DETAILS, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getPaymentModes = async () => {
    let res = {};
    await API.get(GET_PAYMENT_MODES)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getBillsDetailsByID = async (params) => {
    let res = {};
    await API.get(GET_BILLS_DETAILS_BY_ID, params)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getAdvancePayDetailsByID = async (params) => {
    let res = {};
    await API.get(GET_ADVANCE_PAY_DETAILS_BY_ID, params)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  savePayment = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(SAVE_PAYMENT, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  saveAdvancePayment = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(SAVE_ADVANCE_PAYMENT, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  razorPayCreateOrder = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    let URL =
      process.env.REACT_APP_RAZORPAY_ENVIRONMENT === "test"
        ? RAZORPAY_CREATE_ORDER_API
        : RAZORPAY_PROD_CREATE_ORDER_API;
    await API.post(URL, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  stripPaymentIntents = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(STRIPE_PAYMENGT_INTENTS_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getAccountRecievableDetails = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(GET_ACCOUNT_RECIEVABLE_DETAILS, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getAccountpayableDetails = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(GET_ACCOUNT_PAYABLE_DETAILS, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default PaymentAPICall;
