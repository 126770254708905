import React, { forwardRef } from "react";

const CustomButton = forwardRef(
  ({ label, icon, customStyle, ...props }, ref) => {
    return (
      <button
        ref={ref} // Forward the ref to the button element
        className={`custom-primary-button ${customStyle}`}
        {...props}
      >
        {label} {icon}
      </button>
    );
  }
);

export default CustomButton;
