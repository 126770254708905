import React from 'react'

const OtherFeatuers = () => {

    const OTHER_FEATURE = [
        'Inventory Management',
        'Analytics',
        'Payment Reports and Payment Status',
        'Re-Order and Demand Prediction',
        'Customizable Template',
        'Bill Template and Recent Product List',
        'Cloud Web & Mobile App (Android & iOS)',
        '99.9% Uptime SLA',
        'Historical Analytics',
        'Advanced Permissions',
        'Unlimited Storage for Product Images'
    ]

    return (
        <div className="other-features-wrapper">
            <div className='other-features-container'>
                <div className='other-features-title'>
                    <h4>+ Other Features</h4>
                </div>
                <ul className="feature-list">
                    {
                        OTHER_FEATURE.map((item, index) => <li className='feature-item' key={index}>{item}</li>)
                    }
                </ul>
            </div>
        </div>
    )
}

export default OtherFeatuers