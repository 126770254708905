import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/styles/FilterButton.css";
import { Button } from "reactstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from "components/Button/CustomButton";

const FilterButton = ({ getReport, setLoadingFilter }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [timeRange, setTimeRange] = useState(null);
  const [customStartTime, setCustomStartTime] = useState(new Date());
  const [customEndTime, setCustomEndTime] = useState(new Date());
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    setIsOptionSelected(true);
  };

  const popupRef = useRef();

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setPopupVisible(false);
    }
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const firstColumnOptions = ["30min", "1hour", "4hours", "6hours", "12hours"];

  const secondColumnOptions = ["24hours", "3days", "7days", "30days", "custom"];

  const timeOptionsLabels = {
    "30min": "Last 30 minutes",
    "1hour": "Last hour",
    "4hours": "Last 4 hours",
    "6hours": "Last 6 hours",
    "12hours": "Last 12 hours",
    "24hours": "Last 24 hours",
    "3days": "Last 3 days",
    "7days": "Last 7 days",
    "30days": "Last 30 days",
    custom: "Custom",
  };

  const handleSubmit = () => {


    let startDate, endDate;
    // Get the current UTC time
    let currentUTCTime = moment.utc();

    switch (timeRange) {
      case "30min":
        startDate = moment(currentUTCTime).subtract(30, "minutes");
        endDate = moment(currentUTCTime);
        break;
      case "1hour":
        startDate = moment(currentUTCTime).subtract(1, "hours");
        endDate = moment(currentUTCTime);
        break;
      case "4hours":
        startDate = moment(currentUTCTime).subtract(4, "hours");
        endDate = moment(currentUTCTime);
        break;
      case "6hours":
        startDate = moment(currentUTCTime).subtract(6, "hours");
        endDate = moment(currentUTCTime);
        break;
      case "12hours":
        startDate = moment(currentUTCTime).subtract(12, "hours");
        endDate = moment(currentUTCTime);
        break;
      case "24hours":
        startDate = moment(currentUTCTime).subtract(24, "hours");
        endDate = moment(currentUTCTime);
        break;
      case "3days":
        startDate = moment(currentUTCTime).subtract(3, "days");
        endDate = moment(currentUTCTime);
        break;
      case "7days":
        startDate = moment(currentUTCTime).subtract(7, "days");
        endDate = moment(currentUTCTime);
        break;
      case "30days":
        startDate = moment(currentUTCTime).subtract(30, "days");
        endDate = moment(currentUTCTime);
        break;
      case "custom":
        startDate = moment(customStartTime);
        endDate = moment(customEndTime);
        break;
      default:
        alert("Select a valid date range.");
        return;
    }

    setLoadingFilter(true);
    getReport(
      startDate.format("YYYY-MM-DD HH:mm"),
      endDate.format("YYYY-MM-DD HH:mm")
    );
    closePopup();
  };

  const range = (start, end) => {
    const length = end - start;
    return Array.from({ length }, (_, i) => start + i);
  };

  // Generate years and months arrays
  const years = range(1990, new Date().getFullYear() + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Custom header rendering function
  const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div
      style={{
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        className="date-picker-nav-button"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <select
        className="date-picker-select"
        value={date.getFullYear()}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        className="date-picker-select"
        value={months[date.getMonth()]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        className="date-picker-nav-button"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className="custom-date-input"
      onClick={onClick}
      ref={ref}
    >
      <FontAwesomeIcon icon={faCalendarAlt} /> {value}
    </button>
  ));

  return (
    <div className="filter-container" ref={popupRef}>
      <button onClick={togglePopup} className="filter-button" style={{ backgroundColor: '#434CE6' }}>
        {timeRange ? timeOptionsLabels[timeRange] : 'Filter'}
        <FontAwesomeIcon icon={isPopupVisible ? faChevronUp : faChevronDown} />
      </button>
      {isPopupVisible && (
        <div className="filter-popup">
          <form>
            <div className="grid-container">
              <div className="time-range-options first-column">
                {firstColumnOptions.map((option) => (
                  <label
                    key={option}
                    className={`time-option ${option === "custom" ? "custom-option" : ""
                      }`}
                  >
                    <input
                      type="radio"
                      value={option}
                      checked={timeRange === option}
                      onChange={handleTimeRangeChange}
                    />
                    <span className="label-text">
                      {timeOptionsLabels[option]}
                    </span>
                  </label>
                ))}
              </div>
              <div className="time-range-options second-column">
                {secondColumnOptions.map((option) => (
                  <label
                    key={option}
                    className={`time-option ${option === "custom" ? "custom-option" : ""
                      }`}
                  >
                    <input
                      type="radio"
                      value={option}
                      checked={timeRange === option}
                      onChange={handleTimeRangeChange}
                    />
                    <span className="label-text">
                      {timeOptionsLabels[option]}
                    </span>
                  </label>
                ))}
              </div>
            </div>
            {timeRange === "custom" && (
              <div className="custom-time-range">
                <div>
                  <label>Start Time</label>
                  <DatePicker
                    // showIcon
                    selected={customStartTime}
                    onChange={(date) => setCustomStartTime(date)}
                    dateFormat="dd-MM-yyyy HH:mm"
                    // showTimeSelect
                    showTimeInput
                    calendarIconClassname="calenderIcon"
                    timeFormat="HH:mm"
                    customInput={<CustomInput />}
                    timeIntervals={15}
                    renderCustomHeader={renderCustomHeader}
                  />
                </div>
                <div>
                  <label>End Time</label>
                  <DatePicker
                    // showIcon
                    selected={customEndTime}
                    onChange={(date) => setCustomEndTime(date)}
                    dateFormat="dd-MM-yyyy HH:mm"
                    // showTimeSelect
                    showTimeInput
                    timeFormat="HH:mm"
                    customInput={<CustomInput />}
                    timeIntervals={15}
                    renderCustomHeader={renderCustomHeader}
                  />
                </div>
              </div>
            )}
            <div className="popup-actions">
              <CustomButton
                label="Apply"
                type="button"
                onClick={handleSubmit}
                disabled={!isOptionSelected}
              />

              <CustomButton
                label="Cancel"
                customStyle="custom-secondary-button"
                type="button"
                onClick={() => closePopup()}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default FilterButton;
