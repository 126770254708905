import React, { useEffect, useState } from "react";
// reactstrap components
import { Row, Col, Card, CardBody, CardFooter } from "reactstrap";
import "../../assets/styles/Dashboard.css";
// used components
import { useDashboardV2DataStore } from "hooks/DashboardV2";
import PageLoader from "components/Loader/PageLoader";
// import users from "assets/img/icons/dashboard/users.svg";
// import active_user from "assets/img/icons/dashboard/active-user.svg";
// import profit from "assets/img/icons/dashboard/profit.svg";
// import expanse from "assets/img/icons/dashboard/expanse.svg";
import ChildLoader from "components/Loader/ChildLoader";
import sales2 from 'assets/img/icons/dashboard/sales2.png';
import expense from 'assets/img/icons/dashboard/expense2.png';
import profit1 from 'assets/img/icons/dashboard/profit1.png';
import customer1 from 'assets/img/icons/dashboard/customer1.png';
import { MdOutlineTrendingDown, MdOutlineTrendingUp, MdPeopleAlt } from "react-icons/md";
import { ReactComponent as TOTAL_SALES_ICON } from 'assets/img/icons/dashboard/total-sales-icon.svg';
import { GrLineChart } from "react-icons/gr";

function formatDate(date) {
  let inputDate = new Date(date);
  let year = inputDate.getFullYear();
  let month = String(inputDate.getMonth() + 1).padStart(2, "0");
  let day = String(inputDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function getTodayDate() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

const Widgets = (props) => {
  let fromDateParam = props.fromDate;
  let toDateParam = props.toDate;



  const [{ }, { getCustomerCounts, getProducSales }, { }] =
    useDashboardV2DataStore();
  const [loader, setLoader] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [productSalesData, setProductSalesData] = useState(null);

  useEffect(() => {
    getCustomerData();
    getProductSalesData();
  }, []);

  useEffect(() => {
    getProductSalesData();
  }, [fromDateParam]);

  const getCustomerData = async () => {
    try {
      setLoader(true);
      let customerData = await getCustomerCounts();
      setCustomerData(customerData);
      setLoader(false);
    } catch (error) {
      // for error handling
      alert(error);
    }
  };

  const getProductSalesData = async () => {
    try {
      setLoader(true);

      let productsaleData = await getProducSales({
        params: {
          year: new Date().getFullYear(),
          fromdate: fromDateParam.toString(),
          todate: toDateParam || formatDate(getTodayDate()).toString(),
        },
      });

      setProductSalesData(productsaleData);
      setLoader(false);
    } catch (error) {
      // for error handling
      alert(error);
    }
  };

  return (
    <>
      {loader ? (
        <ChildLoader />
      ) : (
        <Col lg={12} md={12} xs={12}>
          <Row className="mb-1 dashboard-widget-wrapper">
            {/* Profit Card */}
            <Col lg={3} md={12} xs={12}>
              <Card className="dashboard-card shadow-effect dashboard-card-bg-style">

                <CardBody className="d-flex flex-column justify-content-between">
                  <Row>
                    <Col lg={8} md={6} sm={6} xs={6} className="d-flex align-items-center justify-content-between">
                      <div className="card-title">Net Profit / Loss</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className={`card-value ${productSalesData?.netProfit > 0 ? "net-profit-card-value" : "net-expense-card-value"}`}>₹ <span>{productSalesData?.netProfit}</span><MdOutlineTrendingUp className={productSalesData?.netProfit > 0 ? "net-profit-card-value-icon" : "net-expense-card-value-icon"} /></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            {/* Purchase Card */}
            <Col lg={3} md={12} xs={12}>
              <Card className="dashboard-card shadow-effect dashboard-card-bg-style">

                <CardBody className="d-flex flex-column justify-content-between">
                  <Row>
                    <Col lg={8} md={6} sm={6} xs={6} className="d-flex align-items-center justify-content-between">
                      <div className="card-title">Net Purchase</div>
                    </Col>
                    {/* <Col lg={4} md={6} sm={6} xs={6} className="center-content">
                      <img
                        src={customer1}
                        alt="user icon"
                        className="dashboard-icon"
                      />
                    </Col> */}
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className="card-value net-expense-card-value">₹ <span>{productSalesData?.netPurchaseAmount}</span><MdOutlineTrendingDown className="net-expense-card-value-icon" /></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            {/* Sales Card */}
            <Col lg={3} md={12} xs={12}>
              <Card className="dashboard-card shadow-effect dashboard-card-bg-style">

                <CardBody className="d-flex flex-column justify-content-between">
                  <Row>
                    <Col lg={8} md={6} sm={6} xs={6} className="d-flex align-items-center justify-content-between">
                      <div className="card-title">Net Sales</div>
                    </Col>
                    <Col lg={4} md={6} sm={6} xs={6} className="d-flex align-items-center justify-content-end pl-0">
                      <div className="dashboard-icon-conainer">
                        {/* <img
                          src={total_sales_icon}
                          alt="user icon"
                        /> */}
                        {/* <TOTAL_SALES_ICON color="red" /> */}
                        <GrLineChart className="dashboard-icon" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className="card-value net-profit-card-value">₹ <span>{productSalesData?.netSalesAmount}</span></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            {/* Customers Card */}
            <Col lg={3} md={12} xs={12}>
              <Card className="dashboard-card shadow-effect dashboard-card-bg-style">

                <CardBody className="d-flex flex-column justify-content-between">
                  <Row>
                    <Col lg={8} md={6} sm={6} xs={6} className="d-flex align-items-center justify-content-between">
                      <div className="card-title">Customer Count</div>
                    </Col>
                    <Col lg={4} md={6} sm={6} xs={6} className="d-flex align-items-center justify-content-end pl-0">
                      <div className="dashboard-icon-conainer">
                        <MdPeopleAlt className="dashboard-icon" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className="card-value"><span>{customerData?.totalCustomers}</span></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
};

export default Widgets;
