import React from "react";

const ForCastReport = () => {
    return(
        <>
            ForCastReport
        </>
    )
}

export default ForCastReport