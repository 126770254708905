const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");
const { InputGroup, Input, InputGroupAddon, InputGroupText } = require("reactstrap");

const CustomInput = ({ onEyeClick, passwordType, showEyeIcon, feedback, ...props }) => {
    return (
        <>
            <InputGroup className="input-group-custom">
                <Input className="input-custom" {...props} />
                <InputGroupAddon addonType="append">
                    {showEyeIcon ?
                        <InputGroupText
                            className="input-group-text-custom"
                            style={{ cursor: "pointer" }}
                            onClick={onEyeClick}
                        >
                            {passwordType === "password" ? (
                                <FontAwesomeIcon
                                    icon="fa-solid fa-eye-slash"
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon="fa-solid fa-eye"
                                />
                            )}
                        </InputGroupText>
                        :
                        (props?.icon) && <InputGroupText className="input-group-text-custom">
                            {props?.icon}
                        </InputGroupText>}
                </InputGroupAddon>
                {/* <FormFeedback>{feedback}</FormFeedback> */}
            </InputGroup>
        </>
    );
};

export default CustomInput;