import React, { memo } from "react";
import "../../assets/styles/Invoice.css";
import CreateBillRepository from "repository/CreateBillRepository";
import getSymbolFromCurrency from "currency-symbol-map";
import QRCodeGenerator from "views/payment/QRCodeGenerator";
import { Col, Row } from "reactstrap";

const InvoiceFooter = memo(({ grandTotal }) => {
  const footer = new CreateBillRepository().getBillInvoiceHeaderFooter()
  const currency = new CreateBillRepository().getCurrencyAbbriviation()

  const processedHTML = footer?.InvoiceFooter?.replace(/\$currency_name|\$current_symbol|\$grand_total_amount/g, (match) => {
    return match === '$currency_name' ? currency : match === '$current_symbol' ? getSymbolFromCurrency(currency) : grandTotal;
  });

  return (
    <div style={{width:'96%',margin:'auto', display:'flex'
    }}>
      
        <div dangerouslySetInnerHTML={{ __html: processedHTML }} />
    
      
        <QRCodeGenerator amount={grandTotal} />
      
    </div>
  );
});

export default InvoiceFooter;
