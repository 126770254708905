import React, { useState } from "react";
import { Line } from "react-chartjs-2";

// reactstrap components
import { Card, CardBody, Row, Col, FormGroup } from "reactstrap";

// core components
import { useEffect } from "react";
import { isArray, isEmpty } from "underscore";
import { useCreateBillStore } from "hooks/CreateBill";
import { useReportStore } from "hooks/Report";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Common from "helpers/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FilterButton from "views/Report/FilterButton";
import DownloadReport from "views/Report/DownloadReport";
import PageLoader from "components/Loader/PageLoader";
import ChildLoader from "components/Loader/ChildLoader";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import LoadMore from "components/Button/LoadMore";
import "assets/styles/analytics/SalesReport.css";
import { Link } from "react-router-dom";
import { URL_HISTORICAL_DEMAND_REPORT } from "helpers/Paths";

const RevenueReportLineChart = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [{}, { getRecentBill }, {}] = useCreateBillStore();
  const [creditReportData, setCreditReportData] = useState([]);
  const [{}, { getRevenueReport }, {}] = useReportStore();
  const [selectedDate, setSelectedDate] = useState("");
  const [highestQty, setHighestQty] = useState(0);
  const [highestRevenue, setHighestRevenue] = useState(0);
  const [startDate, setStartDate] = useState(Common.getLastMonthDate());
  const [endDate, setEndDate] = useState(Common.getTodayDate());
  const [loader, setLoader] = useState(false);
  const [salesQuantity, setSaleQuantity] = useState([]);
  const [salesRevenue, setSalesRevenue] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const MAX_RECORDS = 15;

  const loadMoreData = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
    setLoadingMore(true);
    getReport(startDate, endDate, pageNumber + 1);
  };

  useEffect(() => {
    setLoader(true);
    getReport();
  }, []);

  const revenueChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            return `Revenue: ${context.raw}`;
          },
        },
        displayColors: false,
        backgroundColor: "#585858",
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        padding: 10,
        cornerRadius: 4,
      },
    },
    scales: {
      y: {
        min: 0,
        max: Math.ceil(highestRevenue / 2000) * 2000, // Dynamically adjust the max to the nearest multiple of 2000
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          padding: 20,
          color: "#9f9f9f",
          font: {
            family: "DM Sans, sans-serif",
          },
          stepSize: highestRevenue > 10000 ? 2000 : 1000, // Dynamically adjust the step size
        },
      },
      x: {
        grid: {
          borderDash: [5, 5],
        },
        ticks: {
          padding: 20,
          color: "#9f9f9f",
          font: {
            family: "DM Sans, sans-serif",
          },
        },
      },
    },
  };

  let initialReportState = {
    labels: [],
    datasets: [],
  };

  const [quantityChartData, setQuantityChartData] =
    useState(initialReportState);
  const [revenueChartData, setRevenueChartData] = useState(initialReportState);

  const formatFloat = (value) => {
    return value === null
      ? parseFloat(0.0).toFixed(2)
      : parseFloat(parseFloat(value).toFixed(2));
  };

  function getThreeMonthsAgo() {
    const today = new Date();
    today.setMonth(today.getMonth() - 3); // Subtract 3 months from the current month
    return today;
  }

  const setChartQuantityDetails = (
    labelData,
    salesDataInfo,
    label = "Sales Quantity"
  ) => {
    const chartQuantityDetails = {
      labels: labelData,
      datasets: [
        {
          label: label,
          data: salesDataInfo,
          borderColor: "rgb(67, 76, 230)",
          backgroundColor: "rgb(67, 76, 230)",
          borderWidth: 1,
        },
      ],
    };
    setQuantityChartData(chartQuantityDetails);
  };

  const setChartRevenueDetails = (
    labelData,
    allRevenueData,
    label = "Sales Revenue"
  ) => {
    const chartRevenueDetails = {
      labels: labelData,
      datasets: [
        {
          label: label,
          data: allRevenueData,
          borderColor: "rgb(67, 76, 230)",
          backgroundColor: "rgb(67, 76, 230)",
          borderWidth: 1,
        },
      ],
    };
    setRevenueChartData(chartRevenueDetails);
  };

  async function getReport(Date = "", endDate = "", page = 1) {
    if (page === 1) {
      setPageNumber(1);
    }

    let params = {};
    params.pagenumber = page;
    params.pagesize = new CompanyProfileRepository().getPageSize();
    if (Date && endDate) {
      params.from_date = Date;
      params.to_date = endDate;
      params.fromdate = Date;
      params.todate = endDate;
      setSelectedDate(Date);
      setStartDate(Date);
      setEndDate(endDate);
    } else if (Date) {
      params.from_date = Date;
      params.to_date = Common.getTodayDate();
      params.fromdate = Date;
      params.todate = Common.getTodayDate();
      setStartDate(Date);
      setEndDate(Common.getTodayDate());
    } else {
      let threeMonthsAgo = getThreeMonthsAgo();
      setSelectedDate(Common.getFormatedDate(threeMonthsAgo, "DD-MM-YYYY"));
      params.from_date = threeMonthsAgo;
      params.to_date = Common.getTodayDate();
      params.fromdate = threeMonthsAgo;
      params.todate = Common.getTodayDate();
      setStartDate(threeMonthsAgo);
      setEndDate(Common.getTodayDate());
    }

    let revenueData = await getRevenueReport(params);

    let labelsData = [];
    let salesQuantityData = [];
    let salesRevenueData = [];
    if (revenueData && isArray(revenueData) && !isEmpty(revenueData)) {
      revenueData.forEach((v, i) => {
        labelsData.push(Common.getFormatedDate(v.revenueDate, "DD/MM"));
        salesQuantityData.push(v.salesQuantity);
        salesRevenueData.push(v.salesRevenue);
      });
    }

    if (revenueData.length === 0) {
      if (page === 1) {
        setSaleQuantity(salesQuantityData);
        setSalesRevenue(salesRevenueData);
        setLabels(labelsData);
        setChartRevenueDetails(labelsData, salesRevenueData);
        setChartQuantityDetails(labelsData, salesQuantityData);
        setHighestQty(Math.round(Math.max(...salesQuantityData)));
        setHighestRevenue(Math.round(Math.max(...salesRevenueData)));
      }

      setLoadingMore(false);
      setLoader(false);
    } else if (revenueData.length > 0 && page > 1) {
      // Append new data to existing data for subsequent pages

      const salesinfo = [...salesQuantity, ...salesQuantityData];
      const labelsInfo = [...labels, ...labelsData];
      const revenueInfo = [...salesRevenue, ...salesRevenueData];

      setSaleQuantity((prevData) => [...prevData, ...salesQuantityData]);
      setSalesRevenue((prevData) => [...prevData, ...salesRevenueData]);
      setLabels((prevData) => [...prevData, ...labelsData]);

      if (salesinfo.length > MAX_RECORDS) {
        // Combine data for sorting and slicing
        let combinedData = labelsInfo.map((label, index) => ({
          label,
          salesQuantity: salesinfo[index],
          salesRevenue: revenueInfo[index],
        }));

        // Sort combinedData based on salesQuantity in descending order and slice top 20 records
        combinedData.sort((a, b) => b.salesQuantity - a.salesQuantity);
        combinedData = combinedData.slice(0, MAX_RECORDS);

        // Extract data for the chart
        let topLabels = combinedData.map((data) => data.label);
        let topSalesQuantities = combinedData.map((data) => data.salesQuantity);
        let topSalesRevenues = combinedData.map((data) => data.salesRevenue);

        // Update charts with top 20 data
        setChartQuantityDetails(
          topLabels,
          topSalesQuantities,
          `Top ${MAX_RECORDS} Sales Quantities`
        );
        setChartRevenueDetails(
          topLabels,
          topSalesRevenues,
          `Top ${MAX_RECORDS} Sales Revenues`
        );
        setHighestQty(Math.round(Math.max(...topSalesQuantities)));
        setHighestRevenue(Math.round(Math.max(...topSalesRevenues)));
        setLoadingMore(false);
        setLoader(false);
      } else {
        const allSalesQuantity = [...salesQuantity, ...salesQuantityData];
        const allRevenueData = [...salesRevenue, ...salesRevenueData];
        const allLabelsInfo = [...labels, ...labelsData];

        setChartRevenueDetails(allLabelsInfo, allRevenueData);
        setChartQuantityDetails(allLabelsInfo, allSalesQuantity);

        setHighestQty(Math.round(Math.max(...allSalesQuantity)));
        setHighestRevenue(Math.round(Math.max(...allRevenueData)));

        setLoadingMore(false);
        setLoader(false);
      }
    } else {
      // Set new data for the first page

      setSaleQuantity(salesQuantityData);
      setSalesRevenue(salesRevenueData);
      setLabels(labelsData);
      setChartRevenueDetails(labelsData, salesRevenueData);
      setChartQuantityDetails(labelsData, salesQuantityData);
      setHighestQty(Math.round(Math.max(...salesQuantityData)));
      setHighestRevenue(Math.round(Math.max(...salesRevenueData)));

      setLoadingMore(false);
      setLoader(false);
    }

    let creditData = await getRecentBill(params);

    let arr = [];
    if (creditData && isArray(creditData) && !isEmpty(creditData)) {
      creditData.map((prop, key) => {
        let createDateObj = moment(prop.createDateTime);
        let formattedDate = createDateObj.format("MMMM D, YYYY");
        let formattedTime = createDateObj.format("hh:mm A");

        let formattedDateTime = `${formattedDate}, ${formattedTime}`;

        let obj = {
          id: prop.billId,
          billId: prop.billId,
          billDetail: prop.billDetail,
          customerName: prop.customerName,
          totalDiscountAmount: formatFloat(prop.total_discount_amount),
          netProductPayment: formatFloat(prop.netProductPayment),
          applicableTax: formatFloat(prop.applicableTax),
          netPayment: formatFloat(prop.netPayment),
          dateTime: formattedDateTime,
        };
        arr.push(obj);
      });
    }

    if (page > 1) {
      setCreditReportData((prevData) => [...prevData, ...arr]);
      setLoadingMore(false);
      setLoader(false);
    } else {
      setCreditReportData(arr);
      setLoader(false);
    }

    setLoadingFilter(false);
  }

  const renderInput = () => {
    return (
      <FormGroup className="has-label">
        <div className="row">
          <ReactDatetime
            inputProps={{ placeholder: "Select Date and Time" }}
            onChange={onDateSelect}
          />
          <div className="mt-2 ml-2">
            <FontAwesomeIcon
              className="border-right pr-2"
              icon="fa-solid fa-calendar"
            />
            <FontAwesomeIcon className="pl-2" icon="fa-solid fa-clock" />
          </div>
        </div>

        <div className="row datetime-icon"></div>
      </FormGroup>
    );
  };
  const onDateSelect = (e) => {
    setSelectedDate(moment(e).format("YYYY-MM-DDTHH:MM:SS"));
    getReport();
  };

  const headers = [
    { label: "Bill Id", key: "billId" },
    { label: "Customer Name", key: "customerName" },
    { label: "Discount Amount", key: "totalDiscountAmount" },
    { label: "Product Payment", key: "netProductPayment" },
    { label: "Applicable Tax", key: "applicableTax" },
    { label: "Total Amount", key: "netPayment" },
    { label: "Date Time", key: "dateTime" },
  ];

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <>
          <div className="content-dashboard-report">
            <div className="d-flex justify-content-between">
              <h4 className="title">
                Reports /{" "}
                <Link to={`/admin${URL_HISTORICAL_DEMAND_REPORT}`}>
                  Sales Report
                </Link>{" "}
                / Revenue Report
              </h4>
            </div>

            <Row>
              <Col sm="12" md="12" lg="12">
                <Card className="chart-container">
                  <CardBody>
                    {loadingMore || loadingFilter ? (
                      <ChildLoader />
                    ) : (
                      <>
                        <div className="selling-header">
                          <h5>Revenue Report</h5>
                          <div className="border-0">
                            <FilterButton
                              getReport={getReport}
                              setLoadingFilter={setLoadingFilter}
                            />
                          </div>
                        </div>
                        {revenueChartData.labels.length > 0 ? (
                          <div className="bar-chart-wrapper">
                            <div className="bar-chart-container">
                              <Line
                                data={revenueChartData}
                                options={revenueChartOptions}
                                height={320}
                                width={820}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              height: "calc(100% - 40px)",
                              fontSize: "20px",
                            }}
                            className="d-flex align-items-center justify-content-center fs-4"
                          >
                            No Data Available
                          </div>
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card className="report-table-card">
                  <CardBody>
                    <div className="report-table-title">
                      <h5>Revenue Report</h5>
                      <DownloadReport
                        reportData={creditReportData}
                        headers={headers}
                      />
                    </div>
                    {loadingFilter ? (
                      <ChildLoader />
                    ) : (
                      <>
                        <DataTable
                          value={creditReportData}
                          tableStyle={{ minWidth: "25rem" }}
                        >
                          <Column field="billId" header="Bill ID"></Column>
                          <Column
                            field="customerName"
                            header="Customer Name"
                          ></Column>
                          <Column
                            field="netProductPayment"
                            header="Price"
                            align="center"
                          ></Column>
                          <Column
                            field="applicableTax"
                            header="Applicable Tax"
                            align="center"
                          ></Column>
                          <Column
                            field="totalDiscountAmount"
                            header="Discount"
                            align="center"
                          ></Column>
                          <Column
                            field="netPayment"
                            header="Total Amount"
                            align="center"
                          ></Column>
                          <Column
                            field="dateTime"
                            header="Bill Creation Date"
                            align="center"
                            dataType="date"
                          ></Column>
                        </DataTable>
                      </>
                    )}
                  </CardBody>
                </Card>
                <Row>
                  <Col className="text-center">
                    {loadingMore ? (
                      <ChildLoader />
                    ) : (
                      <LoadMore
                        totalData={creditReportData.length}
                        handleLoadMore={() => loadMoreData()}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};
export default RevenueReportLineChart;
