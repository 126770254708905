import {
  Row,
  Col,
  ListGroupItem,
  Modal,
  ModalBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  ModalHeader,
} from "reactstrap";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { usePaymentStore } from "hooks/Payment";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import CustomButton from "components/Button/CustomButton";

const CashPaymentModal = (props) => {
  const {
    payDetails,
    cashModal,
    setCashModal,
    paymentModeSelect,
    transactionServiceSelect,
    reset,
    setLoader,
    partialPayAmount,
    partialPay,
    customerID,
    setSelectedBills,
    getBillDetails,
    selectedBills,
  } = props;
  const [Description, setDescription] = useState("");
  const [{ }, { }, { savePayment, saveAdvancePayment }] = usePaymentStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();

  const toggle = () => setCashModal(!cashModal);

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    const transactionAmount = payDetails?.map((x) => x.netProductPayment);
    let totalTransactionAmount = 0;
    transactionAmount?.forEach((el) => (totalTransactionAmount += el));

    let transactionTax = payDetails?.map((x) => x.applicableTax);
    let totalTransactionTax = 0;
    transactionTax?.forEach((el) => (totalTransactionTax += el));

    let amount = payDetails?.map((x) => x.netPayment);
    let totalAmount = 0;
    amount?.forEach((el) => (totalAmount += el));
    let invocies = payDetails.map((item) => {
      return {
        billID: item.billId,
        grandTotal: parseFloat(item.netPayment.toFixed(2)),
        referenceNumber: "",
        isSuccess: true,
      };
    });
    let param = {
      transactionServiceID: transactionServiceSelect?.value,
      customerId: partialPay ? customerID : payDetails[0]?.customerId,
      transactionAmount: parseFloat(totalTransactionAmount?.toFixed(2)),
      transactionTax: parseFloat(totalTransactionTax?.toFixed(2)),
      transactionTotalAmount: partialPay
        ? parseFloat(partialPayAmount)
        : parseFloat(totalAmount?.toFixed(2)),
      referenceNumber: "", // kept temporary staic ref numbernder development of payment
      PaymentTypeID: paymentModeSelect?.value,
      paymentStatus: true,
      description: Description,
      invoice_Payments: invocies,
    };
    let res = await savePayment(param);
    if (res) {
      await setAlerts({
        type: "success",
        message: notificationMessages.paymentSuccess,
      });
      setCashModal(!cashModal);
      // setSelectedBills("")
      getBillDetails(selectedBills);
    }
    reset();
    setLoader(false);
  };

  const handleAdvancePaymentSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let param = {
      transactionServiceID: transactionServiceSelect?.value,
      customerId: customerID,
      TransactionTotalAmount: parseFloat(partialPayAmount),
      paymentTypeID: paymentModeSelect?.value,
      description: Description,
    };
    let res = await saveAdvancePayment(param);
    if (res) {
      await setAlerts({
        type: "success",
        message: notificationMessages.paymentSuccess,
      });
      setCashModal(!cashModal);
      // setSelectedBills("")
      getBillDetails(selectedBills);
    }
    reset();
    setLoader(false);
  };

  return (
    <>
      <Modal
        isOpen={cashModal}
        toggle={toggle}
        centered={true}
        fullscreen={"md"}
        size="lg"
      >
        {/* <ListGroupItem>
          <Row xs="2">
            <Col
              xs="10"
              style={{
                paddingLeft: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <h4 toggle={toggle} style={{}}>
                Cash Payment
              </h4>
            </Col>
            <Col
              xs="2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                width: "100%",
              }}
            >
              <AiOutlineClose onClick={toggle} style={{ fontWeight: "bold" }} />
            </Col>
          </Row>
        </ListGroupItem> */}
        <ModalBody>
          <div className="payment-modal-header">
            <h5>Cash Payment</h5>
            <AiOutlineClose onClick={toggle} size={20} className="payment-modal-close-btn" />
          </div>

          <Form
            onSubmit={(e) => {
              if (transactionServiceSelect?.value == 3) {
                handleAdvancePaymentSubmit(e);
              } else {
                handlePaymentSubmit(e);
              }
            }}
          >
            <Row form>
              <Col md={12}>
                <FormGroup className="has-label customer-input-container">
                  <Label for="customerName">Description</Label>
                  <Input
                    id="Description"
                    name="Description"
                    value={Description}
                    onChange={(e) => setDescription(e.target.value)}
                    type="textarea"
                    className="textarea"
                    rows="3"
                    placeholder="Payment Description"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="w-100 d-flex align-items-center justify-content-end">
              <CustomButton type="submit" label="Make Payment" />
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CashPaymentModal;
