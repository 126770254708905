import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNotificationStore } from "hooks/Notification";
import PageLoader from "components/Loader/PageLoader";
import Common from "helpers/Common";
import RefreshButton from "components/Button/RefreshButton";

const type = [
  { name: "All", value: "0" },
  { name: "Email", value: "1" },
  { name: "SMS", value: "2" },
  { name: "WhatsApp", value: "3" },
];

const NotificationReport = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [{}, { getNotificationList }, {}] = useNotificationStore();
  const [loader, setLoader] = useState(false);
  const [NotificationType, setNotificationType] = useState(
    type.find((t) => t.name === "All")
  );
  const dt = useRef(null);

  const getNotificationData = async () => {
    try {
      let params = {};
      if (NotificationType.value !== "0") {
        params.NotificationTypeID = NotificationType.value;
      }
      let List = await getNotificationList(params);
      setLoader(false);
      setNotificationData(List?.payload);
    } catch (error) {
      setLoader(false);
      setNotificationData([]);
    }
  };

  useEffect(() => {
    setLoader(true);
    getNotificationData();
  }, [NotificationType]);

  return (
    <>
      <div
        className="content-dashboard"
        style={{ padding: "20px", backgroundColor: "#f8f9fa" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4
            className="title m-0"
            style={{ color: "#3c4b64", fontWeight: "bold", fontSize: "24px" }}
          >
            Notification Report
          </h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label
              style={{
                marginRight: "10px",
                marginTop: "9px",
                fontSize: "18px",
                color: "black",
              }}
            >
              Notification Type:
            </label>
            <Dropdown
              value={NotificationType.value}
              onChange={(e) => {
                setNotificationType(type.find((t) => t.value === e.value));
              }}
              options={type}
              optionLabel="name"
              style={{ width: "200px", marginRight: "10px" }}
            />
            <RefreshButton />
          </div>
        </div>

        <Row>
          <Col>
            <Card>
              <CardBody>
                {loader ? (
                  <PageLoader />
                ) : (
                  <>
                    <Row>
                      <Col>
                        <DataTable
                          ref={dt}
                          value={notificationData}
                          dataKey="notificationSettingID"
                          selectionMode="single"
                          pt={{ root: { className: "table" } }}
                          stripedRows
                          emptyMessage="No records found"
                          className="text-center"
                          resizableColumns
                          columnResizeMode="expand"
                          paginator
                          rowsPerPageOptions={[5, 10, 15]}
                          rows={5}
                        >
                          <Column
                            field="scheduleName"
                            header="Schedule Name"
                          ></Column>
                          <Column
                            field="notificationTypeName"
                            header="Notification Type"
                          ></Column>
                          <Column
                            field="frequencyName"
                            header="Frequency"
                          ></Column>
                          <Column
                            field="fromDate"
                            header="From Date"
                            dataType="date"
                            body={(rowData) => {
                              try {
                                return Common.getFormatedDate(
                                  rowData.fromDate,
                                  "DD MMMM YYYY, hh:mm A",
                                  true
                                );
                              } catch (error) {
                                console.error(error.message);
                                return "Invalid Date";
                              }
                            }}
                          ></Column>
                          <Column
                            field="toDate"
                            header="To Date"
                            dataType="date"
                            body={(rowData) => {
                              try {
                                return Common.getFormatedDate(
                                  rowData.toDate,
                                  "DD MMMM YYYY, hh:mm A",
                                  true
                                );
                              } catch (error) {
                                console.error(error.message);
                                return "Invalid Date";
                              }
                            }}
                          ></Column>
                          <Column field="subject" header="Subject"></Column>
                          <Column field="body" header="Body"></Column>
                          <Column field="to" header="To"></Column>
                          <Column
                            field="attachedFileName"
                            header="Attachment"
                          ></Column>
                          <Column
                            field="isActive"
                            header="Active"
                            body={(rowData) =>
                              rowData.isActive ? "Yes" : "No"
                            }
                          ></Column>
                        </DataTable>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NotificationReport;
