import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { Dropdown } from "primereact/dropdown";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreateBillStore } from "hooks/CreateBill";
import "../../assets/styles/CreateBill.css";
import { Link, useNavigate } from "react-router-dom";
import PageLoader from "components/Loader/PageLoader";
import { ADMIN_URL_CREATE_BILL } from "helpers/Paths";
import ChildLoader from "components/Loader/ChildLoader";
import "index.css";
import refund from "assets/img/icons/createBill/refund2.png";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import LoadMore from "components/Button/LoadMore";
import { useAzureStore } from "hooks/Azure";
import Common from "helpers/Common";
import { FaDownload, FaEye, FaPlus, FaPrint } from "react-icons/fa6";
import { FaPencilAlt } from "react-icons/fa";
import { RiRefund2Line } from "react-icons/ri";
import "assets/styles/bills/RecentBills.css";

const PData = {
  data: [
    {
      id: "1000",
      code: "f230fh0g3",
      name: "Bamboo Watch",
      description: "Product Description",
      image: "bamboo-watch.jpg",
      price: 65,
      category: "Accessories",
      quantity: 24,
      inventoryStatus: "INSTOCK",
      rating: 5,
    },
  ],
};

function formatDate(date) {
  let inputDate = new Date(date);
  let year = inputDate.getFullYear();
  let month = String(inputDate.getMonth() + 1).padStart(2, "0");
  let day = String(inputDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function formatMonthYear(date) {
  let inputDate = new Date(date);
  let year = inputDate.getFullYear();
  let month = inputDate.toLocaleString("default", { month: "short" });
  return `${month} ${year}`;
}

function getFirstDayOfWeek(date) {
  const dayOfWeek = date.getDay();
  const daysUntilFirstDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  const firstDayOfWeek = new Date(date);
  firstDayOfWeek.setDate(date.getDate() - daysUntilFirstDayOfWeek);
  return firstDayOfWeek;
}

function getLastDayOfWeek(date) {
  const firstDayOfWeek = getFirstDayOfWeek(date);
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
  return lastDayOfWeek;
}

function getFirstDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getLastDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

const now = new Date();
const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

const timelines = [
  {
    name: "Today",
    value: "Today",
    fromDate: formatDate(now),
    toDate: formatDate(now),
  },
  {
    name: "This Week",
    value: `This Week (${formatDate(getFirstDayOfWeek(now))} to ${formatDate(
      getLastDayOfWeek(now)
    )})`,
    fromDate: formatDate(getFirstDayOfWeek(now)),
    toDate: formatDate(getLastDayOfWeek(now)),
  },
  {
    name: "Last Week",
    value: `Last Week (${formatDate(
      getFirstDayOfWeek(lastWeek)
    )} to ${formatDate(getLastDayOfWeek(lastWeek))})`,
    fromDate: formatDate(getFirstDayOfWeek(lastWeek)),
    toDate: formatDate(getLastDayOfWeek(lastWeek)),
  },
  {
    name: "This Month",
    value: formatMonthYear(now),
    fromDate: formatDate(getFirstDayOfMonth(now)),
    toDate: formatDate(getLastDayOfMonth(now)),
  },
  {
    name: "Last Month",
    value: formatMonthYear(new Date(now.getFullYear(), now.getMonth() - 1, 1)),
    fromDate: formatDate(
      getFirstDayOfMonth(new Date(now.getFullYear(), now.getMonth() - 1, 1))
    ),
    toDate: formatDate(
      getLastDayOfMonth(new Date(now.getFullYear(), now.getMonth() - 1, 1))
    ),
  },
];

const BillDashboard = () => {
  const navigate = useNavigate();
  const [recentBillsData, setRecentBillsData] = useState(null);
  const [selectedRecentBillsData, setSelectedRecentBillsData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [timelineOptions, setTimelineOptions] = useState(
    timelines.find((t) => t.name === "This Week")
  );

  const [{ }, { fetchImagesFromAzure }, { }] = useAzureStore();
  const [{ }, { getRecentBill }, { }] = useCreateBillStore();

  const [products, setProducts] = useState(null);
  const [loader, setLoader] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const dt = useRef(null);

  const loadMoreData = () => {
    setLoadingMore(true);
    setPageNumber((prev) => prev + 1);
    getBillsData(pageNumber + 1);
  };

  const getBillsData = async (page = 1) => {
    if (page === 1) {
      setPageNumber(page);
    }

    try {
      async function getRecentBillsData() {
        let recentBillList = await getRecentBill({
          fromdate: timelineOptions.fromDate,
          todate: timelineOptions.toDate,
          pagesize: new CompanyProfileRepository().getPageSize(),
          pageNumber: page,
        });

        if (page > 1) {
          setRecentBillsData((prev) => [...prev, ...recentBillList]);
        } else {
          setRecentBillsData(recentBillList);
        }
        setLoader(false);
        setLoadingMore(false);
      }
      getRecentBillsData();
    } catch (error) {
      // for error handling
      setLoadingMore(false);
      setLoader(false);
      alert(error);
    }
  };

  useEffect(() => {
    setLoader(true);
    getBillsData();
  }, [timelineOptions]);

  useEffect(() => {
    setProducts(PData.data);
  }, []);

  //reports code

  let initialReportState = {
    labels: [],
    datasets: [],
  };

  const [chartData, setChartData] = useState(initialReportState);

  useEffect(() => {
    getSalesChart();
  }, []);

  const getSalesChart = async function getReport() {
    let labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    let salesRevenue = [200, 100, 400, 800, 100, 899, 400];

    const chartDetails = {
      labels,
      datasets: [
        {
          fill: true,
          label: "Sales Revenue",
          data: salesRevenue,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
    setChartData(chartDetails);
  };

  const RecentBills = () => {
    //fetch pdf from azure
    const fetchPDF = async (name, type) => {
      let pdfURL = await fetchImagesFromAzure(name, "bill");
      if (type.toLowerCase() === "preview") {
        const response = await fetch(pdfURL);
        // Get the binary data from the response
        const pdfData = await response.blob();
        // Create a Blob from the PDF data
        const file = new Blob([pdfData], { type: "application/pdf" });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL in a new window
        window.open(fileURL, "_blank");
      } else {
        window.open(pdfURL, "_blank");
      }
    };

    const ActionTemplate = ({ rowData }) => {
      return (
        <div className="d-flex justify-content-around w-100">
          <Link
            to={
              rowData.billType.toLowerCase() === "refund" ||
                rowData.editableStatus === 0
                ? "#"
                : `/admin/editBill/${rowData.billId}`
            }
          >
            <button
              type="button"
              title="Edit Bill"
              disabled={
                rowData.billType.toLowerCase() === "refund" ||
                rowData.editableStatus === 0
              }
              className={`actions-btn ${rowData.billType.toLowerCase() === "refund" ||
                rowData.editableStatus === 0
                ? "disabled-action-btn"
                : ""
                }`}
              style={{
                backgroundColor:
                  rowData.billType.toLowerCase() === "refund" ||
                    rowData.editableStatus === 0
                    ? "#d3d3d3"
                    : "#F8F8FF", // Change to desired background color for disabled state
                color:
                  rowData.billType.toLowerCase() === "refund" ||
                    rowData.editableStatus === 0
                    ? "#a9a9a9"
                    : "#fff", // Change to desired text color for disabled state
                cursor:
                  rowData.billType.toLowerCase() === "refund" ||
                    rowData.editableStatus === 0
                    ? "not-allowed"
                    : "pointer", // Change cursor to indicate disabled state
                opacity:
                  rowData.billType.toLowerCase() === "refund" ||
                    rowData.editableStatus === 0
                    ? "0.6"
                    : "1", // Adjust opacity for disabled state
              }}
            >
              <FaPencilAlt className="table-action-icon" />
            </button>
          </Link>
          <Link
            to={
              rowData.billType.toLowerCase() === "refund"
                ? "#"
                : `/admin/refundBill/${rowData.billId}`
            }
          >
            <button
              type="button"
              title="Refund Bill"
              disabled={rowData.billType.toLowerCase() === "refund"}
              className={`actions-btn ${rowData.billType.toLowerCase() === "refund"
                ? "disabled-action-btn"
                : ""
                }`}
              style={{
                backgroundColor:
                  rowData.billType.toLowerCase() === "refund"
                    ? "#d3d3d3"
                    : "#F8F8FF", // Change to desired background color for disabled state
                color:
                  rowData.billType.toLowerCase() === "refund"
                    ? "#a9a9a9"
                    : "#fff", // Change to desired text color for disabled state
                cursor:
                  rowData.billType.toLowerCase() === "refund"
                    ? "not-allowed"
                    : "pointer", // Change cursor to indicate disabled state
                opacity:
                  rowData.billType.toLowerCase() === "refund"
                    ? "0.6"
                    : "1", // Adjust opacity for disabled state
              }}
            >
              <RiRefund2Line className="table-action-icon" />
            </button>
          </Link>


          <Link>
            <button
              type="button"
              className="actions-btn"
              onClick={() => fetchPDF(rowData.billId, "preview")}
              title="View Bill PDF"
            >
              <FaEye className="table-action-icon" />
            </button>
          </Link>

          <Link>
            <button
              type="button"
              className="actions-btn"
              onClick={() => fetchPDF(rowData.billId, "download")}
              title="Download Bill PDF"
            >
              <FaDownload className="table-action-icon" />
            </button>
          </Link>

          <button type="button" className="actions-btn" title="View Invoice">
            <FaPrint className="table-action-icon" />
          </button>
        </div>
      );
    };

    return (
      <>
        <DataTable
          ref={dt}
          value={recentBillsData ?? []}
          selection={selectedRecentBillsData}
          onSelectionChange={(e) => setSelectedRecentBillsData(e.value)}
          dataKey="billId"
          globalFilter={globalFilter}
          selectionMode="single"
          pt={{
            root: { className: "table" },
          }}
          emptyMessage="No records found"
        >
          <Column
            field="invoiceReferenceNumber"
            header="Reference No"
            body={(rowData) =>
              rowData.invoiceReferenceNumber ? <span title={"Bill Id - " + rowData.billId}>{rowData.invoiceReferenceNumber}</span> : ""
            }
          ></Column>
          {/* <Column field="billId" header="Bill Number"></Column> */}
          <Column field="customerName" header="Patient Name" style={{ maxWidth: "200px" }}></Column>
          <Column field="netPayment" header="Amount"></Column>
          <Column
            field="billType"
            header="Bill Type"
            body={(rowData) => (
              <span
                style={{
                  color:
                    rowData.billType.toLowerCase() === "refund"
                      ? "red"
                      : "black",
                }}
              >
                {rowData.billType}
              </span>
            )}
          ></Column>

          {/* <Column
            field="createDateTime"
            header="Bill Creation Date"
            dataType="date"
            body={(rowData) => Common.getFormatedDate(rowData.createDateTime, "DD MMMM YYYY, hh:mm A", true)}
          ></Column> */}

          <Column
            field="createDateTime"
            header="Bill Creation Date"
            dataType="date"
            body={(rowData) => {
              try {
                return Common.getFormatedDate(
                  rowData.createDateTime,
                  "DD/MM/YYYY hh:mm:ss A",
                  true
                );
              } catch (error) {
                console.error(error.message);
                return "Invalid Date"; // or any fallback display text
              }
            }}
          ></Column>

          <Column
            header="Actions"
            align="center"
            body={(rowData) => <ActionTemplate rowData={rowData} />}
            exportable={false}
          />
        </DataTable>
        <Col className="text-center">
          {loadingMore ? (
            <ChildLoader />
          ) : (
            <LoadMore
              totalData={recentBillsData?.length}
              handleLoadMore={() => loadMoreData()}
            />
          )}
        </Col>
      </>
    );
  };
  return (
    <>
      <div className="content-dashboard">
        <div className="pt-4 mb-3 d-flex align-items-center justify-content-between">
          <h4 className="title m-0">Bills / Recent Bills</h4>
          <Dropdown
            value={timelineOptions.value}
            onChange={(e) => {
              setTimelineOptions(timelines.find((t) => t.value === e.value));
            }}
            options={timelines}
            optionLabel="value"
            className="w-30 ml-5"
          />
        </div>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="title-row">
                  <h6 className="tile-title">Recent Bills</h6>
                  <button
                    onClick={() => navigate(ADMIN_URL_CREATE_BILL)}
                    className="create-bill-btn"
                  >
                    Create Bill <FaPlus />
                  </button>
                </div>

                {loader ? (
                  <PageLoader />
                ) : (
                  <>
                    <Row>
                      <Col>
                        <RecentBills />
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default BillDashboard;
