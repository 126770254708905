import APIHandler from "helpers/APIHandler";
import { USERS_ACTIVITY_LOGS } from "helpers/APIRouter";

const API = new APIHandler();

class ActivityAPICall {
  getActivityLogList = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(USERS_ACTIVITY_LOGS, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default ActivityAPICall;
