import Common from "helpers/Common";
import { roleType } from "helpers/constantMessages";
import { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import CreateCompanyUser from "views/CreateCompany/CreateCompanyUser";
import BranchDetail from "./BranchDetail";
import CustomerSettings from "./CustomerSettings";
import LocationDetail from "./LocationDetail";

import CustomButton from "components/Button/CustomButton";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { AiOutlineApartment } from "react-icons/ai";
import { FaUser, FaUsersCog } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { MdHome, MdNotificationsActive } from "react-icons/md";
import { TfiLayoutSidebar2 } from "react-icons/tfi";
import companyIdentyImage from "../../assets/img/Designer (10).jpeg";
import GenericSettingsImage from "../../assets/img/Designer (11).jpeg";
import CustomerSettingsImage from "../../assets/img/Designer (12).jpeg";
import HeaderandFooterImage from "../../assets/img/Designer (13).jpeg";
import NotificationSettingsImage from "../../assets/img/Designer (14).jpeg";
import userImage from "../../assets/img/Designer (7).jpeg";
import AddressImage from "../../assets/img/Designer (9).jpeg";
import "../../assets/styles/company-profile/CompanyProfile.css";
import "../../assets/styles/CompanyProfile.css";
import HeaderFooter from "./HeaderFooter";
import InventorySettings from "./InventorySettings";
import Notification from "./Notification";
import TaxDetail from "./TaxDetail";


const isAdminRole = Common.getRoleId() == roleType["Admin"];

const items = [
  // { id: "settings", title: "Settings", img: settingsImage },
  {
    id: "address",
    icon: <MdHome size={20} />,
    title: "Address",
    img: `${ERP_CDN_IMAGE_URL}/Designer (9).jpeg`
      ? `${ERP_CDN_IMAGE_URL}/Designer (9).jpeg`
      : AddressImage,
  },
  {
    id: "user",
    icon: <FaUser size={20} />,
    title: "User",
    img: `${ERP_CDN_IMAGE_URL}/Designer (7).jpeg`
      ? `${ERP_CDN_IMAGE_URL}/Designer (7).jpeg`
      : userImage,
  },
  {
    id: "companyIdentity",
    icon: <AiOutlineApartment size={20} />,
    title: "Branch/Franchise",
    img: `${ERP_CDN_IMAGE_URL}/Designer (10).jpeg`
      ? `${ERP_CDN_IMAGE_URL}/Designer (10).jpeg`
      : companyIdentyImage,
  },
  {
    id: "genericSettings",
    icon: <FaGear size={20} />,
    title: "Generic Settings",
    img: `${ERP_CDN_IMAGE_URL}/Designer (11).jpeg`
      ? `${ERP_CDN_IMAGE_URL}/Designer (11).jpeg`
      : GenericSettingsImage,
  },
  {
    id: "customerSettings",
    icon: <FaUsersCog size={20} />,
    title: "Client Preferences",
    img: `${ERP_CDN_IMAGE_URL}/Designer (12).jpeg`
      ? `${ERP_CDN_IMAGE_URL}/Designer (12).jpeg`
      : CustomerSettingsImage,
  },
  {
    id: "headerAndFooter",
    icon: <TfiLayoutSidebar2 style={{ transform: "rotate(-90deg)" }} size={20} />,
    title: "Header and Footer",
    img: `${ERP_CDN_IMAGE_URL}/Designer (13).jpeg`
      ? `${ERP_CDN_IMAGE_URL}/Designer (13).jpeg`
      : HeaderandFooterImage,
  },
  {
    id: "notificationSettings",
    icon: <MdNotificationsActive size={20} />,
    title: "Notification",
    img: `${ERP_CDN_IMAGE_URL}/Designer (14).jpeg`
      ? `${ERP_CDN_IMAGE_URL}/Designer (14).jpeg`
      : NotificationSettingsImage,
  },
  // {
  //   id: "inventorySettings",
  //   icon: <MdNotificationsActive size={20} />,
  //   title: "Inventory Settings",
  //   img: `${ERP_CDN_IMAGE_URL}/productSettings.jpg`
  //     ? `${ERP_CDN_IMAGE_URL}/productSettings.jpg`
  //     : ProductSettingsImage,
  // },
];

const componentMapping = {
  // settings: CreateCompany,
  address: LocationDetail,
  user: CreateCompanyUser,
  companyIdentity: BranchDetail,
  genericSettings: TaxDetail,
  customerSettings: CustomerSettings,
  headerAndFooter: HeaderFooter,
  notificationSettings: Notification,
  inventorySettings: InventorySettings,
};

const CompanyProfilev2 = () => {
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [searchText, setSearchText] = useState("");
  const itemRefs = useRef({});
  const previousHighlightRef = useRef(null);
  const [{ taxDetails }, { getGenricData }] = useCompanyProfileStore();

  useEffect(() => {
    setSelectedSetting("address");

    async function getData() {
      await getGenricData();
    }
    getData();
  }, []);

  const handleSettingClick = (setting) => {
    setSelectedSetting(setting);
    const item = itemRefs.current[setting];
    if (item) {
      if (previousHighlightRef.current) {
        previousHighlightRef.current.classList.remove("highlight");
      }
      item.scrollIntoView({ behavior: "smooth", block: "start" });
      item.classList.add("highlight");
      previousHighlightRef.current = item;
    }
  };

  const handleSearch = () => {
    const searchKey = searchText.toLowerCase();
    const item = items.find((item) =>
      item.title.toLowerCase().includes(searchKey)
    );
    if (item) {
      const itemRef = itemRefs.current[item.id];
      if (itemRef) {
        if (previousHighlightRef.current) {
          previousHighlightRef.current.classList.remove("highlight");
        }
        itemRef.scrollIntoView({ behavior: "smooth", block: "start" });
        itemRef.classList.add("highlight");
        previousHighlightRef.current = itemRef;
        setSelectedSetting(item.id);
        setSearchText("");
      }
    } else {
      // console.log("Item not found");
    }
  };

  const SelectedComponent = selectedSetting
    ? componentMapping[selectedSetting]
    : null;

  return (
    <>
      <div className="company-profile-container">
        <div className="title-route-container">
          <h4 className="title">Profile / {items.find((item) => item.id == selectedSetting)?.title || ''}</h4>
        </div>

        <Card className="company-profile-card">
          <Row className="w-100">
            <Col sm="4" md="4" lg="4" xl="4">
              <p className="company-profile-title">Company Profile</p>
            </Col>
            <Col sm="8" md="8" lg="8" xl="8">
              <div className="search-container">
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search your item here..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />

                <CustomButton
                  label="Search"
                  onClick={handleSearch}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-4 w-100">
            <Col sm="12" md="12" lg="2" xl="2">
              <Row className="company-profile-tabs">
                {items.slice(0, 8).map((item) => (
                  <Col sm="12" md="12" lg="12" xl="12">
                    <div
                      key={item.id}
                      className={`company-profile-item ${selectedSetting === item.id ? "highlight-tab" : ""
                        }`}
                      onClick={() => handleSettingClick(item.id)}
                      ref={(el) => (itemRefs.current[item.id] = el)}
                    >
                      <p><span className="mr-2">{item.icon}</span> {item.title}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col sm="12" md="12" lg="10" xl="10" className="company-profile-details">
              <div className="h-100">
                {SelectedComponent && (
                  <SelectedComponent
                    taxDetails={taxDetails}
                    getGenricData={getGenricData}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Card>

      </div >
    </>
  );
};

export default CompanyProfilev2;

{
  /* <div className="content-dashboard-payment">
        <Card
          body
          color="secondary"
          outline
          style={{
            width: "100%",
          }}
        >
          <CardBody>
            <div className="align-items-center justify-content-between">
              <h6 className="title">Company Profile</h6>
            </div>
            <Row>
              <Col md="12">
                <Accordion allowZeroExpanded preExpanded={["1"]}>
                  {/* <AccordionItem uuid="1">
                    <AccordionItemHeading>
                      <AccordionItemButton>Settings</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <CreateCompany />
                    </AccordionItemPanel>
                  </AccordionItem> */
}
//                   <AccordionItem>
//                     <AccordionItemHeading>
//                       <AccordionItemButton>Address</AccordionItemButton>
//                     </AccordionItemHeading>
//                     <AccordionItemPanel>
//                       <LocationDetail />
//                     </AccordionItemPanel>
//                   </AccordionItem>
//                   <AccordionItem>
//                     <AccordionItemHeading>
//                       <AccordionItemButton>User</AccordionItemButton>
//                     </AccordionItemHeading>
//                     <AccordionItemPanel>
//                       <CreateCompanyUser />
//                     </AccordionItemPanel>
//                   </AccordionItem>
//                   <AccordionItem>
//                     <AccordionItemHeading>
//                       <AccordionItemButton>
//                         Company Identity
//                       </AccordionItemButton>
//                     </AccordionItemHeading>
//                     <AccordionItemPanel>
//                       <BranchDetail />
//                     </AccordionItemPanel>
//                   </AccordionItem>
//                   <AccordionItem>
//                     <AccordionItemHeading>
//                       <AccordionItemButton>
//                         Generic Settings
//                       </AccordionItemButton>
//                     </AccordionItemHeading>
//                     <AccordionItemPanel>
//                       <TaxDetail
//                         taxDetails={taxDetails}
//                         getGenricData={getGenricData}
//                       />
//                     </AccordionItemPanel>
//                   </AccordionItem>
//                   <AccordionItem>
//                     <AccordionItemHeading>
//                       <AccordionItemButton>
//                         Customer Settings
//                       </AccordionItemButton>
//                     </AccordionItemHeading>
//                     <AccordionItemPanel>
//                       <CustomerSettings
//                         taxDetails={taxDetails}
//                         getGenricData={getGenricData}
//                       />
//                     </AccordionItemPanel>
//                   </AccordionItem>
//                   <AccordionItem>
//                     <AccordionItemHeading>
//                       <AccordionItemButton>
//                         Header and Footer
//                       </AccordionItemButton>
//                     </AccordionItemHeading>
//                     <AccordionItemPanel>
//                       <HeaderFooter
//                         taxDetails={taxDetails}
//                         getGenricData={getGenricData}
//                       />
//                     </AccordionItemPanel>
//                   </AccordionItem>
//                   <AccordionItem>
//                     <AccordionItemHeading>
//                       <AccordionItemButton>
//                         Notification Settings
//                       </AccordionItemButton>
//                     </AccordionItemHeading>
//                     <AccordionItemPanel>
//                       <Notification
//                         taxDetails={taxDetails}
//                         getGenricData={getGenricData}
//                       />
//                     </AccordionItemPanel>
//                   </AccordionItem>
//                 </Accordion>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </div> */
// }
