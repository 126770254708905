import React from 'react';
import CompanyProfileRepository from 'repository/CompanyProfileRepository';
import { Button } from "reactstrap";

const pagesize = new CompanyProfileRepository().getPageSize();
function LoadMore(props) {

    const { totalData, handleLoadMore } = props
    return (
        <>
            {
                totalData > 0 &&
                <Button
                    size="sm"
                    onClick={handleLoadMore}
                    color="default" 
                    className="load-more-button"
                >
                    Load More
                </Button>
            }
        </>
    );
}

export default LoadMore;