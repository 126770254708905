import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Row,
  Label,
} from "reactstrap";
import { useLoginStore } from "hooks/Login";
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { notificationMessages } from "helpers/constantMessages";

const InventorySettings = ({ taxDetails, getGenricData }) => {
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [{}, {}, { updateGenricData }] = useCompanyProfileStore();

  const [smsEnable, setSMSEnable] = useState(false);

  useEffect(() => {
    try {
      setSMSEnable(
        taxDetails?.filter(
          (data) => data.feature_key == "is_inventory_stock_available"
        )[0].feature_value == "YES"
          ? true
          : false
      );
    } catch (error) {}
  }, [taxDetails]);

  async function saveDetails(value) {
    let param = [
      {
        feature_key: "is_inventory_stock_available",
        feature_value: value == true ? "YES" : "NO",
      },
    ];
    const res = await updateGenricData(param);
    if (res) {
      const response = await getGenricData();
      await setAlerts({
        type: "success",
        message: notificationMessages.inventorySettingsSuccess,
      });
    }
  }

  return (
    <Card className="company-setting-container">
      <CardHeader>
        <h5>Inventory Settings</h5>
      </CardHeader>
      <CardBody>
        <Row style={{ paddingLeft: "15px" }}>
          <Col sx="6" md="6">
            <FormGroup row>
              <Label for="" sm={4} md={6} lg={5}>
                <Row>Inventory Stock Available</Row>
              </Label>
              <Col sm={6} md={4} lg={6}>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={smsEnable}
                    id="togBtn"
                    onChange={(e) => {
                      saveDetails(e.target.checked);
                    }}
                  />
                  <div className="slider round">
                    <span className="on">Yes</span>
                    <span className="off">No</span>
                  </div>
                </label>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InventorySettings;
