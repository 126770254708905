import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NotificationRepository from "../../repository/NotificationRepository";

export const getNotificationList = createAsyncThunk('notification_getNotificationList', async (params) => {
    return await new NotificationRepository().getNotificationList(params);
});

const initialState = {
    notificationList: {},
};
const slice = createSlice({
    name: "notification",
    initialState: initialState,
    reducers: {},

    extraReducers: {
        [getNotificationList.fulfilled]: (state, { payload }) => {
            state.notificationList = payload;
            return state;
        },
        [getNotificationList.rejected]: (state, { payload }) => {
        }
    },
});

export default slice.reducer;
