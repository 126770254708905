import React, { useRef } from "react";
import logo from "assets/img/new-logo-with-text-dark.svg";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

const Navbar = () => {
  const navbarRef = useRef();
  const logoRef = useRef();
  const signInButtonRef = useRef();
  const createAccountButtonRef = useRef();

  useGSAP(() => {
    const tl = gsap.timeline();

    tl.from(logoRef.current, {
      x: -50,
      opacity: 0,
      duration: 0.6,
    });

    tl.from(
      [signInButtonRef.current, createAccountButtonRef.current],
      {
        x: 50,
        opacity: 0,
        stagger: 0.2,
        duration: 0.6,
      },
      "-=0.2"
    );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <nav className="navbar-container" ref={navbarRef}>
      <div className="nav-logo-container" ref={logoRef}>
        <a href="https://apsthreeai.ai/" target="_blank">
          <img
            src={`${ERP_CDN_IMAGE_URL}/new-logo-with-text-dark.svg`}
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop if logo also fails
              e.target.src = logo;
            }}
            alt="APS THREEAI"
          />
        </a>
      </div>

      <div className="nav-buttons">
        <Link
          to="/auth/login"
          ref={signInButtonRef}
          className="nav-btn nav-login-btn"
        >
          Sign In
        </Link>
        <Link
          to="/auth/signup"
          ref={createAccountButtonRef}
          className="nav-btn nav-signin-btn"
        >
          Create an Account
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
