import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CreateBillRepository from "../../repository/CreateBillRepository";

export const getBranchDetail = createAsyncThunk('createBill_getBranchDetail', async () => {
    return await new CreateBillRepository().getBranchDetail();
});

export const getCustomerDetail = createAsyncThunk('createBill_getCustomerDetail', async (params) => {
    return await new CreateBillRepository().getCustomerDetail(params);
});

const initialState = {
    branchDetail: {},
    patientDetail: {},
    customerDetail: {}
};
const slice = createSlice({
    name: "createBill",
    initialState: initialState,

    reducers: {
        setCustomerDetail: {
            reducer: (state, action) => {
                state.customerDetail = action.payload
            },
        }
    },


    extraReducers: {
        [getBranchDetail.fulfilled]: (state, { payload }) => {
            state.branchDetail = payload;
            const now = new Date();
            const branchItem = {
                value: JSON.stringify(payload),
                expiry: now.getTime() + 60 * 60 * 1000, // 1 hour expiry time
            };
            localStorage.setItem('branchDetail', JSON.stringify(branchItem))
            return state;
        },
        [getBranchDetail.rejected]: (state, { payload }) => {
        }
    },
});

export let { setPatientDetail } = slice.actions;
export let { setCustomerDetail } = slice.actions;

export default slice.reducer;
