import BaseRepository from "./BaseRepository";
import SubscriptionAPICall from "service/SubscriptionAPICall";

class SubscriptionRepository extends BaseRepository {
  getCompanySubscription = async () => {
    return await new SubscriptionAPICall().getCompanySubscription();
  };

  getCompanySubscriptionDetails = async () => {
    return await new SubscriptionAPICall().getCompanySubscriptionDetails();
  };

  createCompanySubscription = async (params) => {
    return await new SubscriptionAPICall().createCompanySubscription(params);
  };

  updateCompanySubscription = async (params) => {
    return await new SubscriptionAPICall().updateCompanySubscription(params);
  };

  createCustomPlanSubscription = async (params) => {
    return await new SubscriptionAPICall().createCustomPlanSubscription(params);
  };
}

export default SubscriptionRepository;
