import CustomInput from "components/Input/CustomInput";
import { useLoginStore } from "hooks/Login";
import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const CompanyInput = ({
  handleNext,
  otpVerify,
  setOtpVerify,
  formData,
  setFormData,
  mobileNumber,
}) => {
  const [{}, {}, { setAlerts }] = useLoginStore();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitCompanyInfo = async (e) => {
    // console.log(formData)
    e.preventDefault();
    if (!formData.companyName) {
      await setAlerts({ type: "danger", message: "Company Name is required" });
      return;
    }

    if (!formData.CIN) {
      await setAlerts({ type: "danger", message: "CIN is required" });
      return;
    }
    if (!formData.email_address || !formData.email_address.includes("@")) {
      await setAlerts({ type: "danger", message: "Email is required" });
      return;
    }
    handleNext();
  };

  return (
    <>
      {/* <div className="header">
        <div className="verification-text">3. Company Information</div>
        <div className="step-indicator">Step 3 - 4</div>
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <FormGroup className="has-label">
          <Label>Company Name *</Label>
          <CustomInput
            autoFocus={true}
            placeholder="Enter Company name"
            type="text"
            name="companyName"
            id="companyName"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={100}
          />
          {/* <Input
            placeholder="Enter Company name"
            type="text"
            name="companyName"
            id="companyName"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={100}
          /> */}
        </FormGroup>

        <FormGroup className="has-label">
          <Label>CIN *</Label>
          <CustomInput
            placeholder="Enter CIN"
            type="text"
            name="CIN"
            id="CIN"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={100}
          />
          {/* <Input
            placeholder="Enter CIN"
            type="text"
            name="CIN"
            id="CIN"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={100}
          /> */}
        </FormGroup>

        <FormGroup className="has-label">
          <Label>Email *</Label>
          <CustomInput
            placeholder="example@mail.com"
            type="text"
            name="email_address"
            id="email_address"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={100}
          />
          {/* <Input
            placeholder="example@mail.com"
            type="text"
            name="email_address"
            id="email_address"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={100}
          /> */}
        </FormGroup>
      </div>
      <button className="custom-login-btn" onClick={submitCompanyInfo}>
        Submit
      </button>
    </>
  );
};

export default CompanyInput;
