import CustomButton from 'components/Button/CustomButton'
import React from 'react'
import { Card, CardBody, Col, FormGroup, Input, Row } from 'reactstrap'
import Select from "react-select";

const AddProduct = () => {
    return (
        <div className='content-dashboard-inventory'>
            <div className="title-route-container">
                <h4 className="title">Inventory / Add Products</h4>
                <CustomButton label="Upload Product by File" />
            </div>

            <Row>
                <Col sm="12" md="8">
                    <Card>
                        <CardBody>
                            <div className="inventory-card-header">
                                <h5>General Information</h5>
                            </div>

                            <Row>
                                <Col sm="6" md="6" className='pr-0'>
                                    <Row>
                                        <Col sm="12" md="12">
                                            <FormGroup className="custom-input-container">
                                                <label>Product Name</label>
                                                <Input
                                                    type="text"
                                                />
                                            </FormGroup></Col>
                                        <Col sm="12" md="12">
                                            <FormGroup className="custom-input-container">
                                                <label>Product Name</label>
                                                <Input
                                                    type="text"
                                                />
                                            </FormGroup></Col>
                                    </Row>
                                </Col>
                                <Col sm="6" md="6">
                                    <FormGroup className="custom-input-container">
                                        <label>Product Name</label>
                                        <Input
                                            type="textarea"
                                            className="textarea"
                                            rows="8"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="4">
                    <Card>
                        <CardBody>
                            <div className="inventory-card-header">
                                <h5>Category</h5>
                            </div>

                            <Row>
                                <Col sm="12" md="12">
                                    <FormGroup className="customer-input-container">
                                        <label for="category">Product Category</label>
                                        <Input
                                            className="w-100"
                                            name="category"
                                            placeholder="Select Category"
                                            type="hidden"
                                        />
                                        <Select
                                            className="profile-custom-select payment-mode-select"
                                            placeholder="Select Category"
                                            name="category"
                                            options={[
                                                { value: "1", label: "Generic Medicine" },
                                                { value: "2", label: "Services" },
                                                { value: "3", label: "Pharmacy accesories" },
                                            ]}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="12">
                                    <FormGroup className="customer-input-container">
                                        <label for="suvCategory">Product Sub Category</label>
                                        <Input
                                            className="w-100"
                                            name="subCategory"
                                            placeholder="Select Category"
                                            type="hidden"
                                        />
                                        <Select
                                            className="profile-custom-select payment-mode-select"
                                            placeholder="Select Category"
                                            name="subCategory"
                                            options={[
                                                { value: "1", label: "Generic Medicine" },
                                                { value: "2", label: "Services" },
                                                { value: "3", label: "Pharmacy accesories" },
                                            ]}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Card>
                <CardBody>
                    <div className="inventory-card-header">
                        <h5>Pricing & Stocks</h5>
                    </div>

                    <Row>
                        <Col sm="4" md="4">
                            <FormGroup className="custom-input-container">
                                <label>MRP*</label>
                                <Input
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="4" md="4">
                            <div className="invoice-form-control">
                                <label>
                                    Discount
                                </label>
                                <div className="inventory-discount-control">
                                    <input
                                        type="number"
                                        min="0"
                                        max="100"
                                        className="manual-discount-input"
                                    />
                                    <Input
                                        id="discount"
                                        name="discount"
                                        type="select"
                                        className="type-dropdown"
                                        style={{ width: "100px" }}
                                    >
                                        <option value={true}>Fixed</option>
                                        <option value={false}>Percentage</option>
                                    </Input>
                                </div>
                            </div>
                        </Col>
                        <Col sm="4" md="4">
                            <FormGroup className="custom-input-container">
                                <label>Total Text*</label>
                                <Input
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="4" md="4">
                            <FormGroup className="custom-input-container">
                                <label>Stock (Quantity)*</label>
                                <Input
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="4" md="4">
                            <FormGroup className="custom-input-container">
                                <label>Add Quantity</label>
                                <Input
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="4" md="4">
                            <FormGroup className="custom-input-container">
                                <label>Total Quantity</label>
                                <Input
                                    type="text"
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <div className="">
                <CustomButton label="Add Product" style={{ backgroundColor: "#207841", marginTop: "20px" }} />
            </div>
        </div>
    )
}

export default AddProduct