import React, { useState } from "react";

// reactstrap components
import { Card, CardBody, Row, Col } from "reactstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { useEffect } from "react";
import { isArray, isEmpty } from "underscore";
import { useInventoryStore } from "hooks/Inventory";
import Common from "helpers/Common";
import { useLocation } from "react-router-dom";
import moment from "moment";
import "index.css";

const InventoryFileSummaryDetail = () => {
  const [{}, { uploadedInventorySummaryDetails }, {}] = useInventoryStore();
  const [debitReportData, setDebitReportData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    async function getReport() {
      let params = {
        fileName: location.state.fileName,
        fromDateTime: moment(location.state.date).format("YYYY-MM-DD"),
      };
      let inventoryUploadData = await uploadedInventorySummaryDetails(params);
      let arr = [];
      if (
        inventoryUploadData &&
        isArray(inventoryUploadData) &&
        !isEmpty(inventoryUploadData)
      ) {
        inventoryUploadData.map((prop, key) => {
          let obj = {
            id: key,
            companyName: prop.companyName,
            fileName: prop.fileName,
            productId: prop.productId,
            description: prop.description,
            dateTime: Common.getFormatedDate(prop.createdDate, "DD-MM-YYYY"),
          };
          arr.push(obj);
        });
      }
      setDebitReportData(arr);
    }
    getReport();
  }, []);

  return (
    <div className="content-dashboard-inventory">
      <h4 className="title"> INVENTORY FILE SUMMARY DETAILS</h4>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <ReactTable
                data={debitReportData}
                columns={[
                  {
                    Header: "Company Name",
                    accessor: "companyName",
                  },
                  {
                    Header: "File Name",
                    accessor: "fileName",
                  },
                  {
                    Header: "Product",
                    accessor: "productId",
                  },
                  {
                    Header: "Description",
                    accessor: "description",
                  },
                  {
                    Header: "Date Time",
                    accessor: "dateTime",
                  },
                ]}
                /*
                    You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                  */
                className="-striped -highlight primary-pagination"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default InventoryFileSummaryDetail;
