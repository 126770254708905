import ReportRepository from "repository/ReportRepository";

export function useReportStore() {
  /**
   * Here is list of use selectors which are relates to report
   * Author: Devanshi Patel
   */

  let data = {};
  let fetch = {
    /**
     * Here is list of fetch methods which are used for get data from API or Repositories related to report
     * Author: Devanshi Patel
     * dispatch is used for reducer or thunk method
     * if redux not needed use repository method
     */
    getMaximumStockUsageReport: async (params) => {
      return await new ReportRepository().getMaximumStockUsageReport(params);
    },

    getMinimumStockUsageReport: async (params) => {
      return await new ReportRepository().getMinimumStockUsageReport(params);
    },

    getRevenueReport: async (params) => {
      return await new ReportRepository().getRevenueReport(params);
    },

    getDailyDemandForecastData: async (params) => {
      return await new ReportRepository().getDailyDemandForecastData(params);
    },

    getWeeklyDemandForecastData: async (params) => {
      return await new ReportRepository().getWeeklyDemandForecastData(params);
    },

    getHistoricalDemandData: async (params) => {
      return await new ReportRepository().getHistoricalDemandData(params);
    },
  };
  let set = {
    /**
     * Here is list of fetch methods which are used for set or update data from API or Repositories related to report
     * Author: Devanshi Patel
     */
  };
  return [data, fetch, set];
}
