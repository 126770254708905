import BaseRepository from "./BaseRepository";
import PaymentAPICall from "../service/PaymentAPICall";

class PaymentRepository extends BaseRepository {
  getTransactionServices = async () => {
    return await new PaymentAPICall().getTransactionServices();
  };

  getPaymentModes = async (params) => {
    return await new PaymentAPICall().getPaymentModes(params);
  };

  getAccountStatementDetails = async (params) => {
    return await new PaymentAPICall().getAccountStatementDetails(params);
  };

  getBillsDetailsByID = async (params) => {
    return await new PaymentAPICall().getBillsDetailsByID(params);
  };

  getAdvancePayDetailsByID = async (params) => {
    return await new PaymentAPICall().getAdvancePayDetailsByID(params);
  };

  getAccountRecievableDetails = async (params) => {
    return await new PaymentAPICall().getAccountRecievableDetails(params);
  };

  getAccountpayableDetails = async (params) => {
    return await new PaymentAPICall().getAccountpayableDetails(params);
  };

  savePayment = async (params) => {
    return await new PaymentAPICall().savePayment(params);
  };

  saveAdvancePayment = async (params) => {
    return await new PaymentAPICall().saveAdvancePayment(params);
  };

  razorPayCreateOrder = async (params) => {
    return await new PaymentAPICall().razorPayCreateOrder(params);
  };

  stripPaymentIntents = async (params) => {
    return await new PaymentAPICall().stripPaymentIntents(params);
  };
}

export default PaymentRepository;
