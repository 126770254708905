import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
  Row,
  Col,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useInventoryStore } from "hooks/Inventory";
import { useLoginStore } from "hooks/Login";
import { isEmpty } from "underscore";
import { notificationMessages } from "helpers/constantMessages";
import { ADMIN_URL_DASHBOARD } from "helpers/Paths";
import PageLoader from "components/Loader/PageLoader";
import CustomButton from "components/Button/CustomButton";

const DiscountByCategory = () => {
  const [loader, setLoader] = useState(false);
  const [{ }, { }, { }] = useInventoryStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();
  const navigate = useNavigate();
  const [categorySelect, setCategorySelect] = useState(null);
  const [subCategorySelect, setSubCategorySelect] = useState(null);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const [
    { },
    { getSubCategoryList, getCategoryList, getDiscountDetailsByCategoryId },
    { createModifyDiscountByCategory },
  ] = useInventoryStore();

  const [inventoryValidate, setInventoryValidate] = useState({});
  // const [isTax1Percentage, setIsTax1Percentage] = useState(true);
  // const [isTax2Percentage, setIsTax2Percentage] = useState(true);
  const [taxTypeSelect, setTaxTypeSelect] = useState(true);
  const [tax1, setTax1] = useState(0);
  const [tax2, setTax2] = useState(0);
  const [tax1Description, setTax1Description] = useState("");
  const [tax2Description, setTax2Description] = useState("");
  const [discountTypeSelect, setDiscountTypeSelect] = useState(null);
  const [discValue, setDiscValue] = useState(0);
  const [discount, setDiscount] = useState(0);

  const ref = useRef();

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    let categoryList = await getCategoryList();
    let list =
      categoryList &&
      !isEmpty(categoryList).length > 0 &&
      categoryList.map((prop, key) => {
        return {
          value: prop.categoryID,
          label: prop.categoryName,
        };
      });
    setCategory(list);
  };

  const getSubCategory = async (val) => {
    let param = {
      categoryIds: val?.value.toString(),
    };
    let subCategoryList = await getSubCategoryList(param);
    let list =
      subCategoryList &&
      !isEmpty(subCategoryList).length > 0 &&
      subCategoryList.map((prop, key) => {
        return {
          value: prop.subCategoryID,
          label: prop.subCategoryName,
        };
      });
    setSubCategory(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    let validate = { ...inventoryValidate };
    if (inventoryValidate && isEmpty(inventoryValidate)) {
      error = true;
      validate.category = "has-danger";
      validate.subCategory = "has-danger";
    } else {
      if (
        !inventoryValidate.category ||
        inventoryValidate.category === "has-danger"
      ) {
        error = true;
        validate.category = "has-danger";
      }
      if (
        !inventoryValidate.subCategory ||
        inventoryValidate.subCategory === "has-danger"
      ) {
        error = true;
        validate.subCategory = "has-danger";
      }
    }
    setInventoryValidate(validate);
    if (!error) {
      setLoader(true);
      let param = {
        categoryId: subCategorySelect.value,
        tax1: tax1,
        isTax1Percentage: taxTypeSelect,
        // tax1Description: tax1Description,
        // tax2: tax2,
        // isTax2Percentage: isTax2Percentage,
        // tax2Description: tax2Description,
        discountType: discountTypeSelect.label,
        dicountValue: discValue,
        discount: discount,
      };
      let res = await createModifyDiscountByCategory(param);
      setLoader(false);
      if (res) {
        await setAlerts({
          type: "success",
          message: notificationMessages.addInventoryDiscountSuccess,
        });
      }
    }
  };

  const close = () => {
    navigate(ADMIN_URL_DASHBOARD);
  };

  const handleCategorySelect = (val) => {
    setSubCategorySelect(null);
    getSubCategory(val);
  };

  const handleSubCategorySelect = async (val) => {
    setLoader(true);
    let param = {
      CategoryId: val?.value,
    };
    let discountDetails = await getDiscountDetailsByCategoryId(param);
    if (discountDetails && !isEmpty(discountDetails).length > 0) {
      // setIsTax1Percentage(discountDetails.isTax1Percentage);
      // setIsTax2Percentage(discountDetails.isTax2Percentage);
      setTaxTypeSelect(discountDetails.isTax1Percentage);
      setTax1(discountDetails.tax1);
      setTax2(discountDetails.tax2);
      setTax1Description(discountDetails.tax1Description);
      setTax2Description(discountDetails.tax2Description);
      setDiscountTypeSelect({
        value: discountDetails.discountType == "Fixed" ? 1 : 2,
        label: discountDetails.discountType,
      });
      setDiscValue(discountDetails.dicountValue);
      setDiscount(discountDetails.discount);
    }
    setLoader(false);
  };

  const preventNegativeValues = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <Form className="form" onSubmit={handleSubmit} innerRef={ref}>
          <Row xs="3">
            <Col xs={12} md={4}>
              <FormGroup className="customer-input-container">
                <Label>Category *</Label>
                <Input
                  className="w-100"
                  name="category"
                  placeholder="Select Category"
                  type="hidden"
                  valid={inventoryValidate?.category === "has-success"}
                  invalid={inventoryValidate?.category === "has-danger"}
                />
                <Select
                  className="profile-custom-select payment-mode-select"
                  placeholder="Select Category"
                  name="category"
                  value={categorySelect}
                  options={category}
                  onChange={(e) => {
                    let error = { ...inventoryValidate };
                    if (e.value == null) {
                      error.category = "has-danger";
                    } else {
                      error.category = "has-success";
                    }
                    setInventoryValidate(error);
                    setCategorySelect(e);
                    handleCategorySelect(e);
                  }}
                />
                <FormFeedback>Category is required.</FormFeedback>
              </FormGroup>
            </Col>
            <Col
              xs={12}
              md={4}
            >
              <FormGroup className="customer-input-container">
                <Label>Sub Category *</Label>
                <Input
                  className="w-100"
                  name="subcategory"
                  placeholder="Select Sub Category"
                  type="hidden"
                  valid={inventoryValidate?.subCategory === "has-success"}
                  invalid={inventoryValidate?.subCategory === "has-danger"}
                />
                <Select
                  className="profile-custom-select payment-mode-select"
                  placeholder="Select Sub Category"
                  name="subcategory"
                  value={subCategorySelect}
                  options={subCategory}
                  onChange={(e) => {
                    let error = { ...inventoryValidate };
                    if (e.value == null) {
                      error.subCategory = "has-danger";
                    } else {
                      error.subCategory = "has-success";
                    }
                    setInventoryValidate(error);
                    setSubCategorySelect(e);
                    handleSubCategorySelect(e);
                  }}
                />
                <FormFeedback>Sub Category is required.</FormFeedback>
              </FormGroup>
            </Col>

            <Col xs="12" md="4">
              <div className="customer-input-container">
                <label>
                  Tax
                </label>
                <div className="inventory-discount-control">
                  <input
                    value={tax1}
                    placeholder="Enter Tax"
                    type="number"
                    min="0"
                    maxLength={10}
                    onKeyDown={preventNegativeValues}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onChange={(e) => setTax1(e.target.value)}
                    className="manual-discount-input"
                  />
                  <Input
                    id="discount"
                    name="taxType"
                    type="select"
                    className="type-dropdown"
                    onChange={(e) => {
                      setTaxTypeSelect(e);
                    }}
                    style={{ width: "100px" }}
                  >
                    <option value={1}>Fixed</option>
                    <option value={2}>Percentage</option>
                  </Input>
                </div>
              </div>
            </Col>

            <Col xs="12" md="4">
              <div className="customer-input-container">
                <label>
                  Discount
                </label>
                <div className="inventory-discount-control">
                  <input
                    type="number"
                    min="0"
                    maxLength={10}
                    onKeyDown={preventNegativeValues}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    value={discValue}
                    placeholder="Enter Discount Value"
                    onChange={(e) => setDiscValue(e.target.value)}
                    className="manual-discount-input"
                  />
                  <Input
                    id="discount"
                    name="discType"
                    type="select"
                    className="type-dropdown"
                    onChange={(e) => {
                      setDiscountTypeSelect(e);
                    }}
                    style={{ width: "100px" }}
                  >
                    <option value={1}>Fixed</option>
                    <option value={2}>Percentage</option>
                  </Input>
                </div>
              </div>
            </Col>

            <Col xs="12" md="4">
              <FormGroup className="custom-input-container">
                <label>Total Discount</label>
                <Input
                  type="text"
                  // value={totalDiscount}
                  disabled
                />
              </FormGroup>
            </Col>

          </Row>
          <Row style={{ justifyContent: "flex-start" }}>
            <Col xs="12" md="4">
              <CustomButton type="submit" label="Add Discount" style={{ backgroundColor: "#207841", margin: "20px 0" }} />
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default DiscountByCategory;
