import React from 'react'
import '../../assets/styles/Inventory.css'
import { Card, CardBody, Col, FormGroup, Input, Row } from 'reactstrap'
import CustomButton from 'components/Button/CustomButton'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { FaEdit } from 'react-icons/fa'
import { FaPlus } from 'react-icons/fa6'

const Products = () => {

    const Sample_Data = [
        {
            id: 1,
            name: 'Product 1',
            brand: 'Brand 1',
            upcCode: '123456789',
            qty: 123,
            category: 'Category 1',
        }
    ];

    const ActionColTemplate = ({ rowData }) => {
        return (
            <div className="payment-list-action-col">
                <div className="d-flex align-items-center" style={{ gap: '10px', cursor: 'pointer' }}>Edit <FaEdit size={20} /></div>
                <div className="d-flex align-items-center" style={{ gap: '10px', cursor: 'pointer' }}>Add Quantity <FaPlus size={20} /></div>
            </div>
        )
    }

    return (
        <div className='content-dashboard-inventory'>
            <div className="title-route-container">
                <h4 className="title">Inventory / Products</h4>
            </div>

            <Card className='inventory-product-card'>
                <CardBody style={{ padding: '30px 20px' }}>
                    <Row>
                        <Col sm="12" md="2">
                            <FormGroup className="custom-input-container">
                                <label>Product Name</label>
                                <Input
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="12" md="2">
                            <FormGroup className="custom-input-container">
                                <label>Brand</label>
                                <Input
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="12" md="2">
                            <FormGroup className="custom-input-container">
                                <label>UPC Code</label>
                                <Input
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="12" md="2">
                            <FormGroup className="custom-input-container">
                                <label>SKU</label>
                                <Input
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="12" md="2" className="d-flex justify-content-start align-items-end" style={{ paddingBottom: '10px' }}>
                            <div className="h-full">
                                <label className="mb-0"></label>
                                <CustomButton label="Apply Filters" customStyle="m-0" style={{ backgroundColor: '#555555' }} />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card className='inventory-product-card'>
                <CardBody className="inventory-data-table-card-body">
                    <DataTable value={Sample_Data}>
                        <Column field="id" header="Product ID" />
                        <Column field="name" header="Product Name" />
                        <Column field="brand" header="Brand" />
                        <Column field="qty" header="Available Quantity" />
                        <Column field="upcCode" header="UPC Code" />
                        <Column field="" header="Actions" body={(rowData) => <ActionColTemplate rowData={rowData} />} />
                    </DataTable>
                </CardBody>
            </Card>
        </div>
    )
}

export default Products