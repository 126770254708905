import React from "react";
import { Col, Row } from "reactstrap";
import AlertImg from "../../assets/images/alert.png";
import { useSearchParams } from "react-router-dom";
import Common from "helpers/Common";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

const TechnicalAlert = () => {
  const [searchParams, setSearchParams] = useSearchParams({ replace: true });

  const getAPIError = () => {
    let message = searchParams.get("message");
    return message ? Common.decryptQueryParam(message.replace(/ /g, "+")) : "";
  };

  return (
    <div className="login-page-design">
      <div class="full-width-login">
        <Row>
          <Col lg={{ offset: 3, size: 6 }} md={{ offset: 3, size: 6 }} sm="12">
            <center>
              <img
                src={`${ERP_CDN_IMAGE_URL}/alert.png`}
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop if logo also fails
                  e.target.src = AlertImg;
                }}
                alt="Alert!"
              />

              <h2>We had some technical trouble</h2>
              <h5>
                We experienced a technical problem while trying to <br></br>
                load this page for you.
              </h5>
              {searchParams.get("message") && (
                <h5>Getting error from {getAPIError()} </h5>
              )}
            </center>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TechnicalAlert;

// import React from 'react';

// function TechnicalAlert() {
//     return (
//         <div className="login-page-design">
//             <center>
//                 <img src='Alert' />

//                 <h2>We had some technical trouble</h2>
//                 <h5>We experienced a technical problem while trying to </h5>
//                 <h5>load this page for you.</h5>
//             </center>
//         </div>
//     );
// }

// export default TechnicalAlert;
