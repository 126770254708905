import { combineReducers } from "redux";
import Login from "./slice/Login";
import CreateBill from "./slice/CreateBill";
import SiteConfig from "./slice/Site"
import CompanyProfile from "./slice/CompanyProfile";
import Template from "./slice/Template";
import Company from "./slice/Company";
import Notification from "./slice/Notification";

export default combineReducers({
    Login: Login,
    CreateBill: CreateBill,
    CompanyProfile: CompanyProfile,
    Config: SiteConfig,
    Template: Template,
    Company: Company,
    Notification: Notification
});
