import BaseRepository from "./BaseRepository";
import CreateBillAPICall from "../service/CreateBillAPICall";
import TemplateAPICall from "service/TemplateAPICall";

class TemplateRepository extends BaseRepository {
  createBillTemplate = async (params) => {
    return await new TemplateAPICall().createBillTemplate(params);
  };

  getTemplateList = async (params) => {
    // console.log(params);
    return await new TemplateAPICall().getTemplateList(params);
  };

  getTemplateDetailsById = async (params) => {
    return await new TemplateAPICall().getTemplateDetailsById(params);
  };

  updateBillTemplate = async (params) => {
    return await new TemplateAPICall().updateBillTemplate(params);
  };
  updateTemplateActiveStatus = async (params) => {
    // console.log(params);
    return await new TemplateAPICall().updateTemplateActiveStatus(params);
  };
}

export default TemplateRepository;
