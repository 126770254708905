import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import { useNotificationStore } from "hooks/Notification";
import React, { useRef, useState } from "react";
import "index.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  Col,
  Row,
  FormGroup,
  Spinner,
  Container,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import CustomButton from "components/Button/CustomButton";

const SMS = () => {
  const ref = useRef();

  const [{ }, { sendSMS }, { }] = useNotificationStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();

  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [smsNumberState, setSMSNumberState] = useState("");
  const [smsMessageState, setSMSMessageState] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let error = false;
    if (smsNumberState === "" || smsNumberState === "has-danger") {
      setSMSNumberState("has-danger");
      error = true;
    }
    if (smsMessageState === "" || smsMessageState === "has-danger") {
      error = true;
      setSMSMessageState("has-danger");
    }

    if (!error) {
      let param = {
        mobileNumber: "+" + number,
        otp: "123456",
        shortMessage: message,
      };
      try {
        let res = await sendSMS(param);
        if (res && res.isOTPSent == false) {
          setAlerts({ type: "danger", message: res.success });
        } else {
          await setAlerts({
            type: "success",
            message: notificationMessages.sendSMSSuccess,
          });
          ref.current.reset();
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        return false;
      }
    }
    setLoader(false);
  };

  return (
    <div className="customer-content-container">
      <div className="customer-content-title">
        <h5>Send SMS</h5>
      </div>
      <Form className="form" onSubmit={handleSubmit} innerRef={ref}>
        <FormGroup className="has-label customer-input-container">
          <label>Number *</label>
          <Input
            name="registerMobileNumber"
            placeholder="Registered Mobile Number"
            type="hidden"
          />
          <PhoneInput
            inputProps={{
              autoFocus: true,
            }}
            containerClass="customer-phone-input-container"
            buttonClass="customer-flag-search-btn"
            dropdownClass="customer-flag-dropdown"
            country={"in"}
            placeholder="Number"
            onChange={(value) => {
              const regex = /[^0-9]/g;
              if (!Common.verifyLength(value, 12) || regex.test(value)) {
                setSMSNumberState("has-danger");
              } else {
                setSMSNumberState("has-success");
              }
              setNumber(value);
            }}
          />
          {smsNumberState === "has-danger" ? (
            <label className="error">Invalid Phone Number.</label>
          ) : null}
        </FormGroup>
        <FormGroup className={`has-label customer-input-container ${smsMessageState}`}>
          <label>Message *</label>
          <Input
            className="textarea"
            type="textarea"
            rows="4"
            placeholder="Type your message here.."
            onChange={(e) => {
              if (!Common.verifyLength(e.target.value, 1)) {
                setSMSMessageState("has-danger");
              } else {
                setSMSMessageState("has-success");
              }
              setMessage(e.target.value);
            }}
            maxLength={100}
          />
          {smsMessageState === "has-danger" ? (
            <label className="error">Message field is required.</label>
          ) : null}
        </FormGroup>
        <CustomButton
          label={loader ? (
            <Spinner
              size="sm"
              color="secondary"
              style={{ marginLeft: "15px" }}
            />
          ) : (
            "Send SMS"
          )}
          type="submit"
          disabled={loader ? true : false}
        />
      </Form>
    </div>
  );
};

export default SMS;
