import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  FormGroup,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Common from "helpers/Common";
import { AUTH_URL_CHANGE_PASSWORD, ADMIN_URL_DASHBOARD } from "helpers/Paths";
import { useNotificationStore } from "hooks/Notification";
import { AUTH_URL_LOGIN } from "helpers/Paths";
import { Link } from "react-router-dom";
import { useLoginStore } from "hooks/Login";
import CustomInput from "components/Input/CustomInput";

const Otp = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [OTPCount, setOTPCount] = useState(3);
  const [loader, setLoader] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [numberError, setNumberError] = useState("");
  const [otpError, setOtpError] = useState("");

  const [locationInfo, setLocationInfo] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [deviceInfo, setDeviceInfo] = useState("");

  const [{}, { sendOTP, verifyOTP, sendOTPForgetPassword }] =
    useNotificationStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const navigate = useNavigate();
  const isLogin = Common.getJwtToken();

  useEffect(() => {
    const getLocation = async () => {
      try {
        const location = await Common.fetchGeolocation();
        setLocationInfo(location);
      } catch (error) {
        setLocationInfo("Unknown Location");
      }
    };

    getLocation();
  }, []);

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api64.ipify.org?format=json");
        if (!response.ok) {
          throw new Error("Failed to fetch IP address");
        }
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIPAddress();
  }, []); // Empty array ensures useEffect runs only once on component mount

  useEffect(() => {
    const fetchDeviceInfo = () => {
      const browserInfo = Common.getBrowserInfo();
      const osInfo = Common.getOSInfo();
      const deviceString = `${browserInfo} (${osInfo})`;
      setDeviceInfo(deviceString);
    };

    fetchDeviceInfo();
  }, []);

  useEffect(() => {
    if (isLogin) {
      navigate(ADMIN_URL_DASHBOARD);
    }
    document.body.classList.add("login-page");
    return () => {
      document.body.classList.remove("login-page");
    };
  }, [isLogin, navigate]);

  const validateMobileNumber = (number) => {
    if (!number || number.length < 10) {
      setNumberError(
        "Mobile number is required and should be at least 10 digits."
      );
      return false;
    }
    setNumberError("");
    return true;
  };

  const validateOtp = (otp) => {
    if (!otp || otp.length !== 6) {
      setOtpError("OTP is required and should be 6 digits.");
      return false;
    }
    setOtpError("");
    return true;
  };

  // const handleSendOtp = async (e) => {
  //   e.preventDefault();
  //   if (!validateMobileNumber(mobileNumber)) return;

  //   setLoader(true);
  //   let param = {
  //     mobileNumber: "+" + mobileNumber,
  //   };
  //   const response = await sendOTP(param);
  //   if (response && response.success) {
  //     setOtpSent(true);
  //   }
  //   setLoader(false);
  // };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!validateMobileNumber(mobileNumber)) return;

    setLoader(true);
    let param = {
      location: locationInfo,
      ipAddress: ipAddress,
      device: deviceInfo,
      mobileNumber: "+" + mobileNumber,
    };
    try {
      let res = await sendOTPForgetPassword(param);
      if (res && res.success && res.isOTPSent === false) {
        setAlerts({ type: "danger", message: res.success });
      } else {
        setOtpSent(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      return false;
    }
    setLoader(false);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!validateOtp(otp)) return;

    setLoader(true);
    let params = {
      location: locationInfo,
      ipAddress: ipAddress,
      device: deviceInfo,
      mobileNumber: "+" + mobileNumber,
      otp: otp,
    };
    const response = await verifyOTP(params);
    // if (response && response.success) {
    //   navigate(AUTH_URL_CHANGE_PASSWORD, { state: { mobileNumber, otp } });
    // }

    if (response && response?.success == "approved") {
      navigate(AUTH_URL_CHANGE_PASSWORD, { state: { mobileNumber, otp } });
    } else {
      await setAlerts({ type: "danger", message: "Please enter valid OTP" });
      setOtp("");
      setOTPCount((prevCount) => {
        const newCount = prevCount - 1;
        if (newCount === 0) {
          localStorage.clear();
          window.location.reload();
        }
        return newCount;
      });
    }
    setLoader(false);
  };

  return (
    // <div className="login-page">
    //   <div className="full-width">
    //     <Row>
    //       <Col lg="12" md="12" sm="12">
    //         <div className="form-center">
    <>
      {!otpSent ? (
        <Form
          className="form login-form-container"
          style={{ justifyContent: "start" }}
          onSubmit={handleSendOtp}
        >
          <h4 className="text-center mt-0 mb-0">Verification</h4>
          <p className="text-center">
            We will send you a One Time Password on your phone number
          </p>
          <FormGroup>
            {/* <label>Mobile Number *</label> */}
            <PhoneInput
              inputProps={{
                autoFocus: true,
              }}
              country={"in"}
              value={mobileNumber}
              onChange={(value) => setMobileNumber(value)}
              inputClass={numberError ? "is-invalid" : ""}
              containerClass="react-tel-input-custom"
              buttonClass="flag-search-btn-custom"
              dropdownClass="flag-dropdown-custom"
            />
            {numberError && (
              <div className="invalid-feedback">{numberError}</div>
            )}
            <div className="sign-up-text" style={{ textAlign: "right" }}>
              Back To&nbsp;
              <Link to={AUTH_URL_LOGIN}>Login</Link>
            </div>
          </FormGroup>
          {/* <div className="form-category">* Required fields</div> */}
          <button type="submit" disabled={loader} className="custom-login-btn">
            Get OTP
            {loader && <Spinner size="sm" color="secondary" className="ml-2" />}
          </button>
        </Form>
      ) : (
        <Form
          className="form login-form-container"
          style={{ justifyContent: "start" }}
          onSubmit={handleVerifyOtp}
        >
          <h4 className="text-center mt-0 mb-0">Verification</h4>
          <p className="text-center">You will get OTP via SMS</p>
          <FormGroup>
            <label>OTP *</label>
            <CustomInput
              placeholder="Enter your OTP"
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              maxLength={6}
              className={otpError ? "is-invalid" : ""}
            />
            {otpError && <div className="invalid-feedback">{otpError}</div>}
            <div className="form-category sign-up-text">* Required fields</div>
            <div className="sign-up-text" style={{ textAlign: "right" }}>
              Didn't receive the OTP?&nbsp;
              <span
                className=""
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={handleSendOtp}
              >
                Resend again
              </span>
            </div>
          </FormGroup>
          <button disabled={loader} className="custom-login-btn">
            Verify
            {loader && <Spinner size="sm" color="light" className="ml-2" />}
          </button>
          <div
            style={{
              fontWeight: "550",
            }}
            className="text-center text-size-small mt-2"
          >
            {OTPCount} Attempts remaining.
          </div>
        </Form>
      )}
    </>
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>
    // </div>
  );
};

export default Otp;

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Common from "helpers/Common";
// import { AUTH_URL_CHANGE_PASSWORD } from "helpers/Paths";
// import { ADMIN_URL_DASHBOARD } from "helpers/Paths";
// import { useLoginStore } from "hooks/Login";
// import { useNotificationStore } from "hooks/Notification";
// import React, { useEffect, useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";

// // reactstrap components
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   CardFooter,
//   Form,
//   Input,
//   Container,
//   Col,
//   Row,
//   Spinner,
//   FormGroup,
//   InputGroupText,
//   InputGroup,
//   Nav,
//   NavItem,
// } from "reactstrap";
// import { isEmpty, isObject } from "underscore";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

// const Otp = () => {
//   const [userName, setUserName] = useState("");
//   const [loginNumberState, setLoginNumberState] = useState("");
//   const [otp, setOtp] = useState("");
//   const [loader, setLoader] = useState(false);
//   const [OTPGetSuccess, setOTPGetSuccess] = useState(false);
//   const [loginOtpState, setLoginOtpState] = useState("");
//   const [{}, { sendOTP, verifyOTP }, {}] = useNotificationStore();
//   const navigate = useNavigate();
//   const isLogin = Common.getJwtToken();
//   useEffect(() => {
//     checkIfAlreadyLoggedIn();
//     document.body.classList.toggle("login-page");
//     return function cleanup() {
//       document.body.classList.toggle("login-page");
//     };
//   }, []);

//   const checkIfAlreadyLoggedIn = () => {
//     if (isLogin) {
//       navigate(ADMIN_URL_DASHBOARD);
//     }
//   };

//   const getOTP = async (e) => {
//     setLoader(true);
//     e.preventDefault();
//     let error = false;
//     if (loginNumberState === "" || loginNumberState === "has-danger") {
//       setLoginNumberState("has-danger");
//       error = true;
//     }

//     if (!error) {
//       let param = {
//         mobileNumber: userName,
//       };
//       let res = await sendOTP(param);
//       if (res && !isEmpty(res)) {
//         setOTPGetSuccess(true);
//       }
//     }

//     setLoader(false);
//   };

//   const OTPVerify = async (e) => {
//     setLoader(true);
//     e.preventDefault();
//     let error = false;
//     if (loginOtpState === "" || loginOtpState === "has-danger") {
//       setLoginOtpState("has-danger");
//       error = true;
//     }

//     if (!error) {
//       navigate(AUTH_URL_CHANGE_PASSWORD, {
//         state: { mobileNumber: userName.split("+91")[1], otp: otp },
//       });
//     }
//     setLoader(false);
//   };

//   return (
//     <div className="login-page">
//       <div class="full-width">
//         <Row>
//           <Col lg="12" md="12" sm="12">
//             <div className="form-center">
//               {!OTPGetSuccess ? (
//                 <Form className="form" onSubmit={getOTP}>
//                   <Card className="card-login">
//                     <CardHeader>
//                       <h4 className="header text-center mt-0 mb-0">
//                         Verification
//                       </h4>
//                       <center>
//                         <div className="text-size-contact-xs">
//                           We will send you a One Time Password on your phone
//                           number
//                         </div>
//                       </center>
//                     </CardHeader>
//                     <CardBody>
//                       <FormGroup className={`has-label ${loginNumberState}`}>
//                         <label>Mobile Number *</label>
//                         <Input
//                           placeholder="Enter Mobile Number"
//                           type="text"
//                           onChange={(e) => {
//                             if (!Common.verifyLength(e.target.value, 1)) {
//                               setLoginNumberState("has-danger");
//                             } else {
//                               setLoginNumberState("has-success");
//                             }
//                             setUserName(e.target.value);
//                           }}
//                         />

//                         {/* <PhoneInput
//                       country={'us'}
//                       placeholder="Enter Mobile Number"
//                      /> */}

//                         {loginNumberState === "has-danger" ? (
//                           <label className="error">
//                             Mobile number is required.
//                           </label>
//                         ) : null}
//                       </FormGroup>
//                       <div className="category form-category">
//                         * Required fields
//                       </div>
//                     </CardBody>
//                     <CardFooter>
//                       <Button
//                         block
//                         className="btn-round mb-1 btn-custom"
//                         color="warning"
//                         type="submit"
//                         disabled={loader ? true : false}
//                       >
//                         GET OTP
//                         {loader ? (
//                           <Spinner
//                             size="sm"
//                             color="secondary"
//                             style={{ marginLeft: "15px" }}
//                           />
//                         ) : (
//                           ""
//                         )}
//                       </Button>
//                     </CardFooter>
//                   </Card>
//                 </Form>
//               ) : (
//                 <Form className="form" onSubmit={OTPVerify}>
//                   <Card className="card-login">
//                     <CardHeader>
//                       <h4 className="header text-center mt-0 mb-0">
//                         Verification
//                       </h4>
//                       <center>
//                         <div className="text-size-contact-xs">
//                           You will get OTP via SMS
//                         </div>
//                       </center>
//                     </CardHeader>
//                     <CardBody>
//                       <FormGroup className={`has-label ${loginOtpState}`}>
//                         <label>OTP *</label>
//                         <Input
//                           placeholder="Enter your OTP"
//                           type="text"
//                           value={otp}
//                           onChange={(e) => {
//                             if (!Common.verifyLength(e.target.value, 1)) {
//                               setLoginOtpState("has-danger");
//                             } else {
//                               setLoginOtpState("has-success");
//                             }
//                             setOtp(e.target.value);
//                           }}
//                           maxLength={6}
//                         />
//                         {loginOtpState === "has-danger" ? (
//                           <label className="error">OTP is required.</label>
//                         ) : null}
//                       </FormGroup>
//                       <div className="category form-category">
//                         * Required fields
//                       </div>
//                     </CardBody>
//                     <CardFooter>
//                       <Button
//                         block
//                         className="btn-round mb-1 btn-custom"
//                         color="warning"
//                         type="submit"
//                         disabled={loader ? true : false}
//                       >
//                         VERIFY
//                         {loader ? (
//                           <Spinner
//                             size="sm"
//                             color="secondary"
//                             style={{ marginLeft: "15px" }}
//                           />
//                         ) : (
//                           ""
//                         )}
//                       </Button>
//                       <div className="text-right text-size-small">
//                         Didn't receive the verification OTP?{" "}
//                         <a
//                           style={{
//                             color: "#1F77D0",
//                             cursor: "pointer",
//                             fontWeight: "bold",
//                           }}
//                           onClick={getOTP}
//                         >
//                           {" "}
//                           Resend again{" "}
//                         </a>
//                       </div>
//                     </CardFooter>
//                   </Card>
//                 </Form>
//               )}
//             </div>
//           </Col>
//         </Row>
//       </div>
//       <div className="full-page-background" />
//     </div>
//   );
// };

// export default Otp;
