import APIHandler from "helpers/APIHandler";
import { LOGIN_WITH_OTP_API } from "helpers/APIRouter";
import { VALIDATE_USER_LOGIN_API } from "helpers/APIRouter";
import { LOGIN_VERIFY_OTP_API } from "helpers/APIRouter";
import { COMPANY_LOGOUT_API } from "helpers/APIRouter";
import { CHANGE_PASSWORD_API } from "helpers/APIRouter";
import { COMPANY_LOGIN_API } from "helpers/APIRouter";


const API = new APIHandler();

class LoginAPICall {
    companyLogin = async (params = {}) => {
        let res = {}
        let param = {};
        param.data = params
        await API
            .post(COMPANY_LOGIN_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };

    changePassword = async (params = {}) => {
        let res = {}
        let param = {};
        param.data = params
        await API
            .post(CHANGE_PASSWORD_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };

    companyLogout = (params = {}) => {
        let res = {}
        let param = {};
        param.params = params
        API
            .get(COMPANY_LOGOUT_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };

    loginWithOTP = async (params = {}) => {
        let res = {}
        let param = {};
        param.data = params
        await API
            .post(LOGIN_WITH_OTP_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };

    loginVerifyOTP = async (params = {}) => {
        let res = {}
        let param = {};
        param.data = params
        await API
            .post(LOGIN_VERIFY_OTP_API, param)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };

    validateUserLogin = async (params = {}) => {
        let res = {}
        // let param = {};
        // param.data = params
        await API
            .get(VALIDATE_USER_LOGIN_API)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };
}

export default LoginAPICall;