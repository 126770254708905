import APIHandler from "helpers/APIHandler";
import { DASHBOARD_DETAIL_API ,GET_COMPANY_LIST } from "helpers/APIRouter";

const API = new APIHandler();

class DashboardAPICall {
    getDashboardDetail = async (params) => {
        let res = {}
        await API
            .get(DASHBOARD_DETAIL_API, params)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };


    getComapnyListDeatils = async (params) => {
        let res = {}
        await API
            .get(GET_COMPANY_LIST, params)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };


}

export default DashboardAPICall;