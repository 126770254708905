import React from "react";
// reactstrap
import { CardBody } from "reactstrap";
// assets
import video from "../../assets/createBillGuide/createbill.mp4";
import search_customer from "../../assets/createBillGuide/search_customer.png";
import search_product from "../../assets/createBillGuide/search_product.png";
import notification from "../../assets/createBillGuide/notification.png";
import save_bill from "../../assets/createBillGuide/save_bill.png";
import qty_rate_discount from "../../assets/createBillGuide/qty_rate_discount.png";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

const CreateInvoice = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <h6 className="title" style={{ color: "#4272c4" }}>
        How To Create Bill
      </h6>
      <video
        src={video}
        style={{ width: "60%", margin: "auto" }}
        controls="play"
      ></video>
      <h4>Follow the Following Steps:</h4>
      <p className="text">Step-1 : Select customer</p>
      <CardBody className="grey-border">
        <img
          src={`${ERP_CDN_IMAGE_URL}/search_customer.png`}
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop if logo also fails
            e.target.src = search_customer;
          }}
        ></img>
      </CardBody>
      <p className="text">Step-2 : Select product</p>
      <CardBody className="grey-border">
        <img
          src={`${ERP_CDN_IMAGE_URL}/search_product.png`}
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop if logo also fails
            e.target.src = search_product;
          }}
        ></img>
      </CardBody>
      <p className="text">
        step-3 : add quantity, rate and discount of product
      </p>
      <CardBody className="grey-border">
        <img
          src={`${ERP_CDN_IMAGE_URL}/qty_rate_discount.png`}
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop if logo also fails
            e.target.src = qty_rate_discount;
          }}
        ></img>
      </CardBody>
      <p className="text">step-4 : click to save button to save a bill</p>
      <CardBody className="grey-border">
        <img
          src={`${ERP_CDN_IMAGE_URL}/save_bill.png`}
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop if logo also fails
            e.target.src = save_bill;
          }}
        ></img>
      </CardBody>
      <p className="text">step-5 : get notifiction and pdf of saved bill</p>
      <CardBody className="grey-border">
        <img
          src={`${ERP_CDN_IMAGE_URL}/notification.png`}
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop if logo also fails
            e.target.src = notification;
          }}
        ></img>
      </CardBody>
    </div>
  );
};

export default CreateInvoice;
