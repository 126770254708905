import BaseRepository from "./BaseRepository";
import CreateBillAPICall from "../service/CreateBillAPICall";
import Common from "helpers/Common";
import { isEmpty } from "underscore";

class CreateBillRepository extends BaseRepository {
  getBranchDetail = async () => {
    return await new CreateBillAPICall().getBranchDetail();
  };

  getPatientDetail = async (params) => {
    return await new CreateBillAPICall().getPatientDetail(params);
  };
  getCustomerDetail = async (params) => {
    return await new CreateBillAPICall().getCustomerDetail(params);
  };
  getProductSearchList = async (params) => {
    return await new CreateBillAPICall().getProductSearchList(params);
  };

  saveBill = async (params) => {
    return await new CreateBillAPICall().saveBill(params);
  };

  saveInvoice = async (params) => {
    return await new CreateBillAPICall().saveInvoice(params);
  };

  refundBill = async (params) => {
    // console.log(params);
    return await new CreateBillAPICall().refundBill(params);
  };

  createCustomer = async (params) => {
    return await new CreateBillAPICall().createCustomer(params);
  };

  updateCustomer = async (params) => {
    return await new CreateBillAPICall().updateCustomer(params);
  };

  getRecentBill = async (params) => {
    return await new CreateBillAPICall().getRecentBill(params);
  };

  getBill = async (params) => {
    return await new CreateBillAPICall().getBill(params);
  };

  GetTemplateDetails_By_ID = async (params) => {
    return await new CreateBillAPICall().GetTemplateDetails_By_ID(params);
  };

  Get_Bills_By_CustomerID = async (params) => {
    return await new CreateBillAPICall().Get_Bills_By_CustomerID(params);
  };

  getTemplateList = async (params) => {
    return await new CreateBillAPICall().getTemplateList(params);
  };

  createBillTemplate = async (params) => {
    return await new CreateBillAPICall().createBillTemplate(params);
  };

  getProductAdvancedSearch = async (params) => {
    return await new CreateBillAPICall().getProductAdvancedSearch(params);
  };

  setSuggestedProduct = (params) => {
    let suggestedProduct = [];
    suggestedProduct = this.getSuggestedProduct()
      ? this.getSuggestedProduct()
      : [];

    if (suggestedProduct?.length > 0) {
      const found = suggestedProduct.some(
        (el) => el.productId === params?.productId
      );
      if (!found) suggestedProduct.push(params);

      if (suggestedProduct.length > 5) {
        suggestedProduct.shift();
      }
    } else {
      suggestedProduct.push(params);
    }

    localStorage.setItem(
      "suggestedProduct-" + Common.getLoginId(),
      JSON.stringify(suggestedProduct)
    );
  };

  getSuggestedProduct = () => {
    return JSON.parse(
      localStorage.getItem("suggestedProduct-" + Common.getLoginId())
    );
  };

  getBillInvoiceHeaderFooter = () => {
    let res = Common.getCompanyProfileSetting();
    return {
      InvoiceHeader: Common.getValueByKeyWithSpecificValueFromKey(
        res,
        "feature_key",
        "bill_header"
      )[0],
      InvoiceFooter: Common.getValueByKeyWithSpecificValueFromKey(
        res,
        "feature_key",
        "bill_footer"
      )[0],
    };
  };

  getCurrencyAbbriviation = () => {
    let res = Common.getCompanyProfileSetting();
    // console.log(res)
    if (!isEmpty(res)) {
      return Common.getValueByKeyWithSpecificValueFromKey(
        res,
        "feature_key",
        "currency_value"
      )[0]
        .split("-")[0]
        .trim()
        .toUpperCase();
    }
  };
}

export default CreateBillRepository;
