import { useEffect, useState } from "react";
// helpers
import Common from "helpers/Common";
// react-select
import Select from "react-select";
// reactstrap
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap";
// underscore
import { isEmpty } from "underscore";
// hooks
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { useLoginStore } from "hooks/Login";
// helpers
import { notificationMessages } from "helpers/constantMessages";
import PhoneInput from "react-phone-input-2";
// varibles
import CustomButton from "components/Button/CustomButton";
import {
  AUTHFACTORLIST,
  CURRENCY,
  ERRORTYPE,
  INVENTORYPAGES,
  OPTIONS,
  SHAREREPORT,
  TEMPLATELIST,
} from "variables/taxdetail";

const TaxDetail = ({ getGenricData, taxDetails }) => {
  const [taxData, setTaxData] = useState({
    gstValue: "",
    cgstValue: "",
    sgstValue: "",
    templateId: "",
    factorId: "",
    iventoryPageId: "",
    errorTypeId: "",
    email: "",
    phoneNumber: "",
    ShareEmailId: "",
    ShareNumberId: "",
    billCopyId: "",
    billReportId: "",
    currency: "",
    upiId: "",
  });
  const [GenricFeildValidate, setGenricFeildValidate] = useState({});
  const [{ }, { }, { updateGenricData }] = useCompanyProfileStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();

  useEffect(() => {
    try {
      setTaxData({
        ...taxData,
        gstValue: taxDetails?.filter(
          (data) => data.feature_key === "gstn_percentage_value"
        )[0].feature_value,
        cgstValue: taxDetails?.filter(
          (data) => data.feature_key === "cgst_percentage_value"
        )[0].feature_value,
        sgstValue: taxDetails?.filter(
          (data) => data.feature_key === "sgst_percentage_value"
        )[0].feature_value,
        templateId: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "print_invoice_template"
          )[0].feature_value,
        },
        factorId: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "mfa_value"
          )[0].feature_value,
        },
        iventoryPageId: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "Inventory_Page_Size"
          )[0].feature_value,
        },
        errorTypeId: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "bill_qty_error_type"
          )[0].feature_value,
        },
        email: taxDetails?.filter(
          (data) => data.feature_key === "admin_email_id"
        )[0].feature_value,
        phoneNumber: taxDetails?.filter(
          (data) => data.feature_key === "admin_phone_number"
        )[0].feature_value,
        ShareEmailId: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "bill_invoice_sharing_to_admin_email"
          )[0].feature_value,
        },
        ShareNumberId: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "bill_invoice_sharing_to_admin_phone"
          )[0].feature_value,
        },
        billCopyId: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "admin_bill_copy_save_local_machine"
          )[0].feature_value,
        },
        billReportId: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "report_based_on_branch_or_company"
          )[0].feature_value,
        },
        currency: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "currency_value"
          )[0].feature_value,
        },
        upiId: taxDetails?.filter(
          (data) => data.feature_key === "admin_upi_id"
        )[0]?.feature_value,
      });
    } catch (error) { }
  }, [taxDetails]);

  async function saveDetails(e) {
    e.preventDefault();
    let error = false;
    let validate = { ...GenricFeildValidate };

    if (GenricFeildValidate && isEmpty(GenricFeildValidate)) {
      error = true;
      validate.cgstValue = "has-danger";
      validate.gstValue = "has-danger";
      validate.sgstValue = "has-danger";
      validate.templateId = "has-danger";
      validate.factorId = "has-danger";
      validate.iventoryPageId = "has-danger";
      validate.errorTypeId = "has-danger";
      validate.email = "has-danger";
      validate.phoneNumber = "has-danger";
      validate.ShareEmailId = "has-danger";
      validate.ShareNumberId = "has-danger";
      validate.billReportId = "has-danger";
      validate.billCopyId = "has-danger";
      validate.currency = "has-danger";
      validate.upiId = "has-danger";
    } else {
      if (!taxData.cgstValue) {
        error = true;
        validate.cgstValue = "has-danger";
      }
      if (!taxData.gstValue) {
        error = true;
        validate.gstValue = "has-danger";
      }
      if (!taxData.sgstValue) {
        error = true;
        validate.sgstValue = "has-danger";
      }
      if (!taxData.templateId?.label) {
        error = true;
        validate.templateId = "has-danger";
      }
      if (!taxData.factorId?.label) {
        error = true;
        validate.factorId = "has-danger";
      }
      if (!taxData.iventoryPageId?.label) {
        error = true;
        validate.iventoryPageId = "has-danger";
      }
      if (
        !taxData.email ||
        !taxData.email.includes("@") ||
        !Common.verifyEmail(taxData.email)
      ) {
        error = true;
        validate.email = "has-danger";
      }
      if (!taxData.phoneNumber || taxData.phoneNumber.length !== 12) {
        error = true;
        await setAlerts({
          type: "danger",
          message: "Valid 10-digit Phone Number is required",
        });
      }
      if (!taxData.ShareEmailId) {
        error = true;
        validate.ShareEmailId = "has-danger";
      }
      if (!taxData.ShareNumberId) {
        error = true;
        validate.ShareNumberId = "has-danger";
      }
      if (!taxData.billReportId) {
        error = true;
        validate.billReportId = "has-danger";
      }
      if (!taxData.currency) {
        error = true;
        validate.currency = "has-danger";
      }
      if (
        !taxData.upiId ||
        !taxData.upiId.includes("@") ||
        !Common.verifyUPIId(taxData.upiId)
      ) {
        error = true;
        validate.upiId = "has-danger";
      }
    }
    setGenricFeildValidate(validate);
    if (!error) {
      let param = [
        {
          feature_key: "gstn_percentage_value",
          feature_value: taxData.gstValue,
        },
        {
          feature_key: "cgst_percentage_value",
          feature_value: taxData.cgstValue,
        },
        {
          feature_key: "sgst_percentage_value",
          feature_value: taxData.sgstValue,
        },
        {
          feature_key: "print_invoice_template",
          feature_value: taxData.templateId?.label,
        },
        {
          feature_key: "mfa_value",
          feature_value: taxData.factorId?.label,
        },
        {
          feature_key: "Inventory_Page_Size",
          feature_value: taxData.iventoryPageId.label,
        },
        {
          feature_key: "bill_qty_error_type",
          feature_value: taxData.errorTypeId.label,
        },
        {
          feature_key: "admin_email_id",
          feature_value: taxData.email,
        },
        {
          feature_key: "admin_phone_number",
          feature_value: "+" + taxData.phoneNumber,
        },
        {
          feature_key: "bill_invoice_sharing_to_admin_email",
          feature_value: taxData.ShareEmailId?.label,
        },
        {
          feature_key: "bill_invoice_sharing_to_admin_phone",
          feature_value: taxData.ShareNumberId.label,
        },
        {
          feature_key: "admin_bill_copy_save_local_machine",
          feature_value: taxData.billCopyId?.label,
        },
        {
          feature_key: "report_based_on_branch_or_company",
          feature_value: taxData.billReportId?.label,
        },
        {
          feature_key: "currency_value",
          feature_value: taxData.currency?.label,
        },
        {
          feature_key: "admin_upi_id",
          feature_value: taxData.upiId,
        },
      ];
      const res = await updateGenricData(param);
      if (res) {
        const response = await getGenricData();
        await setAlerts({
          type: "success",
          message: notificationMessages.genricSettingsSuccess,
        });
      }
    }
  }

  const handlePhoneChange = (value) => {
    let error = { ...GenricFeildValidate };
    if (!Common.verifyLength(value, 1)) {
      error.phonenumber = "has-danger";
    } else {
      error.phonenumber = "has-success";
    }
    setGenricFeildValidate(error);

    setTaxData({
      ...taxData,
      phoneNumber: value,
    });
  };

  const resetGenricSettings = async (e) => {
    e.preventDefault();
    await getGenricData();
  };

  const handleValidation = (value, field) => {
    let error = { ...GenricFeildValidate };
    const floatValue = parseFloat(value);

    if (
      value === "" ||
      isNaN(floatValue) ||
      floatValue < 0 ||
      floatValue > 100
    ) {
      error[field] = "has-danger";
    } else if (!Common.verifyLength(value, 1)) {
      error[field] = "has-danger";
    } else {
      error[field] = "has-success";
    }

    setGenricFeildValidate(error);
    setTaxData({
      ...taxData,
      [field]: value,
    });
  };

  return (
    <div className="profile-content-container">

      <Form className="form" onSubmit={saveDetails}>
        <div className="profile-content-title">
          <h5>Tax Settings</h5>
        </div>
        <Row>
          <Col md="4">
            <div className="custom-input-container">
              <label>GST Percentage Value</label>
              <FormGroup>
                <Input
                  placeholder="Enter GST Value"
                  type="number"
                  value={taxData?.gstValue}
                  valid={
                    GenricFeildValidate?.gstValue === "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.gstValue === "has-danger"
                  }
                  onChange={(e) =>
                    handleValidation(e.target.value, "gstValue")
                  }
                  onPaste={(e) => {
                    const paste = (
                      e.clipboardData || window.clipboardData
                    ).getData("text");
                    const parsedPaste = parseFloat(paste);
                    if (
                      isNaN(parsedPaste) ||
                      parsedPaste < 0 ||
                      parsedPaste > 100
                    ) {
                      e.preventDefault();
                    } else {
                      handleValidation(paste, "gstValue");
                    }
                  }}
                />
                <FormFeedback>GST Value is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>CGST Percentage Value</label>
              <FormGroup>
                <Input
                  placeholder="Enter CGST Value"
                  type="number"
                  min="0"
                  max="100"
                  value={taxData?.cgstValue}
                  valid={
                    GenricFeildValidate?.cgstValue === "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.cgstValue === "has-danger"
                  }
                  onChange={(e) =>
                    handleValidation(e.target.value, "cgstValue")
                  }
                  onPaste={(e) => {
                    const paste = (
                      e.clipboardData || window.clipboardData
                    ).getData("text");
                    const parsedPaste = parseFloat(paste);
                    if (
                      isNaN(parsedPaste) ||
                      parsedPaste < 0 ||
                      parsedPaste > 100
                    ) {
                      e.preventDefault();
                    } else {
                      handleValidation(paste, "cgstValue");
                    }
                  }}
                />
                <FormFeedback>CGST Value is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>SGST Percentage Value</label>
              <FormGroup>
                <FormGroup>
                  <Input
                    placeholder="Enter SGST Value"
                    type="number"
                    value={taxData?.sgstValue}
                    valid={
                      GenricFeildValidate?.sgstValue === "has-success"
                    }
                    invalid={
                      GenricFeildValidate?.sgstValue === "has-danger"
                    }
                    onChange={(e) =>
                      handleValidation(e.target.value, "sgstValue")
                    }
                    onPaste={(e) => {
                      const paste = (
                        e.clipboardData || window.clipboardData
                      ).getData("text");
                      const parsedPaste = parseFloat(paste);
                      if (
                        isNaN(parsedPaste) ||
                        parsedPaste < 0 ||
                        parsedPaste > 100
                      ) {
                        e.preventDefault();
                      } else {
                        handleValidation(paste, "sgstValue");
                      }
                    }}
                  />

                  <FormFeedback>SGST Value is required.</FormFeedback>
                </FormGroup>
              </FormGroup>
            </div>
          </Col>
        </Row>

        <div className="mt-3 profile-content-title">
          <h5>Invoice Settings</h5>
        </div>
        <Row>
          <Col md="4">
            <div className="custom-input-container">
              <label>Print Invoice Template</label>
              <FormGroup>
                <Input
                  name="template"
                  placeholder="Select Template"
                  type="hidden"
                  valid={
                    GenricFeildValidate?.templateId === "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.templateId === "has-danger"
                  }
                />
                <Select
                  className="profile-custom-select"
                  // classNamePrefix="react-select"
                  name="template"
                  value={taxData?.templateId}
                  onChange={(value) => {
                    let error = { ...GenricFeildValidate };
                    if (value && isEmpty(value)) {
                      error.templateId = "has-danger";
                    } else {
                      error.templateId = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({ ...taxData, templateId: value });
                  }}
                  options={
                    TEMPLATELIST &&
                    TEMPLATELIST.length > 0 &&
                    TEMPLATELIST.map((prop, key) => {
                      return {
                        value: prop.id,
                        label: prop.categoryname,
                      };
                    })
                  }
                  placeholder="Select Template"
                  isSearchable={false}
                />
                <FormFeedback>Template is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Second Factor Authentication</label>
              <FormGroup>
                <Input
                  name="factor"
                  placeholder="Select Template"
                  type="hidden"
                  valid={
                    GenricFeildValidate?.factorId === "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.factorId === "has-danger"
                  }
                />
                <Select
                  className="profile-custom-select"
                  // classNamePrefix="react-select"
                  name="factor"
                  value={taxData?.factorId}
                  onChange={(value) => {
                    let error = { ...GenricFeildValidate };
                    if (value && isEmpty(value)) {
                      error.factorId = "has-danger";
                    } else {
                      error.factorId = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({ ...taxData, factorId: value });
                  }}
                  options={
                    AUTHFACTORLIST &&
                    AUTHFACTORLIST.length > 0 &&
                    AUTHFACTORLIST.map((prop, key) => {
                      return {
                        value: prop.id,
                        label: prop.categoryname,
                      };
                    })
                  }
                  placeholder="Select Factor"
                  isSearchable={false}
                />
                <FormFeedback>factor is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Page_Count</label>
              <FormGroup>
                <Input
                  name="iventoryPage"
                  placeholder="Select Template"
                  type="hidden"
                  valid={
                    GenricFeildValidate?.iventoryPageId ===
                    "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.iventoryPageId === "has-danger"
                  }
                />
                <Select
                  className="profile-custom-select"
                  // classNamePrefix="react-select"
                  name="iventoryPageId"
                  value={taxData?.iventoryPageId}
                  onChange={(value) => {
                    let error = { ...GenricFeildValidate };
                    if (value && isEmpty(value)) {
                      error.iventoryPageId = "has-danger";
                    } else {
                      error.iventoryPageId = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({ ...taxData, iventoryPageId: value });
                  }}
                  options={
                    INVENTORYPAGES &&
                    INVENTORYPAGES.length > 0 &&
                    INVENTORYPAGES.map((prop, key) => {
                      return {
                        value: prop.id,
                        label: prop.categoryname,
                      };
                    })
                  }
                  placeholder="Select Page"
                  isSearchable={false}
                />
                <FormFeedback>page is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Bill Quality Error </label>
              <FormGroup>
                <Input
                  name="errorType"
                  placeholder="Select Error Type"
                  type="hidden"
                  valid={
                    GenricFeildValidate?.errorTypeId === "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.errorTypeId === "has-danger"
                  }
                />
                <Select
                  className="profile-custom-select"
                  // classNamePrefix="react-select"
                  name="errotType"
                  value={taxData?.errorTypeId}
                  onChange={(value) => {
                    let error = { ...GenricFeildValidate };
                    if (value && isEmpty(value)) {
                      error.errorTypeId = "has-danger";
                    } else {
                      error.errorTypeId = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({ ...taxData, errorTypeId: value });
                  }}
                  options={
                    ERRORTYPE &&
                    ERRORTYPE.length > 0 &&
                    ERRORTYPE.map((prop, key) => {
                      return {
                        value: prop.id,
                        label: prop.type,
                      };
                    })
                  }
                  placeholder="Select Error Type"
                  isSearchable={false}
                />
                <FormFeedback>Error Type is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Admin Email</label>
              <FormGroup>
                <Input
                  placeholder="example@mail.com"
                  type="text"
                  value={taxData?.email}
                  valid={GenricFeildValidate?.email === "has-success"}
                  invalid={GenricFeildValidate?.email === "has-danger"}
                  onChange={(e) => {
                    let error = { ...GenricFeildValidate };
                    if (!Common.verifyLength(e.target.value, 1)) {
                      error.email = "has-danger";
                    } else {
                      error.email = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({
                      ...taxData,
                      email: e.target.value,
                    });
                  }}
                />
                <FormFeedback>Invalid Email.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Admin PhoneNumber</label>
              <FormGroup>
                <PhoneInput
                  containerClass="custom-phone-input-container"
                  buttonClass="profile-flag-search-btn"
                  dropdownClass="profile-flag-dropdown"
                  inputProps={{
                    autoFocus: true,
                  }}
                  country={"in"}
                  placeholder="Enter Mobile Number"
                  value={taxData.phoneNumber}
                  onChange={handlePhoneChange}
                />
                <FormFeedback>phoneNumber is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Invoice Bill Share To Email</label>
              <FormGroup>
                <Input
                  name="shareEmail"
                  placeholder="Select Sharing Type"
                  type="hidden"
                  valid={
                    GenricFeildValidate?.ShareEmailId === "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.ShareEmailId === "has-danger"
                  }
                />
                <Select
                  className="profile-custom-select"
                  // classNamePrefix="react-select"
                  name="errotType"
                  value={taxData?.ShareEmailId}
                  onChange={(value) => {
                    let error = { ...GenricFeildValidate };
                    if (value && isEmpty(value)) {
                      error.ShareEmailId = "has-danger";
                    } else {
                      error.ShareEmailId = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({ ...taxData, ShareEmailId: value });
                  }}
                  options={
                    OPTIONS &&
                    OPTIONS.length > 0 &&
                    OPTIONS.map((prop, key) => {
                      return {
                        value: prop.id,
                        label: prop.type,
                      };
                    })
                  }
                  placeholder="Select To Share"
                  isSearchable={false}
                />
                <FormFeedback>
                  email Sharing Type is required.
                </FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Invoice Bill Share To PhoneNumber</label>
              <FormGroup>
                <Input
                  name="shareNumber"
                  placeholder="Select Sharing Type"
                  type="hidden"
                  valid={
                    GenricFeildValidate?.ShareNumberId === "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.ShareNumberId === "has-danger"
                  }
                />
                <Select
                  className="profile-custom-select"
                  // classNamePrefix="react-select"
                  name="errotType"
                  value={taxData?.ShareNumberId}
                  onChange={(value) => {
                    let error = { ...GenricFeildValidate };
                    if (value && isEmpty(value)) {
                      error.ShareNumberId = "has-danger";
                    } else {
                      error.ShareNumberId = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({ ...taxData, ShareNumberId: value });
                  }}
                  options={
                    OPTIONS &&
                    OPTIONS.length > 0 &&
                    OPTIONS.map((prop, key) => {
                      return {
                        value: prop.id,
                        label: prop.type,
                      };
                    })
                  }
                  placeholder="Select To Share"
                  isSearchable={false}
                />
                <FormFeedback>
                  Number Sharing Type is required.
                </FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Invoice Bill Save To Local</label>
              <FormGroup>
                <Input
                  name="savebill"
                  placeholder="Select to save"
                  type="hidden"
                  valid={
                    GenricFeildValidate?.billCopyId === "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.billCopyId === "has-danger"
                  }
                />
                <Select
                  className="profile-custom-select"
                  // classNamePrefix="react-select"
                  name="errotType"
                  value={taxData?.billCopyId}
                  onChange={(value) => {
                    let error = { ...GenricFeildValidate };
                    if (value && isEmpty(value)) {
                      error.billCopyId = "has-danger";
                    } else {
                      error.billCopyId = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({ ...taxData, billCopyId: value });
                  }}
                  options={
                    OPTIONS &&
                    OPTIONS.length > 0 &&
                    OPTIONS.map((prop, key) => {
                      return {
                        value: prop.id,
                        label: prop.type,
                      };
                    })
                  }
                  placeholder="Select To Save"
                  isSearchable={false}
                />
                <FormFeedback>
                  Number Sharing Type is required.
                </FormFeedback>
              </FormGroup>
            </div>
          </Col>
        </Row>

        <div className="mt-3 profile-content-title">
          <h5>Bill Settings</h5>
        </div>
        <Row>
          <Col md="4">
            <div className="custom-input-container">
              <label>Bill Report</label>
              <FormGroup>
                <Input
                  name="reprt"
                  placeholder="report"
                  type="hidden"
                  valid={
                    GenricFeildValidate?.billReportId === "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.billReportId === "has-danger"
                  }
                />
                <Select
                  className="profile-custom-select"
                  // classNamePrefix="react-select"
                  name="reportType"
                  value={taxData?.billReportId}
                  onChange={(value) => {
                    let error = { ...GenricFeildValidate };
                    if (value && isEmpty(value)) {
                      error.billReportId = "has-danger";
                    } else {
                      error.billReportId = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({ ...taxData, billReportId: value });
                  }}
                  options={
                    SHAREREPORT &&
                    SHAREREPORT.length > 0 &&
                    SHAREREPORT.map((prop, key) => {
                      return {
                        value: prop.id,
                        label: prop.type,
                      };
                    })
                  }
                  placeholder="Select Bill Type"
                  isSearchable={false}
                />
                <FormFeedback>Report Type is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Select Currency</label>
              <FormGroup>
                <Input
                  name="reprt"
                  placeholder="report"
                  type="hidden"
                  valid={
                    GenricFeildValidate?.currency === "has-success"
                  }
                  invalid={
                    GenricFeildValidate?.currency === "has-danger"
                  }
                />
                <Select
                  className="profile-custom-select"
                  // classNamePrefix="react-select"
                  name="currency"
                  value={taxData?.currency}
                  onChange={(value) => {
                    let error = { ...GenricFeildValidate };
                    if (value && isEmpty(value)) {
                      error.currency = "has-danger";
                    } else {
                      error.currency = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({ ...taxData, currency: value });
                  }}
                  options={
                    CURRENCY &&
                    CURRENCY.length > 0 &&
                    CURRENCY.map((prop, key) => {
                      return {
                        value: prop.symbol,
                        label: prop.code + " - " + prop.name,
                      };
                    })
                  }
                  placeholder="Select Currency"
                  isSearchable={false}
                />
                <FormFeedback>currency is required</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Admin UPI Id</label>
              <FormGroup>
                <Input
                  placeholder="UPI ID : <username>@<bank> or <mobile_number>@<bank>"
                  type="text"
                  value={taxData?.upiId}
                  valid={GenricFeildValidate?.upiId === "has-success"}
                  invalid={GenricFeildValidate?.upiId === "has-danger"}
                  onChange={(e) => {
                    let error = { ...GenricFeildValidate };
                    if (!Common.verifyLength(e.target.value, 1)) {
                      error.upiId = "has-danger";
                    } else {
                      error.upiId = "has-success";
                    }
                    setGenricFeildValidate(error);
                    setTaxData({
                      ...taxData,
                      upiId: e.target.value,
                    });
                  }}
                />
                <FormFeedback>Invalid UPI Id.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
        </Row>

        <div className="mt-lg-2 profile-button-container">
          <CustomButton
            label="Save Details"
            type="submit"
          />
          <CustomButton
            label="Reset"
            onClick={resetGenricSettings}
            customStyle='profile-reset-btn'
          />
        </div>
      </Form>

    </div>
  );
};
export default TaxDetail;
