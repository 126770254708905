import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes } from "react-router-dom";

import routes from "routes.js";
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import { AUTH_URL_LOGIN } from "helpers/Paths";
import { ADMIN_URL_DASHBOARD } from "helpers/Paths";

var ps;

function Pages() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/alert") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <Row style={{ backgroundColor: "#000" }}>
        <Col sm={{ offset: 4, size: 4 }} md={{ offset: 5, size: 2 }}>
          <Nav
            justified
          >
            <NavItem>
              <NavLink
                href={AUTH_URL_LOGIN}
              >
                Login
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={ADMIN_URL_DASHBOARD}>
                Home
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <Routes>
        {getRoutes(routes)}
      </Routes>
    </>
  );
}

export default Pages;
