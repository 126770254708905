import SubscriptionRepository from "repository/SubscriptionRepository";

export function useSubscriptionStore() {
  /**
   * Here is list of use selectors which are relates to subscription
   * Author: Devanshi Patel
   */

  let data = {};

  let fetch = {
    /**
     * Here is list of fetch methods which are used for get data from API or Repositories related to subscription
     * Author: Devanshi Patel
     * dispatch is used for reducer or thunk method
     * if redux not needed use repository method
     */
    getCompanySubscription: async () => {
      return await new SubscriptionRepository().getCompanySubscription();
    },

    getCompanySubscriptionDetails: async () => {
      return await new SubscriptionRepository().getCompanySubscriptionDetails();
    }
  };
  let set = {
    /**
     * Here is list of fetch methods which are used for set or update data from API or Repositories related to subscription
     * Author: Devanshi Patel
     */
    createCompanySubscription: async (params) => {
      return await new SubscriptionRepository().createCompanySubscription(params);
    },

    updateCompanySubscription: async (params) => {
      return await new SubscriptionRepository().updateCompanySubscription(params);
    },

    createCustomPlanSubscription: async (params) => {
      return await new SubscriptionRepository().createCustomPlanSubscription(params);
    },
  };
  return [data, fetch, set];
}
