import store from "store/store";
import CompanyProfileRepository from "../repository/CompanyProfileRepository";
import {
  getLocationDetails,
  getLegalInfo,
  getTaxCSGST,
  getTaxGST,
  getBranchList,
  getGenricData,
} from "../store/slice/CompanyProfile";
import { useSelector } from "react-redux";

export function useCompanyProfileStore() {
  /**
   * Here is list of use selectors which are relates to company profile
   * Author: Devanshi Patel
   */
  const locationDetails = useSelector(
    (state) => state.entities.CompanyProfile.locationDetail
  );
  const legalDetails = useSelector(
    (state) => state.entities.CompanyProfile.legalDetail
  );
  const taxCsGstDetails = useSelector(
    (state) => state.entities.CompanyProfile.csGstDetail
  );
  const taxGstDetails = useSelector(
    (state) => state.entities.CompanyProfile.gstDetail
  );
  const branchList = useSelector(
    (state) => state.entities.CompanyProfile.branchList
  );

  const taxDetails = useSelector(
    (state) => state.entities.CompanyProfile.taxDetails
  )

  let data = {
    locationDetails,
    legalDetails,
    taxCsGstDetails,
    taxGstDetails,
    branchList,
    taxDetails
  };
  let fetch = {
    /**
     * Here is list of fetch methods which are used for get data from API or Repositories related to inventory
     * Author: Devanshi Patel
     * dispatch is used for reducer or thunk method
     * if redux not needed use repository method
     */
    getLocationDetails: async () => {
      return await store.dispatch(getLocationDetails());
    },

    getLegalInfo: async () => {
      return await store.dispatch(getLegalInfo());
    },

    getCompanyTaxCategory: async () => {
      return await new CompanyProfileRepository().getCompanyTaxCategory();
    },

    getCompanyState: async () => {
      return await new CompanyProfileRepository().getCompanyState();
    },

    getTaxCSGST: async () => {
      return await store.dispatch(getTaxCSGST());
    },

    getTaxGST: async () => {
      return await store.dispatch(getTaxGST());
    },

    getBranchList: async () => {
      return await store.dispatch(getBranchList());
    },

    getBranchDetailById: async (params) => {
      return await new CompanyProfileRepository().getBranchDetailById(params);
    },

    getCustomerEnableServicesDetails: async () => {
      return await new CompanyProfileRepository().getCustomerEnableServicesDetails();
    },

    getGenricData: async () => {
        return await store.dispatch(getGenricData())
    },

    getUsers: async () => {
      return await new CompanyProfileRepository().getUsers();
    },

    getRolesList: async () => {
      return await new CompanyProfileRepository().getRolesList();
    },
    
    
  };
  let set = {
    /**
     * Here is list of fetch methods which are used for set or update data from API or Repositories related to inventory
     * Author: Devanshi Patel
     */
    updateLocationDetails: async (params) => {
      return await new CompanyProfileRepository().updateLocationDetails(params);
    },

    updateLegalInfo: async (params) => {
      return await new CompanyProfileRepository().updateLegalInfo(params);
    },

    updateTaxCSGst: async (params) => {
      return await new CompanyProfileRepository().updateTaxCSGst(params);
    },

    updateTaxGst: async (params) => {
      return await new CompanyProfileRepository().updateTaxGst(params);
    },

    createBranchDetails: async (params) => {
      return await new CompanyProfileRepository().createBranchDetails(params);
    },

    updateBranchDetail: async (params) => {
      return await new CompanyProfileRepository().updateBranchDetail(params);
    },
    saveCustomerEnableServicesDetails: async (params) => {
      return await new CompanyProfileRepository().saveCustomerEnableServicesDetails(
        params
      );
    },

    updateGenricData: async (param) => {
        return await new CompanyProfileRepository().updateGenricData(param)
    },
  };
  return [data, fetch, set];
}
