import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CompanyProfileRepository from "repository/CompanyProfileRepository";

export const getLocationDetails = createAsyncThunk('customerProfile_getLocationDetails', async () => {
    return await new CompanyProfileRepository().getLocationDetails();
});

export const getLegalInfo = createAsyncThunk('customerProfile_getLegalInfo', async () => {
    return await new CompanyProfileRepository().getLegalInfo();
});

export const getTaxCSGST = createAsyncThunk('customerProfile_getTaxCSGST', async () => {
    return await new CompanyProfileRepository().getTaxCSGST();
});

export const getTaxGST = createAsyncThunk('customerProfile_getTaxGST', async () => {
    return await new CompanyProfileRepository().getTaxGST();
});

export const getBranchList = createAsyncThunk('customerProfile_getBranchList', async () => {
    return await new CompanyProfileRepository().getBranchList();
});

export const getGenricData = createAsyncThunk('genricSettings_getGenricData', async () =>{
    return await new CompanyProfileRepository().getGenricData();
})

const initialState = {
    locationDetail: {},
    legalDetail: {},
    csGstDetail: {},
    gstDetail: {},
    branchList: {},
    taxDetails: {}
};
const slice = createSlice({
    name: "customerProfile",
    initialState: initialState,
    reducers: {
    },
    extraReducers: {
        [getLocationDetails.fulfilled]: (state, { payload }) => {
            state.locationDetail = payload;
            return state;
        },
        [getLocationDetails.rejected]: (state, { payload }) => {
        },
        [getLegalInfo.fulfilled]: (state, { payload }) => {
            state.legalDetail = payload;
            return state;
        },
        [getLegalInfo.rejected]: (state, { payload }) => {
        },
        [getTaxCSGST.fulfilled]: (state, { payload }) => {
            state.csGstDetail = payload;
            return state;
        },
        [getTaxCSGST.rejected]: (state, { payload }) => {
        },
        [getTaxGST.fulfilled]: (state, { payload }) => {
            state.gstDetail = payload;
            return state;
        },
        [getTaxGST.rejected]: (state, { payload }) => {
        },
        [getBranchList.fulfilled]: (state, { payload }) => {
            state.branchList = payload;
            return state;
        },
        [getBranchList.rejected]: (state, { payload }) => {
        },
        [getGenricData.fulfilled]: (state, { payload }) => {
            state.taxDetails = payload;
            localStorage.setItem('companyProfile', JSON.stringify(payload))
            return state;
        },
        [getGenricData.rejected]: (state, { payload }) => {
        }
    },
});

export let { } = slice.actions;

export default slice.reducer;
