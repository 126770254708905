import DashboardV2Repository from "repository/DashboardV2Repository";

export function useDashboardV2DataStore() {
  /**
   * Here is list of use selectors which are relates to DashboardV2
   * Author: Shivam Rayjada
   */

  let data = {};
  let fetch = {
    /**
     * Here is list of fetch methods which are used for get data from API or Repositories related to Dashboardv2
     * Author: Shivam Rayjada
     * dispatch is used for reducer or thunk method
     * if redux not needed use repository method
     */
    getCustomerCounts: async () => {
      return await new DashboardV2Repository().getCustomerCounts();
    },
    getProducSales: async (params) => {
      return await new DashboardV2Repository().getProducSales(params);
    },
    getFinancialAnalysis: async (params) => {
      return await new DashboardV2Repository().getFinancialAnalysis(params);
    },
    getTopSellingMedicines: async (params) => {
      return await new DashboardV2Repository().getTopSellingMedicines(params);
    },
    getRecentInventory: async (params) => {
      return await new DashboardV2Repository().getRecentInventory(params);
    },
    getRecentBills: async (params) => {
      return await new DashboardV2Repository().getRecentBills(params);
    },
  };
  let set = {};
  return [data, fetch, set];
}
