// 
import { Col, FormFeedback, FormGroup, Input } from "reactstrap";
// react-select - library
import Select from "react-select";
// helpers
import Common from "helpers/Common";
// underscore - library
import { isEmpty } from "underscore";


const EmailContent = (
  {
    handleNotificationData,
    notificationData,
    handleNotificationValidation,
    notificationValidate,
  }
) => {
  const options = [{ id: '1', label: 'Yes' }, { id: '2', label: 'No' }]

  return (
    <>
      <Col sm="12" md="6">
        <div className="custom-input-container">
          <label>Subject*</label>
          <FormGroup>
            <Input
              type="text"
              placeholder="Subject"
              value={notificationData.emailSubjectValue}
              valid={notificationValidate?.emaiSubjectValue === "has-success"}
              invalid={notificationValidate?.emailSubjectValue === "has-danger"}
              onChange={(e) => {
                let error = { ...notificationValidate };
                if (!Common.verifyLength(e.target.value, 1)) {
                  error.emailSubjectValue = "has-danger";
                } else {
                  error.emailSubjectValue = "has-success";
                }
                handleNotificationValidation(error);
                handleNotificationData({
                  ...notificationData, emailSubjectValue: e.target.value
                });
              }}
              maxLength={100}
            />
            <FormFeedback>Subject is required.</FormFeedback>
          </FormGroup>
        </div>
      </Col>

      <Col sm="12" md="4">
        <div className="custom-input-container">
          <label>Body*</label>
          <FormGroup>
            <Input
              type="text"
              placeholder="Type your message here..."
              value={notificationData.emailBodyData}
              valid={notificationValidate?.emailBodyData === "has-success"}
              invalid={notificationValidate?.emailBodyData === "has-danger"}
              onChange={(e) => {
                let error = { ...notificationValidate };
                if (!Common.verifyLength(e.target.value, 1)) {
                  error.emailbodyData = "has-danger";
                } else {
                  error.emailbodyData = "has-success";
                }
                handleNotificationValidation(error);
                handleNotificationData({
                  ...notificationData,
                  emailBodyData: e.target.value
                });
              }}
              maxLength={200}
            />
            <FormFeedback>Body feild is required.</FormFeedback>
          </FormGroup>
        </div>
      </Col>

      <Col sm="12" md="6">
        <div className="custom-input-container">
          <label>Email Attachment*</label>
          <FormGroup>
            <Input
              name="attachment"
              placeholder=""
              type="hidden"
              valid={notificationValidate?.emailAttachmentId === "has-success"}
              invalid={notificationValidate?.emailAttachmentId === "has-danger"}
            />
            <Select
              className="profile-custom-select"
              classNamePrefix="react-select"
              name="email attachment"
              value={notificationData.emailAttachmentId}
              onChange={(value) => {
                let error = { ...notificationValidate };
                if (value && isEmpty(value)) {
                  error.emailAttachmentId = "has-danger";
                } else {
                  error.emailAttachmentId = "has-success";
                }
                handleNotificationValidation(error);
                handleNotificationData({
                  ...notificationData,
                  emailAttachmentId: value
                });
              }}
              options={
                options &&
                options.length > 0 &&
                options.map((prop, key) => {
                  return {
                    value: prop.id,
                    label: prop.label,
                  };
                })
              }
              placeholder="Select here"
              isSearchable={false}
            />
            <FormFeedback>selection is required.</FormFeedback>
          </FormGroup>
        </div>
      </Col>
    </>
  );
}
export default EmailContent