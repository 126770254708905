import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import { useNotificationStore } from "hooks/Notification";
import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/notification/CustomerNotification.css"

// reactstrap components
import {
  Label,
  Button,
  Input,
  Col,
  Row,
  FormGroup,
  CardTitle,
  FormFeedback,
  Form
} from "reactstrap";
import { isEmpty } from "underscore";
import { useCompanyStore } from "hooks/Company";
import Select from "react-select";
import CustomButton from "components/Button/CustomButton";

const NotificationSMSSchedule = (props) => {
  const ref = useRef();
  const [{ }, { getNotificationList, getNotificationById }, { createNotification, updateNotification, updateNotificationStatus }] = useNotificationStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();
  const [{ customerList }, { }, { }] = useCompanyStore();

  const [loader, setLoader] = useState(false);
  const [notificationValidate, setNotificationValidate] = useState({});
  const [notificationData, setNotificationData] = useState({});
  const [customerEmail, setCustomerEmail] = useState(null);

  useEffect(() => {
    if (props.notificationData) {
      setNotificationData(props.notificationData)
      setCustomerEmail(props.notificationData.customerEmail)
    }
  }, [props.notificationData])

  const getCustomerList = () => {
    let custList = []
    custList.push({
      value: 'ALL',
      label: 'ALL',
    })
    let list = customerList && !isEmpty(customerList) > 0 && customerList.map((prop, key) => {
      return {
        value: prop.customerID,
        label: prop.customerName + " (" + prop.phoneNumber + ")",
      }
    })
    custList = custList.concat(list)
    return custList
  }

  const onCustomerChange = (selectedItems) => {

    // Check if the "Select All" option is selected
    if (selectedItems.some(item => item.value === 'ALL')) {
      let filteredOptions = getCustomerList()
      setCustomerEmail(filteredOptions.filter(option => option.value !== 'ALL'));
    } else {
      setCustomerEmail(selectedItems);
    }

    let error = { ...notificationValidate }

    if (selectedItems && selectedItems.length > 0) {
      error.customerEmail = 'has-success';
    } else {
      error.customerEmail = 'has-danger';
    }
    setNotificationValidate(error)
  };

  const SMSNotificationSave = async (e) => {
    setLoader(true);
    e.preventDefault();

    let error = false
    let validate = { ...notificationValidate }
    if (!notificationData.notificationSettingID) {
      if (notificationValidate && isEmpty(notificationValidate)) {
        error = true
        validate.scheduleName = 'has-danger';
        validate.customerEmail = 'has-danger';
        validate.frequency = 'has-danger';
        validate.fromDate = 'has-danger';
        validate.toDate = 'has-danger';
        validate.subject = 'has-danger';
        validate.body = 'has-danger';
      } else {
        if (!notificationValidate.scheduleName || notificationValidate.scheduleName === 'has-danger') {
          error = true
          validate.scheduleName = 'has-danger';
        }
        if (!notificationValidate.customerEmail || notificationValidate.customerEmail === 'has-danger') {
          error = true
          validate.customerEmail = 'has-danger';
        }
        if (!notificationValidate.frequency || notificationValidate.frequency === 'has-danger') {
          error = true
          validate.frequency = 'has-danger';
        }
        if (!notificationValidate.fromDate || notificationValidate.fromDate === 'has-danger') {
          error = true
          validate.fromDate = 'has-danger';
        }
        if (!notificationValidate.toDate || notificationValidate.toDate === 'has-danger') {
          error = true
          validate.toDate = 'has-danger';
        }
        if (!notificationValidate.subject || notificationValidate.subject === 'has-danger') {
          error = true
          validate.subject = 'has-danger';
        }
        if (!notificationValidate.body || notificationValidate.body === 'has-danger') {
          error = true
          validate.body = 'has-danger';
        }
      }
    } else {
      if (notificationValidate.scheduleName === 'has-danger') {
        error = true
        validate.scheduleName = 'has-danger';
      }
      if (notificationValidate.customerEmail === 'has-danger') {
        error = true
        validate.customerEmail = 'has-danger';
      }
      if (notificationValidate.frequency === 'has-danger') {
        error = true
        validate.frequency = 'has-danger';
      }
      if (notificationValidate.fromDate === 'has-danger') {
        error = true
        validate.fromDate = 'has-danger';
      }
      if (notificationValidate.toDate === 'has-danger') {
        error = true
        validate.toDate = 'has-danger';
      }
      if (notificationValidate.subject === 'has-danger') {
        error = true
        validate.subject = 'has-danger';
      }
      if (notificationValidate.body === 'has-danger') {
        error = true
        validate.body = 'has-danger';
      }
    }

    setNotificationValidate(validate)

    if (!error) {
      let formData = new FormData();
      let customerList = []
      let isSelectedAll = false
      customerEmail.map((v) => {
        customerList.push(v.value)
        if (v.value === 'ALL') {
          isSelectedAll = true
        }
      })

      formData.append("NotificationTypeID", 2);
      formData.append("ScheduleName", notificationData.scheduleName);
      formData.append("FrequencyID", notificationData.frequency);
      formData.append("FromDate", notificationData.fromDate);
      formData.append("ToDate", notificationData.toDate);
      formData.append("Subject", notificationData.subject);
      formData.append("Body", notificationData.body);
      formData.append("To", isSelectedAll ? 'ALL' : customerList);

      let res = ''
      if (notificationData.notificationSettingID) {
        formData.append("notificationSettingID", notificationData.notificationSettingID);
        res = await updateNotification(formData);
      } else {
        res = await createNotification(formData);
      }

      if (res) {
        await setAlerts({ type: 'success', message: notificationMessages.notificationSCheduleSuccess })
        await getNotificationList({ "NotificationTypeID": 2 })
      }
      reset(e)
    }
    setLoader(false);
  }

  const reset = (ev) => {
    ev.preventDefault();
    ref.current.reset();
    setNotificationData({});
    setNotificationValidate({})
    setCustomerEmail(null)
  }

  const getFrequencyList = () => {
    return [
      { label: 'Daily', value: 1 },
      { label: 'Weekly', value: 2 },
      { label: 'Week Day', value: 3 },
      { label: 'Week Off', value: 4 },
      { label: 'Monthly', value: 5 },
    ];
  };

  return (
    <>
      <Form className="form mt-4" innerRef={ref}>
        <Row>
          <Col lg={6} sm={12} md={6}>
            <FormGroup className="has-label customer-input-container">
              <label>Schedule Name *</label>
              <Input placeholder="Subject" type="text"
                valid={notificationValidate?.scheduleName === "has-success"}
                invalid={notificationValidate?.scheduleName === "has-danger"}
                value={notificationData?.scheduleName}
                onChange={(e) => {
                  let error = { ...notificationValidate }
                  if (!Common.verifyLength(e.target.value, 1)) {
                    error.scheduleName = 'has-danger';
                  } else {
                    error.scheduleName = 'has-success';
                  }
                  setNotificationValidate(error)
                  setNotificationData({ ...notificationData, scheduleName: e.target.value })
                }} maxLength={100} />
              <FormFeedback>
                Schedule name is required.
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <FormGroup className="customer-input-container">
              <Label for="payment">Customer List *</Label>
              <Input
                name="customerList"
                placeholder="customer list"
                type="hidden"
                valid={notificationValidate?.customerEmail === "has-success"}
                invalid={notificationValidate?.customerEmail === "has-danger"}
              />
              <Select
                isMulti
                name="customerList"
                className="basic-multi-select customer-custom-select"
                classNamePrefix="select"
                value={customerEmail}
                options={getCustomerList()}
                placeholder="Select Customers"
                onChange={onCustomerChange}
              />
              <FormFeedback>
                Select atleast one customer from list.
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6} sm={12} md={6}>
            <FormGroup className="customer-input-container">
              <Label for="frequency">Frequency *</Label>
              <Select
                id="frequency"
                name="frequency"
                className="basic-multi-select customer-custom-select"
                classNamePrefix="select"
                options={getFrequencyList()}
                valid={notificationValidate?.frequency === "has-success"}
                invalid={notificationValidate?.frequency === "has-danger"}
                value={notificationData?.frequency ? notificationData?.frequency : ""}
                onChange={(e) => {
                  let error = { ...notificationValidate }
                  if (!Common.verifyLength(e.target.value, 1)) {
                    error.frequency = 'has-danger';
                  } else {
                    error.frequency = 'has-success';
                  }
                  setNotificationValidate(error)
                  setNotificationData({ ...notificationData, frequency: e.target.value })
                }}
              />
              <FormFeedback>
                select any one frequency.
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <Row>
              <Col lg={6} sm={12} md={6}>
                <FormGroup className="customer-input-container">
                  <Label>From Date *</Label>
                  <Input
                    id="exampleDate"
                    name="date"
                    placeholder="from Date"
                    type="date"
                    valid={notificationValidate?.fromDate === "has-success"}
                    invalid={notificationValidate?.fromDate === "has-danger"}
                    value={notificationData?.fromDate}
                    onChange={(e) => {
                      let error = { ...notificationValidate }
                      if (!Common.verifyLength(e.target.value, 1)) {
                        error.fromDate = 'has-danger';
                      } else {
                        error.fromDate = 'has-success';
                      }
                      setNotificationValidate(error)
                      setNotificationData({ ...notificationData, fromDate: e.target.value })
                    }}
                  />
                  <FormFeedback>
                    From date is required.
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col lg={6} sm={12} md={6}>
                <FormGroup className="customer-input-container">
                  <Label>To Date *</Label>
                  <Input
                    id="exampleDate"
                    name="date"
                    placeholder="To Date"
                    type="date"
                    valid={notificationValidate?.toDate === "has-success"}
                    invalid={notificationValidate?.toDate === "has-danger"}
                    value={notificationData?.toDate}
                    onChange={(e) => {
                      let error = { ...notificationValidate }
                      if (!Common.verifyLength(e.target.value, 1)) {
                        error.toDate = 'has-danger';
                      } else {
                        error.toDate = 'has-success';
                      }
                      setNotificationValidate(error)
                      setNotificationData({ ...notificationData, toDate: e.target.value })
                    }}
                  />
                </FormGroup>
                <FormFeedback>
                  To date is required.
                </FormFeedback>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6} sm={12} md={6}>
            <FormGroup className="has-label customer-input-container">
              <label>Subject *</label>
              <Input placeholder="Subject" type="text"
                valid={notificationValidate?.subject === "has-success"}
                invalid={notificationValidate?.subject === "has-danger"}
                value={notificationData?.subject}
                onChange={(e) => {
                  let error = { ...notificationValidate }
                  if (!Common.verifyLength(e.target.value, 1)) {
                    error.subject = 'has-danger';
                  } else {
                    error.subject = 'has-success';
                  }
                  setNotificationValidate(error)
                  setNotificationData({ ...notificationData, subject: e.target.value })
                }} maxLength={100} />
              <FormFeedback>
                Subject is required.
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <FormGroup className="has-label customer-input-container">
              <label>Body *</label>
              <Input placeholder="Subject" type="textarea" className="textarea"
                rows="1"
                valid={notificationValidate?.body === "has-success"}
                invalid={notificationValidate?.body === "has-danger"}
                value={notificationData?.body ? notificationData?.body : ''}
                onChange={(e) => {
                  let error = { ...notificationValidate }
                  if (!Common.verifyLength(e.target.value, 1)) {
                    error.body = 'has-danger';
                  } else {
                    error.body = 'has-success';
                  }
                  setNotificationValidate(error)
                  setNotificationData({ ...notificationData, body: e.target.value })
                }} maxLength={100} />
              <FormFeedback>
                Body is required.
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row className="">
          <Col>
            <CustomButton label="Save Details" onClick={SMSNotificationSave}
            />
          </Col>
        </Row>
      </Form >
    </>
  );
};

export default NotificationSMSSchedule;
