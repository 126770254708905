import APIHandler from "helpers/APIHandler";
import { BILL_FILE_UPLOAD_TO_AZURE_API } from "helpers/APIRouter";
import { INVENTORY_FILE_UPLOAD_TO_AZURE_API } from "helpers/APIRouter";

const API = new APIHandler();

class AzureAPICall {

  inventoryFileUploadToAzure = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    param.isMultipart = true;
    await API.post(INVENTORY_FILE_UPLOAD_TO_AZURE_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  billFileUploadToAzure = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    param.isMultipart = true;
    await API.post(BILL_FILE_UPLOAD_TO_AZURE_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default AzureAPICall;
