import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import { useFeedbackStore } from "hooks/Feedback";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// reactstrap components
import {
  Label,
  Button,
  Card,
  Form,
  Input,
  Col,
  Row,
  FormGroup,
  Spinner,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ADMIN_URL_DASHBOARD } from "helpers/Paths";
import ChildLoader from "components/Loader/ChildLoader";

const DemandForecast = () => {
  const ref = useRef();
  const [overAllExperience, setOverAllExperience] = useState("Average");
  const [uiExperience, setUIExperience] = useState("Average");
  const [featureExperience, setFeatureExperience] = useState("Average");
  const [userComments, setUserComments] = useState("");
  const [loader, setLoader] = useState(false);
  const [gridLoader, setGridLoader] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const navigate = useNavigate();
  const [{}, { getFeedbackList }, { submitFeedback }] = useFeedbackStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const dt = useRef(null);

  return (
    <div className="content-dashboard-feedback">
      <h4 className="title">Preview - Demand Forecast</h4>
    </div>
  );
};

export default DemandForecast;
