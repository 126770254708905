import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Row, Col, FormGroup, Label } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AutoComplete } from "primereact/autocomplete";
import { useLocation } from "react-router-dom";
import { isEmpty } from "underscore";
import { useProductQytStore } from "hooks/ProductQyt";
import { useCreateBillStore } from "hooks/CreateBill";
import { useLoginStore } from "hooks/Login";
import PageLoader from "components/Loader/PageLoader";
import "assets/styles/Inventory.css";

const InventoryHistory = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const productNameFromQuery = params.get("productName");
  const productIDFromQuery = params.get("productID");
  const [{ getProductQytData }] = useProductQytStore();
  const [{}, { getProductSearchList }, {}] = useCreateBillStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [productQytDetails, setProductQytDetails] = useState([]);
  const [filteredProduct, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const debounceTimer = useRef(null);
  const [loader, setLoader] = useState(false);
  const dt = useRef(null);

  useEffect(() => {
    if (productNameFromQuery) {
      setSelectedProduct(productNameFromQuery);
    }
    if (productIDFromQuery) {
      getProductHistory(productIDFromQuery);
    }
  }, [productNameFromQuery, productIDFromQuery]);

  const filterProduct = (event) => {
    const query = event.query;

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      retriveProducts(query);
    }, 1000); // 1s delay
  };

  const retriveProducts = async (queryText) => {
    if (queryText.length >= 2) {
      let productList = await getProductSearchList(queryText);
      if (productList && !isEmpty(productList)) {
        setFilteredProducts(productList);
      } else {
        setFilteredProducts([
          {
            productName: (
              <span style={{ cursor: "none", padding: "70px" }}>
                no result found
              </span>
            ),
          },
        ]);
      }
    } else {
      setTimeout(() => {
        setAlerts({
          type: "danger",
          message: "Minimum 2 characters are needed for product search",
        });
      }, 2000);
    }
  };

  const getProductHistory = async (e) => {
    let productQytData = await getProductQytData({
      params: {
        ProductID: productIDFromQuery ? e : e?.productId,
      },
    });

    setProductQytDetails(productQytData);
    setLoader(false);
  };

  return (
    <div
      style={{
        padding: "20px",
        maxHeight: "600px", // Limit height to prevent outer scroll
        overflow: "hidden",
      }}
    >
      <h4 className="title">INVENTORY HISTORY</h4>
      <Row>
        <Col md="12">
          <Card style={{ height: "100%", overflow: "hidden" }}>
            <CardBody style={{ height: "100%", overflow: "hidden" }}>
              {/* Bill Hero Section */}
              <Row>
                <Col sm={12} md={8} lg={6}>
                  <FormGroup row>
                    <Label
                      for="productName"
                      sm={4}
                      style={{ fontSize: "16px" }}
                    >
                      Product Name:
                    </Label>
                    <Col sm={8}>
                      <AutoComplete
                        inputStyle={{
                          width: "250px",
                        }}
                        id="productName"
                        value={selectedProduct}
                        suggestions={filteredProduct}
                        completeMethod={filterProduct}
                        field="productName"
                        placeholder="Search by Product Name"
                        minLength={1}
                        onChange={(e) => {
                          setSelectedProduct(e.value);
                        }}
                        onSelect={(e) => {
                          setSelectedProduct(e.value);
                          getProductHistory(e.value);
                          setLoader(true);
                        }}
                        pt={{
                          panel: {
                            className: "autocomplete-Zindex",
                          },
                        }}
                        delay={300}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              {loader ? (
                <PageLoader />
              ) : (
                <>
                  {!selectedProduct && (
                    <p className="text-message" style={{ textAlign: "center" }}>
                      View transaction history: Search product by entering (2
                      characters) & selecting product
                    </p>
                  )}

                  {selectedProduct &&
                    productQytDetails &&
                    isEmpty(productQytDetails) && (
                      <p
                        className="text-message"
                        style={{ textAlign: "center" }}
                      >
                        No inventory history available for selected product.
                      </p>
                    )}

                  {selectedProduct &&
                    productQytDetails &&
                    !isEmpty(productQytDetails) && (
                      <div
                        style={{
                          maxWidth: "100%",
                        }}
                      >
                        <DataTable
                          scrollHeight="24rem"
                          ref={dt}
                          stripedRows
                          value={productQytDetails ?? []}
                          dataKey="productID"
                          tableStyle={{ minWidth: "25rem" }} // Ensure horizontal scrolling if content overflows
                          size="large"
                          resizableColumns
                          columnResizeMode="expand"
                          paginator
                          rowsPerPageOptions={[10, 50, 100]}
                          rows={100}
                          pt={{
                            root: { className: "table" },
                          }}
                        >
                          <Column
                            field="billID"
                            align="center"
                            header="Bill ID"
                          ></Column>
                          <Column
                            field="productID"
                            align="center"
                            header="Product ID"
                          ></Column>
                          <Column
                            field="productActivityDescription"
                            align="center"
                            header="Activity Type"
                          ></Column>
                          <Column
                            field="billCreatedDateTime"
                            align="center"
                            header="Activity Date Time"
                          ></Column>
                          <Column
                            field="selledQTY"
                            align="center"
                            header="Quantity Change (+/-)"
                          ></Column>
                          <Column
                            field="availableQtyAfterSell"
                            align="center"
                            header="Net Available Quantity (at this time)"
                          ></Column>
                        </DataTable>
                      </div>
                    )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default InventoryHistory;
