import CustomButton from "components/Button/CustomButton";
import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import { useNotificationStore } from "hooks/Notification";
import React, { useRef, useState } from "react";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  Col,
  Row,
  FormGroup,
  Spinner,
  FormText,
  CardTitle,
  Container
} from "reactstrap";

const Email = () => {
  const ref = useRef();

  const [{ }, { sendEmail }, { }] = useNotificationStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();

  const [toEmail, setToEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [emailToState, setEmailToState] = useState("");
  const [emailSubjectState, setEmailSubjectState] = useState("");
  const [emailBodyState, setEmailBodyState] = useState("");
  const [fileUploadErr, setFileUploadErr] = useState("");
  const [loader, setLoader] = useState(false);

  const onFileChange = event => {
    setSelectedFile(event.target.files[0])
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let error = false
    let fileExtention = selectedFile?.name.split('.')[1].toLowerCase()
    let fileSize = selectedFile.size
    let maxSize = 2000000
    if (emailToState === "" || emailToState === "has-danger") {
      setEmailToState("has-danger");
      error = true
    }
    if (emailSubjectState === "" || emailSubjectState === "has-danger") {
      error = true
      setEmailSubjectState("has-danger");
    }
    if (emailBodyState === "" || emailBodyState === "has-danger") {
      error = true
      setEmailBodyState("has-danger");
    }
    if (fileExtention !== "jpg" && fileExtention !== "jpeg" && fileExtention !== "png" && fileExtention !== "txt" && fileExtention !== "pdf") {
      error = true
      setFileUploadErr("Please select correct extention file");
    } else {
      setFileUploadErr("");
    }
    if (fileSize > maxSize) {
      error = true
      setFileUploadErr("Maximum 2 mb file size is allowed.");
    }


    if (!error) {
      let formData = new FormData();
      formData.append("EmailToAddress", toEmail);
      formData.append("EmailSubject", subject);
      formData.append("EmailBody", body);
      formData.append("files", selectedFile);
      let res = await sendEmail(formData);
      if (res) {
        await setAlerts({ type: 'success', message: notificationMessages.sendEMAILSuccess })
        ref.current.reset();
      }
    }
    setLoader(false);
  }

  return (
    <div className="customer-content-container">
      <div className="customer-content-title">
        <h5>Send Email</h5>
      </div>
      <Form className="form" onSubmit={handleSubmit} innerRef={ref}>
        <FormGroup className={`has-label customer-input-container ${emailToState}`}>
          <label>To *</label>
          <Input
            placeholder="Email To Address"
            type="text"
            onChange={(e) => {
              if (!Common.verifyEmail(e.target.value)) {
                setEmailToState("has-danger");
              } else {
                setEmailToState("has-success");
              }
              setToEmail(e.target.value);
            }}
            maxLength={100}
          />
          {emailToState === "has-danger" ? (
            <label className="error">
              Please enter valid email.
            </label>
          ) : null}
        </FormGroup>
        <FormGroup className={`has-label customer-input-container ${emailSubjectState}`}>
          <label>Subject *</label>
          <Input
            placeholder="Email Subject"
            type="text"
            onChange={(e) => {
              if (!Common.verifyLength(e.target.value, 1)) {
                setEmailSubjectState("has-danger");
              } else {
                setEmailSubjectState("has-success");
              }
              setSubject(e.target.value);
            }}
            maxLength={100}
          />
          {emailSubjectState === "has-danger" ? (
            <label className="error">
              Subject is required.
            </label>
          ) : null}
        </FormGroup>
        <FormGroup className={`has-label customer-input-container ${emailBodyState}`}>
          <label>Body *</label>
          <Input
            className="textarea"
            type="textarea"
            rows="4"
            placeholder="Type your email here.."
            onChange={(e) => {
              if (!Common.verifyLength(e.target.value, 1)) {
                setEmailBodyState("has-danger");
              } else {
                setEmailBodyState("has-success");
              }
              setBody(e.target.value);
            }}
            maxLength={200}
          />
          {emailBodyState === "has-danger" ? (
            <label className="error">Email Body is required.</label>
          ) : null}
        </FormGroup>
        {/* file */}
        <FormGroup className={'has-label  customer-input-container' + fileUploadErr ? 'has-danger' : ''}>
          <label>File</label>
          <div className="upload-file-btn" style={{ maxWidth: "250px" }}>
            <Button
              style={{
                height: "100%",
                width: "50%",
                display: "inline-block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                position: "relative",
                borderRadius: "10px 0 0 10px",
              }}
              className="custom-button-secondary"
            >
              <input
                onChange={onFileChange}
                accept="image/jpg, image/jpeg, image/png ,application/pdf,.txt"
                type="file"
                name="file"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
              <span style={{ color: "white", fontFamily: "'DM Sans', sans-serif" }}>Choose File</span>
            </Button>
            <div
              className="upload-file-name"
            >
              {fileUploadErr || "Choose File"}
            </div>
          </div>
          {/* <Input type="file" name="file" id="exampleFile" onChange={onFileChange} accept="image/jpg, image/jpeg, image/png ,application/pdf,.txt" />
          {fileUploadErr !== '' ? (
            <label className="error">{fileUploadErr}</label>
          ) : null} */}
          <FormText color="muted">
            Supported formats: jpg, jpeg, png, pdf, txt <br></br>
            Max file Size: 2mb
          </FormText>
        </FormGroup>
        <CustomButton
          type="submit"
          disabled={loader ? true : false}
          label={loader ? <Spinner size="sm" color="secondary" style={{ marginLeft: '15px' }} /> : "Send Email"} />
      </Form>
    </div>
  );
};

export default Email;
