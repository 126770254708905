import ActivityRepository from "repository/ActivityRepository";

export function useActivityStore() {

  let data = {};
  let fetch = {
    /**
     * Here is list of fetch methods which are used for get data from API or Repositories related to Activity
     * Author: Devanshi Patel
     * dispatch is used for reducer or thunk method
     * if redux not needed use repository method
     */
    getActivityLogList: async (params) => {
      return await new ActivityRepository().getActivityLogList(params);
    },
  };
  let set = {
  };
  return [data, fetch, set];
}
