import React, { useEffect, useState } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, useLocation } from "react-router-dom";
import app_logo from "assets/img/new-logo-with-text-dark.svg";
import { FaEnvelope, FaLinkedin } from "react-icons/fa6";
import { IoCall, IoLogoWhatsapp } from "react-icons/io5";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Tooltip,
} from "reactstrap";
import routes from "routes.js";
import Login from "views/Login/Login";
import "../assets/styles/Boxes.css";
import "../assets/styles/MultiStepRegister.css";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import FixedPlugin from "components/FixedPlugin/FixedPlugin";

var ps;

function Pages() {
  const fullPages = React.useRef();
  const location = useLocation();
  const [videoLink, setVideoLink] = useState("https://youtu.be/pkBN--VFc6A");
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(fullPages.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (location.pathname === "/auth/login") {
      setVideoLink("https://youtu.be/gzhnaxoL4iw");
    } else {
      setVideoLink("https://youtu.be/pkBN--VFc6A");
    }
  }, [location.pathname]);

  const [tooltipOpen, setTooltipOpen] = useState({
    phone: false,
    email: false,
    whatsapp: false,
    linkedin: false,
  });

  const toggleTooltip = (tooltip) => {
    setTooltipOpen({ ...tooltipOpen, [tooltip]: !tooltipOpen[tooltip] });
  };

  return (
    <>
      {/* <AuthNavbar /> */}

      <div className="" ref={fullPages}>
        <div className="container-box-main page">
          <div className="login-container">
            <Container fluid className="bg-transparent">
              <Row>
                <Col lg={10} xl={9} className="mx-auto">
                  <Card className="d-flex overflow-hidden shadow my-5">
                    <CardTitle className="px-4 py-2 text-light login-header">
                      <Row>
                        <Col
                          lg={8}
                          md={6}
                          xs={12}
                          className="login-header-title"
                        >
                          Have trouble{" "}
                          {location.pathname === "/auth/login"
                            ? "logging in"
                            : "signing up"}
                          ?&nbsp;
                          <span>
                            <a href={videoLink} target="_blank">
                              Watch this video
                            </a>
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          className="d-none d-md-flex align-items-center justify-content-end"
                          style={{ gap: "2rem" }}
                        >
                          <a
                            href="tel:+917434000130"
                            target="_blank"
                            id="phoneTooltip"
                            className="social-media-icons"
                          >
                            <IoCall className="phone-icon" />
                          </a>
                          <Tooltip
                            autohide={true}
                            flip={true}
                            placement="top"
                            isOpen={tooltipOpen.phone}
                            target="phoneTooltip"
                            toggle={() => toggleTooltip("phone")}
                            style={{
                              backgroundColor: "#000000",
                              color: "#ffffff",
                            }}
                          >
                            +917434000130
                          </Tooltip>

                          <a
                            href="mailto:admin@apsthreeai.ai"
                            target="_blank"
                            id="emailTooltip"
                            className="social-media-icons"
                          >
                            <FaEnvelope className="email-icon" />
                          </a>
                          <Tooltip
                            autohide={true}
                            flip={true}
                            placement="top"
                            isOpen={tooltipOpen.email}
                            target="emailTooltip"
                            toggle={() => toggleTooltip("email")}
                            style={{
                              backgroundColor: "#000000",
                              color: "#ffffff",
                            }}
                          >
                            admin@apsthreeai.ai
                          </Tooltip>

                          <a
                            href="https://wa.me/7434000130"
                            target="_blank"
                            id="whatsappTooltip"
                            className="social-media-icons"
                          >
                            <IoLogoWhatsapp className="whatsapp-icon" />
                          </a>
                          <Tooltip
                            autohide={true}
                            flip={true}
                            placement="top"
                            isOpen={tooltipOpen.whatsapp}
                            target="whatsappTooltip"
                            toggle={() => toggleTooltip("whatsapp")}
                            style={{
                              backgroundColor: "#000000",
                              color: "#ffffff",
                            }}
                          >
                            +917434000130
                          </Tooltip>

                          <a
                            href="https://www.linkedin.com/in/apsthreeaillp/"
                            target="_blank"
                            id="linkedinTooltip"
                            className="social-media-icons"
                          >
                            <FaLinkedin className="linkedin-icon" />
                          </a>
                          <Tooltip
                            autohide={true}
                            flip={true}
                            placement="top"
                            isOpen={tooltipOpen.linkedin}
                            target="linkedinTooltip"
                            toggle={() => toggleTooltip("linkedin")}
                            style={{
                              backgroundColor: "#000000",
                              color: "#ffffff",
                            }}
                          >
                            APS Three AI LLP LinkedIn
                          </Tooltip>
                        </Col>
                      </Row>
                    </CardTitle>
                    <CardBody style={{ paddingTop: "0", paddingBottom: "0" }}>
                      <Row>
                        <Col
                          xs={12}
                          md={5}
                          className="auth-form-container"
                          style={{
                            justifyContent: "start",
                          }}
                        >
                          <div className="company-title-container">
                            <a href="https://apsthreeai.ai/" target="_blank">
                              {/* <img
                                src={app_logo}
                                alt="APS THREEAI"
                                className="app-logo"
                              /> */}
                              <img
                                src={`${ERP_CDN_IMAGE_URL}/new-logo-with-text-dark.svg`}
                                onError={(e) => {
                                  e.target.onerror = null; // Prevent infinite loop if logo also fails
                                  e.target.src = app_logo;
                                }}
                                alt="APS THREEAI"
                              />
                            </a>
                          </div>
                          <Routes>
                            {getRoutes(routes)}
                            <Route path="*" element={<Login />} />
                          </Routes>
                        </Col>
                        <Col
                          xs={12}
                          md={7}
                          className="auth-banner-container d-none d-md-block"
                        >
                          <Row className="auth-banner-title-row">
                            <Col xs={9} md={9} lg={9}>
                              <h3 className="login-heading">
                                Manage all our Financial operation of your store
                              </h3>
                            </Col>
                            <Col xs={7} md={7} lg={7}>
                              <p className="login-para">
                                Try our ERP and see how you can get all your
                                task done seamlessly
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="auth-banner"
                            ></Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <FixedPlugin />
    </>
  );
}

export default Pages;
