import React from 'react'

const PlusMinusButton = ({ plusBtnClickHandler, minusBtnClickHandler, ...props }) => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <button className="minus-chip-btn" onClick={minusBtnClickHandler}>-</button>
            <input className="custom-chip-input" {...props} />
            <button className="plus-chip-btn" onClick={plusBtnClickHandler}>+</button>
        </div>
    )
}

export default PlusMinusButton