import { useCompanyStore } from "hooks/Company";
import { useLoginStore } from "hooks/Login";
import React, { useEffect } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { AUTH_URL_LOGIN, ADMIN_URL_DASHBOARD } from "helpers/Paths";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Common from "helpers/Common";
import { isEmpty } from "underscore";
import CustomInput from "components/Input/CustomInput";

const FinishInput = ({
  handleNext,
  otpVerify,
  setOtpVerify,
  formData,
  setFormData,
  submitHandler,
}) => {
  const [{}, {}, { setAlerts }] = useLoginStore();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      {/* <div className="header">
        <div className="verification-text">4. Password Details</div>
        <div className="step-indicator">Step 4 - 4</div>
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <FormGroup className="has-label">
          <Label>Login ID *</Label>
          <CustomInput
            autoFocus={true}
            placeholder="example@mail.com"
            type="text"
            name="loginID"
            id="loginID"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={100}
          />
          {/* <Input
            placeholder="example@mail.com"
            type="text"
            name="loginID"
            id="loginID"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={100}
          /> */}
        </FormGroup>

        <FormGroup className="has-label">
          <Label>Password *</Label>
          <CustomInput
            placeholder="Enter Password"
            type="password"
            name="loginPassword"
            id="loginPassword"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={15}
          />
          {/* <Input
            placeholder="Enter Password"
            type="password"
            name="loginPassword"
            id="loginPassword"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={15}
          /> */}
        </FormGroup>

        <FormGroup className="has-label">
          <label>Confirm Password *</label>
          <CustomInput
            placeholder="Enter Password Again"
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={15}
          />
          {/* <Input
            placeholder="Enter Password Again"
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={15}
          /> */}
        </FormGroup>
      </div>
      <button className="custom-login-btn" onClick={submitHandler}>
        Finish
      </button>
    </>
  );
};

export default FinishInput;
