import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
  Row,
  Col,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import Select from "react-select";
import { useInventoryStore } from "hooks/Inventory";
import { useLoginStore } from "hooks/Login";
import { isEmpty, isObject } from "underscore";
import { notificationMessages } from "helpers/constantMessages";
import { useCreateBillStore } from "hooks/CreateBill";
import { AutoComplete } from "primereact/autocomplete";
import Common from "helpers/Common";
import { ADMIN_URL_DASHBOARD } from "helpers/Paths";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "components/Loader/PageLoader";
import Placeholder from "../../assets/images/placeholder.jpeg";
import { useAzureStore } from "hooks/Azure";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

const EditInventory = ({ setFormChanged, productNameQtyModify }) => {
  const { productName } = useParams();

  const [{}, {}, { createModifyInventory }] = useInventoryStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [categorySelect, setCategorySelect] = useState(null);
  const [subCategorySelect, setSubCategorySelect] = useState(null);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [isCategoryupdated, setisCategoryupdated] = useState(false);
  const [isSubCategoryupdated, setisSubCategoryupdated] = useState(false);

  const [inventoryData, setInventoryData] = useState({});
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [filteredProduct, setFilteredProducts] = useState([]);
  const [{}, { getSubCategoryList, getCategoryList }, {}] = useInventoryStore();
  const [{}, { getProductSearchList }, {}] = useCreateBillStore();
  const [inventoryValidate, setInventoryValidate] = useState({});

  const [file, setFile] = useState(null);
  const imageUrls = useRef([]);
  const [productImageUrl, setProductImageUrl] = useState(null);
  const [fileName, setFileName] = useState("");

  const [isProductSelected, setisProductSelected] = useState(false);

  const [buyPrice, setbuyPrice] = useState(0);
  const [netBuyPrice, setNetBuyPrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [activeStep, setactiveStep] = useState(0);
  const [appDisc, setAppDisc] = useState("");
  const discountTypeRef = useRef();
  const [{}, { fetchImagesFromAzure }, { inventoryFileUploadToAzure }] =
    useAzureStore();
  const [displayMsg, setDisplayMsg] = useState("");
  const ref = useRef();
  const debounceTimer = useRef(null);
  const categoriesRef = useRef([]);

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    if (productName !== undefined) {
      retriveProducts(productName);
      setSelectedProductName(productName);
    }
  }, [productName]);

  useEffect(() => {
    if (productNameQtyModify) {
      retriveProducts(productNameQtyModify);
      setSelectedProductName(productNameQtyModify);
    }
  }, [productNameQtyModify]);

  function formatDate(date) {
    let inputDate = new Date(date);
    let year = inputDate.getFullYear();
    let month = String(inputDate.getMonth() + 1).padStart(2, "0");
    let day = String(inputDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const getCategory = async () => {
    let categoryList = await getCategoryList();
    let list =
      categoryList &&
      !isEmpty(categoryList).length > 0 &&
      categoryList.map((prop, key) => {
        return {
          value: prop.categoryID,
          label: prop.categoryName,
        };
      });
    categoriesRef.current = list;
    setCategory(list);
  };

  const getSubCategory = async (val) => {
    let param = {
      categoryIds: val?.value.toString(),
    };
    let subCategoryList = await getSubCategoryList(param);
    let list =
      subCategoryList &&
      !isEmpty(subCategoryList).length > 0 &&
      subCategoryList?.map((prop, key) => {
        return {
          value: prop.subCategoryID,
          label: prop.subCategoryName,
        };
      });
    setSubCategory(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    let error = false;
    let validate = { ...inventoryValidate };

    if (categorySelect == null) {
      error = true;
      validate.category = "has-danger";
    }
    if (subCategorySelect == null) {
      error = true;
      validate.subCategory = "has-danger";
    }
    if (
      inventoryData?.productName == "" ||
      inventoryData?.productName == null
    ) {
      error = true;
      validate.productName = "has-danger";
    }
    if (!inventoryData?.totalQty) {
      error = true;
      validate.quantity = "has-danger";
    }
    if (inventoryData?.mrp == "" || inventoryData?.mrp == null) {
      error = true;
      validate.mrp = "has-danger";
    }

    if (!selectedProduct || selectedProduct <= 0) {
      error = true;
      validate.selectedProduct = "has-danger";
    }

    if (error) {
      alert("Please fill out required fields in previous steps.");
      return;
    }

    setInventoryValidate(validate);
    if (!error) {
      setLoader(true);
      if (inventoryData && isObject(inventoryData) && !isEmpty(inventoryData)) {
        let param = {
          productId: selectedProduct,
          productName: inventoryData?.productName,
          categoryID: isCategoryupdated
            ? categorySelect[0]?.value
            : categorySelect[0]?.value,
          subCategoryId: subCategorySelect?.value,
          brand: inventoryData?.brand,
          upC_Code: inventoryData?.upcCode,
          sku: inventoryData?.sku,
          measure: inventoryData?.measure,
          quantity: inventoryData?.totalQty,
          un_used_quantity: inventoryData?.un_used_quantity,
          maximumRetailPrice: inventoryData?.mrp,
          discountPercentage:
            discountTypeRef.current == "Percentage"
              ? inventoryData?.discount
              : 0,
          discountFixed:
            discountTypeRef.current == "Fixed" ? inventoryData?.discount : 0,
          applicableDiscountAmount: appDisc == "" ? null : appDisc,
          expiryDate:
            inventoryData?.expiryDate == "" ? null : inventoryData?.expiryDate,
          mrpDiscountAmount:
            inventoryData?.ubTotal == "" ? 0 : inventoryData?.ubTotal,
          gstRate: inventoryData?.gst == "" ? 0 : inventoryData?.gst,
          igstRate: inventoryData?.igst == "" ? 0 : inventoryData?.igst,
          productDescription: inventoryData?.description,

          reorder_point: inventoryData?.reorder_point,
          lead_time: inventoryData?.lead_time,
          taxAmount: inventoryData?.taxAmount,

          cgst: inventoryData?.cgst == "" ? 0 : inventoryData?.cgst,
          sgst: inventoryData?.sgst == "" ? 0 : inventoryData?.sgst,
        };
        if (fileName || inventoryData?.productImageName) {
          param.productImageName = fileName
            ? fileName
            : inventoryData?.productImageName;
        }
        let res = await createModifyInventory(param);
        setLoader(false);
        if (res?.isSuccess == true) {
          await setAlerts({
            type: "success",
            message: notificationMessages.updateInventorySuccess,
          });

          // file upload section starts
          if (file != null) {
            let formData = new FormData();
            formData.append("formFile", file);
            await inventoryFileUploadToAzure(formData);
          }
          // file upload section ends
        }
        if (res?.isSuccess == false) {
          await setAlerts({
            type: "danger",
            message: res?.message,
          });
        }
      }
      reset(e);
    }
  };

  const reset = (ev) => {
    ev.preventDefault();
    // ref.current.reset();
    setInventoryData({});
    setCategorySelect(null);
    setSubCategorySelect(null);
    setInventoryValidate({});
    setFile(null);
    setFileName("");
    discountTypeRef.current = "Fixed";
    setSelectedProduct(0);
    setProductImageUrl(null);
    setSelectedProductName("");
    setbuyPrice(0);
    setNetBuyPrice(0);
    setTotalAmount(0);
    setactiveStep(0);
    setFormChanged(false); // Reset form changed state
  };

  const close = () => {
    navigate(ADMIN_URL_DASHBOARD);
  };

  const handleCategorySelect = (val) => {
    setSubCategorySelect(null);
    getSubCategory(val);
  };

  const uploadProduct = (e) => {
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      alert("Please select image size lesser than 2MB.");
      e.target.value = "";
    } else {
      let fileName =
        Common.getCompanyId() +
        "_" +
        Date.now() +
        "." +
        e.target.files[0].name.split(".")[1];
      setFileName(fileName);
      setFile(new File([e.target.files[0]], fileName));
      setProductImageUrl(null);
    }
  };

  //fetch images from azure
  const fetchImages = async (imageName) => {
    let imgURL = await fetchImagesFromAzure(imageName, "inventory");
    setProductImageUrl(imgURL);
  };

  const preventNegativeValues = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const retriveProducts = async (queryText) => {
    if (queryText.length >= 2) {
      let productList = await getProductSearchList(queryText);
      setFilteredProducts(productList);

      if (productNameQtyModify && productList.length > 0) {
        onSelectProduct(productList[0]);
      }
    }
  };

  const filterProduct = (event) => {
    const query = productNameQtyModify || event.query;

    // Clear the existing timer if any
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer
    debounceTimer.current = setTimeout(() => {
      retriveProducts(query);
    }, 300); // 300ms delay
  };

  const onSelectProduct = async (val) => {
    setLoader(true);
    setisProductSelected(true);
    // Determine category ID based on whether productNameQtyModify is provided
    let param = {
      categoryIds: val?.categoryId.toString(),
    };

    // Fetch subcategories
    let subCategoryList = await getSubCategoryList(param);
    let list =
      subCategoryList && subCategoryList.length > 0
        ? subCategoryList.map((prop) => ({
            value: prop.subCategoryID,
            label: prop.subCategoryName,
          }))
        : [];

    setSubCategory(list);

    setSubCategorySelect({
      value: val?.subCategoryId,
      label: val?.subCategoryName,
    });

    // Filter and set selected category
    let categorySaved = categoriesRef.current.filter(
      (d) => d.value === val.categoryId
    );
    setCategorySelect(categorySaved);

    // Determine discount type
    discountTypeRef.current =
      val?.discountFixed > 0
        ? "Fixed"
        : val?.discountPercentage > 0
        ? "Percentage"
        : "Fixed";

    let calculatedDisc =
      val?.discountFixed > 0 ? val?.discountFixed : val?.discountPercentage;

    // Populate inventory details
    let inventoryDetails = {
      productName: val?.productName,
      brand: val?.brand,
      upcCode: val?.upc_code,
      sku: val?.sku,
      measure: val?.measure,
      quantity: val?.available_qty,
      un_used_quantity: val?.un_used_quantity,
      addQty: 0,
      totalQty: val?.available_qty,
      mrp: val?.maximumRetailPrice,
      discount: calculatedDisc,
      expiryDate: formatDate(val?.expiryDate),
      gstRate: val?.gstRate,
      igst: val?.igstRate,
      description: val?.productDescription,
      productImageName: val?.productImageName,
      discountPercentage: val?.discountPercentage,
      discountFixed: val?.discountFixed,
      applicableDiscountAmount:
        val?.discountPercentage > 0
          ? (calculatedDisc * val?.maximumRetailPrice) / 100
          : val?.applicableDiscountAmount,
      lead_time: val?.lead_time,
      reorder_point: val?.reorder_point,
      cgst: val?.cgst,
      sgst: val?.sgst,
      gst: val?.gstRate,
      taxAmount: val?.taxAmount,
      unitItems: val?.unitItems,
    };
    setAppDisc(
      val?.discountPercentage > 0
        ? (calculatedDisc * val?.maximumRetailPrice) / 100
        : val?.applicableDiscountAmount
    );
    setInventoryData(inventoryDetails);
    setSelectedProduct(val?.productId);
    setSelectedProductName(val?.productName);

    // Calculate prices
    let percentageApplicableDiscount =
      val?.discountPercentage > 0
        ? (calculatedDisc * val?.maximumRetailPrice) / 100
        : 0;
    let buyP =
      parseFloat(val?.maximumRetailPrice).toFixed(2) -
      parseFloat(
        val?.discountPercentage > 0
          ? percentageApplicableDiscount
          : val?.applicableDiscountAmount
      ).toFixed(2);
    let cgstP = (buyP * 7) / 100;
    let sgstP = (buyP * 9) / 100;
    let gstP = (buyP * 5) / 100;
    let totaltaxP = cgstP + sgstP + gstP;
    let netBuyP = buyP + totaltaxP;
    let totalAmountP =
      netBuyP.toFixed(2) * parseInt(val?.available_qty).toFixed(2);

    setbuyPrice(buyP.toFixed(2));
    setNetBuyPrice(netBuyP.toFixed(2));
    setTotalAmount(totalAmountP.toFixed(2));

    fetchImages(val?.productImageName);
    setLoader(false);
  };

  const calculateTaxAndDiscount = () => {
    if (inventoryData?.quantity != "" && inventoryData?.mrp != "") {
      let finalDiscAmount = 0;
      if (discountTypeRef.current == "Fixed") {
        let fixDisc = 0;
        fixDisc = inventoryData?.discount;
        setInventoryData({
          ...inventoryData,
          applicableDiscountAmount: fixDisc,
        });
        setAppDisc(fixDisc);
        calculateTaxAndFinalAmount(parseFloat(inventoryData?.discount));
      }
      if (discountTypeRef.current == "Percentage") {
        finalDiscAmount = (inventoryData?.discount * inventoryData?.mrp) / 100;
        setInventoryData({
          ...inventoryData,
          applicableDiscountAmount: finalDiscAmount,
        });
        setAppDisc(finalDiscAmount);
        calculateTaxAndFinalAmount(parseFloat(finalDiscAmount));
      }
    } else {
      setDisplayMsg(
        "Please enter Quantity/MRP/Discount to calculate Tax/Discounts."
      );
    }
  };

  const calculateTaxAndFinalAmount = (finalDiscAmount) => {
    let buyP;
    let cgstP;
    let sgstP;
    let gstP;
    let totaltaxP;
    let netBuyP;
    let totalAmountP;

    buyP =
      parseFloat(inventoryData?.mrp).toFixed(2) -
      parseFloat(finalDiscAmount).toFixed(2);
    cgstP = (buyP * 7) / 100;
    sgstP = (buyP * 9) / 100;
    gstP = (buyP * 5) / 100;
    totaltaxP = cgstP + sgstP + gstP;
    netBuyP = buyP + totaltaxP;
    totalAmountP =
      netBuyP.toFixed(2) * parseInt(inventoryData?.totalQty).toFixed(2);

    setInventoryData({
      ...inventoryData,
      cgst: cgstP.toFixed(2),
      sgst: sgstP.toFixed(2),
      gst: gstP.toFixed(2),
      taxAmount: totaltaxP.toFixed(2),
    });
    setbuyPrice(buyP.toFixed(2));
    setNetBuyPrice(netBuyP.toFixed(2));
    setTotalAmount(totalAmountP.toFixed(2));
  };

  const today = new Date().toISOString().split("T")[0];

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
    setFormChanged(true); // Notify parent of form change
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <Form className="form" onSubmit={handleSubmit} innerRef={ref}>
          <FormWizard
            stepSize="sm"
            backButtonTemplate={(handlePrevious) => (
              <Button
                // style={{ display: activeStep == 2 ? "none" : "inline" }}
                style={
                  activeStep == 2
                    ? {
                        marginTop: "-62px",
                        position: "absolute",
                        left: "310px",
                      }
                    : null
                }
                color="info"
                className=""
                size="md"
                onClick={() => {
                  handlePrevious();
                  setactiveStep(activeStep - 1);
                }}
              >
                Previous
              </Button>
            )}
            nextButtonTemplate={(handleNext) => (
              <Button
                style={{ display: activeStep == 2 ? "none" : "inline" }}
                color="info"
                className=""
                size="md"
                onClick={() => {
                  if (activeStep == 0) {
                    if (
                      categorySelect != null &&
                      subCategorySelect != null &&
                      inventoryData.productName != ""
                    ) {
                      handleNext();
                      setactiveStep(activeStep + 1);
                    } else {
                      setAlerts({
                        type: "info",
                        message: "Please enter required fields.",
                      });
                    }
                  }
                  if (activeStep == 1) {
                    if (
                      inventoryData?.totalQty != 0 &&
                      inventoryData?.totalQty != null &&
                      inventoryData?.mrp != 0 &&
                      inventoryData?.mrp != null
                    ) {
                      handleNext();
                      setactiveStep(activeStep + 1);
                    } else {
                      setAlerts({
                        type: "info",
                        message: "Please enter required fields.",
                      });
                    }
                  }
                }}
              >
                Next
              </Button>
            )}
            finishButtonTemplate={(handleComplete) => (
              <Button style={{ display: "none" }}></Button>
            )}
          >
            <FormWizard.TabContent title="Product Details">
              <Row xs="2">
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Product ID</Label>

                    <Input
                      name="productId"
                      placeholder="product id"
                      type="hidden"
                      valid={
                        inventoryValidate?.selectedProduct === "has-success"
                      }
                      invalid={
                        inventoryValidate?.selectedProduct === "has-danger"
                      }
                    />
                    <AutoComplete
                      autoFocus
                      className="custom-autocomplete"
                      value={selectedProductName}
                      suggestions={filteredProduct}
                      completeMethod={filterProduct}
                      field="productName"
                      placeholder="Search by Product Name - Min. 2 chars"
                      minLength={1}
                      onChange={(e) => {
                        setSelectedProductName(e.value);
                        handleInputChange(e, setSelectedProductName);
                      }}
                      onSelect={(e) => {
                        let error = { ...inventoryValidate };
                        error.selectedProduct = "has-success";
                        setInventoryValidate(error);
                        onSelectProduct(e.value);
                      }}
                      pt={{
                        panel: {
                          className: "autocomplete-Zindex",
                        },
                      }}
                      delay={300}
                    />
                    <FormFeedback>
                      {" "}
                      Product selection is required when existing option select.
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Category *</Label>

                    <Input
                      disabled={isProductSelected == false ? true : false}
                      name="category"
                      placeholder="Select Category"
                      type="hidden"
                    />
                    <Select
                      isDisabled={isProductSelected == false ? true : false}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Category"
                      name="category"
                      value={categorySelect}
                      options={category}
                      onChange={(e) => {
                        let error = { ...inventoryValidate };
                        setInventoryValidate(error);
                        setCategorySelect(e);
                        handleCategorySelect(e);
                        setisCategoryupdated(true);
                      }}
                    />
                    <FormFeedback>Category is required.</FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Sub Category *</Label>

                    <Input
                      disabled={isProductSelected == false ? true : false}
                      name="subcategory"
                      placeholder="Select Sub Category"
                      type="hidden"
                    />
                    <Select
                      isDisabled={isProductSelected == false ? true : false}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Sub Category"
                      name="subcategory"
                      value={subCategorySelect}
                      options={subCategory}
                      onChange={(e) => {
                        let error = { ...inventoryValidate };
                        setInventoryValidate(error);
                        setSubCategorySelect(e);
                        setisSubCategoryupdated(true);
                      }}
                    />
                    <FormFeedback>Sub Category is required.</FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Product Name *</Label>

                    <Input
                      disabled={isProductSelected == false ? true : false}
                      placeholder="Enter product name"
                      type="text"
                      value={inventoryData?.productName}
                      onChange={(e) => {
                        let error = { ...inventoryValidate };
                        if (inventoryData.productName) {
                          error.productName = "has-danger";
                        } else {
                          error.productName = "has-success";
                        }
                        setInventoryValidate(error);
                        setInventoryData({
                          ...inventoryData,
                          productName: e.target.value,
                        });
                      }}
                    />
                    <FormFeedback>Product name is required.</FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Brand</Label>

                    <Input
                      disabled={isProductSelected == false ? true : false}
                      maxLength={25}
                      value={inventoryData?.brand}
                      placeholder="Enter Brand"
                      type="text"
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          brand: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>UPC Code</Label>
                    <Input
                      disabled={isProductSelected == false ? true : false}
                      placeholder="Enter UPC Code"
                      type="text"
                      value={inventoryData?.upcCode}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          upcCode: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>SKU</Label>
                    <Input
                      disabled={isProductSelected == false ? true : false}
                      placeholder="Enter SKU"
                      type="text"
                      value={inventoryData?.sku}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          sku: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Measure</Label>
                    <Input
                      disabled={isProductSelected == false ? true : false}
                      placeholder="Enter Measure"
                      type="text"
                      value={inventoryData?.measure}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          measure: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormWizard.TabContent>
            <FormWizard.TabContent title="Product Totals">
              <Row xs="2">
                <Col className="" xs={12} md={4} lg={2}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Quantity *</Label>

                    <Input
                      // disabled={isProductSelected == false ? true : false}
                      disabled
                      readOnly
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      name="quantity"
                      placeholder="Enter Quantity"
                      valid={inventoryValidate?.quantity === "has-success"}
                      invalid={inventoryValidate?.quantity === "has-danger"}
                      value={inventoryData?.quantity}
                      // onBlur={() => calculateTaxAndDiscount()}
                      onChange={(e) => {
                        let error = { ...inventoryValidate };
                        if (!Common.verifyLength(e.target.value, 1)) {
                          error.quantity = "has-danger";
                        } else {
                          error.quantity = "has-success";
                        }
                        setInventoryValidate(error);
                        setInventoryData({
                          ...inventoryData,
                          quantity: e.target.value,
                        });
                      }}
                    />
                    <FormFeedback>
                      Quantity in positive amount is required.
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={2}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Add Quantity</Label>
                    <Input
                      disabled={isProductSelected == false ? true : false}
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      name="addQuantity"
                      placeholder="Add Quantity"
                      value={inventoryData?.addQty}
                      onBlur={() => calculateTaxAndDiscount()}
                      onChange={(e) => {
                        setInventoryData({
                          ...inventoryData,
                          addQty: e.target.value,
                          totalQty:
                            parseInt(e.target.value) + inventoryData?.quantity,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={2}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Total Quantity</Label>
                    <Input
                      disabled
                      readOnly
                      type="number"
                      min="0"
                      maxLength={10}
                      name="totalQuantity"
                      placeholder="Total Quantity"
                      value={inventoryData?.unitItems}
                      style={{
                        backgroundColor: "#E3E3E3",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={2}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Unused Quantity</Label>
                    <Input
                      disabled={isProductSelected == false ? true : false}
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      name="unUsedquantity"
                      placeholder="Enter Unused Quantity"
                      value={inventoryData?.un_used_quantity}
                      onChange={(e) => {
                        setInventoryData({
                          ...inventoryData,
                          un_used_quantity: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={2}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>MRP *</Label>

                    <Input
                      disabled={isProductSelected == false ? true : false}
                      name="MRP"
                      placeholder="Enter MRP"
                      maxLength={10}
                      type="number"
                      min="0"
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      valid={inventoryValidate?.mrp === "has-success"}
                      invalid={inventoryValidate?.mrp === "has-danger"}
                      value={inventoryData?.mrp}
                      onBlur={() => calculateTaxAndDiscount()}
                      onChange={(e) => {
                        let error = { ...inventoryValidate };
                        if (!Common.verifyLength(e.target.value, 1)) {
                          error.mrp = "has-danger";
                        } else {
                          error.mrp = "has-success";
                        }
                        setInventoryValidate(error);
                        setInventoryData({
                          ...inventoryData,
                          mrp: e.target.value,
                        });
                      }}
                    />
                    <FormFeedback>
                      MRP in positive amount is required.
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={2}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Discount</Label>
                    <Input
                      disabled={isProductSelected == false ? true : false}
                      type="number"
                      min="0"
                      maxLength={10}
                      placeholder="Enter Discount"
                      onBlur={() => calculateTaxAndDiscount()}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          discount: e.target.value,
                        })
                      }
                      value={inventoryData?.discount}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col
                  className=""
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <span>{displayMsg}</span>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    paddingTop: "22px",
                    paddingBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        marginRight: "35px",
                      }}
                    >
                      <Input
                        disabled={!isProductSelected}
                        value={"Fixed"}
                        defaultChecked={inventoryData?.discountFixed > 0.0}
                        checked={discountTypeRef.current === "Fixed"}
                        onChange={(e) => {
                          discountTypeRef.current = e.target.value;
                          calculateTaxAndDiscount();
                        }}
                        name="Discount"
                        type="radio"
                        style={{ marginRight: "5px" }}
                      />
                      <Label check>Fixed Discount</Label>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Input
                        disabled={!isProductSelected}
                        value={"Percentage"}
                        defaultChecked={inventoryData?.discountPercentage > 0.0}
                        checked={discountTypeRef.current === "Percentage"}
                        onChange={(e) => {
                          discountTypeRef.current = e.target.value;
                          calculateTaxAndDiscount();
                        }}
                        name="Discount1"
                        type="radio"
                        style={{ marginRight: "5px" }}
                      />
                      <Label check>Percentage Discount</Label>
                    </div>
                  </Row>
                </Col>

                <Col
                  className=""
                  xs={12}
                  md={4}
                  lg={4}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <FormGroup>
                    <Label style={{ display: "flex" }}>
                      Applicable Discount
                    </Label>

                    <Input
                      value={appDisc}
                      readOnly
                      type="number"
                      min="0"
                      maxLength={10}
                      placeholder="0"
                    />
                  </FormGroup>
                </Col>
                <Col
                  className=""
                  xs={12}
                  md={4}
                  lg={4}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Buy Price</Label>

                    <Input
                      readOnly
                      value={buyPrice}
                      onChange={(e) => setbuyPrice(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col
                  className=""
                  xs={12}
                  md={4}
                  lg={4}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <FormGroup>
                    <Label style={{ display: "flex" }}>CGST(7%)</Label>

                    <Input
                      readOnly
                      value={inventoryData?.cgst}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          cgst: e.target.value,
                        })
                      }
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>SGST(9%)</Label>

                    <Input
                      readOnly
                      value={inventoryData?.sgst}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          sgst: e.target.value,
                        })
                      }
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>GST(5%)</Label>

                    <Input
                      readOnly
                      value={inventoryData?.gst}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          gst: e.target.value,
                        })
                      }
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Total Tax</Label>

                    <Input
                      readOnly
                      value={inventoryData?.taxAmount}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          taxAmount: e.target.value,
                        })
                      }
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Net Buy Price</Label>

                    <Input
                      readOnly
                      value={netBuyPrice}
                      onChange={(e) => setNetBuyPrice(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Total Amount</Label>
                    <Input
                      readOnly
                      value={totalAmount}
                      onChange={(e) => setTotalAmount(e.target.value)}
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormWizard.TabContent>
            <FormWizard.TabContent title="Other Details">
              <Row>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Reorder Point</Label>

                    <Input
                      disabled={isProductSelected == false ? true : false}
                      value={inventoryData?.reorder_point}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          reorder_point: e.target.value,
                        })
                      }
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Lead Time</Label>
                    <Input
                      disabled={isProductSelected == false ? true : false}
                      value={inventoryData?.lead_time}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          lead_time: e.target.value,
                        })
                      }
                      placeholder="0"
                      type="number"
                      min="0"
                      maxLength={10}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Expiry Date</Label>

                    <Input
                      disabled={isProductSelected == false ? true : false}
                      id="exampleDate"
                      name="date"
                      placeholder="Enter Expiry Date"
                      type="date"
                      max="9999-12-31"
                      min={today}
                      value={inventoryData?.expiryDate}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          expiryDate: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label style={{ display: "flex" }}>Description</Label>

                    <Input
                      disabled={isProductSelected == false ? true : false}
                      value={inventoryData?.description}
                      onChange={(e) =>
                        setInventoryData({
                          ...inventoryData,
                          description: e.target.value,
                        })
                      }
                      maxLength={500}
                      type="textarea"
                      placeholder="Enter Description"
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label>Inventory Image</Label>

                    <Button className="button-upload" outline>
                      <input
                        disabled={isProductSelected == false ? true : false}
                        accept="image/x-png,image/svg,image/jpeg,image/jpg"
                        onChange={(e) => uploadProduct(e)}
                        type="file"
                        name="file"
                      />
                    </Button>
                  </FormGroup>
                </Col>

                {/**
                 * Preview uploaded image
                 * Author: Devanshi Patel
                 */}
                <Col className="" xs={12} md={4} lg={4}>
                  <div className="upload-form_display">
                    {productImageUrl ? (
                      <img src={productImageUrl} alt="no pic" />
                    ) : file ? (
                      <img
                        className="displayImg"
                        src={URL.createObjectURL(file)}
                        alt="no pic"
                      />
                    ) : (
                      <img
                        className="displayImg"
                        src={`${ERP_CDN_IMAGE_URL}/placeholder.jpeg`}
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop if logo also fails
                          e.target.src = Placeholder;
                        }}
                        alt="nopic"
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <CardFooter className="d-flex justify-content-flex-end">
                  <Button
                    disabled={isProductSelected == false ? true : false}
                    color="success"
                    type="submit"
                    className="m-r-20"
                  >
                    Update
                  </Button>
                  <Button color="danger" onClick={() => close()}>
                    Cancel
                  </Button>
                </CardFooter>
              </Row>
            </FormWizard.TabContent>
          </FormWizard>
        </Form>
      )}
    </>
  );
};

export default EditInventory;
