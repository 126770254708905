import { createSlice } from '@reduxjs/toolkit'

export const siteConfigSlice = createSlice({
  name: 'siteConfig',
  initialState: {
    siteConfig: {},
  },
  reducers: {
    loadSiteConfig: (state, action) => {
        state.siteConfig = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { loadSiteConfig } = siteConfigSlice.actions

export default siteConfigSlice.reducer