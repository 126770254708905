import APIHandler from "helpers/APIHandler";
import { OTP_SEND_API } from "helpers/APIRouter";
import { NOTIFICATION_LIST_API } from "helpers/APIRouter";
import { CREATE_NOTIFICATION_API } from "helpers/APIRouter";
import { UPDATE_NOTIFICATION_STATUS_API } from "helpers/APIRouter";
import { OTP_SEND_SIGNUP } from "helpers/APIRouter";
import { OTP_SEND_FORGOT_PASSWORD } from "helpers/APIRouter";
import { OTP_SEND_GENERAL } from "helpers/APIRouter";
import { GET_NOTIFICATION_By_ID_API } from "helpers/APIRouter";
import { UPDATE_NOTIFICATION_API } from "helpers/APIRouter";
import { VERIFY_OTP_API } from "helpers/APIRouter";
import { WHATSAPP_SMS_SEND_API } from "helpers/APIRouter";
import { EMAIL_SEND_API } from "helpers/APIRouter";
import { SMS_SEND_API } from "helpers/APIRouter";

const API = new APIHandler();

class NotificationAPICall {
  sendSMS = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(SMS_SEND_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  sendEmail = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    param.isMultipart = true;
    await API.post(EMAIL_SEND_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  sendWPSMS = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    param.isMultipart = true;
    await API.post(WHATSAPP_SMS_SEND_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  sendOTP = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(OTP_SEND_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
  
  sendWPSMS = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(WHATSAPP_SMS_SEND_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  sendOTPForgetPassword = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(OTP_SEND_FORGOT_PASSWORD, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  sendOTPSignup = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(OTP_SEND_SIGNUP, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  sendOTPGeneral = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(OTP_SEND_GENERAL, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  verifyOTP = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(VERIFY_OTP_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getNotificationList = async (params) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(NOTIFICATION_LIST_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  createNotification = async (params) => {
    let res = {};
    let param = {};
    param.data = params;
    param.isMultipart = true;
    await API.post(CREATE_NOTIFICATION_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  updateNotification = async (params) => {
    let res = {};
    let param = {};
    param.data = params;
    param.isMultipart = true;
    await API.post(UPDATE_NOTIFICATION_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getNotificationById = async (params) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_NOTIFICATION_By_ID_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  updateNotificationStatus = async (params) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPDATE_NOTIFICATION_STATUS_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default NotificationAPICall;
