import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "../../assets/styles/Payment.css";
import PageLoader from "components/Loader/PageLoader";
import ChildLoader from "components/Loader/ChildLoader";
import autoTable from "jspdf-autotable";
import {
  RiFilePdfFill,
  RiFileExcelFill,
  RiFileExcel2Fill,
} from "react-icons/ri";
import CustomButton from "components/Button/CustomButton";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { useInventoryStore } from "hooks/Inventory";
import Common from "helpers/Common";

const InventoryExpiryReport = () => {
  const [{}, { getProductExpiryDateReport }, {}] = useInventoryStore();
  const [productData, setProductData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loader, setLoader] = useState(false);
  const dt = useRef(null);
  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState(1);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const parseISODate = (isoString) => {
    const date = new Date(isoString);
    return isNaN(date.getTime())
      ? "Invalid Date"
      : date.toLocaleString("en-US", { dateStyle: "long", timeStyle: "short" });
  };

  const formatNull = (value) => (value === null ? "N/A" : value);

  const getProducts = async (page = 1) => {
    setLoader(true);
    if (page === 1) {
      setPageNumber(1);
    }

    const today = new Date();

    let param = {
      from_date: fromDate || "2024-03-10",
      to_date: toDate || formatDate(today),
      // pagesize: pageSize,
      // pagenumber: page,
    };

    let Data = await getProductExpiryDateReport(param);
    setProductData(Data);
    setLoader(false);
  };

  const exportToPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: "A4",
    });

    doc.setFontSize(12);
    doc.text("Inventory Expiry Report", 40, 40);

    autoTable(doc, {
      startY: 60,
      head: [
        [
          "Product ID",
          "Product Name",
          "Quantity",
          "Sold Quantity",
          "Available Quantity",
          "Expiry Date",
        ],
      ],
      body: productData.map((data) => [
        data.productId,
        data.productName,
        data.quantity,
        data.selledQtyTillToday,
        data.availableQty,
        Common.getFormatedDate(data.expiryDate, "DD MMMM YYYY", true),
      ]),
      theme: "grid",
      styles: {
        cellPadding: 5,
        fontSize: 8,
        halign: "center",
      },
      headStyles: {
        fillColor: [52, 152, 219],
        textColor: [255, 255, 255],
      },
      footStyles: {
        fillColor: [52, 152, 219],
      },
    });

    doc.save("inventory_expiry_report.pdf");
  };

  const exportToExcel = () => {
    const worksheetData = [
      [
        "Product ID",
        "Product Name",
        "Quantity",
        "Sold Quantity",
        "Available Quantity",
        "Expiry Date",
      ],
      ...productData.map((data) => [
        data.productId,
        data.productName,
        data.quantity,
        data.selledQtyTillToday,
        data.availableQty,
        Common.getFormatedDate(data.expiryDate, "DD MMMM YYYY", true),
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Inventory Expiry Report"
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(data, "inventory_expiry_report.xlsx");
  };

  useEffect(() => {
    setLoader(true);
    getProducts();
  }, []);

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="content-dashboard-payment">
          <div className="title-route-container">
            <h4 className="title">Inventory / Expiry Report</h4>
          </div>

          <Card className="payment-data-card">
            <CardBody>
              <div className="payment-card-title-container">
                <h5>Expiry Report</h5>

                <div className="payment-header-btn-container">
                  <button onClick={exportToExcel} className="export-xls-btn">
                    Export XLS <RiFileExcel2Fill size={20} />
                  </button>
                  <button onClick={exportToPDF} className="export-pdf-btn">
                    Export PDF <BsFileEarmarkPdfFill size={20} />
                  </button>
                </div>
              </div>
              <Row xs="2" className="mb-3">
                <Col xs={12} md={3} lg={3}>
                  <FormGroup className="customer-input-container">
                    <Label style={{ display: "flex" }}>From Date</Label>
                    <Input
                      id="fromDate"
                      name="date"
                      placeholder="From Date"
                      type="date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <FormGroup className="customer-input-container">
                    <Label style={{ display: "flex" }}>To Date</Label>
                    <Input
                      id="toDate"
                      name="date"
                      placeholder="To Date"
                      type="date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col
                  xs={12}
                  md={3}
                  lg={3}
                  className="d-flex justify-content-start align-items-end"
                  style={{ marginBottom: "10px" }}
                >
                  <CustomButton label="Search" onClick={() => getProducts()} />
                </Col>
              </Row>
              {loader ? (
                <PageLoader />
              ) : (
                <Row>
                  <Col xs={12} md={12} lg={12} className="p-0">
                    <DataTable
                      tableStyle={{ minWidth: "25rem" }}
                      size="large"
                      resizableColumns
                      columnResizeMode="expand"
                      paginator
                      rowsPerPageOptions={[5, 10, 15]}
                      rows={5}
                      pt={{
                        root: { className: "table" },
                      }}
                      value={productData}
                      stripedRows
                      scrollable
                      scrollHeight="500px"
                      ref={dt}
                    >
                      <Column
                        field="productId"
                        header="Product ID"
                        align="center"
                      />
                      <Column
                        field="productName"
                        header="Product Name"
                        align="center"
                      />
                      <Column
                        field="quantity"
                        header="Quantity"
                        align="center"
                      />
                      <Column
                        field="selledQtyTillToday"
                        header="Sold Quantity"
                        align="center"
                      />
                      <Column
                        field="availableQty"
                        header="Available Quantity"
                        align="center"
                      />
                      <Column
                        field="expiryDate"
                        header="Expiry Date"
                        dataType="date"
                        bodyStyle={{ fontWeight: "600", color: "#000000" }}
                        body={(rowData) => {
                          try {
                            return Common.getFormatedDate(
                              rowData.expiryDate,
                              "DD MMMM YYYY",
                              true
                            );
                          } catch (error) {
                            console.error(error.message);
                            return "Invalid Date"; // or any fallback display text
                          }
                        }}
                      ></Column>
                    </DataTable>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </div>
      )}{" "}
    </>
  );
};

export default InventoryExpiryReport;
