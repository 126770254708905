import APIHandler from "helpers/APIHandler";
import { UPDATE_BILL_TEMPLATE_API } from "helpers/APIRouter";
import { UPDATE_TEMPLATE_ACTIVE_STATUS_API } from "helpers/APIRouter";
import { GET_TEMPLATE_DETAILS_BY_ID_API } from "helpers/APIRouter";
import { GET_TEMPLATE_LIST_API } from "helpers/APIRouter";
import { CREATE_BILL_TEMPLATE_API } from "helpers/APIRouter";

const API = new APIHandler();

class TemplateAPICall {
  getTemplateList = async (params = {}) => {
    let res = {};

    let param = {};
    param.data = params;

    let url = "";

    if (params.isActive) {
      url =
        GET_TEMPLATE_LIST_API + `?searchTemplate=&isActive=${params.isActive}`;
    } else {
      url = GET_TEMPLATE_LIST_API;
    }

    // console.log(url);

    await API.get(url)
      .then((result) => {
        // console.log("recent bill result", result);
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  createBillTemplate = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;

    // console.log(param);

    await API.post(CREATE_BILL_TEMPLATE_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getTemplateDetailsById = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_TEMPLATE_DETAILS_BY_ID_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  updateBillTemplate = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPDATE_BILL_TEMPLATE_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  updateTemplateActiveStatus = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    // console.log(param);

    const url =
      UPDATE_TEMPLATE_ACTIVE_STATUS_API +
      `?templateID=${params.templateID}&isactive=${params.isActive}`;

    // console.log(url);

    await API.post(url)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default TemplateAPICall;
