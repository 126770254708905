import { useSelector } from "react-redux";
import LoginRepository from "repository/LoginRepository";
import { loginVerifyOTP } from "store/slice/Login";
import { companyLogin, setAlerts, resetAlerts } from "store/slice/Login";
import store from "store/store";

export function useLoginStore() {
    const companyDetail = useSelector((state) => state.entities.Login.companyDetail)
    const alertList = useSelector((state) => state.entities.Login.alertList)

    let data = {
        companyDetail,
        alertList
    };
    let fetch = {

    };
    let set = {
        /**
         * Here is list of fetch methods which are used for set or update data from API or Repositories related to login
         * Author: Devanshi Patel
         */
        companyLogin: async (params) => {
            return await store.dispatch(companyLogin(params))
        },

        setAlerts: async (params) => {
            return await store.dispatch(setAlerts(params))
        },

        resetAlerts: async () => {
            return await store.dispatch(resetAlerts())
        },

        changePassword: async (params) => {
            return await new LoginRepository().changePassword(params);
        },

        loginWithOTP: async (params) => {
            return await new LoginRepository().loginWithOTP(params);
        },

        loginVerifyOTP: async (params) => {
            return await store.dispatch(loginVerifyOTP(params))
        },

        validateUserLogin: async () => {
            return await new LoginRepository().validateUserLogin();
        },
    };
    return [data, fetch, set]
}