import { useLoginStore } from 'hooks/Login';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { UncontrolledAlert } from 'reactstrap';

function Alert() {
    const [{ alertList }, { }, { resetAlerts }] = useLoginStore();

    useEffect(() => {
        const timeId = setTimeout(async () => {
            await resetAlerts()
        }, 5000)

        return () => {
            clearTimeout(timeId)
        }
    }, [alertList]);

    return (
        <div style={{ position: 'absolute', top: '15px', right: '15px', zIndex: '99999' }}>
            {alertList && alertList.length > 0 &&
                alertList.map((v, i) => (
                    <UncontrolledAlert key={i} color={v.type}>
                        {v.message}
                    </UncontrolledAlert>
                ))
            }

        </div>
    );
}

export default Alert;