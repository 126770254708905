import React, { useState } from "react";
import { Bar } from "react-chartjs-2";

// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  FormGroup,
} from "reactstrap";

// core components
import { useReportStore } from "hooks/Report";
import { useEffect } from "react";
import { isArray, isEmpty } from "underscore";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Common from "helpers/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DownloadReport from "./DownloadReport";
import PageLoader from "components/Loader/PageLoader";
import ChildLoader from "components/Loader/ChildLoader";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import LoadMore from "components/Button/LoadMore";
import { Link } from "react-router-dom";
import { URL_FORECAST_REPORT } from "helpers/Paths";

const ForecastingAndDemandReport = () => {
  const [{}, { getDailyDemandForecastData, getWeeklyDemandForecastData }, {}] =
    useReportStore();
  const [selectedDate, setSelectedDate] = useState("");
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [salesQuantity, setSaleQuantity] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);

  const MAX_RECORDS = 15;

  const [demandForecastReportData, setDemandForecastReportData] = useState([]);

  const loadMoreData = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
    setLoadingMore(true);
    getReport(pageNumber + 1);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            return `Quantity: ${context.raw}`;
          }
        },
        displayColors: false,
        backgroundColor: '#585858',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        padding: 10,
        cornerRadius: 4,
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          borderDash: [5, 5],
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          padding: 20,
          color: "#9f9f9f",
          maxRotation: 0,
          minRotation: 0,
          callback: function (value) {
            const label = this.getLabelForValue(value);
            const words = label.split(' ');
            const maxLineLength = 10;
            let line = '';
            let lines = [];
            words.forEach(word => {
              if ((line + word).length > maxLineLength) {
                lines.push(line.trim());
                line = '';
              }
              line += word + ' ';
            });
            lines.push(line.trim());
            return lines;
          },
        },
      }
    },
  };

  let initialReportState = {
    labels: [],
    datasets: [],
  };

  const setChartQuantityDetails = (
    labelData,
    salesDataInfo,
    label = "Forecasted Sales Quantity"
  ) => {

    const maxValue = Math.max(...salesDataInfo);

    const chartQuantityDetails = {
      labels: labelData,
      datasets: [
        {
          label: label,
          data: salesDataInfo,
          backgroundColor: salesDataInfo.map(value =>
            value === maxValue ? '#3146F5' : '#B5BCFB'
          ),
          borderRadius: 10,
          maxBarThickness: 50
        },
      ],
    };
    setChartData(chartQuantityDetails);
  };

  const [chartData, setChartData] = useState(initialReportState);

  async function getReport(page = 1) {
    if (page === 1) {
      setPageNumber(1);
    }

    let params = {};
    params.pagenumber = page;
    params.pagesize = new CompanyProfileRepository().getPageSize();

    let weeklyForecastData = await getWeeklyDemandForecastData(params);

    let arr = [];
    let labelsData = [];
    let forecastedSalesList = [];

    if (
      weeklyForecastData &&
      isArray(weeklyForecastData) &&
      !isEmpty(weeklyForecastData)
    ) {
      weeklyForecastData.map((prop, key) => {
        let createDateObj = moment(prop.createdDate);
        let formattedDate = createDateObj.format("MMMM D, YYYY");
        let formattedTime = createDateObj.format("hh:mm A");

        let formattedDateTime = `${formattedDate}, ${formattedTime}`;

        let obj = {
          id: prop.productID,
          productName: prop.productName,
          forecastedSales: prop.weeklyForecastedSales,
          reorderStatus: prop.reorderStatus,
          dateTime: formattedDateTime,
        };
        arr.push(obj);
        labelsData.push(prop.productName);
        forecastedSalesList.push(prop.weeklyForecastedSales);
      });
    }

    if (page > 1) {
      setDemandForecastReportData((prev) => [...prev, ...arr]);

      const labelsInfo = [...labels, ...labelsData];
      const salesinfo = [...salesQuantity, ...forecastedSalesList];

      setSaleQuantity((prevData) => [...prevData, ...forecastedSalesList]);
      setLabels((prevData) => [...prevData, ...labelsData]);

      if (salesinfo.length > MAX_RECORDS) {
        let combinedData = labelsInfo.map((label, index) => ({
          label,
          forecastedSalesQuantity: salesinfo[index],
        }));

        combinedData.sort((a, b) => b.salesQuantity - a.salesQuantity);
        combinedData = combinedData.slice(0, MAX_RECORDS);

        let topLabels = combinedData.map((data) => data.label);
        let topForecastedSalesQuantities = combinedData.map(
          (data) => data.forecastedSalesQuantity
        );

        setChartQuantityDetails(
          topLabels,
          topForecastedSalesQuantities,
          `Top ${MAX_RECORDS} Forecasted Sales Quantities`
        );
        setLoadingMore(false);
        setLoader(false);
      } else {
        const allSalesQuantity = [...salesQuantity, ...forecastedSalesList];

        const allLabelsInfo = [...labels, ...labelsData];
        setChartQuantityDetails(allLabelsInfo, allSalesQuantity);
        setLoadingMore(false);
        setLoader(false);
      }
    } else {
      setDemandForecastReportData(arr);
      setSaleQuantity(forecastedSalesList);
      setLabels(labelsData);
      setChartQuantityDetails(labelsData, forecastedSalesList);
      setLoadingMore(false);
      setLoader(false);
    }
  }
  useEffect(() => {
    setLoader(true);
    getReport();
  }, []);

  const onDateSelect = (e) => {
    setSelectedDate(moment(e).format("YYYY-MM-DDTHH:MM:SS"));
    getReport();
  };

  const renderInput = () => {
    return (
      <FormGroup className="has-label">
        <div className="row">
          <ReactDatetime
            inputProps={{ placeholder: "Select Date and Time" }}
            onChange={onDateSelect}
          />
          <div className="mt-2 ml-2">
            <FontAwesomeIcon
              className="border-right pr-2"
              icon="fa-solid fa-calendar"
            />
            <FontAwesomeIcon className="pl-2" icon="fa-solid fa-clock" />
          </div>
        </div>

        <div className="row datetime-icon"></div>
      </FormGroup>
    );
  };
  const headers = [
    { label: "Product Id", key: "id" },
    { label: "Product Name", key: "productName" },
    { label: "Forecasted Sales", key: "forecastedSales" },
    { label: "Reorder Status", key: "reorderStatus" },
    { label: "Date Time", key: "dateTime" },
  ];

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <>
          <div className="content-dashboard-report">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="title">Reports / <Link to={`/admin${URL_FORECAST_REPORT}`}>Forecast</Link> / Revenue per product Forecast </h4>
            </div>

            <Row>
              <Col md="12">
                <Card className="chart-container">
                  <CardBody>
                    {loadingMore ? (
                      <ChildLoader />
                    ) : (
                      <>
                        <div className="selling-header">
                          <h5>Revenue per product Forecast</h5>
                          {/* <div className="border-0">
                          <FilterButton
                            getReport={getReport}
                            setLoadingFilter={setLoadingFilter}
                          />
                        </div> */}
                        </div>
                        <div className="bar-chart-wrapper">
                          <div className="bar-chart-container">
                            <Bar
                              data={chartData}
                              options={options}
                              height={320}
                              width={820}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card className="report-table-card">
                  <CardBody>

                    <div className="report-table-title">
                      <h5>Forecasting Revenue Report</h5>
                      <DownloadReport
                        reportData={demandForecastReportData}
                        headers={headers}
                      />
                    </div>

                    <DataTable
                      value={demandForecastReportData}
                      tableStyle={{ minWidth: "25rem" }}
                    >
                      <Column field="id" header="Product ID"></Column>
                      <Column
                        field="productName"
                        header="Product Name"
                      ></Column>
                      <Column
                        field="forecastedSales"
                        header="Forecasted Sales"
                        align="center"
                      ></Column>
                      <Column
                        field="reorderStatus"
                        header="Reorder Status"
                        align="center"
                      ></Column>
                      <Column
                        field="dateTime"
                        header="Date Time"
                        align="center"
                      ></Column>
                      <Column
                        field=""
                        header="Bill Creation Date"
                        align="center"
                        dataType="date"
                        body={(rowData) =>
                          Common.getFormatedDate(
                            rowData.dateTime,
                            "DD MMMM YYYY, hh:mm A",
                            true
                          )
                        }
                      ></Column>
                    </DataTable>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                {loadingMore ? (
                  <ChildLoader />
                ) : (
                  <LoadMore
                    totalData={demandForecastReportData.length}
                    handleLoadMore={() => loadMoreData()}
                  />
                )}
              </Col>
            </Row>
          </div>
        </>
      )
      }{" "}
    </>
  );
};
export default ForecastingAndDemandReport;
