import { notificationMessages } from "helpers/constantMessages";
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { useLoginStore } from "hooks/Login";
import { useEffect, useState } from "react";
import { FaCommentSms, FaEnvelope } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io5";
import {
  CustomInput,
  FormGroup,
  Label
} from "reactstrap";

const CustomerSettings = ({ taxDetails, getGenricData }) => {
  const [{ }, { }, { saveCustomerEnableServicesDetails }] =
    useCompanyProfileStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();

  const [emailEnable, setEmailEnable] = useState(false);
  const [smsEnable, setSMSEnable] = useState(false);
  const [whatsappEnable, setWhatsappEnable] = useState(false);

  useEffect(() => {
    try {
      setEmailEnable(
        taxDetails?.filter((data) => data.feature_key == "email_enabled")[0]
          .feature_value == "YES"
          ? true
          : false
      );
      setSMSEnable(
        taxDetails?.filter((data) => data.feature_key == "sms_enabled")[0]
          .feature_value == "YES"
          ? true
          : false
      );
      setWhatsappEnable(
        taxDetails?.filter((data) => data.feature_key == "whatsup_enabled")[0]
          .feature_value == "YES"
          ? true
          : false
      );
    } catch (error) { }
  }, [taxDetails]);

  const saveCustomerDetails = async (value, type) => {
    if (type == "sms") setSMSEnable((prev) => !prev);
    if (type == "email") setEmailEnable((prev) => !prev);
    if (type == "whatsapp") setWhatsappEnable((prev) => !prev);

    let res = {};
    let param = {
      feature_key:
        type == "sms"
          ? "sms_enabled"
          : type == "email"
            ? "email_enabled"
            : "whatsup_enabled",
      feature_value: value == true ? "YES" : "NO",
    };
    res = await saveCustomerEnableServicesDetails(param);
    if (res) {
      await setAlerts({
        type: "success",
        message: notificationMessages.customerProfileSettings,
      });
    }
  };

  return (
    <div className="profile-content-container">
      <div className="profile-content-title">
        <h5>Customer Notification Settings</h5>
      </div>

      <FormGroup className="profile-notification-setting-item">
        <FaCommentSms size={25} />

        <Label for="">
          SMS Enabled
        </Label>

        <CustomInput
          type="switch"
          id="smsTogBtn"
          onChange={(e) => {
            saveCustomerDetails(e.target.checked, "sms");
          }}
          checked={smsEnable}
        />
      </FormGroup>

      <FormGroup className="profile-notification-setting-item">
        <FaEnvelope size={25} />

        <Label for="">
          Email Enabled
        </Label>

        <CustomInput
          type="switch"
          id="emailTogBtn"
          onChange={(e) => {
            saveCustomerDetails(e.target.checked, "email");
          }}
          checked={emailEnable}
        />
      </FormGroup>


      <FormGroup className="profile-notification-setting-item">
        <IoLogoWhatsapp size={25} />

        <Label for="">
          Whatsapp Enabled
        </Label>

        <CustomInput
          type="switch"
          id="whatsappTogBtn"
          onChange={(e) => {
            saveCustomerDetails(e.target.checked, "whatsapp");
          }}
          checked={whatsappEnable}
        />
      </FormGroup>


    </div>
  );
};

export default CustomerSettings;
