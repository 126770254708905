import APIHandler from "helpers/APIHandler";
import { SUBMIT_FEEDBACK_API, USERS_FEEDBACKS } from "helpers/APIRouter";

const API = new APIHandler();

class FeedbackAPICall {
  submitFeedback = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(SUBMIT_FEEDBACK_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
  getFeedbackList = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(USERS_FEEDBACKS, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default FeedbackAPICall;
