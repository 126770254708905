// RefreshButton.js
import React from "react";
import { IoMdRefresh } from "react-icons/io";

const RefreshButton = ({
  onClick,
  title = "Refresh",
  size = 20,
  color = "white",
}) => {
  const handleClick = () => {
    window.location.reload();
  };
  return (
    <div
      title={title}
      style={{
        backgroundColor: "#434ce6",
        borderRadius: "8px",
        padding: "6px",
        color: color,
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "34.5px",
        height: "34.5px",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <IoMdRefresh size={size} />
    </div>
  );
};

export default RefreshButton;
