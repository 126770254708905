/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

function Footer(props) {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid ? true : false}>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a href="https://apsthreeai.ai/pricing" target="_blank">
                  {window.APSTHREEAILLP.productName}
                </a>
              </li>
              <li>
                <a href="https://apsthreeai.ai/#blog" target="_blank">
                  Blog
                </a>
              </li>
              <li>
                {/* kept href blank as temporary no link is available for Licenses */}
                <a>Licenses</a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
              &copy; {1900 + new Date().getYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by{" "}
              <a
                style={{
                  color: "#434ce6",
                }}
                href="http://apsthreeai.ai/"
                target="_blank"
              >
                {window.APSTHREEAILLP.comapanyName}
              </a>
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
