import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Common from "helpers/Common";
import { useLoginStore } from "hooks/Login";
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  Container,
  Col,
  Row,
  Spinner, FormGroup, InputGroupText, InputGroup, Nav, NavItem
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NavLink, useNavigate } from "react-router-dom";


const SignupEmail = () => {
  const navigate = useNavigate();
  const isLogin = Common.getJwtToken();

  useEffect(() => {
    checkIfAlreadyLoggedIn();
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const checkIfAlreadyLoggedIn = () => {
    if (isLogin) {
      navigate(ADMIN_URL_DASHBOARD);
    }
  }

  return (
    <div className="login-page">
      <div class="full-width">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="form-center">
              <Form className="form">
                <Card className="card-login">
                  <CardHeader>
                    <h4 className="header text-center mt-0">Sign Up</h4>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className="has-label">
                      <label>Email Id *</label>
                      <Input
                        placeholder="example@mail.com"
                        type="text"
                        maxLength={100}
                      />
                    </FormGroup>
                    <div className="category form-category">
                      * Required fields
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-1 btn-custom"
                      color="warning"
                      type="submit"
                    >
                      GET OTP
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
              <Form className="form">
                <Card className="card-login">
                  <CardHeader>
                    <h4 className="header text-center mt-0 mb-0">Verification</h4>
                    <center><div className="text-size-contact-xs">You will get OTP via SMS</div></center>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className="has-label">
                      <label>OTP *</label>
                      <Input
                        placeholder="1234"
                        type="text"
                        maxLength={6}
                      />
                    </FormGroup>
                    <div className="category form-category">
                      * Required fields
                    </div>
                    <div>
                      <label className="custom-checkbox-container">
                        <input
                          type="checkbox"
                          id="custom-checkbox"
                        />{" "}
                        <span className="custom-checkbox-box"></span>
                        I agree to the <a href="/auth/terms" className="tc">Terms and Conditions</a>
                      </label>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-1 btn-custom"
                      color="warning"
                      type="submit"
                    >
                      VERIFY OTP
                    </Button>
                    <div className="text-right text-size-small">Didn't receive the verification OTP? <a style={{ color: '#1F77D0', cursor: 'pointer', fontWeight: 'bold' }}> Resend again </a></div>
                  </CardFooter>
                </Card>
              </Form>

            </div>
          </Col>
        </Row>
      </div>
      <div className="full-page-background" />
    </div>
  );
}


export default SignupEmail;