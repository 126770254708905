import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
} from "reactstrap";
import "../../assets/styles/CreateBill.css";
import { useEffect, useRef, useState } from "react";
import { useCreateBillStore } from "hooks/CreateBill";
import { useLoginStore } from "hooks/Login";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AutoComplete } from "primereact/autocomplete";
import CustomButton from "components/Button/CustomButton";
import PageLoader from "components/Loader/PageLoader";

const EditCustomer = ({ setFormChanged }) => {
  const addCustomerRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    customerName: "",
    activeStatus: 1,
    emailId: "",
    phoneNumber: "",
    description: "",
    pincode: "",
    address1: "",
    address2: "",
    street_Area: "",
    city: "",
    state: "",
    country: "India",
  });
  const [formDataValidate, setFormDataValidate] = useState({});

  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [isCustomerSelected, setisCustomerSelected] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [loader, setLoader] = useState(false);

  const [isMobileValid, setIsMobileValid] = useState(true);

  const [{}, { getCustomerDetail }, { updateCustomer }] = useCreateBillStore();
  const [{}, {}, { setAlerts }] = useLoginStore();

  const [countries, setCountries] = useState([]);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = () => {
    let countryData = localStorage.getItem("countryList");
    if (!countryData) {
      fetch("https://restcountries.com/v3.1/all")
        .then((response) => response.json())
        .then((data) => {
          const countryNames = data.map((country) => country.name.common);
          countryNames.sort(); // Sort the countries alphabetically

          localStorage.setItem("countryList", JSON.stringify(countryNames));

          setCountries(countryNames);
        })
        .catch((error) => console.error("Error fetching countries:", error));
    } else {
      setCountries(JSON.parse(countryData));
    }

    // if (props.customerName && addCustomerRef.current) {
    //   addCustomerRef.current.focus(); // Focus the button when no results are found
    //   props.setNoResultFound();
    //   setFormData({
    //     ...formData,
    //     customerName: props.customerName,
    //   });
    // }
  };

  const handleMobileChange = (e) => {
    setFormData({
      ...formData,
      phoneNumber: "+" + e,
    });
    setCustomerData({
      ...customerData,
      phoneNumber: "+" + e,
    });
    if (e.length < 12) {
      setIsMobileValid(false);
    } else {
      setIsMobileValid(true);
    }
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let error = false;
    let validate = { ...formDataValidate };

    if (!customerData?.customerFullName) {
      error = true;
      validate.customerFullName = "has-danger";
    }
    // Validate phone number
    const phoneNumber = customerData?.phoneNumber?.trim();
    if (!phoneNumber) {
      setIsMobileValid(false); // Update isMobileValid state to indicate invalid
      error = true;
      validate.phoneNumber = "has-danger";
    } else {
      setIsMobileValid(true); // Update isMobileValid state to indicate valid
      validate.phoneNumber = "has-success";
    }
    setFormDataValidate(validate);

    try {
      if (!error) {
        let param = {
          customerID: customerData?.customerID,
          customerName: customerData?.customerFullName,
          activeStatus: customerData?.activeStatus,
          emailId: customerData?.emailId,
          phoneNumber: customerData?.phoneNumber,
          description: customerData?.description,
          pincode: customerData?.pincode,
          address1: customerData?.address1,
          address2: customerData?.address2,
          street_Area: customerData?.street_Area,
          city: customerData?.city,
          state: customerData?.state,
          country: customerData?.country,
        };
        const response = await updateCustomer(param);
        if (response?.data == false) {
          setLoader(false);

          await setAlerts({
            type: "danger",
            message: response?.message,
          });
        }
        if (response?.data == true) {
          setLoader(false);

          await setAlerts({
            type: "success",
            message: "Customer successfully updated",
          });
        }
        reset();
      } else {
        alert("Enter required fields.");
      }
    } catch (error) {
      setLoader(false);
      console.error("Error creating customer:", error);
      await setAlerts({ type: "danger", message: "Error creating customer" });
    }
    setLoader(false);
  };

  const preventPincodeInput = (e) => {
    let value = e.target.value.trim(); // Trim any leading or trailing spaces
    const isNegative = value.startsWith("-");

    // Remove non-digit characters
    value = value?.replace(/\D/g, "");

    // Limit to 10 digits
    value = value?.slice(0, 5);

    // If it's negative, append the negative sign
    if (isNegative) {
      value = "-" + value;
    }

    e.target.value = value;
  };

  const mobileInputInvalidClass = "form-control is-invalid form-control";
  const mobileInputValidClass = "form-control";

  const filterCustomer = (event) => {
    retriveCustomers(event.query);
  };

  const retriveCustomers = async (queryText) => {
    try {
      let customerList = await getCustomerDetail(queryText);

      // Add customerFullName before modifying customerName
      customerList = customerList.map((customer) => ({
        ...customer,
        customerFullName: customer.customerName,
      }));

      const modifyCustomerNames = () => {
        customerList.forEach((customer) => {
          // Regular expression to match a phone number within parentheses
          const phoneRegex = /\(\+\d+\)/;
          const match = customer.customerName.match(phoneRegex);

          if (match) {
            const existingPhoneNumber = match[0];

            // If the existing phone number is different from the phoneNumber field
            if (existingPhoneNumber !== `(${customer.phoneNumber})`) {
              // Remove the old phone number and append the correct one
              customer.customerName = customer.customerName.replace(
                existingPhoneNumber,
                `(${customer.phoneNumber})`
              );
            }
          } else {
            // If no phone number is in the customerName, append the phone number
            customer.customerName = `${customer.customerName} (${customer.phoneNumber})`;
          }
        });
      };
      // Call the function to modify customer names
      modifyCustomerNames();
      setFilteredCustomer(customerList);
    } catch (error) {
      console.error("Error retrieving customers:", error);
      throw error; // Re-throw the error to handle it further up the call stack
    }
  };

  const onSelectCustomer = async (val) => {
    setLoader(true);
    setisCustomerSelected(true);
    let customerDetails = {
      customerID: val?.customerID,
      emailId: val?.emailId,
      phoneNumber: val?.phoneNumber,
      country: val?.country,
      state: val?.state,
      pincode: val?.pincode,
      address1: val?.address1,
      address2: val?.address2,
      street_Area: val?.street_Area,
      city: val?.city,
      description: val?.description,
      activeStatus: val?.activeStatus,
      customerName: val?.customerName,
      customerFullName: val?.customerFullName,
    };
    setCustomerData(customerDetails);
    setLoader(false);
  };

  const reset = (ev) => {
    setFormDataValidate({});
    setSelectedCustomer("");
    setIsMobileValid(true);
    getCountryList();
    setFormData({
      customerName: formData.customerName ? formData.customerName : "",
      activeStatus: 1,
      emailId: "",
      phoneNumber: "",
      description: "",
      pincode: "",
      address1: "",
      address2: "",
      street_Area: "",
      city: "",
      state: "",
      country: "India",
    });
    setCustomerData({});
    setFormChanged(false); // Reset formChanged state
  };

  return (
    <Container>
      {loader ? (
        <PageLoader />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="customerName" className="label-addcustomer">
                  Search Customer
                </Label>
                <span className="text-danger ml-1">*</span>
                <AutoComplete
                  ref={autoCompleteRef}
                  value={selectedCustomer}
                  suggestions={filteredCustomer}
                  completeMethod={filterCustomer}
                  field="customerName"
                  placeholder="Search by Customer Name or Mobile number"
                  showEmptyMessage={true}
                  emptyMessage="No results found"
                  minLength={1}
                  inputStyle={{ width: "450px" }}
                  onChange={(e) => {
                    setSelectedCustomer(e.value);
                    // setCustomerData({
                    //   ...customerData,
                    //   customerName: e.target.value.customerName,
                    // });
                    setFormChanged(true); // Notify parent of form change
                  }}
                  onSelect={(e) => {
                    onSelectCustomer(e.value);
                  }}
                  pt={{
                    panel: {
                      className: "autocomplete-Zindex",
                    },
                    input: {
                      root: {
                        className: "custom-width-input form-control",
                      },
                    },
                  }}
                  className="custom-width-input"
                  style={{ width: "100%" }}
                  delay={300}
                />
                <FormFeedback>Customer name is required.</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="emailId" className="label-addcustomer">
                  Customer Name
                </Label>
                <span className="text-danger ml-1">*</span>
                <Input
                  disabled={isCustomerSelected == false ? true : false}
                  name="customerFullName"
                  id="customerFullName"
                  placeholder="Enter Customer Name"
                  value={customerData?.customerFullName}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      customerFullName: e.target.value,
                    });
                  }}
                  maxLength={100}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="phoneNumber" className="label-addcustomer">
                  Phone Number{" "}
                </Label>
                <span className="text-danger ml-1">*</span>
                <PhoneInput
                  containerClass="phone-input-custom"
                  buttonClass="flag-search-btn-custom"
                  dropdownClass="flag-dropdown-custom"
                  disabled={isCustomerSelected == false ? true : false}
                  country={"in"}
                  inputClass={
                    !isMobileValid
                      ? mobileInputInvalidClass
                      : mobileInputValidClass
                  }
                  inputStyle={{
                    borderColor: !isMobileValid ? "red" : "",
                  }}
                  name="phoneNumber"
                  id="phoneNumber"
                  value={customerData?.phoneNumber}
                  enableSearch={true}
                  placeholder="+91 00000-00000"
                  valid={isMobileValid === "has-success"}
                  prefix="+"
                  invalid={!isMobileValid === "has-danger"}
                  onChange={(e) => {
                    handleMobileChange(e);
                  }}
                />
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    display: !isMobileValid ? "inline" : "none",
                  }}
                >
                  Phone Number with correct format is required.
                </span>
                <FormFeedback>Please enter valid number.</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="emailId" className="label-addcustomer">
                  Email ID
                </Label>
                <Input
                  disabled={isCustomerSelected == false ? true : false}
                  type="email"
                  name="emailId"
                  id="emailId"
                  placeholder="Enter Email ID"
                  value={customerData?.emailId}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      emailId: e.target.value,
                    });
                  }}
                  maxLength={100}
                />
              </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="address1" className="label-addcustomer">
                  Address 1
                </Label>
                <Input
                  disabled={isCustomerSelected == false ? true : false}
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder="Enter Address 1"
                  maxLength={100}
                  value={customerData?.address1}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      address1: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="address2" className="label-addcustomer">
                  Address 2
                </Label>
                <Input
                  disabled={isCustomerSelected == false ? true : false}
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Enter Address 2"
                  value={customerData?.address2}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      address2: e.target.value,
                    });
                  }}
                  maxLength={100}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="street_Area" className="label-addcustomer">
                  Street/Area
                </Label>
                <Input
                  disabled={isCustomerSelected == false ? true : false}
                  type="text"
                  name="street_Area"
                  id="street_Area"
                  placeholder="Enter Street/Area"
                  maxLength={100}
                  value={customerData?.street_Area}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      street_Area: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="pincode" className="label-addcustomer">
                  Pincode
                </Label>
                <Input
                  disabled={isCustomerSelected == false ? true : false}
                  name="pincode"
                  id="pincode"
                  placeholder="Enter Pincode"
                  type="number"
                  min="0"
                  onKeyDown={preventPincodeInput}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  value={customerData?.pincode}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      pincode: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="city" className="label-addcustomer">
                  City
                </Label>
                <Input
                  disabled={isCustomerSelected == false ? true : false}
                  type="text"
                  name="city"
                  id="city"
                  placeholder="Enter City"
                  value={customerData?.city}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      city: e.target.value,
                    });
                  }}
                  maxLength={100}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="state" className="label-addcustomer">
                  State
                </Label>
                <Input
                  disabled={isCustomerSelected == false ? true : false}
                  type="text"
                  name="state"
                  id="state"
                  placeholder="Enter State"
                  maxLength={100}
                  value={customerData?.state}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      state: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="country" className="label-addcustomer">
                  Country
                </Label>
                <Input
                  disabled={isCustomerSelected == false ? true : false}
                  type="select"
                  name="country"
                  id="country"
                  value={customerData?.country}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      country: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled selected>
                    Select your country
                  </option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="description" className="label-addcustomer">
                  Description
                </Label>
                <Input
                  disabled={isCustomerSelected == false ? true : false}
                  type="textarea"
                  name="description"
                  id="description"
                  placeholder="Enter Description"
                  value={customerData?.description}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      description: e.target.value,
                    });
                  }}
                  maxLength={100}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Col
              xs="12"
              md="6"
              lg="4"
              className="d-flex align-items-end justify-content-end"
            >
              <CustomButton
                label="Submit"
                type="submit"
                customStyle="add-customer-submit-btn"
              />
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
};

export default EditCustomer;
