import React from "react";
import { Col, Row } from "reactstrap";
import DEMAND_FORECASTING from "assets/img/landing-page/features/demand-forecasting.png";
import EXPIRY_DATE_TRACKING from "assets/img/landing-page/features/expiry-date-tracking.png";
import GST_COMPLIANCE from "assets/img/landing-page/features/gst-compliance.png";
import INVENTORY_MANAGEMENT from "assets/img/landing-page/features/inventory-management.png";
import MESSAGE_AUTOMATION from "assets/img/landing-page/features/message-automation.png";
import MULTI_DEVICE_SUPPORT from "assets/img/landing-page/features/multi-device-support.png";
import OCR_TECHNOLOGY from "assets/img/landing-page/features/ocr-technology.png";
import SPEECH_TO_TEXT from "assets/img/landing-page/features/speech-to-text.png";
import SUPPLIER_INTEGRATION from "assets/img/landing-page/features/supplier-integration.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

const Features = () => {
  const features = [
    {
      title: "OCR Technology for Bills",
      description:
        "Utilize Optical Character Recognition (OCR) to quickly extract text and data from scanned bills, streamlining data entry processes.",
      img: `${ERP_CDN_IMAGE_URL}/ocr-technology.png`
        ? `${ERP_CDN_IMAGE_URL}/ocr-technology.png`
        : OCR_TECHNOLOGY,
    },
    {
      title: "Expiry Date Tracking & Notification System",
      description:
        "Keep track of product expiry dates effectively with automated alerts and notifications, minimizing waste and improving inventory management.",
      img: `${ERP_CDN_IMAGE_URL}/expiry-date-tracking.png`
        ? `${ERP_CDN_IMAGE_URL}/expiry-date-tracking.png`
        : EXPIRY_DATE_TRACKING,
    },
    {
      title: "Demand Forecasting & Procurement Planning",
      description:
        "Leverage predictive analytics to forecast demand and plan procurement accurately, ensuring optimal stock levels and reducing overhead costs.",
      img: `${ERP_CDN_IMAGE_URL}/demand-forecasting.png`
        ? `${ERP_CDN_IMAGE_URL}/demand-forecasting.png`
        : DEMAND_FORECASTING,
    },
    {
      title: "Advanced Speech-to-Text Invoicing",
      description:
        "Streamline your invoicing process with advanced speech-to-text technology, making it faster and more intuitive to create detailed invoices.",
      img: `${ERP_CDN_IMAGE_URL}/speech-to-text.png`
        ? `${ERP_CDN_IMAGE_URL}/speech-to-text.png`
        : SPEECH_TO_TEXT,
    },
    {
      title: "GST Compilance",
      description:
        "Ensure seamless GST compliance with automated calculations, real-time updates, and accurate tax reporting, reducing the risk of errors.",
      img: `${ERP_CDN_IMAGE_URL}/gst-compliance.png`
        ? `${ERP_CDN_IMAGE_URL}/gst-compliance.png`
        : GST_COMPLIANCE,
    },
    {
      title: "Supplier Integration",
      description:
        "Integrate with suppliers effortlessly to streamline procurement processes, improve communication, and ensure timely delivery of goods.",
      img: `${ERP_CDN_IMAGE_URL}/supplier-integration.png`
        ? `${ERP_CDN_IMAGE_URL}/supplier-integration.png`
        : SUPPLIER_INTEGRATION,
    },
    {
      title: "Inventory Management",
      description:
        "Optimize inventory with real-time tracking, automated reordering, and comprehensive reporting to maintain optimal stock levels.",
      img: `${ERP_CDN_IMAGE_URL}/inventory-management.png`
        ? `${ERP_CDN_IMAGE_URL}/inventory-management.png`
        : INVENTORY_MANAGEMENT,
    },
    {
      title: "Message Automation",
      description:
        "Enhance communication with automated messaging for notifications, reminders, and customer interactions, eliminating manual intervention.",
      img: `${ERP_CDN_IMAGE_URL}/message-automation.png`
        ? `${ERP_CDN_IMAGE_URL}/message-automation.png`
        : MESSAGE_AUTOMATION,
    },
    {
      title: "Multi-Device Support",
      description:
        "Access your system seamlessly across multiple devices, including mobile, cloud, and PC, providing flexibility and convenience for on-the-go operations.",
      img: `${ERP_CDN_IMAGE_URL}/multi-device-support.png`
        ? `${ERP_CDN_IMAGE_URL}/multi-device-support.png`
        : MULTI_DEVICE_SUPPORT,
    },
  ];

  return (
    <div className="features-section">
      <h1 className="landing-page-title">Our Features</h1>
      <div className="feature-card-container">
        {/* <Row> */}
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          className="feature-swiper"
          autoplay={{
            delay: 2500,
          }}
          breakpoints={{
            480: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1920: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          modules={[Autoplay]}
        >
          {features.map((feature, index) => (
            // <Col lg={4} md={4} xs={12} key={index}>
            <SwiperSlide key={index}>
              <div className="feature-card">
                <h4>{feature.title}</h4>
                <img src={feature.img} alt={feature.title} />
                <p>{feature.description}</p>
              </div>
            </SwiperSlide>
            // </Col>
          ))}
        </Swiper>
        {/* </Row> */}
      </div>
    </div>
  );
};

export default Features;
