import { DirectLine } from "botframework-directlinejs";
import React from "react";
import ReactWebChat from "botframework-webchat";
import { Card, CardBody, CardHeader } from "reactstrap";
import { GiMagicHat } from "react-icons/gi";
import { FaWindowMaximize } from "react-icons/fa";
import { MdMaximize } from "react-icons/md";
import { FaSnapchatGhost } from "react-icons/fa";

import { BsMarkdownFill } from 'react-icons/bs'; // Import Bootstrap icon


// Custom renderMarkdown function to format URLs with the icon
const renderMarkdown = (markdownText) => {
  // Regex to find URLs in the text
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  // Replace URLs with clickable links and the BsMarkdownFill icon
  return markdownText.replace(urlRegex, (url) => {
    return `
      <span style="color: inherit;"> 
        <BsMarkdownFill style="color: #1a0dab; margin-right: 4px;" /> 
        <a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #1a0dab; text-decoration: underline;">${url}</a>
      </span>
    `;
  });
};


export default class extends React.Component {
  constructor(props) {
    super(props);
    // this.handleClickChat2 = this.props.handleClickChat2

    this.directLine = new DirectLine({
      token: "PNSL6r8aYu4.PLP6xvsSn4L2dTkkL6VabOcOu5pTnWWu7XZjbMAUTv8",
    });

    this.styleOptions = {
        rootHeight: "390px",
        innerHeight: "100px",
        // General bubble styling
        bubbleBackground: '#e3f2fd',  // Soft Blue background for bubbles
        bubbleTextColor: '#553366',   // Navy Blue text color
      
        // User message bubble styling
        bubbleBackgroundColorUser: '#0078d4',  // Blue background for user messages
        bubbleTextColorUser: '#ffffff',        // White text color for user messages

        // Bot message bubble styling
        bubbleBackgroundColorBot: '#ffffff',   // White background for bot messages
        bubbleTextColorBot: '#333333',         // Dark gray text color for bot messages

        // Link styling
        linkColor: '#1a0dab',  // Standard blue color for links
        linkHoverColor: '#551A8B', // Color when hovering over links

        // Bubble border styling
        bubbleBorderColor: '#dcdcdc', // Light gray border color
        bubbleBorderRadius: '10px',   // Rounded corners for bubbles

        // Bubble padding and margin
        bubblePadding: '7px', // Padding inside the bubble
        bubbleMargin: '4px',   // Margin between bubbles

        // Font settings
        bubbleFontFamily: 'Arial, sans-serif', // Font family for bubble text
        bubbleFontSize: '18px', // Font size for bubble text

    };
  }
  render() {
    const userID = localStorage.getItem("loginId");
    return (
      <>
        <Card style={{ border: "1px solid #186bc3" }}>
          <CardHeader
            style={{
              backgroundImage:
                "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)",
            }}
          >
            <p style={{ color: "#ffff" }}>
              <FaSnapchatGhost
                size={23}
                style={{ color: "#ffff", marginTop: "-2px" }}
              />
              <span
                style={{
                  color: "#ffff",
                  paddingLeft: "8px",
                  verticalAlign: "middle",
                }}
              >
                HelpChat
              </span>
              <span
                onClick={() => {
                  this.props.setClasses("dropdown");
                  localStorage.setItem("isChatBotActive", "false"); // Set chatbot as inactive in local storage
                }}
                style={{
                  float: "right",
                  marginRight: "3%",
                  marginTop: "2%",
                  color: "#ffff",
                }}
              >
                <MdMaximize size={24} style={{ cursor: "pointer" }} />
              </span>
            </p>
          </CardHeader>
          <CardBody id="chat">
            <ReactWebChat
              styleOptions={this.styleOptions}
              directLine={this.directLine}
              userID={this.userID}
              renderMarkdown={renderMarkdown}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}
