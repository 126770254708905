import BaseRepository from "./BaseRepository";
import CompanyProfileAPICall from "../service/CompanyProfileAPICall";
import Common from "helpers/Common";
import { isEmpty } from "underscore";

class CompanyProfileRepository extends BaseRepository {
  updateLocationDetails = async (params) => {
    return await new CompanyProfileAPICall().updateLocationDetails(params);
  };

  getLocationDetails = async () => {
    return await new CompanyProfileAPICall().getLocationDetails();
  };

  updateLegalInfo = async (params) => {
    return await new CompanyProfileAPICall().updateLegalInfo(params);
  };

  getLegalInfo = async () => {
    return await new CompanyProfileAPICall().getLegalInfo();
  };

  getCompanyTaxCategory = async () => {
    return await new CompanyProfileAPICall().getCompanyTaxCategory();
  };

  getCompanyState = async () => {
    return await new CompanyProfileAPICall().getCompanyState();
  };

  updateTaxCSGst = async (params) => {
    return await new CompanyProfileAPICall().updateTaxCSGst(params);
  };

  getTaxCSGST = async () => {
    return await new CompanyProfileAPICall().getTaxCSGST();
  };

  updateTaxGst = async (params) => {
    return await new CompanyProfileAPICall().updateTaxGst(params);
  };

  getTaxGST = async () => {
    return await new CompanyProfileAPICall().getTaxGST();
  };

  createBranchDetails = async (params) => {
    return await new CompanyProfileAPICall().createBranchDetails(params);
  };

  getBranchList = async () => {
    return await new CompanyProfileAPICall().getBranchList();
  };

  getBranchDetailById = async (params) => {
    return await new CompanyProfileAPICall().getBranchDetailById(params);
  };

  updateBranchDetail = async (params) => {
    return await new CompanyProfileAPICall().updateBranchDetail(params);
  };

  getCustomerEnableServicesDetails = async () => {
    return await new CompanyProfileAPICall().getCustomerEnableServicesDetails();
  };
  saveCustomerEnableServicesDetails = async (params) => {
    return await new CompanyProfileAPICall().saveCustomerEnableServicesDetails(
      params
    );
  };

  updateGenricData = async (param) => {
    return await new CompanyProfileAPICall().updateGenricData(param);
  };

  getGenricData = async () => {
    return await new CompanyProfileAPICall().getGenricData();
  };

  getUsers = async () => {
    return await new CompanyProfileAPICall().getUsers();
  };

  getRolesList = async () => {
    return await new CompanyProfileAPICall().getRolesList();
  };
  

  getPageSize = () => {
    let res = Common.getCompanyProfileSetting();
    return !isEmpty(res)
      ? Common.getValueByKeyWithSpecificValueFromKey(
        res,
        "feature_key",
        "Inventory_Page_Size"
      )[0]
      : 5;
  };

  getUPIId = () => {
    let res = Common.getCompanyProfileSetting();
    return !isEmpty(res)
      ? Common.getValueByKeyWithSpecificValueFromKey(
        res,
        "feature_key",
        "admin_upi_id"
      )[0]
      : '';
  };
}

export default CompanyProfileRepository;
