import BaseRepository from "./BaseRepository";
import DashboardAPICall from "service/DashboardAPICall";

class CreateDashboardRepository extends BaseRepository {
    getDashboardData = async (params) => {
        return await new DashboardAPICall().getDashboardDetail(params);
    };
    getCompanyData = async (params) => {
        return await new DashboardAPICall().getComapnyListDeatils(params);
    };
}

export default CreateDashboardRepository;