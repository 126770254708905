
import ActivityAPICall from "service/ActivityAPICall";
import BaseRepository from "./BaseRepository";

class ActivityRepository extends BaseRepository {
  getActivityLogList = async (params) => {
    return await new ActivityAPICall().getActivityLogList(params);
  };
}

export default ActivityRepository;
