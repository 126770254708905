import FeedbackAPICall from "service/FeedbackAPICall";
import BaseRepository from "./BaseRepository";

class FeedbackRepository extends BaseRepository {
  submitFeedback = async (params) => {
    return await new FeedbackAPICall().submitFeedback(params);
  };
  getFeedbackList = async (params) => {
    return await new FeedbackAPICall().getFeedbackList(params);
  };
}

export default FeedbackRepository;
