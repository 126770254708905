import APIHandler from "helpers/APIHandler";
import { GET_PRODUCT_QTY_HISTORY } from "helpers/APIRouter"; 

const API = new APIHandler();

class ProductQytAPICall {

    getProductQytDetails = async (params) => {
        let res = {}
        await API
            .get(GET_PRODUCT_QTY_HISTORY, params)
            .then((result) => {
                if (result) {
                    res = result.result
                }
            }).catch((e) => {
                console.log(e)
            });
        return res
    };
}

export default ProductQytAPICall;