import CustomButton from 'components/Button/CustomButton';
import LoadMore from 'components/Button/LoadMore';
import PageLoader from 'components/Loader/PageLoader';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { GoUpload } from 'react-icons/go';
import { IoMdSearch } from 'react-icons/io';
import { Card, CardBody, Col, Row } from 'reactstrap';

const CustomersList = () => {
    const [loader, setLoader] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    const SAMAPLE_DATA = [
        {
            name: 'Ajit Solanki',
            email: 'ajitsolanki@gmail.com',
            phone: '1234567890',
            city: 'Ahmedabad',
            pincode: '402154',
            state: 'Gujarat'
        },
        {
            name: 'Ajit Solanki',
            email: 'ajitsolanki@gmail.com',
            phone: '1234567890',
            city: 'Ahmedabad',
            pincode: '402154',
            state: 'Gujarat'
        },
        {
            name: 'Ajit Solanki',
            email: 'ajitsolanki@gmail.com',
            phone: '1234567890',
            city: 'Ahmedabad',
            pincode: '402154',
            state: 'Gujarat'
        },
        {
            name: 'Ajit Solanki',
            email: 'ajitsolanki@gmail.com',
            phone: '1234567890',
            city: 'Ahmedabad',
            pincode: '402154',
            state: 'Gujarat'
        },
        {
            name: 'Ajit Solanki',
            email: 'ajitsolanki@gmail.com',
            phone: '1234567890',
            city: 'Ahmedabad',
            pincode: '402154',
            state: 'Gujarat'
        },
    ];

    const ActionTemplate = ({ rowData }) => {
        return (
            <div className="d-flex justify-content-around w-100">
                {/* <button
                    type="button"
                    className="btn btn-link"
                >
                    <FaEdit className="table-action-icon" />
                </button> */}
                <button
                    type="button"
                    className="template-edit-btn"
                    onClick={() => { }}
                >
                    <FaEdit size={20} />
                </button>
            </div>
        )
    }

    const loadMoreData = () => {
    };

    return (
        <>
            {loader ? (
                <PageLoader />
            ) : (
                <>
                    <div className="content-dashboard-report">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="title">Customers / List</h4>
                        </div>

                        <Row>
                            <Col md="12">
                                <Card className="report-table-card">
                                    <CardBody>
                                        <Row className='mb-2'>
                                            <Col sm="12" md="4" lg="4">
                                                <div className="report-table-title">
                                                    <h5>Customer List</h5>
                                                </div>
                                            </Col>
                                            <Col sm="12" md="4" lg="4" className='d-flex justify-content-center align-items-center'>
                                                <div className="customer-search-box">
                                                    <input type="text" placeholder='Search customer...' />
                                                    <IoMdSearch size={20} />
                                                </div>
                                            </Col>
                                            <Col sm="12" md="4" lg="4" className='export-btn-wrapper'>
                                                <div className="export-btn-container">
                                                    <CustomButton
                                                        label="Export Data"
                                                        icon={<GoUpload size={15} />}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <DataTable
                                            value={SAMAPLE_DATA ?? []}
                                            tableStyle={{ minWidth: "25rem" }}
                                            pt={{
                                                root: { className: "table" },
                                            }}
                                        >
                                            <Column field="name" header="Customer Name"></Column>
                                            <Column field="email" header="Customer Email"></Column>
                                            <Column field="phone" header="Phone"></Column>
                                            <Column field="city" header="City"></Column>
                                            <Column field="pincode" header="Pincode"></Column>
                                            <Column field="state" header="State"></Column>
                                            <Column
                                                className="text-center"
                                                header="Edit"
                                                body={(rowData) => <ActionTemplate rowData={rowData} />}
                                                exportable={false}
                                                align="center"
                                            />
                                        </DataTable>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                {loadingMore ? (
                                    <ChildLoader />
                                ) : (
                                    <LoadMore
                                        totalData={SAMAPLE_DATA.length}
                                        handleLoadMore={() => loadMoreData()}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </>
    )
}

export default CustomersList