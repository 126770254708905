import React from "react";

const InvoiceBillPrint = () => {
    return(
        <>
            InvoiceBillPrint
        </>
    )
}

export default InvoiceBillPrint