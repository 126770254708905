import APIHandler from "helpers/APIHandler";
import { UPDATE_COMPANY_BRANCH_API } from "helpers/APIRouter";
import { UPDATE_GENRIC_DATA_API } from "helpers/APIRouter";
import { GET_ROLES_LIST_API } from "helpers/APIRouter";
import { GET_USERS_LIST_API } from "helpers/APIRouter";
import { GET_GENRIC_DATA_API } from "helpers/APIRouter";
import { GET_BRANCH_DETAIL_BY_ID_API } from "helpers/APIRouter";
import { GET_COMPANY_BRANCH_LIST_API } from "helpers/APIRouter";
import {
  UPDATE_LOCATION_DETAILS_API,
  GET_LOCATION_DETAILS_API,
  UPDATE_LEGAL_INFO_API,
  GET_LEGAL_DETAILS_API,
  GET_COMPANY_TAX_CATEGORY_API,
  GET_COMPANY_STATE_API,
  UPDATE_TAX_CSGST_API,
  GET_TAX_CSGST_API,
  UPDATE_TAX_GST_API,
  GET_TAX_GST_API,
  CREATE_COMPANY_BRANCH_API,
  GET_CUSTOMER_ENABLED_SERVICES_DETAILS_API,
  SAVE_CUSTOMER_ENABLED_SERVICES_DETAILS_API,
} from "helpers/APIRouter";

const API = new APIHandler();

class CompanyProfileAPICall {
  updateLocationDetails = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPDATE_LOCATION_DETAILS_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getLocationDetails = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_LOCATION_DETAILS_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  updateLegalInfo = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPDATE_LEGAL_INFO_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getLegalInfo = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_LEGAL_DETAILS_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getCompanyTaxCategory = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_COMPANY_TAX_CATEGORY_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getCompanyState = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_COMPANY_STATE_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  updateTaxCSGst = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPDATE_TAX_CSGST_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getTaxCSGST = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_TAX_CSGST_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  updateTaxGst = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPDATE_TAX_GST_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getTaxGST = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_TAX_GST_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  createBranchDetails = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(CREATE_COMPANY_BRANCH_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getBranchList = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_COMPANY_BRANCH_LIST_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getBranchDetailById = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_BRANCH_DETAIL_BY_ID_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  updateBranchDetail = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPDATE_COMPANY_BRANCH_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getCustomerEnableServicesDetails = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_CUSTOMER_ENABLED_SERVICES_DETAILS_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  saveCustomerEnableServicesDetails = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(SAVE_CUSTOMER_ENABLED_SERVICES_DETAILS_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
  updateBranchDetail = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPDATE_COMPANY_BRANCH_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  updateGenricData = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPDATE_GENRIC_DATA_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getGenricData = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_GENRIC_DATA_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getUsers = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_USERS_LIST_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getRolesList = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_ROLES_LIST_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default CompanyProfileAPICall;
