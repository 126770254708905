import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import logo from "assets/img/new-logo.svg";
import { URL_LOGIN } from "helpers/Paths";
import store from "store/store";
import { logout } from "store/slice/Login";
import { AUTH_URL_LOGIN } from "helpers/Paths";
import { URL_OTP } from "helpers/Paths";
import { URL_CHANGE_PASSWORD } from "helpers/Paths";
import { URL_INVENTORY_FILE_SUMMARY_DETAIL } from "helpers/Paths";
import { URL_SIGNUP } from "helpers/Paths";
import { URL_SIGNUPEMAIL } from "helpers/Paths";
import { URL_REGISTRATION } from "helpers/Paths";
import { URL_COMPANY_PROFILE_V2 } from "helpers/Paths";
import { URL_TECHNICAL_ALERT } from "helpers/Paths";
import { URL_RECENT_BILLS } from "helpers/Paths";
import { URL_RECENT_ENVENTORY } from "helpers/Paths";
import { URL_SUB_HELP } from "helpers/Paths";
import { URL_SIGNUP_WITH_DOCUMENTS } from "helpers/Paths";
import { AUTH_SIGNUP_DOCUMENT_DETAILS } from "helpers/Paths";
import app_logo from "assets/img/logo-text-light.svg";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

var ps;

function Sidebar(props) {
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const sidebar = React.useRef();
  const navigate = useNavigate();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  /**
   * Default all collapse state will be set false
   * Author: Devanshi Patel
   */
  const getInitialCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: false,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes, withCollapse = false) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseStates[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            {/**
             * when click on collapse menu default first sub menu open
             * Author: Devanshi Patel
             */}

            <Link
              className="d-flex"
              to={prop.views[0].layout + prop.views[0].path}
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                if (collapseStates[prop.state]) {
                  e.preventDefault();
                }
                setCollapseStates(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  {/* <img
                    className="mr-1"
                    src={prop.icon}
                    alt=""
                    width={25}
                    height={25}
                  /> */}
                  <div className="mr-2 icon-size">{prop.icon}</div>
                  {/* <i className={prop.icon} /> */}
                  <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {prop.name}
                  </p>
                  <b className="caret" />
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </Link>
            <Collapse
              className="background-temp font-size-collapse"
              isOpen={collapseStates[prop.state]}
            >
              <ul className="nav">{createLinks(prop.views, true)}</ul>
            </Collapse>
          </li>
        );
      }
      if (
        prop.path !== URL_LOGIN &&
        prop.path !== URL_OTP &&
        prop.path !== URL_CHANGE_PASSWORD &&
        prop.path !== URL_INVENTORY_FILE_SUMMARY_DETAIL &&
        prop.path !== URL_SIGNUP &&
        prop.path !== URL_SIGNUPEMAIL &&
        prop.path !== URL_REGISTRATION &&
        prop.path !== URL_COMPANY_PROFILE_V2 &&
        prop.path !== URL_RECENT_BILLS &&
        prop.path !== URL_RECENT_ENVENTORY &&
        prop.path !== URL_TECHNICAL_ALERT &&
        prop.path !== URL_SIGNUP_WITH_DOCUMENTS &&
        prop.path !== AUTH_SIGNUP_DOCUMENT_DETAILS
      ) {
        return (
          <li className={activeRoute(prop.layout + prop.path)} key={key}>
            <Link
              className="d-flex"
              to={prop.layout + prop.path}
              /**
               * close all collapse onclick of without collapse menu link
               * Author: Devanshi patel
               */
              onClick={(e) => {
                if (!withCollapse)
                  setCollapseStates(getInitialCollapseStates(props.routes));
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  {/* <img
                    className="mr-2 icon-size"
                    src={prop.icon}
                    alt=""
                    width={25}
                    height={25}
                  /> */}
                  {prop.icon && (
                    <div className="mr-2 icon-size">{prop.icon}</div>
                  )}
                  {/* <i className={prop.icon} /> */}
                  <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {prop.name}
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            </Link>
          </li>
        );
      }
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);

  const onLogoutClick = () => {
    store.dispatch(logout());
    navigate(AUTH_URL_LOGIN);
  };

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a
          href="https://apsthreeai.ai/"
          target="_blank"
          className="simple-text logo-mini"
        >
          <div className="logo-img">
            {/* <img src={logo} alt="react-logo" /> */}
            <img
              src={`${ERP_CDN_IMAGE_URL}/new-logo.svg`}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop if logo also fails
                e.target.src = logo;
              }}
              alt="react-logo"
            />
          </div>
        </a>
        <a
          href="https://apsthreeai.ai/"
          target="_blank"
          className="simple-text logo-normal"
        >
          {/* <img src={app_logo} alt="APS THREEAI" className="" /> */}
          <img
            src={`${ERP_CDN_IMAGE_URL}/logo-text-light.svg`}
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop if logo also fails
              e.target.src = app_logo;
            }}
            alt="APS THREEAI"
          />
          {/* {window.APSTHREEAILLP.productName} */}
        </a>
        {/* <a href="https://apsthreeai.ai/" target="_blank" className="logo-normal">
        </a> */}
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>{createLinks(props.routes)}</Nav>
      </div>
    </div>
  );
}

export default Sidebar;
