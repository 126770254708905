import React, { useEffect, useState } from "react";
// reactstrap components
import { Card, CardBody, Table } from "reactstrap";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ChildLoader from "components/Loader/ChildLoader";
import { useSubscriptionStore } from "hooks/Subscription";
import { isEmpty } from "underscore";
import { useLoginStore } from "hooks/Login";

const PlanDetails = () => {
  const [{}, { getCompanySubscriptionDetails }, {}] = useSubscriptionStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [subData, setsubData] = useState(null);
  const [loadingFilter, setLoadingFilter] = useState(false);

  useEffect(() => {
    setLoadingFilter(true);
    const getCurrentSubscriptionDetailsData = async () => {
      let res = await getCompanySubscriptionDetails();
      if (res && !isEmpty(res)) {
        setsubData(res);
      }
      setLoadingFilter(false);
    };
    getCurrentSubscriptionDetailsData();
  }, []);

  return (
    <>
      <div className="content-dashboard-report">
        <div className="d-flex justify-content-between">
          <h4 className="title">Billing & Usage Overview</h4>
        </div>
        <Card>
          <CardBody>
            {loadingFilter ? (
              <ChildLoader />
            ) : (
              subData && (
                <div style={{ padding: "2px 40px 2px 40px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        padding: "8px",
                      }}
                    >
                      <h5 style={{ margin: "0 0 8px 0" }}>Company Details</h5>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: "0.85rem",
                        }}
                      >
                        <div style={{ flex: "1", paddingRight: "20px" }}>
                          <p>
                            <strong>Company ID:</strong> {subData.companyId}
                          </p>
                          <p>
                            <strong>Subscription ID:</strong>{" "}
                            {subData.subscriptionId}
                          </p>
                        </div>

                        {/* Middle Column */}
                        <div style={{ flex: "1", paddingRight: "20px" }}>
                          <p>
                            <strong>Company Name:</strong> {subData.companyName}
                          </p>
                          <p>
                            <strong>Subscription Name:</strong>{" "}
                            {subData.subscriptionName}
                          </p>
                        </div>
                        <div style={{ flex: "1" }}>
                          <p>
                            <strong>Start Date:</strong>{" "}
                            {`${new Date(subData.startDate)
                              .getDate()
                              .toString()
                              .padStart(2, "0")}/${(
                              new Date(subData.startDate).getMonth() + 1
                            )
                              .toString()
                              .padStart(2, "0")}/${new Date(
                              subData.startDate
                            ).getFullYear()}`}
                          </p>
                          <p>
                            <strong>End Date:</strong>{" "}
                            {`${new Date(subData.endDate)
                              .getDate()
                              .toString()
                              .padStart(2, "0")}/${(
                              new Date(subData.endDate).getMonth() + 1
                            )
                              .toString()
                              .padStart(2, "0")}/${new Date(
                              subData.endDate
                            ).getFullYear()}`}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Invoice Table */}
                    <div
                      style={{
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        padding: "8px",
                      }}
                    >
                      <h5 style={{ margin: "0 0 0px 0" }}>Invoice Usage</h5>
                      <Table
                        bordered
                        style={{
                          margin: 0,
                          fontSize: "0.85rem", // Slightly smaller font size
                          borderCollapse: "collapse",
                          tableLayout: "fixed", // Ensure consistent column width
                        }}
                      >
                        <tbody>
                          <tr>
                            <th style={{ padding: "6px", textAlign: "center" }}>
                              Invoice Usage
                            </th>
                            <td style={{ padding: "6px", textAlign: "center" }}>
                              {subData.invoiceUsage}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ padding: "6px", textAlign: "center" }}>
                              Bill Creation Limit
                            </th>
                            <td style={{ padding: "6px", textAlign: "center" }}>
                              {subData.billCreationLimit}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>

                    {/* Inventory Table */}
                    <div
                      style={{
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        padding: "8px",
                      }}
                    >
                      <h5 style={{ margin: "0 0 0px 0" }}>Inventory Usage</h5>
                      <Table
                        bordered
                        style={{
                          margin: 0,
                          fontSize: "0.85rem", // Slightly smaller font size
                          borderCollapse: "collapse",
                          tableLayout: "fixed", // Ensure consistent column width
                        }}
                      >
                        <tbody>
                          <tr>
                            <th style={{ padding: "6px", textAlign: "center" }}>
                              Inventory Usage
                            </th>
                            <td style={{ padding: "6px", textAlign: "center" }}>
                              {subData.inventoryUsage}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ padding: "6px", textAlign: "center" }}>
                              Inventory Creation Limit
                            </th>
                            <td style={{ padding: "6px", textAlign: "center" }}>
                              {subData.inventoryCreationLimit}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>

                    {/* Notification Table */}
                    <div
                      style={{
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        padding: "8px",
                      }}
                    >
                      <h5 style={{ margin: "0 0 0px 0" }}>
                        Notification Usage
                      </h5>
                      <Table
                        bordered
                        style={{
                          margin: 0,
                          fontSize: "0.85rem", // Slightly smaller font size
                          borderCollapse: "collapse",
                          tableLayout: "fixed", // Ensure consistent column width
                        }}
                      >
                        <tbody>
                          <tr>
                            <th style={{ padding: "6px", textAlign: "center" }}>
                              SMS Usage
                            </th>
                            <td style={{ padding: "6px", textAlign: "center" }}>
                              {subData.smsUsage}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ padding: "6px", textAlign: "center" }}>
                              SMS Limit
                            </th>
                            <td style={{ padding: "6px", textAlign: "center" }}>
                              {subData.smsLimit}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ padding: "6px", textAlign: "center" }}>
                              Email Usage
                            </th>
                            <td style={{ padding: "6px", textAlign: "center" }}>
                              {subData.emailUsage}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ padding: "6px", textAlign: "center" }}>
                              Email Limit
                            </th>
                            <td style={{ padding: "6px", textAlign: "center" }}>
                              {subData.emailLimit}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ padding: "6px", textAlign: "center" }}>
                              WhatsApp Usage
                            </th>
                            <td style={{ padding: "6px", textAlign: "center" }}>
                              {subData.whatsUpUsage}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ padding: "6px", textAlign: "center" }}>
                              WhatsApp Limit
                            </th>
                            <td style={{ padding: "6px", textAlign: "center" }}>
                              {subData.whatsUpLimit}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              )
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default PlanDetails;
