import FeedbackRepository from "repository/FeedbackRepository";

export function useFeedbackStore() {
  /**
   * Here is list of use selectors which are relates to Feedback
   * Author: Shivam Rayjada
   */

  let data = {};
  let fetch = {
    getFeedbackList: async (params) => {
      return await new FeedbackRepository().getFeedbackList(params);
    },
  };
  let set = {
    /**
     * Here is list of fetch methods which are used for set or update data from API or Repositories related to Feedback
     * Author: Shivam Rayjada
     */
    submitFeedback: async (params) => {
      return await new FeedbackRepository().submitFeedback(params);
    },
  };
  return [data, fetch, set];
}
