import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/Stepper.css";

const Stepper = ({
  currentStep,
  setCurrentStep,
  steps,
  isComplete,
  setIsComplete,
}) => {
  // const [currentStep, setCurrentStep] = useState(1);
  // const [isComplete, setIsComplete] = useState(false);
  const [margins, setMargins] = useState({
    marginLeft: 0,
    marginRight: 0,
  });
  const stepRef = useRef([]);

  useEffect(() => {
    if (stepRef.current[0] && stepRef.current[steps.length - 1]) {
      setMargins({
        marginLeft: stepRef.current[0].offsetWidth / 2,
        marginRight: stepRef.current[steps.length - 1].offsetWidth / 2,
      });
    }
  }, [stepRef, steps.length]);

  if (!steps.length) {
    return <></>;
  }

  const calculateProgressBarWidth = () => {
    return ((currentStep - 1) / (steps.length - 1)) * 100;
  };

  const progressBarColor = isComplete
    ? "#28a745"
    : currentStep > 1
      ? "#007bff"
      : "#ccc";

  return (
    <>
      <div className="stepper">
        {steps.map((step, index) => (
          <div key={index} className="steps">
            <p className={`step-count ${currentStep > index + 1 || isComplete ? "active-step" : ""
              } ${currentStep === index + 1 ? "active-step" : ""}`}>{index + 1}</p>
            <p>{step.title}</p>
            <div className={`line d-none d-md-block ${currentStep > index + 1 || isComplete ? "active-step" : ""
              } ${currentStep === index + 1 ? "active-step" : ""}`}></div>
          </div>
        ))}
        {/* {steps.map((step, index) => (
          <div
            key={step.title}
            ref={(el) => (stepRef.current[index] = el)}
            className={`step ${
              currentStep > index + 1 || isComplete ? "complete" : ""
            } ${currentStep === index + 1 ? "active" : ""}`}
          >
            <div className="step-number">
              {currentStep > index + 1 || isComplete ? (
                <span>&#10003;</span>
              ) : (
                <img
                  src={step.icon}
                  alt={step.title}
                  className={`step-icon ${
                    currentStep === index + 1 ? "step-icon-active" : ""
                  }`}
                />
              )}
            </div>
            <div className="step-name">{step.title}</div>
          </div>
        ))}

        <div
          className="progress-bar"
          style={{
            width: `calc(100% - ${margins.marginLeft + margins.marginRight}px)`,
            marginLeft: margins.marginLeft,
            marginRight: margins.marginRight,
          }}
        >
          <div
            className="progress"
            style={{
              width: `${calculateProgressBarWidth()}%`,
              backgroundColor: progressBarColor,
            }}
          ></div>
        </div> */}
      </div>
    </>
  );
};

export default Stepper;
