import { useLoginStore } from "hooks/Login";
import { useNotificationStore } from "hooks/Notification";
import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, ButtonGroup, Card, Col, Container, Row } from "reactstrap";
import { TabView, TabPanel } from "primereact/tabview";
import { isEmpty } from "underscore";
import { useCompanyStore } from "hooks/Company";
import NotificationEmailSchedule from "./NotificationEmailSchedule";
import NotificationWPSchedule from "./NotificationWPSchedule";
import NotificationSMSSchedule from "./NotificationSMSSchedule";
import NotificationScheduleList from "./NotificationScheduleList";
import "index.css";
import Common from "helpers/Common";
import "assets/styles/notification/CustomerNotification.css"

// react icons
import { MdSms } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaEnvelope } from 'react-icons/fa';



const items = [
  {
    id: 0,
    icon: <FaEnvelope size={20} />,
    title: "Email",
  },
  {
    id: 1,
    icon: <IoLogoWhatsapp size={20} />,
    title: "Whatsapp",
  },
  {
    id: 2,
    icon: <MdSms size={20} />,
    title: "SMS",
  },


]

const NotificationSchedule = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [notificationData, setNotificationData] = useState("");

  const [, { getNotificationById },] = useNotificationStore();
  const [{ customerList }, { getCustomerByCompanyId },] = useCompanyStore();

  useEffect(() => {
    async function getCustomerList() {
      await getCustomerByCompanyId();
    }
    getCustomerList();
  }, []);

  const notificationUpdateClick = async (notificationSettingID) => {
    let notificationsData = await getNotificationById({
      NotificationSettingID: notificationSettingID,
    });

    if (notificationsData && !isEmpty(notificationsData)) {
      let customerEmails = notificationsData[0]?.to.split(",");

      let selectedCustomer = [];
      if (customerList && customerList.length > 0) {
        selectedCustomer = customerList.filter((item) =>
          customerEmails.includes(item.customerID.toString())
        );
      }

      let customerDropdownData = [];
      if (selectedCustomer && !isEmpty(selectedCustomer)) {
        selectedCustomer.map((v) => {
          customerDropdownData.push({
            value: v.customerID,
            label: v.customerName,
          });
        });
      }

      let previewData = notificationsData[0];
      setNotificationData({
        scheduleName: previewData?.scheduleName,
        customerEmail: customerDropdownData,
        frequency: previewData?.frequencyID,
        fromDate: Common.getFormatedDate(
          previewData?.fromDate,
          "YYYY-MM-DD",
          true
        ),
        toDate: Common.getFormatedDate(previewData?.toDate, "YYYY-MM-DD", true),
        subject: previewData?.subject,
        body: previewData?.body,
        notificationSettingID: notificationSettingID,
        attachedFileName: previewData?.attachedFileName || "",
      });
    }
  };

  return (
    <div className="customer-container ">
      <div className="title-route-container">
        <h4 className="title">Customer / {items.find((item) => item.id == activeIndex)?.title || ''}</h4>
      </div>

      <Card
        outline
        style={{
          width: "100%",
          height: "100%",
          padding: "10px 10px 20px 10px",
        }}
      >
        <Container fluid>
          <Row className="justify-content-center mt-3">
            <Col xs="12" md="6" lg="6">
              <Row className="notification-profile-tabs overflow-hidden">
                {items.slice(0, 8).map((item) => (

                  <Col sm="12" md="4" lg="4" xl="4"
                    key={item.id}
                    className={`notification-tab-item ${activeIndex === item.id ? "highlight-tab" : ""
                      }`}
                    onClick={() => setActiveIndex(item.id)}
                  >
                    <span>{item.icon}</span>
                    <p>{item.title}</p>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="" xs="12" md="12" lg="12">
              <div className="form-container">
                {
                  activeIndex == 0 && <NotificationEmailSchedule notificationData={notificationData} />
                }
                {
                  activeIndex == 1 && <NotificationWPSchedule notificationData={notificationData} />
                }
                {
                  activeIndex == 2 && <NotificationSMSSchedule notificationData={notificationData} />
                }
              </div>
            </Col>
          </Row>


          <NotificationScheduleList
            notificationTypeId={activeIndex}
            notificationUpdateClick={(notificationSettingID) =>
              notificationUpdateClick(notificationSettingID)
            }
          />
        </Container>
      </Card>
      {/* <Card
        body
        color="secondary"
        outline
        style={{
          width: "100%",
        }}
      >
        <Container className="" fluid>
          <Row xs="2">
            <Col className="" xs="12" md="12" lg="12">
              <div className="form-container">
                <TabView
                  activeIndex={activeIndex}
                  onTabChange={(e) => {
                    setActiveIndex(e.index);
                    setNotificationData("");
                  }}
                >
                  <TabPanel header="Email">
                    <NotificationEmailSchedule
                      notificationData={notificationData}
                    />
                  </TabPanel>
                  <TabPanel header="Whatsapp">
                    <NotificationWPSchedule
                      notificationData={notificationData}
                    />
                  </TabPanel>
                  <TabPanel header="SMS">
                    <NotificationSMSSchedule
                      notificationData={notificationData}
                    />
                  </TabPanel>
                </TabView>
              </div>
            </Col>
          </Row>
        </Container>
      </Card> */}
      {/* 
      <NotificationScheduleList
        notificationTypeId={activeIndex}
        notificationUpdateClick={(notificationSettingID) =>
          notificationUpdateClick(notificationSettingID)
        }
      /> */}
    </div>
  );
};

export default NotificationSchedule;
