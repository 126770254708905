import React from "react";
import {
  Spinner
} from "reactstrap";

function PageLoader(props) {
  return (
    <div style={{ height: '100vh', lineHeight: '75vh', textAlign: 'center' }}>
      <>
        <Spinner
          color="primary"
          type="grow"
        >
          Loading...
        </Spinner>
        <Spinner
          color="success"
          type="grow"
        >
          Loading...
        </Spinner>
        <Spinner
          color="warning"
          type="grow"
        >
          Loading...
        </Spinner>
      </>
    </div>
  );
}

export default PageLoader;
