/**
 * Branch API LIST
 * Author: Devanshi Patel
 */
export const BRANCH_DETAIL_API = "Branch";

/**
 * Patient API LIST
 * Author: Devanshi Patel
 */
export const PATIENT_DETAIL_API_V1 = "Patient";
export const PATIENT_DETAIL_API = "v2/Patient/";
export const SEARCH_MULTIPLE_PATIENT_API = "v2/SearchMultiplePatient/";
export const SEARCH_MULTIPLE_CUSTOMER_API =
  "Customer/Get_Customer_Search?searchCustomer=";
export const CREATE_CUSTOMER_API = "Customer/Create_Customer";
export const UPDATE_CUSTOMER_API = "Customer/Update_Customer";

/**
 * Product API LIST
 * Author: Devanshi Patel
 */
export const PRODUCT_SEARCH_API = "Products/";
export const SAVE_BILL_API_V1 = "SaveBill";
export const SAVE_BILL_API = "SaveBill_V5";
export const SAVE_INVOICE_API = "SaveInvoice";
export const REFUND_BILL_API = "RefundBill";
export const GET_RECENT_BILL_API = "GetRecentBill_V5";
export const GET_BILL_API = "GetBill_V5/";
export const TEMPLATE_LIST_API = "/GetTemplateList";
export const GET_TEMPLATE_DETAIL_API = "GetTemplateDetails_By_ID?templateID=";
export const GET_BILLS_BY_CUSTOMERID =
  "/BillPayment/Get_Bills_By_CustomerID?customerID=";
export const INVENTORY_FILE_UPLOAD_TO_AZURE_API = "InventoryImageFileUpload";
export const BILL_FILE_UPLOAD_TO_AZURE_API = "ERPInvoicePDFDocumentUpload";

/**
 * Company Login API LIST
 * Author: Devanshi Patel
 */
export const COMPANY_LOGIN_API = "CompanyLogin";
export const CHANGE_PASSWORD_API = "ChangePassword";
export const COMPANY_LOGOUT_API = "CompanyLogout";
export const LOGIN_WITH_OTP_API = "LoginWithOTP";
export const LOGIN_VERIFY_OTP_API = "LoginVerifyOTP";
export const VALIDATE_USER_LOGIN_API = "ValidateUserLogin";

/**
 * Inventory API LIST
 * Author: Devanshi Patel
 */
export const CREATE_MODIFY_INVENTORY_API = "CreateModifyInventory";
export const INVENTORY_REPORT_API = "InventoryReport";
export const UPLOAD_BLOBS_INVENTORY_API = "UploadBlobs";
export const INVENTORY_FILE_CONTENT_API = "GetFileContent";
export const UPLOAD_INVENTORY_BY_FILE_API = "UploadInventoryByFile";
export const INVENTORY_SUMMARY_API = "UploadedInventorySummary";
export const INVENTORY_SUMMARY_DETAILS_API = "UploadedInventorySummaryDetails";
export const REORDERSTOCK_REPORT_API = "ERP/ProductEligiblityReOrderV2";
export const CATEGORIES_API = "Get_ProductCategory";
export const SUB_CATEGORIES_API = "Get_ProductSubCategory";
export const SEARCH_CATEGORIES_SUB_CATEGORIES_API =
  "Search_Product_Category_SubCategory";
export const ASSIGN_INVENTORY_API = "CreateInventoryFromDefaultInventory";
export const PRODUCT_EXPIRY_REPORT_API = "ProductExpiryDateReport";


export const CREATE_MODIFY_DISCOUNT_BY_CATEGORY_API =
  "Create_Modify_CategoryBaseDiscount";
export const CREATE_MODIFY_DISCOUNT_BY_PRODUCT_API =
  "Create_Modify_ProductBaseDiscount";
export const GET_DETAILS_DISCOUNT_BY_PRODUCT_API = "DiscountDetails_By_product";
export const GET_DETAILS_DISCOUNT_BY_CATEGORY_API =
  "DiscountDetails_By_Category";

/**
 * Reports API LIST
 * Author: Devanshi Patel
 */
export const MAXIMUM_STOCK_USAGE_REPORT_API =
  "ERP/Get_Most_Used_Product_Paging";
export const MINIMUM_STOCK_USAGE_REPORT_API = "ERP/Get_Least_Used_Product";
export const REVENUE_REPORT_API = "ERP/Get_Revenue_by_Date";
export const DAILY_DEMAND_FORECAST_REPORT_API = "ERP/Get_DailyForeast";
export const WEEKLY_DEMAND_FORECAST_REPORT_API = "ERP/Get_WeeklyForeast";
export const HISTORICAL_DEMAND_REPORT_API = "ERP/Get_Historical_Sales_Data";

/**
 * Company Profile API LIST
 * Author: Devanshi Patel
 */
export const UPDATE_LOCATION_DETAILS_API =
  "CompanyProfile/UpdateLocationDetails";
export const GET_LOCATION_DETAILS_API = "CompanyProfile/GetLocationDetails";
export const UPDATE_LEGAL_INFO_API = "CompanyProfile/UpdateLegalInfo";
export const GET_LEGAL_DETAILS_API = "CompanyProfile/GetLegalInfo";
export const GET_COMPANY_TAX_CATEGORY_API =
  "CompanyProfile/GetCompanyTaxCategoryMaster";
export const GET_COMPANY_STATE_API = "CompanyProfile/GetCompanyStateMaster";
export const UPDATE_TAX_CSGST_API = "CompanyProfile/UpdateTax_CS_GST";
export const GET_TAX_CSGST_API = "CompanyProfile/GetTax_CSGST";
export const UPDATE_TAX_GST_API = "CompanyProfile/UpdateTax_GST";
export const GET_TAX_GST_API = "CompanyProfile/GetTax_GST";
export const CREATE_COMPANY_BRANCH_API = "Create_CompanyBranch";
export const GET_COMPANY_BRANCH_LIST_API = "Get_Branchlist";
export const GET_BRANCH_DETAIL_BY_ID_API = "Get_Branches_By_ID";
export const UPDATE_COMPANY_BRANCH_API = "Update_CompanyBranch";
export const GET_CUSTOMER_ENABLED_SERVICES_DETAILS_API =
  "CompanyProfile/GET_CompanyProfile_KeyValue";
export const SAVE_CUSTOMER_ENABLED_SERVICES_DETAILS_API =
  "CompanyProfile/Update_CompanyProfile_KeyValue";
export const UPDATE_GENRIC_DATA_API =
  "CompanyProfile/Update_CompanyProfile_KeyValue_ArrayData";
export const GET_GENRIC_DATA_API = "CompanyProfile/GET_CompanyProfile_KeyValue";
export const GET_USERS_LIST_API = "Get_CompanyUsers";
export const GET_ROLES_LIST_API = "GeneralERP/RoleMaster";

/**
 * Notification API LIST
 * Author: Devanshi Patel
 */
export const SMS_SEND_API = "sendSMS";
export const EMAIL_SEND_API = "SendEmail";
export const WHATSAPP_SMS_SEND_API = "whatsupmessagewithAttachmentV2";
export const OTP_SEND_API = "sendOTP";
export const OTP_SEND_FORGOT_PASSWORD = "ForgotPasswordOTPRequest";
export const OTP_SEND_SIGNUP = "SingupOTPRequest";
export const OTP_SEND_GENERAL = "SendOTPRequest";

export const VERIFY_OTP_API = "verifyOTP";
export const DASHBOARD_DETAIL_API = "GetDashboard";
export const NOTIFICATION_LIST_API = "Get_List_of_Notification";
export const CREATE_NOTIFICATION_API = "Create_NotificationSettings";
export const UPDATE_NOTIFICATION_API = "Update_NotificationSettings";
export const GET_NOTIFICATION_By_ID_API = "Get_NotificationSettings_By_ID";
export const UPDATE_NOTIFICATION_STATUS_API =
  "Update_NotificationSettingsStatus";

/**
 * Template API LIST
 * Author: Devanshi Patel
 */
export const CREATE_BILL_TEMPLATE_API = "CreateBillTemplate";
export const GET_TEMPLATE_LIST_API = "GetTemplateList";
export const GET_TEMPLATE_DETAILS_BY_ID_API = "GetTemplateDetails_By_ID";
export const UPDATE_BILL_TEMPLATE_API = "UpdateBillTemplate";
export const UPDATE_TEMPLATE_ACTIVE_STATUS_API =
  "Update_Template_Active_Status";
/**
 * Company API LIST
 * Author: Devanshi Patel
 */
export const COMPANY_SIGNUP_API = "Create_CompanyUser";
export const CREATE_COMPANY_API = "Create_Company";
export const GET_COMPANY_LIST = "Get_Companylist";
export const CREATE_COMPANY_USER_API = "Create_CompanyUser";
export const UPDATE_COMPANY_API = "Update_Company";
export const GET_CUSTOMER_By_COMPANY_ID_API =
  "Customer/Get_Customers_By_CompanyID";

export const GET_PRODUCT_QTY_HISTORY = "Get_Product_Qty_History";

/**
 * Feedback API LIST
 * Author: Shivam Rayjada
 */
export const SUBMIT_FEEDBACK_API = "GeneralERP/Create_Feedback";
export const USERS_FEEDBACKS = "GeneralERP/Get_Users_Feedbacks";

/**
 * Dashbord V2 API LIST
 * Author: Shivam Rayjada
 */
export const GET_CUSTOMER_COUNTS = "Dashboard/Get_Customer_Counts";
export const GET_PRODUCT_SALES = "Dashboard/Get_Product_Sells_CountsV2";
export const GET_FINANCIAL_ANALYSIS = "Dashboard/Get_Finance_Analysis";
export const GET_TOP_SELLING_MEDICINES = "Dashboard/Get_Top_Selling_Medicines";
export const GET_RECENT_INVENTORY = "Dashboard/Get_Recent_Inventory";
export const GET_RECENT_BILLS = "Dashboard/Get_RecentBills";
export const GET_PRODUCT_ADVANCE_SEARCH = "ProductAdvancedSearch";

/**
 * Payment API LIST
 * Author: Shivam Rayjada / Devanshi Patel
 */
export const GET_TRANSACTION_SERVICES = "BillPayment/Get_TransactionServices";
export const GET_PAYMENT_MODES = "BillPayment/Get_PaymentTypeMaster";
export const GET_BILLS_DETAILS_BY_ID = "BillPayment/Get_Bills_By_CustomerID";
export const GET_ADVANCE_PAY_DETAILS_BY_ID = "BillPayment/Get_AdvancedPayments";
export const SAVE_PAYMENT = "BillPayment/Create_Payment_Transaction";
export const SAVE_ADVANCE_PAYMENT =
  "BillPayment/Create_AdvancedPayment_Transaction";
export const RAZORPAY_CREATE_ORDER_API = "CreateOrder";
export const RAZORPAY_PROD_CREATE_ORDER_API = "CreateProdOrder";
export const STRIPE_PAYMENGT_INTENTS_API = "StripPaymentIntents";
export const GET_ACCOUNT_RECIEVABLE_DETAILS =
  "BillPayment/GetAccountReceivable";
export const GET_ACCOUNT_PAYABLE_DETAILS = "BillPayment/GetAccountPayable";
export const GET_ACCOUNT_STATEMENT_DETAILS = "BillPayment/GetAccountStatement";
/**
 * Activity API LIST
 * Author: Devanshi Patel
 */
export const USERS_ACTIVITY_LOGS = "GeneralERP/Get_Activity_Log_Notification";

/**
 * Subscription API LIST
 * Author: Devanshi Patel
 */
export const GET_COMPANY_SUBSCRIPTION =
  "CompanyProfile/Get_Company_Subscription";
export const GET_SUBSCRIPTION_DETAILS =
  "CompanyProfile/Subscription_Limit_And_Usage";
export const CREATE_COMPANY_SUBSCRIPTION =
  "CompanyProfile/Create_CompanySubscription";
export const UPDATE_COMPANY_SUBSCRIPTION =
  "CompanyProfile/Update_CompanySubscription";
export const CREATE_CUSTOM_PLAN_SUBSCRIPTION =
  "CompanyProfile/Renew_CompanySubscription";
