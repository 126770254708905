import { useEffect, useRef, useState } from "react";

// reactstrap components
import { Button, Card, CardBody, Col } from "reactstrap";
import { } from "recharts";

// used components
import { useDashboardV2DataStore } from "hooks/DashboardV2";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FaDownload, FaEye, FaPlus } from "react-icons/fa6";
import { FaPencilAlt } from "react-icons/fa";
import ChildLoader from "components/Loader/ChildLoader";
import { ADMIN_URL_RECENT_BILLS } from "helpers/Paths";
import { FaTrash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import { Link } from "react-router-dom";
import LoadMore from "components/Button/LoadMore";
import { RiRefund2Line } from "react-icons/ri";
import Common from "helpers/Common";
import { useAzureStore } from "hooks/Azure";

const Bills = (props) => {
  const [{ }, { getRecentBills }, { }] = useDashboardV2DataStore();
  let fromDateParam = props.fromDate;
  let toDateParam = props.toDate;
  const [loader, setLoader] = useState(false);
  const [recentBillsData, setRecentBillsData] = useState([]);
  const [selectedRecentBills, setSelectedRecentBills] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const dt = useRef(null);
  const navigate = useNavigate();
  const [{ }, { fetchImagesFromAzure }, { }] = useAzureStore();

  useEffect(() => {
    setLoader(true);
    getRecentBillsData();
  }, []);

  useEffect(() => {
    setLoader(true);
    getRecentBillsData();
  }, [fromDateParam]);

  function formatDate(date) {
    let inputDate = new Date(date);
    let year = inputDate.getFullYear();
    let month = String(inputDate.getMonth() + 1).padStart(2, "0");
    let day = String(inputDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getTodayDate() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  const getRecentBillsData = async (page = 1) => {
    if (page === 1) {
      setPageNumber(1);
    }

    try {
      let RecentBillsData = await getRecentBills({
        params: {
          fromdate: fromDateParam,
          toDate: toDateParam || formatDate(getTodayDate()),
          pagesize: new CompanyProfileRepository().getPageSize(),
          pageNumber: page,
          // fromdate: "2022-11-01",
          // todate: "2024-12-07",
          // topRecords: 3,
        },
      });
      if (page > 1) {
        setRecentBillsData((prev) => [...prev, ...RecentBillsData]);
        setLoadingMore(false);
        setLoader(false);
      } else {
        setRecentBillsData(RecentBillsData);
        setLoader(false);
        setLoadingMore(false);
      }
    } catch (error) {
      // for error handling
      // alert(error);
    }
    setLoadingMore(false);
  };

  const loadMoreData = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
    setLoadingMore(true);
    getRecentBillsData(pageNumber + 1);
  };

  const onDelete = (value) => console.log(value);

  const redirectToRecentBills = () => {
    navigate(ADMIN_URL_RECENT_BILLS);
  };

  const RecentBills = () => {
    // Fetch PDF from Azure
    const fetchPDF = async (name, type) => {
      let pdfURL = await fetchImagesFromAzure(name, "bill");
      if (type?.toLowerCase() === "preview") {
        const response = await fetch(pdfURL);
        const pdfData = await response.blob();
        const file = new Blob([pdfData], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      } else {
        window.open(pdfURL, "_blank");
      }
    };

    const ActionTemplate = ({ rowData }) => {
      return (
        <div className="d-flex justify-content-around w-100">
          <Link
            to={
              rowData.billType?.toLowerCase() === "refund" ||
                rowData.editableStatus === 0
                ? "#"
                : `/admin/editBill/${rowData.billId}`
            }
            onClick={(e) => {
              if (
                rowData.billType?.toLowerCase() === "refund" ||
                rowData.editableStatus === 0
              ) {
                e.preventDefault();
              }
            }}
          >
            <button
              type="button"
              title="Edit Bill"
              disabled={
                rowData.billType?.toLowerCase() === "refund" ||
                rowData.editableStatus === 0
              }
              className={`actions-btn ${rowData.billType?.toLowerCase() === "refund" ||
                rowData.editableStatus === 0
                ? "disabled-action-btn"
                : ""
                }`}
              style={{
                backgroundColor:
                  rowData.billType?.toLowerCase() === "refund" ||
                    rowData.editableStatus === 0
                    ? "#d3d3d3"
                    : "#F8F8FF",
                color:
                  rowData.billType?.toLowerCase() === "refund" ||
                    rowData.editableStatus === 0
                    ? "#a9a9a9"
                    : "#fff",
                cursor:
                  rowData.billType?.toLowerCase() === "refund" ||
                    rowData.editableStatus === 0
                    ? "not-allowed"
                    : "pointer",
                opacity:
                  rowData.billType?.toLowerCase() === "refund" ||
                    rowData.editableStatus === 0
                    ? "0.6"
                    : "1",
              }}
            >
              <FaPencilAlt className="table-action-icon" />
            </button>
          </Link>

          <Link
            to={
              rowData.billType.toLowerCase() === "refund"
                ? "#"
                : `/admin/refundBill/${rowData.billId}`
            }
          >
            <button
              type="button"
              title="Refund Bill"
              disabled={rowData.billType.toLowerCase() === "refund"}
              className={`actions-btn ${rowData.billType.toLowerCase() === "refund"
                ? "disabled-action-btn"
                : ""
                }`}
              style={{
                backgroundColor:
                  rowData.billType.toLowerCase() === "refund"
                    ? "#d3d3d3"
                    : "#F8F8FF", // Change to desired background color for disabled state
                color:
                  rowData.billType.toLowerCase() === "refund"
                    ? "#a9a9a9"
                    : "#fff", // Change to desired text color for disabled state
                cursor:
                  rowData.billType.toLowerCase() === "refund"
                    ? "not-allowed"
                    : "pointer", // Change cursor to indicate disabled state
                opacity:
                  rowData.billType.toLowerCase() === "refund" ? "0.6" : "1", // Adjust opacity for disabled state
              }}
            >
              <RiRefund2Line className="table-action-icon" />
            </button>
          </Link>
          <button
            type="button"
            className="actions-btn"
            onClick={() => fetchPDF(rowData.billId, "preview")}
            title="View Bill PDF"
          >
            <FaEye className="table-action-icon" />
          </button>
          <button
            type="button"
            className="actions-btn"
            onClick={() => fetchPDF(rowData.billId, "download")}
            title="Download Bill PDF"
          >
            <FaDownload className="table-action-icon" />
          </button>
          <button
            type="button"
            className="actions-btn"
            onClick={() => onDelete(rowData)}
            title="Delete Bill"
          >
            <FaTrash className="table-action-icon" />
          </button>
        </div>
      );
    };

    return (
      <>
        <DataTable
          value={recentBillsData ?? []}
          selection={selectedRecentBills}
          onSelectionChange={(e) => setSelectedRecentBills(e.value)}
          dataKey="billId"
          tableStyle={{ minWidth: "25rem" }}
          pt={{
            root: { className: "table" },
          }}
          selectionMode="single"
          emptyMessage="No records found"
        >
          <Column
            field="invoiceReferenceNumber"
            header="Reference No"
            body={(rowData) =>
              rowData.invoiceReferenceNumber ? <span title={"Bill Id - " + rowData.billId}>{rowData.invoiceReferenceNumber}</span> : ""
            }
          ></Column>
          {/* <Column field="billId" header="Bill Number"></Column> */}
          <Column field="customerName" header="Patient Name"></Column>
          {/* <Column field="billDetail" header="Bill Detail"></Column> */}
          <Column field="netPayment" header="Amount"></Column>
          <Column
            field="billType"
            header="Bill Type"
            body={(rowData) => (
              <span
                style={{
                  color:
                    rowData.billType.toLowerCase() === "refund"
                      ? "red"
                      : "black",
                }}
              >
                {rowData.billType}
              </span>
            )}
          ></Column>

          <Column
            field="createDateTime"
            header="Bill Creation Date"
            dataType="date"
            body={(rowData) => {
              try {
                return Common.getFormatedDate(
                  rowData.createDateTime,
                  "DD/MM/YYYY hh:mm:ss A",
                  true
                );
              } catch (error) {
                console.error(error.message);
                return "Invalid Date";
              }
            }}
          ></Column>
          <Column
            className="d-flex align-items-center justify-content-center text-center"
            header="Actions"
            body={(rowData) => <ActionTemplate rowData={rowData} />}
            exportable={false}
          />
        </DataTable>
        <Col className="text-center">
          {loadingMore ? (
            <ChildLoader />
          ) : (
            <LoadMore
              totalData={recentBillsData?.length}
              handleLoadMore={() => loadMoreData()}
            />
          )}
        </Col>
      </>
    );
  };

  return (
    <>
      <div className="content w-100">
        <Col lg={12} md={12} xs={12}>
          <div>
            <Card className="dashboard-inventory-tile">
              <CardBody>
                <div className="d-flex align-items-center justify-content-between pb-2">
                  <div className="poppins-title recent-bills-title">
                    {/* <Link to={ADMIN_URL_RECENT_BILLS} className="table-title-link"> */}
                    Recent Bills
                    {/* </Link> */}
                  </div>
                  <Button
                    color="default"
                    className="show-more-button"
                    onClick={redirectToRecentBills}
                  >
                    Show More
                  </Button>
                </div>
                {loader ? <ChildLoader /> : <RecentBills />}
              </CardBody>
            </Card>
          </div>
        </Col>
      </div>
    </>
  );
};

export default Bills;
