import Common from 'helpers/Common';
import { ADMIN_URL_DASHBOARD } from 'helpers/Paths';
import { AUTH_URL_LOGIN } from 'helpers/Paths';
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
    const isLogin = Common.getJwtToken();
    // determine if authorized, from context or however you're doing it
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isLogin ? <Outlet /> : <Navigate to={AUTH_URL_LOGIN} />;
}

export default ProtectedRoute;