import Common from "helpers/Common";
import { useLoginStore } from "hooks/Login";
import { useNotificationStore } from "hooks/Notification";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
// reactstrap components
import { Button, Card, Col, CustomInput, FormGroup, Input, Label, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "react-switch";
import PageLoader from "components/Loader/PageLoader";
import { useCompanyStore } from "hooks/Company";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";


const TempData = [
  {
    "scheduleName": "Test",
    "customerEmail": "ajitksolanki@gmail.com",
    "frequency": "Weekend",
    "fromDate": "18-08-2024",
    "toDate": "19-08-2024",
    "status": "true"
  }
]


const NotificationScheduleList = (props) => {
  const [
    { notificationList },
    { getNotificationList },
    { updateNotificationStatus },
  ] = useNotificationStore();
  const [{ customerList }, { }, { }] = useCompanyStore();

  const [loader, setLoader] = useState(false);

  const ActionTemplate = ({ rowData }) => {
    return (
      <div className="d-flex justify-content-around w-100">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            props.notificationUpdateClick(
              rowData?.notificationSettingID
            )
          }}
        >
          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" size="lg" />
        </button>
      </div>
    );
  };

  const SwitchTemplate = ({ rowData }) => {
    return (
      <div className="d-flex justify-content-around w-100">
        <CustomInput
          type="switch"
          onChange={(e) => handleStatusChange(rowData?.notificationSettingID, e)}
          checked={rowData.isActive}
        />
      </div>
    )
  }


  useEffect(() => {
    async function fetchNotificationList() {
      setLoader(true);

      let typeid =
        props.notificationTypeId == 0
          ? 1
          : props.notificationTypeId == 1
            ? 3
            : props.notificationTypeId == 2
              ? 2
              : 0;
      await getNotificationList({
        NotificationTypeID: typeid,
      });
      setLoader(false);
    }
    fetchNotificationList();
  }, [props.notificationTypeId]);

  const handleStatusChange = async (notificationSettingID, checked) => {
    console.log(checked.target.checked);
    await updateNotificationStatus({
      notificationSettingID: notificationSettingID,
      isactive: checked,
    });

    await getNotificationList({
      NotificationTypeID: props.notificationTypeId + 1,
    });
  };

  const getCustomerEmail = (customerIds) => {
    let selectedIds = customerIds.split(",");
    let selectedCustomers = [];

    if (customerList?.length > 0) {
      if (props.notificationTypeId === 0) {
        selectedCustomers = customerList
          .filter((customer) =>
            selectedIds.includes(customer.customerID.toString())
          )
          .map((user) => user.emailId)
          .join(",");
      } else {
        selectedCustomers = customerList
          .filter((customer) =>
            selectedIds.includes(customer.customerID.toString())
          )
          .map((user) => user.phoneNumber)
          .join(",");
      }
    }
    return selectedCustomers;
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        // <Card
        //   body
        //   color="secondary"
        //   outline
        //   style={{
        //     width: "100%",
        //   }}
        // >
        //   <div className="d-flex justify-content-between">
        //     <h6 className="title">Notification List</h6>
        //   </div> 
        //   <div style={{ overflowX: "auto" }}>
        //     <table className="custom-table custom-table-font">
        //       <tr>
        //         <th>Schedule Name</th>
        //         <th>
        //           {props.notificationTypeId === 0
        //             ? "Customer Email"
        //             : "Customer Number"}
        //         </th>
        //         <th style={{ width: "100px" }}>Frequency</th>
        //         <th style={{ width: "150px" }}>From Date</th>
        //         <th style={{ width: "150px" }}>To Date</th>
        //         <th style={{ width: "180px" }}>Action</th>
        //       </tr>
        //       <tbody>
        //         {notificationList.length > 0 &&
        //           notificationList.map((v, i) => (
        //             <tr key={i}>
        //               <td>{v?.scheduleName}</td>
        //               <td>
        //                 <div
        //                   className="text-limit-70"
        //                   id={"text-limit-70-" + i}
        //                 >
        //                   {getCustomerEmail(v.to)}
        //                 </div>
        //                 {getCustomerEmail(v.to).length > 70 && (
        //                   <Tooltip
        //                     target={"#text-limit-70-" + i}
        //                     content={getCustomerEmail(v.to)}
        //                   />
        //                 )}
        //               </td>
        //               <td style={{ width: "100px" }}>{v?.frequencyName}</td>

        //               <td style={{ width: "150px" }}>
        //                 {Common.getFormatedDate(v?.fromDate, "DD-MM-YYYY")}
        //               </td>
        //               <td style={{ width: "150px" }}>
        //                 {Common.getFormatedDate(v?.toDate, "DD-MM-YYYY")}
        //               </td>
        //               <td>
        //                 <div className="d-flex">
        //                   <Button className="btn-round">
        //                     <FontAwesomeIcon
        //                       icon="fa-solid fa-pen-to-square"
        //                       size="lg"
        //                       onClick={() =>
        //                         props.notificationUpdateClick(
        //                           v?.notificationSettingID
        //                         )
        //                       }
        //                     />{" "}
        //                   </Button>
        //                   <div className="ml-1 mt-3">
        //                     <Switch
        //                       onChange={(e) =>
        //                         handleStatusChange(v?.notificationSettingID, e)
        //                       }
        //                       checked={v?.isActive}
        //                     />
        //                   </div>
        //                 </div>
        //               </td>
        //             </tr>
        //           ))}
        //       </tbody>
        //     </table>
        //   </div>
        // </Card>
        <div>
          <Row className="mt-5">
            <Col lg={12} md={12} xs={12}>
              <DataTable
                value={notificationList ?? []}
                // dataKey="branchID"
                tableStyle={{ minWidth: "25rem" }}
                pt={{
                  root: { className: "table" },
                }}
              >
                <Column field="scheduleName" header="Schedule Name"></Column>
                <Column field="customerEmail" header="Customer Email"></Column>
                <Column field="frequencyName" header="Frequency"></Column>
                <Column field="fromDate" header="From Data" body={(rowData) => {
                  try {
                    return Common.getFormatedDate(
                      rowData.fromDate,
                      "DD-MM-YYYY",
                      true
                    );
                  } catch (error) {
                    console.error(error.message);
                    return "Invalid Date";
                  }
                }}></Column>
                <Column field="toDate" header="To Date"
                  body={(rowData) => {
                    try {
                      return Common.getFormatedDate(
                        rowData.toDate,
                        "DD-MM-YYYY",
                        true
                      );
                    } catch (error) {
                      console.error(error.message);
                      return "Invalid Date";
                    }
                  }}></Column>
                <Column
                  className="text-center"
                  header="Edit"
                  body={(rowData) => <ActionTemplate rowData={rowData} />}
                  exportable={false}
                  align="center"
                />
                <Column field="status" header="Status" body={(rowData) => <SwitchTemplate rowData={rowData} />} />
              </DataTable>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default NotificationScheduleList;
