import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "components/Button/CustomButton";
import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { useLoginStore } from "hooks/Login";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row
} from "reactstrap";
import { isEmpty } from "underscore";

const BranchDetail = () => {
  const dt = useRef(null);
  const ref = useRef();

  const [
    { branchList },
    { getBranchList, getBranchDetailById },
    { createBranchDetails, updateBranchDetail },
  ] = useCompanyProfileStore();
  const [{ }, { }, { setAlerts }] = useLoginStore();
  const [branchData, setBranchData] = useState({});
  const [branchValidate, setBranchValidate] = useState({});

  useEffect(() => {
    async function getData() {
      await getBranchList();
    }
    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = { ...branchValidate };
    if (!Common.verifyLength(value, 1)) {
      error[name] = "has-danger";
    } else {
      error[name] = "has-success";
    }
    setBranchValidate(error);
    setBranchData({
      ...branchData,
      [name]: value,
    });
  };

  const saveBranchDetails = async (e) => {
    e.preventDefault();
    let error = false;
    let validate = { ...branchValidate };

    if (branchValidate && isEmpty(branchValidate)) {
      error = true;
      validate.branchName = "has-danger";
      validate.branchCode = "has-danger";
      validate.branchAddress = "has-danger";
      validate.stateName = "has-danger";
      validate.stateCode = "has-danger";
      validate.country = "has-danger";
    } else {
      if (!branchData.branchName) {
        error = true;
        validate.branchName = "has-danger";
      }
      if (!branchData.branchCode) {
        error = true;
        validate.branchCode = "has-danger";
      }
      if (!branchData.branchAddress) {
        error = true;
        validate.branchAddress = "has-danger";
      }
      if (!branchData.stateName) {
        error = true;
        validate.stateName = "has-danger";
      }
      if (!branchData.stateCode) {
        error = true;
        validate.stateCode = "has-danger";
      }
      if (!branchData.country) {
        error = true;
        validate.country = "has-danger";
      }
    }

    setBranchValidate(validate);
    if (!error) {
      let res = {};
      let param = {
        branchName: branchData.branchName,
        branchCode: branchData.branchCode,
        branchAddress: branchData.branchAddress,
        stateName: branchData.stateName,
        stateCode: branchData.stateCode,
        country: branchData.country,
      };
      if (branchData?.branchID) {
        param.branchid = branchData.branchID;

        res = await updateBranchDetail(param);
      } else {
        res = await createBranchDetails(param);
      }

      if (res) {
        await resetBranchDetail(e);
        await setAlerts({
          type: "success",
          message: notificationMessages.updateBranchSuccess,
        });
        await getBranchList();
      }
    }
  };

  const resetBranchDetail = async (ev) => {
    ev.preventDefault();
    ref.current.reset();
    setBranchData({});
  };

  const ActionTemplate = ({ rowData }) => {
    return (
      <div className="d-flex justify-content-around w-100">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => onEdit(rowData)}
        >
          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" size="lg" />
        </button>
      </div>
    );
  };

  const onEdit = async (data) => {
    let branchData = await getBranchDetailById({ branchID: data?.branchID });
    setBranchData(branchData);
  };

  return (
    <div className="profile-content-container">
      <div className="profile-content-title">
        <h5>{branchData?.branchID ? "Edit " : "Add "} Branch Detail</h5>
      </div>

      <Form className="profile-address-form" onSubmit={saveBranchDetails} innerRef={ref}>
        <Row>
          <Col md="4">
            <div className="custom-input-container">
              <label>Branch Name</label>
              <FormGroup>
                <Input
                  placeholder="Write your Branch Name"
                  type="text"
                  name="branchName"
                  valid={branchValidate?.branchName === "has-success"}
                  invalid={branchValidate?.branchName === "has-danger"}
                  value={branchData?.branchName}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>Branch name is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Branch Code</label>
              <FormGroup>
                <Input
                  placeholder="Write your branch code"
                  type="text"
                  name="branchCode"
                  valid={branchValidate?.branchCode === "has-success"}
                  invalid={branchValidate?.branchCode === "has-danger"}
                  value={branchData?.branchCode}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>Branch code is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Branch Address</label>
              <FormGroup>
                <Input
                  placeholder="Please enter branch address"
                  type="text"
                  name="branchAddress"
                  valid={branchValidate?.branchAddress === "has-success"}
                  invalid={branchValidate?.branchAddress === "has-danger"}
                  value={branchData?.branchAddress}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>Branch address is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <div className="custom-input-container">
              <label>State Name</label>
              <FormGroup>
                <Input
                  placeholder="Please enter state name"
                  type="text"
                  name="stateName"
                  valid={branchValidate?.stateName === "has-success"}
                  invalid={branchValidate?.stateName === "has-danger"}
                  value={branchData?.stateName}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>State name is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>State Code</label>
              <FormGroup>
                <Input
                  placeholder="Please enter state code"
                  type="text"
                  name="stateCode"
                  valid={branchValidate?.stateCode === "has-success"}
                  invalid={branchValidate?.stateCode === "has-danger"}
                  value={branchData?.stateCode}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>State code is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-input-container">
              <label>Country</label>
              <FormGroup>
                <Input
                  placeholder="Please enter your value here"
                  type="text"
                  name="country"
                  valid={branchValidate?.country === "has-success"}
                  invalid={branchValidate?.country === "has-danger"}
                  value={branchData?.country}
                  onChange={handleChange}
                  maxLength={100}
                />
                <FormFeedback>Country is required.</FormFeedback>
              </FormGroup>
            </div>
          </Col>
        </Row>

        <div className="mt-lg-2 profile-button-container">
          <CustomButton
            label="Save Details"
            type="submit"
          />
          <CustomButton
            label="Reset"
            onClick={resetBranchDetail}
            customStyle='profile-reset-btn'
          />
        </div>
      </Form>

      <Row className="mt-5">
        <Col lg={12} md={12} xs={12}>
          <h5 className="profile-data-table-title">Branch List</h5>
          <DataTable
            ref={dt}
            value={branchList ?? []}
            dataKey="branchID"
            tableStyle={{ minWidth: "25rem" }}
            pt={{
              root: { className: "table" },
            }}
          >
            <Column field="branchName" header="Branch Name"></Column>
            <Column field="branchCode" header="Branch Code"></Column>
            <Column field="branchAddress" header="Branch Address"></Column>
            <Column field="stateCode" header="State Code"></Column>
            <Column field="stateName" header="State Name"></Column>
            <Column field="country" header="Country"></Column>
            <Column
              className="text-center"
              header="Actions"
              body={(rowData) => <ActionTemplate rowData={rowData} />}
              exportable={false}
            />
          </DataTable>
        </Col>
      </Row>
    </div>
  );
};

export default BranchDetail;
